import { Spin } from "antd";
import { SpinSize } from "antd/es/spin";
import { FC, PropsWithChildren, useEffect, useState } from "react";

type Props = {
  loading: boolean;
  size?: SpinSize;
};

export const LoadingWrapper: FC<PropsWithChildren<Props>> = ({
  loading,
  size = "large",
  children,
}) => {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: size === "large" ? "100vh" : "25vh",
      }}
    >
      <Spin size={size} />
    </div>
  ) : (
    <>{children}</>
  );
};

export default LoadingWrapper;
