import LoadingWrapper from "@/components/loading";
import { Contact } from "@/types";
import { HttpError, useOne, useParsed } from "@refinedev/core";
import { Col, Row } from "antd";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import { ObjectNotes } from "../notes";
import { ContactInfoForm } from "./info-form";
import { ContactTitleForm } from "./title-form";

export const ContactShowPage = () => {
  const { id: entityId } = useParsed();

  const queryResult = useOne<Contact, HttpError>({
    resource: "contacts",
    id: entityId,
  });

  return (
    <LoadingWrapper loading={queryResult.isLoading}>
      <div className="page-container">
        <ContactTitleForm queryResult={queryResult} />
        <Row
          gutter={[32, 32]}
          style={{
            marginTop: 32,
          }}
        >
          <Col span={16}>
            <ObjectNotes
              reference_table="Contact"
              style={{
                marginTop: 32,
              }}
            />
          </Col>
          <Col span={8}>
            <ContactInfoForm queryResult={queryResult} />

            <TagBasedCustomFieldsTable
              taggable_object={"Contact"}
              field_reference_table={"Contact"}
              style={{
                marginTop: 32,
              }}
              show_resource="contact-types"
            />
          </Col>
        </Row>
      </div>
    </LoadingWrapper>
  );
};
