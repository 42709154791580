import React from "react";
import { Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface TextWithInfoProps {
  title: string;
  infoDetails: string;
}

export const TextWithInfo: React.FC<TextWithInfoProps> = ({
  title,
  infoDetails,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Text style={{ marginRight: 4 }}>{title}</Text>
      <Tooltip title={infoDetails} placement="right">
        <InfoCircleOutlined
          style={{
            fontSize: "14px",
            cursor: "pointer",
            transition: "color 0.3s ease",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = "#FFD700")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "")}
        />
      </Tooltip>
    </div>
  );
};
