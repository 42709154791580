import { Text } from "@/components";
import { PublicPartnerOpportunity } from "@/types";
import { RenderCustomFieldsViewCell } from "@/utilities/custom-fields-render";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { useDelete, useNavigation } from "@refinedev/core";
import type { MenuProps } from "antd";
import { Button, Card, ConfigProvider, Dropdown, Space, theme } from "antd";
import { List } from "antd/lib";
import { useMemo } from "react";

type Props = {
  partner: PublicPartnerOpportunity;
};

export const PartnerInboundCard = ({ partner }: Props) => {
  const { token } = theme.useToken();
  const { show } = useNavigation();
  const { mutate: deleteRecord } = useDelete();

  const dropdownItems = useMemo(() => {
    const dropdownItems: MenuProps["items"] = [
      {
        danger: true,
        label: "Delete Partner",
        key: "1",
        icon: <DeleteOutlined />,
        onClick: () => {
          deleteRecord({
            resource: "partners/opportunities",
            id: partner.ID,
          });
        },
      },
    ];

    return dropdownItems;
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Tag: {
            colorText: token.colorTextSecondary,
          },
          Card: {
            headerBg: "transparent",
          },
        },
      }}
    >
      <Card
        size="small"
        style={{
          borderRadius: 8,
          paddingTop: 10,
          marginBottom: 10,
        }}
        title={
          <Space
            style={{
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            <Text>{"New Partner Request"}</Text>
          </Space>
        }
        onClick={() => {
          show("partner-page", partner.ID);
        }}
        extra={
          <Dropdown
            trigger={["click"]}
            menu={{
              items: dropdownItems,
              onPointerDown: (e) => {
                e.stopPropagation();
              },
              onClick: (e) => {
                e.domEvent.stopPropagation();
              },
            }}
            placement="bottom"
            arrow={{ pointAtCenter: true }}
          >
            <Button
              type="text"
              shape="circle"
              icon={
                <MoreOutlined
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              }
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        }
      >
        <List
          dataSource={partner.custom_fields.slice(0, 3)}
          renderItem={(field) => (
            <List.Item
              style={{
                paddingTop: 2,
              }}
            >
              <List.Item.Meta
                title={<Text strong>{field.name}</Text>}
                description={RenderCustomFieldsViewCell(field)}
              />
            </List.Item>
          )}
          style={{
            paddingLeft: 8,
            paddingRight: 8,
          }}
        />
        {partner.custom_fields.length > 3 && (
          <Text strong>{"Click to see more"}</Text>
        )}
      </Card>
    </ConfigProvider>
  );
};
