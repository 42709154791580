import { AvatarGroup, CustomAvatar, PaginationTotal, Text } from "@/components";
import { PartnerInboundTable } from "@/pages/partner_inbound";
import { useUserPreference } from "@/providers/identity";
import {
  PartnerRecord,
  PublicPartnerOpportunity,
  Tag,
  transformTags,
} from "@/types";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { DateField, EditButton, useTable } from "@refinedev/antd";
import {
  CrudFilters,
  CrudSorting,
  getDefaultFilter,
  HttpError,
  useList,
  useUpdate,
} from "@refinedev/core";
import { Card, Select, Space, Table, TableProps } from "antd";
import { FC, useEffect, useState } from "react";

type Props = {
  tableProps: TableProps<PartnerRecord>;
  statusOptions: { label: string; value: number }[];
  filters: CrudFilters;
  sorters: CrudSorting;
};

export const PartnersTableView: FC<Props> = ({
  tableProps,
  filters,
  statusOptions,
}) => {
  const { mutate } = useUpdate();
  const preference = useUserPreference();

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Company",
      },
    ],
    pagination: {
      mode: "off",
    },
  });
  const tagOptions = transformTags(tags?.data);

  const [selectedStatusValue, setSelectedStatusValue] = useState<
    number | undefined
  >();
  const [selectedTypeValue, setSelectedTypeValue] = useState<
    number | undefined
  >();

  // Update selectedValue when preference changes
  useEffect(() => {
    if (preference?.default_partner_filter !== undefined) {
      setSelectedStatusValue(preference.default_partner_filter);
    }

    if (preference?.default_partner_type_filter !== undefined) {
      setSelectedTypeValue(preference.default_partner_type_filter);
    }
  }, [preference]);

  const handleSelectStatusChange = (newValue: number) => {
    setSelectedStatusValue(newValue);
    mutate({
      resource: "user/preference",
      values: {
        default_partner_filter: newValue,
      },
      id: "0",
      successNotification: false,
    });
  };

  const handleSelectTypeChange = (newValue: number) => {
    setSelectedTypeValue(newValue);
    mutate({
      resource: "user/preference",
      values: {
        default_partner_type_filter: newValue,
      },
      id: "0",
      successNotification: false,
    });
  };

  const handleStatusChange =
    (partnerRecordId: number) => (newValue: number) => {
      mutate({
        resource: "partners",
        values: {
          status_id: newValue,
        },
        id: partnerRecordId,
        successNotification: false,
      });
    };

  const filteredData = tableProps?.dataSource
    ?.filter((record) =>
      selectedStatusValue == 0 ? true : record.status_id == selectedStatusValue
    )
    .filter((record) =>
      selectedTypeValue == 0
        ? true
        : record?.tags?.findIndex((x) => x.ID == selectedTypeValue) > -1
    );

  return (
    <div>
      <InboundPartnersTableView />
      <Card
        title={
          <Space direction="horizontal" style={{ padding: "2px" }}>
            <Space direction="horizontal">
              <Text>Status: </Text>
              <Select
                options={[{ label: "No Filter", value: 0 }, ...statusOptions]}
                value={selectedStatusValue}
                style={{ width: "200px" }}
                onChange={handleSelectStatusChange}
              />
            </Space>
            <Space direction="horizontal" style={{ marginLeft: "20px" }}>
              <Text>Partner Types: </Text>
              <Select
                options={[{ label: "No Filter", value: 0 }, ...tagOptions]}
                value={selectedTypeValue}
                style={{ width: "200px" }}
                onChange={handleSelectTypeChange}
              />
            </Space>
          </Space>
        }
      >
        <Table
          {...tableProps}
          dataSource={filteredData}
          pagination={{
            pageSizeOptions: ["10", "50", "100", "200"],
            showSizeChanger: true,
            showTotal: (total) => (
              <PaginationTotal total={total} entityName="companies" />
            ),
          }}
          rowKey="ID"
        >
          <Table.Column<PartnerRecord>
            fixed="right"
            dataIndex="ID"
            width={40}
            render={(_, record) => (
              <Space>
                <EditButton
                  icon={<EyeOutlined />}
                  hideText
                  size="small"
                  recordItemId={record.ID}
                />

                {/*<DeleteButton hideText size="small" recordItemId={value} />*/}
              </Space>
            )}
          />
          <Table.Column<PartnerRecord>
            dataIndex="company_name"
            title="Company"
            defaultFilteredValue={getDefaultFilter("id", filters)}
            filterIcon={<SearchOutlined />}
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    shape="square"
                    name={record.partner_company.CompanyName}
                    src={record.partner_company.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.partner_company.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex={["owner_id"]}
            title="Owner"
            defaultFilteredValue={getDefaultFilter("owner_id", filters)}
            sorter
            render={(_, record) => {
              const owner = record.owner;
              return (
                <Space>
                  <CustomAvatar name={owner.name} />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {owner.name}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex={["contact_id"]}
            title="Contact"
            defaultFilteredValue={getDefaultFilter("contact_id", filters)}
            render={(_, record) => {
              const avatars =
                record?.contacts?.map((contact) => {
                  return {
                    name: contact.person?.name as string | undefined,
                    src: contact.person?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex="status"
            title="Status"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Select
                    options={statusOptions}
                    value={record.status_id}
                    style={{ width: "100%" }}
                    onChange={handleStatusChange(record.ID)}
                  />
                </Space>
              );
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex="tags"
            title="Partner Types"
            render={(_, record) => {
              return (
                <Space>
                  <Text>{record?.tags?.map((tag) => tag.Name).join(", ")}</Text>
                </Space>
              );
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex="last_touch"
            title="Last Meeting"
            sorter
            render={(_, record) => {
              return !record.last_touch.Valid ? (
                <Text>-</Text>
              ) : (
                <DateField format="YYYY-MM-DD" value={record.last_touch.Time} />
              );
            }}
          />

          <Table.Column<PartnerRecord>
            dataIndex="next_meeting"
            title="Next Meeting"
            sorter
            render={(_, record) => {
              return !record.next_meeting.Valid ? (
                <Text>-</Text>
              ) : (
                <DateField
                  format="YYYY-MM-DD"
                  value={record.next_meeting.Time}
                />
              );
            }}
          />
        </Table>
      </Card>
    </div>
  );
};

const InboundPartnersTableView: FC = () => {
  const { tableProps, tableQuery } = useTable<
    PublicPartnerOpportunity,
    HttpError
  >({
    resource: `partners/pages/list_inbound/0`,
    pagination: {
      mode: "off",
    },
  });

  if (tableQuery?.data?.data?.length === 0) {
    return <div />;
  }
  return (
    <Card
      title="Pending"
      bordered={false}
      style={{ width: "100%", marginBottom: "20px" }}
    >
      <PartnerInboundTable tableProps={tableProps} />
    </Card>
  );
};
