import { ListTitleButton, Text } from "@/components";
import { Prompt } from "@/types";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";

export const PromptsListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<Prompt, HttpError>({
    resource: "prompts",
    pagination: {
      mode: "off",
    },
  });

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={<ListTitleButton toPath="prompts" buttonText="Add new Prompt" />}
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Prompt>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  resource="prompts"
                  recordItemId={record.ID}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="prompts"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
          <Table.Column<Prompt>
            dataIndex="key"
            title="Key"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.key}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Prompt>
            dataIndex="prompt_text"
            title="Prompt Text"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.prompt_text}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
