import { DatePickerWrapper } from "@/components";
import LoadingWrapper from "@/components/loading";
import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { useOpportunityStatuses } from "@/providers/options";
import { usePeople } from "@/providers/people";
import {
  ClientRecord,
  PartnerRecord,
  PublicOpportunity,
  transformClientRecords,
  transformCompanies,
  transformPersons,
} from "@/types";
import { isValidDate } from "@/utilities";
import { FilterOption } from "@/utilities/select";
import { DollarOutlined, LeftOutlined } from "@ant-design/icons";
import {
  createPerson,
  useCreatePersonModalForm,
} from "@/providers/useCreatePerson";
import { useModalForm } from "@refinedev/antd";
import {
  useInvalidate,
  useList,
  useNavigation,
  useOne,
  useParsed,
} from "@refinedev/core";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import dayjs from "dayjs";
import CreatePersonModal from "@/providers/createPerson";
import { useEffect, useState } from "react";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import { useSalesforceConversion } from "@/utilities/useSalesforceDeduplication";

type Props = {
  isOverModal?: boolean;
  close_route?: string;
};

type OpportunityConvertRequest = {
  partner_record_id: number;
  client_record_id: number;
  opportunity_id: number;
  create_opportunity: boolean;
  approved: boolean;
};

export const ReferralsShowPage = ({
  isOverModal,
  close_route = "referrals",
}: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();

  const statusOptions = useOpportunityStatuses();
  const [reasonForm] = Form.useForm();

  const { handleCreateRecord } = useSalesforceConversion({
    mode: "referrals",
    onSuccess: () => {
      invalidate({
        resource: "opportunities",
        invalidates: ["list", "many", "detail"],
      });
      close();
      list(close_route, "replace");
      setButtonLoading(false);
    },
  });

  const {
    formProps,
    modalProps,
    close,
    submit,
    formValues,
    form: convertDealForm,
  } = useModalForm<OpportunityConvertRequest>({
    action: "create",
    defaultVisible: true,
    resource: "referral_pages/convert_from_opportunity",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partners" });
      invalidate({ invalidates: ["list"], resource: "partner-page" });
      invalidate({ invalidates: ["list"], resource: "referral_pages" });
      invalidate({ invalidates: ["list"], resource: "public_opportunities" });
      list(close_route, "replace");
    },
    successNotification: false,
  });

  const queryResult = useOne<PublicOpportunity>({
    resource: "public/opportunities",
    id: id,
  });
  const data = queryResult?.data?.data;
  const isLoading = queryResult.isLoading;

  const [partnerRecordId, setPartnerRecordId] = useState<number>(0);
  const [clientRecordOptions, setClientRecordOptions] = useState([]);

  const {
    people,
    loading,
    refetch: refetchPeople,
  } = usePeople(
    partnerRecordId !== 0 ? partnerRecordId : data?.partner_record_id
  );
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [subscriberSearchText, setSubscriberSearchText] = useState("");
  const [assigneeSearchText, setAssigneeSearchText] = useState("");

  useEffect(() => {
    if (!loading) {
      setPeopleOptions(transformPersons(people));
    }
  }, [people, loading]);

  const afterSuccess = async (personData, fieldKey) => {
    await refetchPeople();
    convertDealForm.setFieldValue(fieldKey, [
      ...(convertDealForm.getFieldValue(fieldKey) ?? []),
      personData.ID,
    ]);
  };
  const afterAssigneeSuccess = async (personData) => {
    await afterSuccess(personData, "assignees_ids");
  };

  const afterSubscriberSuccess = async (personData) => {
    await afterSuccess(personData, "subscribed_ids");
  };

  const {
    formProps: assigneeFormProps,
    modalProps: assigneeModalProps,
    show: showAssigneeModal,
    close: closeAssigneeModal,
    submit: submitAssignee,
  } = useCreatePersonModalForm(afterAssigneeSuccess);

  const {
    formProps: subscriberFormProps,
    modalProps: subscriberModalProps,
    show: showSubscriberModal,
    close: closeSubscriberModal,
    submit: submitSubscriber,
  } = useCreatePersonModalForm(afterSubscriberSuccess);

  useEffect(() => {
    if (partnerRecordId !== undefined && partnerRecordId !== 0) {
      const fetchData = async () => {
        try {
          const record = partners.data.find(
            (record) => record.ID === partnerRecordId
          );
          const companyId = record ? record.partner_company_id : 0;
          const response = await globalAxiosInstance.get<ClientRecord[]>(
            `${API_BASE_URL}/partners/client_records`,
            {
              params: { company_id: companyId },
            }
          );

          setClientRecordOptions(transformClientRecords(response?.data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [partnerRecordId]);

  const [syncedName, setSyncedName] = useState("");

  const handleValuesChange = (changedValues) => {
    if (changedValues && changedValues.partner_record_id) {
      setPartnerRecordId(changedValues.partner_record_id);
    }
    if (changedValues && changedValues.name) {
      setSyncedName(changedValues.name);
    }
  };

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners",
    filters: [
      {
        field: "hydrate_company",
        operator: "eq",
        value: true,
      },
    ],
  });
  const partnerRecordIdToUse =
    partnerRecordId !== 0 ? partnerRecordId : data?.partner_record_id;
  const partnerRecord = partners?.data.find(
    (r) => r.ID === partnerRecordIdToUse
  );
  useEffect(() => {
    const companies = partnerRecord
      ? [partnerRecord.company, partnerRecord.partner_company]
      : [];
    setCompanyOptions(transformCompanies(companies));
  }, [partnerRecord]);
  const partnerOptions = transformPartnerRecords(partners?.data);

  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reason, setReason] = useState("");

  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      okText={buttonLoading ? "Approving..." : "Approve"}
      onCancel={() => {
        close();
        list(close_route, "replace");
      }}
      title="Convert Deal Opportunity"
      width={"80%"}
      closeIcon={<LeftOutlined />}
      onOk={() => {
        formProps.form
          .validateFields()
          .then((values: OpportunityConvertRequest) => {
            formProps.form.setFieldsValue({ approved: true });
            setButtonLoading(true);
            if (values.create_opportunity) {
              handleCreateRecord(+id, {
                ...values,
                approved: true,
                opportunity_id: +id,
              }).catch(() => {
                // Reset loading state if there's an error
                setButtonLoading(false);
              });
            } else {
              submit().finally(() => {
                setButtonLoading(false);
              });
            }
          });
      }}
      okButtonProps={{
        style: {
          backgroundColor: "#64c49c",
          borderColor: "#64c49c",
          boxShadow: "0 2px 0 #64c49c",
        },
        loading: buttonLoading,
      }}
      footer={(originNode) => (
        <>
          {originNode}
          <Button
            type="primary"
            style={{
              backgroundColor: "#FF6666",
              borderColor: "#FF6666",
              boxShadow: "0 2px 0 #FF6666",
            }}
            onClick={() => setShowReasonModal(true)}
          >
            Reject
          </Button>
        </>
      )}
    >
      <LoadingWrapper loading={isLoading}>
        <TagBasedCustomFieldsTable
          override_taggable_object={"Opportunity"}
          override_taggable_object_id={data?.referral_page_id}
          taggable_object={"ReferralPageInfos"}
          field_reference_table={"PublicOpportunity"}
          override_object_id={+id}
          style={{
            marginTop: 8,
          }}
          show_resource="opportunity-types"
          collapsable={true}
        />
        <Form
          {...formProps}
          layout="vertical"
          style={{ marginTop: "40px" }}
          onValuesChange={handleValuesChange}
          initialValues={{
            create_opportunity: false,
            partner_record_id: data?.partner_record_id,
            name: syncedName,
          }}
        >
          <Form.Item
            hidden
            initialValue={+id}
            name="opportunity_id"
          ></Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            label="Opportunity Name"
            name="name"
          >
            <Input />
          </Form.Item>

          <Form.Item label="Stage" name="status" rules={[{ required: true }]}>
            <Select options={statusOptions} />
          </Form.Item>

          <Form.Item
            label="Partner"
            name="partner_record_id"
            rules={[{ required: true }]}
          >
            <Select
              options={partnerOptions}
              showSearch={true}
              filterOption={FilterOption}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Client" name="client_record_id">
            <Select
              options={clientRecordOptions}
              style={{ width: "100%" }}
              showSearch={true}
              filterOption={FilterOption}
            />
          </Form.Item>

          {convertDealForm.getFieldValue("partner_record_id") && (
            <Form.Item label="Subscribed" name="subscribed_ids">
              <Select
                mode="multiple"
                allowClear
                showSearch={true}
                options={peopleOptions}
                onSearch={(input) => setSubscriberSearchText(input)}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Subscribed"
                notFoundContent={
                  <Button
                    type="primary"
                    onClick={() => showSubscriberModal(subscriberSearchText)}
                  >
                    Add New Subscriber
                  </Button>
                }
              />
            </Form.Item>
          )}
          <CreatePersonModal
            title="Add New Subscriber"
            formProps={subscriberFormProps}
            modalProps={subscriberModalProps}
            onFinish={(values) => createPerson(values, submitSubscriber)}
            companyOptions={companyOptions}
            closeModal={closeSubscriberModal}
          />

          {convertDealForm.getFieldValue("partner_record_id") && (
            <Form.Item label="Assignees" name="assignees_ids">
              <Select
                mode="multiple"
                allowClear
                showSearch={true}
                onSearch={(input) => setAssigneeSearchText(input)}
                options={peopleOptions}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Assignees"
                notFoundContent={
                  <Button
                    type="primary"
                    onClick={() => showAssigneeModal(assigneeSearchText)}
                  >
                    Add New Assignee
                  </Button>
                }
              />
            </Form.Item>
          )}
          <CreatePersonModal
            title="Add New Assignee"
            formProps={assigneeFormProps}
            modalProps={assigneeModalProps}
            onFinish={(values) => createPerson(values, submitAssignee)}
            companyOptions={companyOptions}
            closeModal={closeAssigneeModal}
          />
          <Form.Item label="Contract Value" name="contract_value">
            <InputNumber
              min={0}
              addonBefore={<DollarOutlined />}
              placeholder="0,00"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
          <Form.Item
            label="Close Date"
            name="close_date"
            rules={[{ required: true }]}
            initialValue={dayjs()}
          >
            <DatePickerWrapper />
          </Form.Item>
          {isValidDate(data?.expiration_date) && (
            <Form.Item
              label="Expiration Date"
              name="expiry_notificaton_date"
              initialValue={dayjs(data?.expiration_date)}
            >
              <DatePickerWrapper />
            </Form.Item>
          )}
          <Form.Item
            label="Create Record in CRM"
            name="create_opportunity"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </LoadingWrapper>

      {showReasonModal && (
        <Modal
          title="Enter Details for Rejection"
          open={showReasonModal}
          onCancel={() => setShowReasonModal(false)}
          onOk={() => {
            reasonForm
              .validateFields() // This triggers form validation
              .then(() => {
                formProps.form.setFieldsValue({ reason: reason });
                formProps.form.setFieldsValue({ name: syncedName });
                formProps.form.setFieldsValue({
                  partner_record_id:
                    reasonForm.getFieldValue("partner_record_id"),
                });
                formProps.form.setFieldsValue({ approved: false });
                submit();
              });
          }}
        >
          <Form
            form={reasonForm}
            layout="vertical"
            initialValues={{
              name: syncedName,
            }}
          >
            <Form.Item
              name="name"
              label="Opportunity Name"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter an opportunity name for tracking purposes",
                },
              ]}
            >
              <Input
                placeholder="Opportunity Name"
                value={syncedName} // Ensure the Opportunity Name is in sync
                onChange={(e) => setSyncedName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Partner"
              name="partner_record_id"
              rules={[{ required: true }]}
            >
              <Select
                options={partnerOptions}
                showSearch={true}
                filterOption={FilterOption}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="reason"
              label="Reason for Rejection"
              rules={[
                {
                  required: true,
                  message: "Please enter a reason for rejection",
                },
              ]}
            >
              <Input
                placeholder="Enter reason for rejection"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Modal>
  );
};

function transformPartnerRecords(
  records: PartnerRecord[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.partner_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
