import { CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { IDocument } from "@/types";
import { EyeOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { DateField, useTable } from "@refinedev/antd";
import { HttpError, useOne } from "@refinedev/core";
import { Button, Card, Modal, Space, Table } from "antd";
import debounce from "lodash/debounce";
import { useRef, useState } from "react";
import { DownloadDocumentButton } from "../resources";

export const PartnerResourcesPage = () => {
  return (
    <div>
      <Card title="Shared With Me" bordered={false} style={{ width: "100%" }}>
        <PartnerResources />
      </Card>

      {/*
      <Card
        title="My Resources"
        bordered={false}
        style={{ width: "100%", marginTop: "20px" }}
      >
        <ResourcesComponent />
      </Card>
      */}
    </div>
  );
};

export const PartnerResources = () => {
  const [modalDocId, setModalDocId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (docId: number) => {
    setModalDocId(docId);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const { tableProps } = useTable<IDocument>({
    resource: "partner_documents",
    syncWithLocation: false,
    pagination: {
      mode: "off",
    },
  });

  return (
    <div>
      <Table {...tableProps} rowKey="ID">
        <Table.Column<IDocument>
          dataIndex="Company"
          title="Shared By"
          render={(_, record) => {
            return (
              <Space>
                <CustomAvatar
                  name={record?.company?.CompanyName}
                  src={record?.company?.PhotoUrl}
                />
                <Text
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {record?.company.CompanyName}
                </Text>
              </Space>
            );
          }}
        />
        <Table.Column<IDocument> dataIndex="Name" title="File Name" />
        <Table.Column<IDocument>
          dataIndex="CreatedAt"
          title="Uploaded On"
          render={(value) => {
            return (
              <Space
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <DateField format="LL" value={value} />
              </Space>
            );
          }}
        />
        <Table.Column<IDocument>
          title="Actions"
          key="actions"
          render={(_, record, index) => {
            return (
              <Space size="small">
                <Button
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={() => showModal(record.ID)}
                />
                <DownloadDocumentButton
                  record={record}
                  onDownloadComplete={debounce(() => {
                    globalAxiosInstance.post(API_BASE_URL + "/metrics", {
                      reference_id: record.ID,
                      reference_table: "Document",
                      key: "Download",
                      value: 1,
                    });
                  }, 500)}
                />
              </Space>
            );
          }}
        />
      </Table>

      {modalDocId !== 0 && (
        <DocumentViewerModal
          isVisible={isModalVisible}
          onClose={handleModalClose}
          documentId={modalDocId}
        />
      )}
    </div>
  );
};

interface DocumentViewerModalProps {
  isVisible: boolean;
  onClose: () => void;
  documentId: number;
}

const DocumentViewerModal: React.FC<DocumentViewerModalProps> = ({
  isVisible,
  onClose,
  documentId,
}) => {
  const { data, isLoading } = useOne<IDocument, HttpError>({
    resource: "document",
    id: documentId,
  });

  const videoRef = useRef(null);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [viewed, setViewed] = useState(false);
  const [startTime, setStartTime] = useState(0);

  const [viewDuration, setViewDuration] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleDocumentLoad = debounce(() => {
    if (!viewed) {
      globalAxiosInstance.post(API_BASE_URL + "/metrics", {
        reference_id: documentId,
        reference_table: "Document",
        key: "View",
        value: 1,
      });
      setViewed(true);
    }
  }, 500);

  const stopVideo = () => {
    if (videoRef?.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleModalClose = () => {
    stopVideo(); // Stop the video when modal is closed
    handleVideoPause();
    if (viewDuration > 0) {
      globalAxiosInstance.post(API_BASE_URL + "/metrics", {
        reference_id: documentId,
        reference_table: "Document",
        key: "Duration",
        value: Math.round(viewDuration),
      });
      setViewDuration(0);
      setStartTime(0);
    }
    setViewed(false);
    onClose(); // Close the modal
  };

  const handleVideoPlay = () => {
    setStartTime(Date.now()); // Record start time for playback duration
  };

  const handleVideoPause = () => {
    if (startTime > 0) {
      const endTime = Date.now();
      const duration = endTime - startTime; // Calculate duration in seconds
      setViewDuration(duration + viewDuration);
    }
    setStartTime(0);
  };

  const renderContent = () => {
    if (data?.data) {
      switch (data.data.ContentType) {
        case "application/pdf":
          return (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  width: "100%",
                }}
              >
                <Viewer
                  fileUrl={data.data.Url}
                  onDocumentLoad={handleDocumentLoad}
                />
              </div>
            </Worker>
          );
        case "image":
          return (
            <img
              src={data.data.Url}
              alt="Document Image"
              style={{ width: "100%", height: "auto" }}
              onLoad={handleDocumentLoad}
            />
          );
        case "video/mp4":
          return (
            <video
              ref={videoRef}
              controls
              preload="auto"
              style={{ width: "100%" }}
              onCanPlay={handleDocumentLoad}
              onPlay={handleVideoPlay}
              onPause={handleVideoPause}
            >
              <source src={data.data.Url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          );
        default:
          return <Text>Unsupported document type.</Text>;
      }
    } else {
      return <Text>Document details not available.</Text>;
    }
  };

  return (
    <Modal
      title={data?.data?.Name}
      open={isVisible}
      onCancel={handleModalClose}
      footer={null}
      width="80%"
    >
      <LoadingWrapper loading={isLoading}>{renderContent()}</LoadingWrapper>
    </Modal>
  );
};
