import { DatePickerWrapper } from "@/components";
import "@/pages/partners/create-higlight-input.css";
import { ClientRecord, Opportunity, PartnerRecord } from "@/types";
import { FilterOption } from "@/utilities/select";
import { DollarOutlined, LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { DatePicker, Form, InputNumber, Modal, Select } from "antd";
import dayjs from "dayjs";

type Props = {
  isOverModal?: boolean;
};

export const DealDeskCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { formProps, modalProps, close } = useModalForm<
    Opportunity,
    HttpError,
    Opportunity
  >({
    action: "create",
    defaultVisible: true,
    resource: "opportunities",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "deal-desk" });
      list("deal-desk", "replace");
    },
  });

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "reverse_partners_list",
  });
  const partnerOptions = transformPartnerRecords(partners?.data);

  const { data: clients } = useList<ClientRecord>({
    pagination: {
      mode: "off",
    },
    resource: "client_records",
  });
  const clientRecordOptions = transformClientRecords(clients?.data);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("deal-desk", "replace");
      }}
      title="Add new opportunity"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Partner"
          name="partner_record_id"
          rules={[{ required: true }]}
        >
          <Select
            options={partnerOptions}
            showSearch={true}
            filterOption={FilterOption}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Client"
          name="client_record_id"
          rules={[{ required: true }]}
        >
          <Select
            options={clientRecordOptions}
            showSearch={true}
            filterOption={FilterOption}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label="Contract Value" name="contract_value">
          <InputNumber
            min={0}
            addonBefore={<DollarOutlined />}
            placeholder="0,00"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>

        <Form.Item
          label="Close Date"
          name="close_date"
          rules={[{ required: true }]}
          initialValue={dayjs()}
        >
          <DatePickerWrapper />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function transformPartnerRecords(
  records: PartnerRecord[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

function transformClientRecords(
  records: ClientRecord[] | undefined
): { label: string; value: number }[] {
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.client_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
