import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers/data";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { ClientRecord, Company } from "@/types";
import { ClientRecordStatusOptions } from "@/types/client_record_status";
import {
  CheckCircleOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useNavigation } from "@refinedev/core";
import { Form, Input, InputNumber, Modal, Select, Tooltip } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import isFQDN from "validator/lib/isFQDN";

type Props = {
  isOverModal?: boolean;
};

export const ClientRecordCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { form, formProps, modalProps, close } = useModalForm<
    ClientRecord,
    HttpError,
    ClientRecord
  >({
    action: "create",
    defaultVisible: true,
    resource: "client_records",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "clients" });
      list("clients", "replace");
    },
  });

  const [domain, setDomain] = useState();
  const [found, setFound] = useState(false);

  useEffect(() => {
    if (domain !== undefined) {
      if (domain === "") {
        form?.setFieldsValue({
          client_company_id: 0,
        });
        setFound(false);
      } else if (isFQDN(domain)) {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<Company>(
              `${API_BASE_URL}/companies/search`,
              {
                params: { domain: domain },
              }
            );

            if (response.data && response.data.ID !== 0) {
              form?.setFieldsValue({
                client_company: response.data,
                client_company_id: response.data.ID,
              });
              setFound(true);
            } else {
              form?.setFieldsValue({
                client_company: {
                  Domain: domain,
                },
                client_company_id: 0,
              });
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [domain]);

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setDomain(changedValues.domain);
    }
  }, 500);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("clients", "replace");
      }}
      title="Add new client"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Please enter a domain"
          name="domain"
          rules={[{ required: true }]}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              placeholder="Please enter a domain"
              style={{ marginRight: 8 }}
            />
            {found ? (
              <Tooltip title="Domain found in our database">
                <CheckCircleOutlined style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Domain not found in our database">
                <QuestionCircleOutlined style={{ color: "gray" }} />
              </Tooltip>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label="Client name"
          name={["client_company", "CompanyName"]}
          rules={[{ required: true }]}
        >
          <Input
            className="readonlyable-input"
            readOnly={found}
            placeholder="Please enter company name"
          />
        </Form.Item>

        <Form.Item hidden={true} name={["client_company_id"]}>
          <InputNumber />
        </Form.Item>

        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select
            options={ClientRecordStatusOptions}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
