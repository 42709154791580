import { FC, PropsWithChildren } from "react";
import { ObjectMeetings } from "./list";

export const MeetingListPage: FC<PropsWithChildren> = () => {
  return (
    <ObjectMeetings
      reference_table="PartnerRecord"
      style={{
        marginTop: 32,
      }}
      showCreation={false}
    />
  );
};
