import { Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import {
  DataConnection,
  DefaultField,
  SalesforceCustomField,
  SalesforceCustomObject,
} from "@/types";
import {
  SalesforceObjectType,
  SalesforceObjectTypeOptions,
} from "@/types/salesforce_object_type";
import { FilterOption } from "@/utilities/select";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  DeleteButton,
  EditButton,
  SaveButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useCreateMany,
  useList,
  useNavigation,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { FC, PropsWithChildren, useState } from "react";
import { ObjectSyncIcon } from "../sync";
import { TextWithInfo } from "@/components/text-with-info";

export interface FieldOption {
  label: string;
  value: string;
  type: string;
}

export const CustomFieldsExternalList: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data: dataConnections, isLoading: dataConnectionsLoading } =
    useList<DataConnection>({
      pagination: {
        mode: "off",
      },
      filters: [
        {
          field: "provider_config_key",
          operator: "eq",
          value: "salesforce",
        },
      ],
      resource: "data_connections",
    });
  const hasConnections = dataConnections?.data?.length > 0;
  const { push } = useNavigation();
  return (
    <div className="page-container">
      <LoadingWrapper loading={dataConnectionsLoading}>
        {!hasConnections ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              padding: "50px",
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description={
                <Text style={{ fontSize: "18px", marginTop: "10px" }}>
                  No CRM is connected yet!
                  <br />
                  Connect your CRM to get started.
                </Text>
              }
            />
            <Button
              type="primary"
              size="large"
              style={{ marginTop: "20px" }}
              onClick={() => push("/connections/catalog")}
            >
              Connect CRM
            </Button>
          </div>
        ) : (
          <Tabs defaultActiveKey="1" type="line">
            <TabPane
              tab={
                <TextWithInfo
                  title="Field Mapping"
                  infoDetails="This tab controls how different fields gets synced from Propel to your underlying CRM."
                />
              }
              key="1"
            >
              <CustomFieldsMappingTable />
            </TabPane>
            <TabPane
              tab={
                <TextWithInfo
                  title="Object Mapping"
                  infoDetails="This tab allows you to map different Propel objects to your CRM objects."
                />
              }
              key="2"
            >
              <CustomObjectsMappingTable />
            </TabPane>
            <TabPane
              tab={
                <TextWithInfo
                  title="Default Fields"
                  infoDetails="This tab controls which fields are synced by default when creating new objects in Propel."
                />
              }
              key="3"
            >
              <DefaultFieldsTable />
            </TabPane>
          </Tabs>
        )}
      </LoadingWrapper>
      {children}
    </div>
  );
};

export const CustomFieldsMappingTable: FC<PropsWithChildren> = ({
  children,
}) => {
  const { mutate } = useUpdate();
  const [selectedStatusValue, setSelectedStatusValue] =
    useState<SalesforceObjectType>("ACCOUNT");

  const { tableProps } = useTable<
    SalesforceCustomField,
    HttpError,
    { name: string }
  >({
    resource: "salesforce_custom_fields",
    filters: {
      permanent: [
        {
          field: "reference_table",
          operator: "eq",
          value: selectedStatusValue,
        },
      ],
      mode: "server",
    },
    pagination: {
      mode: "off",
    },
    syncWithLocation: false,
  });

  const { data: options } = useList<FieldOption>({
    resource: "salesforce_custom_fields_options",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: selectedStatusValue,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const fieldOptions = options?.data;

  const handleSelectStatusChange = (newValue: SalesforceObjectType) => {
    setSelectedStatusValue(newValue);
  };

  const handleFieldMappingUpdate = (recordId: number) => (newValue: string) => {
    let updateValues = {};
    if (newValue && newValue.startsWith("cf_")) {
      const customFieldId = newValue.slice(3);
      const parsedNumber = parseInt(customFieldId, 10);

      if (!isNaN(parsedNumber)) {
        updateValues = {
          custom_field_id: parsedNumber,
          native_field: "",
        };
      }
    } else {
      updateValues = {
        custom_field_id: null,
        native_field: newValue ?? "",
      };
    }

    mutate({
      resource: "salesforce_custom_fields",
      values: updateValues,
      id: recordId,
      successNotification: false,
    });
  };

  const handleDirectionUpdate = (recordId: number) => (newValue: string) => {
    mutate({
      resource: "salesforce_custom_fields",
      values: {
        sync_direction: newValue,
      },
      id: recordId,
      successNotification: false,
    });
  };

  return (
    <div className="page-container">
      <Card
        title={
          <Space direction="horizontal" size={0}>
            <Space
              direction="horizontal"
              style={{ marginTop: "10px", padding: "2px" }}
            >
              <ObjectSyncIcon name={""} />

              <Text style={{ marginLeft: "10px" }}>Object Type: </Text>
              <Select
                options={SalesforceObjectTypeOptions}
                value={selectedStatusValue}
                style={{ width: "200px" }}
                onChange={handleSelectStatusChange}
              />
            </Space>
          </Space>
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<SalesforceCustomField>
            dataIndex="name"
            title="Name"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.name}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<SalesforceCustomField>
            dataIndex="label"
            title="Label"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.label}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<SalesforceCustomField>
            dataIndex="type"
            title="Type"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.type}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<SalesforceCustomField>
            dataIndex="relationship_name"
            title="Relationship Name"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.relationship_name}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<SalesforceCustomField>
            dataIndex="sync_direction"
            title="Sync Direction"
            render={(_, record) => {
              return (
                <Space>
                  <Select
                    value={record.sync_direction}
                    filterOption={FilterOption}
                    showSearch={true}
                    style={{ width: "100%", minWidth: "200px" }}
                    onChange={handleDirectionUpdate(record.ID)}
                  >
                    <Select.Option value="READONLY">Read Only</Select.Option>
                    <Select.Option value="BIRECTIONAL">
                      Bidirectional
                    </Select.Option>
                  </Select>
                </Space>
              );
            }}
          />
          <Table.Column<SalesforceCustomField>
            dataIndex="field_mapping"
            title="Field Mapping"
            render={(_, record) => {
              const fieldMappingValue =
                record.native_field && record.native_field.length > 0
                  ? record.native_field
                  : record.custom_field_id
                  ? `cf_${record.custom_field_id}`
                  : "";

              // This logic should probably live in the backend, TODO move to backend somewhere
              const filteredFieldOptions = fieldOptions?.filter((option) => {
                if (record.type === "double") {
                  return option.type === "DECIMAL";
                } else if (record.type === "boolean") {
                  return option.type === "BOOL";
                } else if (record.type === "currency") {
                  return option.type === "DECIMAL";
                } else if (
                  record.type === "datetime" ||
                  record.type === "date"
                ) {
                  return option.type === "DATETIME";
                } else if (record.type === "email") {
                  return option.type === "EMAIL";
                } else {
                  return option.type === "STRING";
                }
              });

              return (
                <Space>
                  <Select
                    options={filteredFieldOptions}
                    value={fieldMappingValue}
                    filterOption={FilterOption}
                    showSearch={true}
                    allowClear={true}
                    style={{ width: "100%", minWidth: "200px" }}
                    onChange={handleFieldMappingUpdate(record.ID)}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Card>

      {children}
    </div>
  );
};

export const CustomObjectsMappingTable: FC<PropsWithChildren> = ({
  children,
}) => {
  const { mutate } = useUpdate();

  const { tableProps } = useTable<
    SalesforceCustomField,
    HttpError,
    { name: string }
  >({
    resource: "salesforce_custom_objects",
    filters: {
      mode: "server",
    },
    pagination: {
      mode: "off",
    },
    syncWithLocation: false,
  });

  const handleFieldMappingUpdate = (recordId: number) => (newValue: string) => {
    mutate({
      resource: "salesforce_custom_objects",
      values: {
        external_object: newValue,
      },
      id: recordId,
      successNotification: false,
    });
  };

  const handleDirectionUpdate = (recordId: number) => (newValue: string) => {
    mutate({
      resource: "salesforce_custom_objects",
      values: {
        sync_direction: newValue,
      },
      id: recordId,
      successNotification: false,
    });
  };

  return (
    <div className="page-container">
      <Card
        title={
          <Space direction="horizontal" size={0}>
            <Space direction="horizontal" style={{ marginTop: "10px" }}>
              <ObjectSyncIcon name={""} />
              <Text style={{ marginLeft: "10px" }}>Object Mapping</Text>
            </Space>
          </Space>
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<SalesforceCustomObject>
            dataIndex="reference_table"
            title="Name"
            sorter
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.reference_table}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<SalesforceCustomObject>
            dataIndex="external_object"
            title="External Object"
            sorter
            render={(_, record) => {
              return (
                <Select
                  options={SalesforceObjectTypeOptions}
                  value={record.external_object}
                  filterOption={FilterOption}
                  showSearch={true}
                  allowClear={true}
                  style={{ width: "100%", minWidth: "200px" }}
                  onChange={handleFieldMappingUpdate(record.ID)}
                />
              );
            }}
          />
          <Table.Column<SalesforceCustomField>
            dataIndex="sync_direction"
            title="Sync Direction"
            render={(_, record) => {
              return (
                <Space>
                  <Select
                    value={record.sync_direction}
                    filterOption={FilterOption}
                    showSearch={true}
                    style={{ width: "100%", minWidth: "200px" }}
                    onChange={handleDirectionUpdate(record.ID)}
                  >
                    <Select.Option value="READONLY">Read Only</Select.Option>
                    <Select.Option value="BIRECTIONAL">
                      Bidirectional
                    </Select.Option>
                  </Select>
                </Space>
              );
            }}
          />
        </Table>
      </Card>

      {children}
    </div>
  );
};
export const DefaultFieldsTable: FC<PropsWithChildren> = ({ children }) => {
  const { mutate } = useUpdate();
  const { mutateAsync } = useCreateMany();
  const { tableProps } = useTable<DefaultField, HttpError, { name: string }>({
    resource: "default_fields",
    filters: {
      mode: "server",
    },
    pagination: {
      mode: "off",
    },
    syncWithLocation: false,
  });

  const [editMode, setEditMode] = useState<Record<number, boolean>>({});
  const [editableValues, setEditableValues] = useState<
    Record<number, Partial<DefaultField>>
  >({});
  const [form] = Form.useForm<DefaultField>();

  const toggleEditMode = (recordId: number) => {
    setEditMode((prev) => ({
      ...prev,
      [recordId]: !prev[recordId],
    }));
  };

  const handleFieldUpdate =
    (recordId: number, field: keyof DefaultField) => (newValue: string) => {
      setEditableValues((prev) => ({
        ...prev,
        [recordId]: {
          ...prev[recordId],
          [field]: newValue,
        },
      }));
    };

  const handleSave = async (recordId: number) => {
    const updatedValues = editableValues[recordId];
    if (updatedValues) {
      await mutate({
        resource: "default_fields",
        values: updatedValues,
        id: recordId,
        successNotification: false,
      });
    }
    toggleEditMode(recordId);
  };

  const handleCancel = (recordId: number) => {
    toggleEditMode(recordId);
  };

  const handleOnFinish = async (values: any) => {
    const newFields = values.fields.map((field: DefaultField) => ({
      reference_table: field.reference_table,
      name: field.name,
      value: field.value,
    }));

    await mutateAsync({
      resource: "default_fields",
      values: newFields,
      successNotification: false,
    });

    form.resetFields();
  };

  return (
    <div className="page-container">
      <Card
        title={
          <Space direction="horizontal" size={0}>
            <Space direction="horizontal" style={{ marginTop: "10px" }}>
              <ObjectSyncIcon name={""} />
              <Text style={{ marginLeft: "10px" }}>Object Mapping</Text>
            </Space>
          </Space>
        }
      >
        <Table {...tableProps} rowKey="ID">
          {/* Existing Columns */}
          <Table.Column<DefaultField>
            title="Actions"
            key="actions"
            width={120}
            render={(_, record) => {
              return (
                <Space>
                  {editMode[record.ID] ? (
                    <>
                      <Tooltip title="Save Changes">
                        <SaveButton
                          type="default"
                          hideText
                          onClick={() => handleSave(record.ID)}
                        />
                      </Tooltip>
                      <Tooltip title="Discard Changes">
                        <Button
                          type="default"
                          size="middle"
                          danger
                          icon={<CloseCircleOutlined />}
                          onClick={() => handleCancel(record.ID)}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <EditButton
                        hideText
                        onClick={() => toggleEditMode(record.ID)}
                      />
                      <DeleteButton
                        hideText
                        size="small"
                        resource="default_fields"
                        recordItemId={record.ID}
                      />
                    </>
                  )}
                </Space>
              );
            }}
          />
          <Table.Column<DefaultField>
            dataIndex="reference_table"
            title="External Object"
            sorter
            render={(_, record) => {
              return editMode[record.ID] ? (
                <Select
                  options={SalesforceObjectTypeOptions}
                  value={
                    editableValues[record.ID]?.reference_table ||
                    record.reference_table
                  }
                  filterOption={FilterOption}
                  showSearch={true}
                  allowClear={true}
                  style={{ width: "100%", minWidth: "200px" }}
                  onChange={(value) =>
                    handleFieldUpdate(record.ID, "reference_table")(value)
                  }
                />
              ) : (
                <Text>
                  {SalesforceObjectTypeOptions.find(
                    (item) => item.value === record.reference_table
                  )?.label || ""}
                </Text>
              );
            }}
          />
          <Table.Column<DefaultField>
            dataIndex="name"
            title="Name"
            sorter
            render={(_, record) => {
              return editMode[record.ID] ? (
                <Input
                  value={editableValues[record.ID]?.name || record.name}
                  onChange={(e) =>
                    handleFieldUpdate(record.ID, "name")(e.target.value)
                  }
                />
              ) : (
                <Text>{record.name}</Text>
              );
            }}
          />
          <Table.Column<DefaultField>
            dataIndex="value"
            title="Value"
            sorter
            render={(_, record) => {
              return editMode[record.ID] ? (
                <Input
                  value={editableValues[record.ID]?.value || record.value}
                  onChange={(e) =>
                    handleFieldUpdate(record.ID, "value")(e.target.value)
                  }
                />
              ) : (
                <Text>{record.value}</Text>
              );
            }}
          />
        </Table>

        {/* Form to add new rows */}
        <Form form={form} onFinish={handleOnFinish}>
          <Form.List name="fields">
            {(fields, { add, remove }) => (
              <div style={{ marginTop: 16 }}>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    gutter={16}
                    style={{ display: "flex", flexWrap: "nowrap" }}
                  >
                    <Col style={{ width: "120px" }}>
                      <Button
                        style={{ marginLeft: "16px" }}
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Col>
                    <Col style={{ flex: 1, marginRight: "16px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "reference_table"]}
                        rules={[
                          {
                            required: true,
                            message: "Select an external object",
                          },
                        ]}
                      >
                        <Select
                          options={SalesforceObjectTypeOptions}
                          placeholder="Select External Object"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ flex: 1, marginRight: "16px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Enter a name" }]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col style={{ flex: 1 }}>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[{ required: true, message: "Enter a value" }]}
                      >
                        <Input placeholder="Value" />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                >
                  Add New Field
                </Button>
                {/* Conditionally render the Save button only if there are fields */}
                {fields.length > 0 && (
                  <div style={{ marginTop: 24 }}>
                    <Button type="primary" htmlType="submit">
                      Save New Fields
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Form.List>
        </Form>
      </Card>

      {children}
    </div>
  );
};
