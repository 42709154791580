// import { Typography } from "antd";
import DOMPurify from "dompurify";
import { Text } from "@/components";
// const { Text } = Typography;

const HtmlRenderer = ({
  src: htmlString,
  style,
}: {
  src: string;
  style?: React.CSSProperties;
}) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlString);
  return (sanitizedHtml ?? "").trim() !== "<p><br></p>" ? (
    <Text size="lg">
      <div
        className="ql-editor"
        style={{ height: "unset", ...style }}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    </Text>
  ) : null;
};

export default HtmlRenderer;
