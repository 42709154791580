import {
  BookOpen,
  ChartLineUp,
  Clipboard,
  CurrencyCircleDollar,
  Database,
  Gauge,
  Handshake,
  Lock,
  Robot,
  UserGear,
  UserList,
} from "@phosphor-icons/react";
import type { IResourceItem } from "@refinedev/core";

export const common_resources: IResourceItem[] = [
  {
    name: "companies",
    list: "/companies",
    edit: "/companies/edit/:id",
    show: "/companies/show/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "contacts",
    list: "/contacts",
    show: "/contacts/show/:id",
    edit: "/contacts/edit/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "leads",
    show: "/leads/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "administration",
    meta: {
      label: "Administration",
      icon: <UserGear size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "settings",
    list: "/administration/settings",
    meta: {
      label: "Company Settings",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "settings_team",
    edit: "/administration/settings/team/edit/:id",
    meta: {
      hide: true,
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "reports",
    list: "/reports",
    create: "/reports/create",
    edit: "/reports/edit/:id",
    meta: {
      label: "Reports",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "logs",
    list: "/administration/logs",
    meta: {
      label: "Logs",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "catalog",
    list: "/connections/catalog",
    meta: {
      label: "Integrations",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
];

export const super_admin_resources: IResourceItem[] = [
  {
    name: "admin-base",
    meta: {
      label: "Super Admin",
      icon: <Lock size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "prompts",
    list: "/prompts",
    edit: "/prompts/edit/:id",
    create: "/prompts/create",
    meta: {
      label: "Manage Prompts",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "feature_flags",
    list: "/feature_flags",
    edit: "/feature_flags/edit/:id",
    create: "/feature_flags/create",
    meta: {
      label: "Manage Features",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "debug",
    list: "/debug",
    meta: {
      label: "Debug",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
];
export const company_resources: IResourceItem[] = [
  {
    name: "dashboard",
    list: "/dashboard",
    meta: {
      label: "Dashboard",
      icon: <Gauge size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partners-base",
    meta: {
      label: "Partners",
      icon: <Handshake size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partners",
    list: "/partners",
    edit: "/partners/edit/:id",
    show: "/partners/show/:id",
    create: "/partners/create",
    meta: {
      label: "Manage Partners",
      parent: "partners-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "statuses",
    create: "/partners/statuses/create",
    edit: "/partners/statuses/edit/:id",
    list: "/partners/statuses",
    show: "/partners/statuses/manage",
    meta: {
      hide: true,
    },
  },
  {
    name: "partner-page",
    list: "/partners/pages",
    create: "/partners/pages/create",
    edit: "/partners/pages/edit/:id",
    show: "/partners/pages/opportunities/show/:id",
    meta: {
      label: "Partner Signup",
      canDelete: true,
      parent: "partners-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "contracts",
    list: "/partners/contracts",
    create: "/partners/contracts/create",
    edit: "/partners/contracts/edit/:id",
    meta: {
      label: "Contracts",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "prospect",
    list: "/partners/prospecting",
    meta: {
      label: "Prospecting",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  /*
  {
    name: "meetings",
    list: "/partners/meetings",
    meta: {
      label: "Meetings",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  */
  {
    name: "automations",
    list: "/automations",
    create: "/automations/create",
    edit: "/automations/edit/:id",
    meta: {
      label: "AI Agents",
      icon: <Robot size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "tasks",
    list: "/tasks",
    create: "/tasks/create",
    edit: "/tasks/edit/:id",
    meta: {
      label: "Tasks",
      canDelete: true,
      icon: <Clipboard size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "commissions",
    meta: {
      label: "Revenue",
      icon: <ChartLineUp size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "opportunities",
    list: "/commissions/opportunities",
    create: "/commissions/opportunities/create",
    edit: "/commissions/opportunities/edit/:id",
    show: "/commissions/opportunities/show/:id",
    meta: {
      label: "Opportunities",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "referrals",
    list: "/referral-pages",
    create: "/referral-pages/create",
    edit: "/referral-pages/edit/:id",
    show: "/referral-pages/opportunities/show/:id",
    meta: {
      label: "Deal Reg Forms",
      canDelete: true,
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "import",
    list: "/revenue/import",
    meta: {
      label: "Import",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "structure",
    list: "/commissions/structure",
    edit: "/commissions/structure/edit/:id",
    meta: {
      label: "Commissions",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  /*
  {
    name: "payments",
    list: "/commissions/payments",
    meta: {
      label: "Payments",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  */
  {
    name: "enablement",
    meta: {
      label: "Enablement",
      icon: <BookOpen size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "resources",
    list: "/resources",
    meta: {
      label: "Resources",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  /*
  {
    name: "co-marketing",
    list: "/co-marketing",
    meta: {
      label: "Co-Marketing",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  */
  {
    name: "training",
    list: "/training",
    create: "/training/create",
    edit: "/training/edit/:id",
    meta: {
      label: "Training",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "feedback",
    list: "/feedback",
    meta: {
      label: "Feedback",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "email_campaign",
    list: "/email_campaign",
    meta: {
      label: "Email campaign",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "data-management",
    meta: {
      label: "Data Manager",
      icon: <Database size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-types",
    list: "/partners/types",
    create: "/partners/types/create",
    edit: "/partners/types/edit/:id",
    show: "/partners/types/show/:id",
    meta: {
      label: "Partner Object",
      parent: "data-management",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "contact-types",
    list: "/contacts/types",
    create: "/contacts/types/create",
    edit: "/contacts/types/edit/:id",
    show: "/contacts/types/show/:id",
    meta: {
      label: "Contact Object",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "opportunity-types",
    list: "/commissions/opportunities/types",
    create: "/commissions/opportunities/types/create",
    edit: "/commissions/opportunities/types/edit/:id",
    show: "/commissions/opportunities/types/show/:id",
    meta: {
      label: "Opportunity Object",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "custom-fields-external",
    list: "/partners/fields/external",
    meta: {
      label: "External Mapping",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
];
export const partner_resources: IResourceItem[] = [
  {
    name: "partner-dashboard",
    list: "/partner-dashboard",
    meta: {
      label: "Dashboard",
      icon: <Gauge size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "clients-base",
    meta: {
      label: "Clients",
      icon: <UserList size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "clients",
    list: "/clients",
    edit: "/clients/edit/:id",
    show: "/clients/show/:id",
    create: "/clients/create",
    meta: {
      label: "Manage Clients",
      parent: "clients-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "mapping",
    list: "/clients/mapping",
    meta: {
      label: "Team",
      parent: "clients-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "enablement",
    meta: {
      label: "Enablement",
      icon: <BookOpen size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-resources",
    list: "/partner-resources",
    meta: {
      label: "Resources",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-training",
    list: "/partner-training",
    meta: {
      label: "Training",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "revenue",
    meta: {
      label: "Revenue",
      icon: <CurrencyCircleDollar size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "deal-desk",
    list: "/revenue/deal-desk",
    //edit: "/revenue/deal-desk/edit/:id",
    show: "/revenue/deal-desk/show/:id",
    create: "/revenue/deal-desk/create",
    meta: {
      label: "Deal Desk",
      parent: "revenue",
      dataProviderName: "propelApi",
    },
  },
  /*
  {
    name: "payments",
    list: "/revenue/payments",
    meta: {
      label: "Payments",
      parent: "revenue",
      dataProviderName: "propelApi",
    },
  },
  */
];
