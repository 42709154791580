import { SelectOption } from "@/types";
import { useList } from "@refinedev/core";

export const useOpportunityStatuses = (): SelectOption[] => {
  const { data: statuses } = useList<SelectOption>({
    pagination: {
      mode: "off",
    },
    resource: "opportunities/status",
  });
  return statuses?.data;
};
