import { Contact, Person } from "@/types";
import { BaseKey, useList } from "@refinedev/core";

export const usePeople = (partnerId: number) => {
  const {
    data: peopleData,
    isLoading: peopleLoading,
    refetch,
  } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "partners/people/" + partnerId,
  });

  //const contactsPersons = contactsData?.data?.map((c) => c.person);
  const loading = peopleLoading; //|| contactsLoading;
  const people = peopleData?.data;
  return { people, loading, refetch };
};
