import { DatePickerWrapper } from "@/components";
import "@/pages/partners/create-higlight-input.css";
import { useOpportunityStatuses } from "@/providers/options";
import { usePeople } from "@/providers/people";
import {
  Opportunity,
  Tag,
  TagInfo,
  transformPersons,
  transformTags,
} from "@/types";
import { isValidDate } from "@/utilities";
import { DollarOutlined, LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  useInvalidate,
  useList,
  useNavigation,
  useParsed,
  useUpdate,
} from "@refinedev/core";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { TagBasedCustomFieldsTable } from "../custom_fields";

type Props = {
  isOverModal?: boolean;
  enableStatus?: boolean;
  resource?: string;
};

export const EditOpportunitiesPage = ({
  isOverModal,
  enableStatus,
  resource = "opportunities",
}: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const api_resource = "opportunities";
  const statusOptions = useOpportunityStatuses();

  const { formProps, query, modalProps, close } = useModalForm<Opportunity>({
    action: "edit",
    defaultVisible: true,
    resource: api_resource,
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      invalidate({
        invalidates: ["list"],
        resource: "opportunities_view_as_company",
      });

      list(resource, "replace");
    },
    redirect: false,
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Opportunity",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "Opportunity",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: id,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const opportunityData = query?.data?.data;
  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);

  const { mutate } = useUpdate();
  const handleChange = (newValues) => {
    mutate(
      {
        resource: "opportunities",
        values: {
          tags: newValues,
        },
        id: id,
        successNotification: false,
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ["list"],
            resource: "generic_tags",
          });
        },
      }
    );
  };

  const [isApproved, setIsApproved] = useState(undefined);
  const [partnerRecordId, setPartnerRecordId] = useState(0);
  useEffect(() => {
    const opportunityData = query?.data?.data;
    if (opportunityData?.approved !== undefined && isApproved === undefined) {
      setIsApproved(opportunityData?.approved);
    }

    if (
      opportunityData?.partner_record_id !== undefined &&
      opportunityData?.partner_record_id !== 0 &&
      opportunityData?.partner_record_id !== partnerRecordId
    ) {
      setPartnerRecordId(opportunityData?.partner_record_id);
    }
  }, [formProps]);

  const { people, loading } = usePeople(partnerRecordId);

  const peopleOptions = transformPersons(loading ? [] : people);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Edit Opportunity"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(changedValues) => {
          if ("approved" in changedValues) {
            setIsApproved(changedValues.approved);
          }
        }}
      >
        <Form.Item
          label="Opportunity Name"
          name={"name"}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contract Value"
          name="contract_value"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            addonBefore={<DollarOutlined />}
            placeholder="0,00"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>
        <Form.Item
          label="Close Date"
          name="close_date"
          rules={[{ required: true }]}
        >
          <DatePickerWrapper />
        </Form.Item>
        {isValidDate(opportunityData?.expiry_notificaton_date) && (
          <Form.Item
            label="Expiry Date"
            name="expiry_notificaton_date"
            rules={[{ required: true }]}
          >
            <DatePickerWrapper />
          </Form.Item>
        )}

        {enableStatus && (
          <Form.Item label="Stage" name="status" rules={[{ required: true }]}>
            <Select options={statusOptions} />
          </Form.Item>
        )}
        {enableStatus && (
          <Form.Item
            label="Override Commission Amount"
            name={["commission_override_value", "Float64"]}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={0}
              addonBefore={<DollarOutlined />}
              placeholder="0,00"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
        )}
        {enableStatus && (
          <Form.Item label="Approved" name={"approved"} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        )}
        {enableStatus && (
          <Form.Item
            label="Rejection Reason"
            name={"rejection_reason"}
            hidden={isApproved}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item hidden={true} name={["commission_override_value", "Valid"]}>
          <Switch value={true} />
        </Form.Item>
        <Form.Item
          hidden={true}
          name={["client_record", "client_company_id"]}
        ></Form.Item>
        <Form.Item hidden={true} name={["partner_record_id"]}></Form.Item>

        {enableStatus && (
          <Form.Item label="Subscribed" name="subscribed_ids">
            <Select
              mode="multiple"
              allowClear
              showSearch={true}
              options={peopleOptions}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Subscribed"
            />
          </Form.Item>
        )}
        {enableStatus && (
          <Form.Item label="Assignees" name="assignees_ids">
            <Select
              mode="multiple"
              allowClear
              showSearch={true}
              options={peopleOptions}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Assignees"
            />
          </Form.Item>
        )}
        {enableStatus && appliedTagValues?.length > 0 && (
          <Form.Item label="Opportunity Type">
            <Select
              autoFocus
              allowClear
              showSearch={true}
              mode="multiple"
              defaultValue={appliedTagValues}
              value={appliedTagValues}
              options={tagOptions}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChange}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        )}
      </Form>

      <TagBasedCustomFieldsTable
        taggable_object={"Opportunity"}
        field_reference_table={"Opportunity"}
        override_object_id={opportunityData?.client_record?.client_company_id}
        style={{
          marginTop: 32,
        }}
        show_resource="opportunity-types"
        collapsable={true}
      />
    </Modal>
  );
};
