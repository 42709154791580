import { AvatarGroup, CopyableText, ListTitleButton, Text } from "@/components";
import { APP_BASE_URL } from "@/providers";
import { PartnerPageInfo, Person, PublicPartnerOpportunity } from "@/types";
import { generateColumns } from "@/utilities/public-form";
import { SwapOutlined } from "@ant-design/icons";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { HttpError, useInvalidate, useList } from "@refinedev/core";
import { Collapse, Empty, Space, Table, Tooltip } from "antd";
import { TableProps } from "antd/lib";
import { FC, PropsWithChildren } from "react";

export const PartnerInboundListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<PartnerPageInfo, HttpError>({
    resource: "partners/pages",
    pagination: {
      mode: "off",
    },
  });

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = people?.data;

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={
          <ListTitleButton toPath="partner-page" buttonText="Add new page" />
        }
      >
        <Table
          {...tableProps}
          rowKey="ID"
          expandable={{
            expandedRowRender: (record) => {
              return <ReferralTableWrapper recordId={record.ID} />;
            },
            rowExpandable: () => {
              return true;
            },
          }}
        >
          <Table.Column<PartnerPageInfo>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  resource="partner-page"
                  recordItemId={record?.ID}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="partners/pages"
                  recordItemId={record?.ID}
                />
              </Space>
            )}
          />

          <Table.Column<PartnerPageInfo>
            dataIndex="key"
            title="Key"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record?.key}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<PartnerPageInfo>
            dataIndex="subscribed_people_ids"
            title="Subscribed"
            render={(_, record) => {
              const avatars =
                record?.subscribed_people_ids?.map((person_id) => {
                  return {
                    name: peopleOptions?.find((item) => item.ID === person_id)
                      ?.name as string | undefined,
                    src: peopleOptions?.find((item) => item.ID === person_id)
                      ?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />

          <Table.Column<PartnerPageInfo>
            dataIndex="key"
            title="URL"
            render={(_, record) => {
              const url = `${APP_BASE_URL}/partner-signup/${record.key}`;
              return <CopyableText content={url} />;
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};

type ReferralTableWrapperProps = {
  recordId: number;
};
const ReferralTableWrapper: FC<ReferralTableWrapperProps> = ({ recordId }) => {
  const { tableProps } = useTable<PublicPartnerOpportunity, HttpError>({
    resource: `partners/pages/list_inbound/${recordId}`,
    pagination: {
      mode: "off",
    },
  });
  return (
    <List breadcrumb={false} title={<div />} headerButtons={<div />}>
      <PartnerInboundTable tableProps={tableProps} />
    </List>
  );
};

type Props = {
  tableProps: TableProps<PublicPartnerOpportunity>;
};

export const PartnerInboundTable: FC<Props> = ({ tableProps }) => {
  const invalidate = useInvalidate();

  if (!tableProps.loading && tableProps?.dataSource?.length == 0) {
    return <Text>No Inbound Yet</Text>;
  }
  const customFieldsColumns = generateColumns(tableProps?.dataSource);
  return (
    <Table
      {...tableProps}
      showHeader={tableProps?.dataSource?.length > 0}
      rowKey="ID"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description="No pending opportunities found"
          />
        ),
      }}
      pagination={false}
      columns={[
        {
          title: "",
          key: "actions",
          width: 40,
          render: (_, record) => (
            <Space>
              <DeleteButton
                hideText
                size="small"
                resource="partners/opportunities"
                recordItemId={record.ID}
                onSuccess={() => {
                  invalidate({
                    invalidates: ["all"],
                    resource: `partners/opportunities/${record.ID}`,
                  });
                  invalidate({
                    invalidates: ["all"],
                    resource: `partners/pages/list_inbound`,
                  });
                }}
              />
              <Tooltip title="Convert to Partner">
                <ShowButton
                  icon={<SwapOutlined />}
                  hideText
                  size="small"
                  resource="partner-page"
                  recordItemId={record.ID}
                />
              </Tooltip>
            </Space>
          ),
        },
        ...customFieldsColumns,
      ]}
    />
  );
};
