import { ListTitleButton } from "@/components";
import { useIdentity } from "@/providers/identity";
import { Dashboard, QuillProvider } from "@quillsql/react";
import { List } from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Card } from "antd";
import { FC, PropsWithChildren } from "react";

export const ReportsListPage: FC<PropsWithChildren> = ({ children }) => {
  const me = useIdentity();
  // const edit = useNavigate();
  const { edit } = useNavigation();

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={
          <ListTitleButton
            toPath="reports"
            buttonText="Add report to dashboard"
          />
        }
      >
        <Card
          style={{
            width: "100%",
          }}
        >
          {me?.person?.company_id && (
            <QuillProvider
              organizationId={me?.person?.company_id?.toString()}
              publicKey="665610862cf7a3000be66453"
            >
              <Dashboard
                name="Company Dashboard"
                onClickReport={(item) => {
                  edit("reports", item.id);
                }}
              />
            </QuillProvider>
          )}
        </Card>
      </List>
      {children}
    </div>
  );
};
