
export type CustomFieldType = 
	| "BOOL"
	| "DATETIME"
	| "DECIMAL"
	| "EMAIL"
	| "FILE"
	| "MULTI_SELECT"
	| "SELECT"
	| "STRING"
	
export const CustomFieldTypeOptions: {
	label: string;
	value: CustomFieldType;
}[] = [
		{ label: "True or False", value: "BOOL" },
		{ label: "Date & Time", value: "DATETIME" },
		{ label: "Number", value: "DECIMAL" },
		{ label: "Email", value: "EMAIL" },
		{ label: "File", value: "FILE" },
		{ label: "Multi Select", value: "MULTI_SELECT" },
		{ label: "Single Select", value: "SELECT" },
		{ label: "Text", value: "STRING" },
	];
