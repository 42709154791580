import { ListTitleButton, Text } from "@/components";
import { Workflow } from "@/types";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { HttpError, useUpdate } from "@refinedev/core";
import { Checkbox, Space, Switch, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FC, PropsWithChildren } from "react";

// https://reactflow.dev/examples

export const AutomationListPage: FC<PropsWithChildren> = ({ children }) => {
  const { mutate } = useUpdate();

  const { tableProps } = useTable<Workflow, HttpError>({
    resource: "workflows",
    pagination: {
      mode: "off",
    },
  });

  const handleSwitchChange = (
    checked: CheckboxChangeEvent,
    record: Workflow
  ) => {
    mutate({
      resource: "workflows",
      values: {
        active: checked.target.checked,
      },
      id: record.ID,
      successNotification: false,
    });
  };

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={
          <ListTitleButton toPath="automations" buttonText="Add new agent" />
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Workflow>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  resource="automations"
                  recordItemId={record.ID}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="workflows"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
          <Table.Column<Workflow>
            dataIndex="active"
            title="Status"
            width={140}
            render={(_, record) => {
              return (
                <Space>
                  <Checkbox
                    checked={record.active}
                    onChange={(checked) => handleSwitchChange(checked, record)}
                  />
                  <Text>{record.active ? "Active" : "Inactive"}</Text>
                </Space>
              );
            }}
          />
          <Table.Column<Workflow>
            dataIndex="name"
            title="Name"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.name}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Workflow>
            dataIndex="description"
            title="Description"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.description}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
