import { CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import HtmlRenderer from "@/components/html-renderer";
import { CustomField, FormStyles, PublicOpportunity } from "@/types";
import { isNullOrZero } from "@/utilities";
import FormItem, { processCustomFieldsForm } from "@/utilities/public-form";
import { SaveOutlined } from "@ant-design/icons";
import { ErrorComponent, MarkdownField, useForm } from "@refinedev/antd";
import { HttpError, useOne, useParsed } from "@refinedev/core";
import { RefineErrorPageProps } from "@refinedev/ui-types";
import { Button, Col, Form, Row, Space, Divider, ConfigProvider } from "antd";
import React from "react";
type ReferralPagePublicGetResponse = {
  company_name: string;
  company_logo: string;
  partner_name: string;
  partner_logo: string;
  partner_record_id: number;
  referral_page_id: number;
  details?: string;
  html_details?: string;
  form_styles?: FormStyles;
  custom_fields: CustomField[];
};

interface ReferralPageProps extends RefineErrorPageProps {
  authed: boolean;
}

export const ReferralPage: React.FC<ReferralPageProps> = ({ authed }) => {
  const { form, formProps } = useForm<
    PublicOpportunity,
    HttpError,
    PublicOpportunity
  >({
    action: "create",
    resource: "public/opportunities",
    redirect: false,
    mutationMode: "pessimistic",

    onMutationSuccess: () => {
      form.resetFields();
    },
    successNotification: () => ({
      key: `referral`,
      message: "Successfully added referral",
      description: "Successful",
      type: "success",
    }),
    errorNotification: () => ({
      key: `referral`,
      message:
        "Error adding referral, please reach out to tony@propelgtm.com to fix the issue",
      description: "Error",
      type: "error",
    }),
  });

  const { id: key } = useParsed();

  const urlKey = authed ? "authed" : "public";
  const queryResult = useOne<ReferralPagePublicGetResponse>({
    resource: `${urlKey}/referral_pages`,
    id: key,
    queryOptions: {
      retry: 1,
    },
    errorNotification: false,
  });

  if (queryResult.isError) {
    return <ErrorComponent />;
  }

  const data: ReferralPagePublicGetResponse = queryResult.data?.data;
  const handleSubmit = async (values) => {
    const updatedValues = await processCustomFieldsForm(values);
    form.setFieldsValue(updatedValues);
    return formProps.onFinish?.(updatedValues as PublicOpportunity);
  };
  const hasPartner = !isNullOrZero(data?.partner_record_id);
  const formStyles = data?.form_styles;

  if (!data) return null;
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
        },
      }}
    >
      <div className="page-container">
        <LoadingWrapper loading={queryResult.isLoading}>
          <Row
            justify={formStyles?.align || "start"}
            style={{
              margin: "0 24px",
            }}
          >
            <Col xs={32} sm={24} xl={16}>
              <Space
                size={16}
                style={{
                  width: "100%",
                  paddingBottom: "24px",
                }}
              >
                <CustomAvatar
                  size="large"
                  shape="square"
                  src={data?.company_logo}
                  name={data?.company_name}
                  style={{
                    width: 60,
                    height: 60,
                    fontSize: 48,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                  }}
                />
                <Text style={{ fontSize: 50, fontWeight: 700 }}>
                  {data?.company_name}
                </Text>
              </Space>
              {hasPartner && (
                <Space
                  size={16}
                  style={{
                    width: "100%",
                    paddingBottom: "24px",
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "32px",
                  }}
                >
                  <Text size="xl">In Partnership With: </Text>
                  <CustomAvatar
                    size="large"
                    shape="square"
                    src={data?.partner_logo}
                    name={data?.partner_name}
                    style={{
                      width: 24,
                      height: 24,
                      fontSize: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                    }}
                  />
                  <Text style={{ fontSize: 24, fontWeight: 400 }}>
                    {data?.partner_name}
                  </Text>
                </Space>
              )}

              {data?.details && (
                <Space
                  size={16}
                  style={{
                    width: "100%",
                    paddingBottom: "24px",
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "8px",
                    fontSize: 14,
                  }}
                >
                  <MarkdownField value={data?.details}></MarkdownField>
                </Space>
              )}
              {!data?.details && data?.html_details && (
                <HtmlRenderer
                  src={data?.html_details}
                  style={{
                    paddingBottom: "24px",
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "8px",
                  }}
                />
              )}
              <Form
                {...formProps}
                style={{
                  marginTop: 8,
                }}
                onFinish={handleSubmit}
                layout={formStyles?.layout || "vertical"}
                size={formStyles?.size || "large"}
                variant={formStyles?.variant || "outlined"}
              >
                {hasPartner && (
                  <Form.Item
                    hidden={true}
                    name={["partner_record_id"]}
                    initialValue={data?.partner_record_id}
                  />
                )}
                {data?.custom_fields.map((x) => (
                  <>
                    <FormItem
                      key={x.ID}
                      field={x.ID}
                      name={x.name}
                      type={x.type}
                      metadata={x.metadata}
                      applyCustomStyle={!formStyles}
                    />
                    <Divider />
                  </>
                ))}
                <FormItem
                  key={"confirmation_email"}
                  field={"confirmation_email"}
                  name={"Send Confirmation Email To"}
                  type="EMAIL"
                  required={false}
                  applyCustomStyle={!formStyles}
                />
                <Form.Item
                  hidden={true}
                  name={["referral_page_id"]}
                  initialValue={data?.referral_page_id}
                />
                <Divider />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={
                      !formStyles ? { height: 50, fontSize: 16 } : undefined
                    }
                    icon={<SaveOutlined />}
                  >
                    Submit a Deal Registration
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </LoadingWrapper>
      </div>
    </ConfigProvider>
  );
};
