import { SingleElementForm, Text } from "@/components";
import { CustomFieldsCreateTable } from "@/pages/custom_fields";
import "@/pages/partners/create-higlight-input.css";
import { Tag } from "@/types";
import { NativeObjectType } from "@/types/native_object_type";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useNavigation,
  useOne,
} from "@refinedev/core";
import { Input, Modal, Space } from "antd";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

type Props = {
  isOverModal?: boolean;
  parent_resources: string;
  reference_table: NativeObjectType;
  default_object_table: NativeObjectType;
  title?: string;
};

export const CustomFieldsTypeEditPage = ({
  isOverModal,
  parent_resources,
  reference_table,
  default_object_table,
  title,
}: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const params = useParams();
  const isDefaultObject = params.id == "0";

  const { modalProps, close } = useModalForm<Tag, HttpError, Tag>({
    action: "create",
    defaultVisible: true,
    resource: "tags",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: parent_resources });
      list(parent_resources, "replace");
    },
  });

  const formattedTitle =
    title ?? (isDefaultObject ? `Define Default Fields` : `Define Fields`);
  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(parent_resources, "replace");
      }}
      title={formattedTitle}
      width={"75%"}
      closeIcon={<LeftOutlined />}
      cancelText={""}
      footer={<div />}
      styles={{ body: { overflow: "auto" } }}
    >
      {!isDefaultObject && <EditableName />}

      <CustomFieldsCreateTable
        reference_table={
          isDefaultObject ? default_object_table : reference_table
        }
        reference_id={+params.id}
      />
    </Modal>
  );
};

const EditableName: FC = () => {
  const params = useParams();
  const isDefaultObject = params.id == "0";
  const queryResult = useOne<Tag, HttpError>({
    resource: "tags",
    id: params.id,
  });

  const [activeForm, setActiveForm] = useState<"Name">();

  const getActiveForm = (args: { formName: keyof Tag }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }
    return "view";
  };
  const data = !isDefaultObject ? queryResult?.data?.data : null;
  const loading = !isDefaultObject ? queryResult?.isLoading : false;

  return (
    <SingleElementForm
      resource={"tags"}
      id={params.id}
      loading={loading}
      style={{
        marginBottom: "40px",
      }}
      state={getActiveForm({ formName: "Name" })}
      itemProps={{
        name: ["Name"],
        label: "Name",
      }}
      view={
        <Space>
          <Text
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.Name}
          </Text>
        </Space>
      }
      onClick={() => setActiveForm("Name")}
      onUpdate={() => setActiveForm(undefined)}
      onCancel={() => setActiveForm(undefined)}
    >
      <Input defaultValue={data?.Name} />
    </SingleElementForm>
  );
};
