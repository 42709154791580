export type SalesforceObjectType = "ACCOUNT" | "CONTACT" | "DEAL" | "LEAD" | "OPPORTUNITY";

export const SalesforceObjectTypeOptions: {
  label: string;
  value: SalesforceObjectType;
}[] = [
  { label: "Account", value: "ACCOUNT" },
  { label: "Contact", value: "CONTACT" },
  { label: "Deal", value: "DEAL" },
  { label: "Lead", value: "LEAD" },
  { label: "Opportunity", value: "OPPORTUNITY" },
];
