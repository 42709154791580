import { DatePickerWrapper } from "@/components";
import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { ClientRecord, Opportunity, PartnerRecord } from "@/types";
import { FilterOption } from "@/utilities/select";
import { DollarOutlined, LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Form, Input, InputNumber, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

type Props = {
  isOverModal?: boolean;
};

export const CreateOpportunitiesPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { formProps, modalProps, close } = useModalForm<
    Opportunity,
    HttpError,
    Opportunity
  >({
    action: "create",
    defaultVisible: true,
    resource: "opportunities",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "opportunities" });
      invalidate({
        invalidates: ["list"],
        resource: "opportunities_view_as_company",
      });
      list("opportunities", "replace");
    },
  });

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners",
  });
  const partnerOptions = transformPartnerRecords(partners?.data);
  const [partnerRecord, setPartnerRecord] = useState();
  const [clientRecordOptions, setClientRecordOptions] = useState([]);

  useEffect(() => {
    if (partnerRecord !== undefined) {
      const fetchData = async () => {
        try {
          const record = partners.data.find(
            (record) => record.ID === partnerRecord
          );
          const companyId = record ? record.partner_company_id : 0;

          const response = await globalAxiosInstance.get<ClientRecord[]>(
            `${API_BASE_URL}/partners/client_records`,
            {
              params: { company_id: companyId },
            }
          );

          setClientRecordOptions(transformClientRecords(response?.data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [partnerRecord]);

  const handleValuesChange = (changedValues) => {
    if (changedValues) {
      setPartnerRecord(changedValues.partner_record_id);
    }
  };

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("opportunities", "replace");
      }}
      title="Add new opportunity"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item label="Name" name={"name"} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Partner"
          name="partner_record_id"
          rules={[{ required: true }]}
        >
          <Select
            options={partnerOptions}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={FilterOption}
          />
        </Form.Item>

        <Form.Item
          label="Client"
          name="client_record_id"
          rules={[{ required: true }]}
        >
          <Select
            options={clientRecordOptions}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={FilterOption}
          />
        </Form.Item>

        <Form.Item label="Contract Value" name="contract_value">
          <InputNumber
            min={0}
            addonBefore={<DollarOutlined />}
            placeholder="0,00"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>

        <Form.Item
          label="Close Date"
          name="close_date"
          rules={[{ required: true }]}
          initialValue={dayjs()}
        >
          <DatePickerWrapper />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function transformPartnerRecords(
  records: PartnerRecord[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.partner_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

function transformClientRecords(
  records: ClientRecord[] | undefined
): { label: string; value: number }[] {
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.client_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
