import { message } from "antd";

export const CopyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Copied to clipboard!");
    },
    (err) => {
      message.error("Failed to copy");
      console.error("Error copying text to clipboard: ", err);
    }
  );
};
