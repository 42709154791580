import { PaginationTotal } from "@/components";
export const getTablePropsWithPagination = (tableProps, entityName) => {
  return {
    ...tableProps,
    pagination: {
      ...tableProps.pagination,
      pageSizeOptions: ["10", "50", "100", "200"],
      showSizeChanger: true,
      hideOnSinglePage: true,
      ...(entityName && {
        showTotal: (total) => (
          <PaginationTotal total={total} entityName={entityName} />
        ),
      }),
    },
  };
};
