import { SortableTable, Text } from "@/components";
import { Status } from "@/types";
import { useModalForm, useTable } from "@refinedev/antd";
import { useInvalidate, useNavigation } from "@refinedev/core";
import { Form, Input, InputNumber, Modal, Space, Table } from "antd";

export const KanbanCreateStage = () => {
  const invalidate = useInvalidate();
  const { list } = useNavigation();
  const { formProps, modalProps, close } = useModalForm<Status>({
    action: "create",
    defaultVisible: true,
    resource: "partners/status",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partners" });
      list("partners", "replace");
    },
    redirect: false,
    successNotification: () => {
      return {
        key: "partners",
        type: "success",
        message: "Successfully created stage",
        description: "Successful",
      };
    },
  });

  const { tableProps } = useTable<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: Status) => (
        <Space>
          <Text style={{ whiteSpace: "nowrap" }}>{record?.name}</Text>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("partners", "replace");
      }}
      title="Add new Stage"
      width={"50%"}
    >
      <div>
        <Form {...formProps} layout="vertical">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {tableProps?.loading ? null : (
            <Form.Item
              label="Order"
              hidden
              name="order"
              initialValue={tableProps?.dataSource?.length ?? 0 + 1}
            >
              <InputNumber value={tableProps?.dataSource?.length ?? 0 + 1} />
            </Form.Item>
          )}
        </Form>
        <SortableTable<Status>
          tableProps={tableProps}
          columns={columns}
          resource="partners/status"
        />
      </div>
    </Modal>
  );
};
