import { CustomAvatar } from "@/components";
import LoadingWrapper from "@/components/loading";
import { getNameInitials } from "@/utilities";
import { DateField } from "@refinedev/antd";
import { Button, List, message, Modal, Typography } from "antd";
import React, {  useState } from "react";
import { useOne, useCreate, useDelete, useInvalidate} from "@refinedev/core";

interface PartnerCompany {
  partner_company_id: number;
  partner_company_name: string;
  partner_company_logo: string;
  status: string;
  created_at: string | null;
  course_share_id: number | null;
  partner_record_id: number;
}

interface CourseDetails {
  course_id: number;
  sharing_details: PartnerCompany[];
}

interface CourseSharingDetailsModalProps {
  isVisible: boolean;
  onClose: () => void;
  courseId: number;
}

const CourseSharingDetailsModal: React.FC<CourseSharingDetailsModalProps> = ({
  isVisible,
  onClose,
  courseId,
}) => {
  
  const invalidate = useInvalidate();
  const [isLoadingArray, setIsLoadingArray] = useState<boolean[]>([]);

  // Fetching course details using useOne hook
  const { data: courseDetailsData, isLoading: isDataLoading } = useOne<CourseDetails>({
    resource: "lms/course/partner/sharing_details",
    id: courseId,
    queryOptions: {
      enabled: isVisible, // Only fetch when modal is visible
    },
  });

  const courseDetails = courseDetailsData?.data;

  // Create hook for sharing a course
  const { mutateAsync: createShare } = useCreate();

  // Delete hook for unsharing a course
  const { mutateAsync: deleteShare } = useDelete();

  const updateSharingStatus = async (
    partnerRecord: PartnerCompany,
    newStatus: boolean,
    index: number
  ) => {
    setIsLoadingArray((prev) =>
      prev.map((item, idx) => (idx === index ? true : item))
    );

    try {
      if (newStatus) {
        // Share the course (create)
        const response = await createShare({
          resource: "lms/course_share",
          values: {
            company: null,
            partner: partnerRecord.partner_record_id,
            course_id: courseId,
          },
          successNotification: () => ({
            message: `Successfully shared`,
            description: "Course has been shared successfully.",
            type: "success",
          }),
        });
        partnerRecord.course_share_id = response.data.ID;
        partnerRecord.status = "Shared";
        partnerRecord.created_at = new Date().toISOString();
        invalidate({ resource: "lms/courses", invalidates: ["list"] });
      } else {
        // Unshare the course (delete)
        await deleteShare({
          resource: "lms/course_share",
          id: partnerRecord.course_share_id,
          successNotification: () => ({
            message: `Successfully unshared`,
            description: "Course has been unshared successfully.",
            type: "success",
          }),
        });
        partnerRecord.status = "Not Shared";
        invalidate({ resource: "lms/courses", invalidates: ["list"] });
      }
    } catch (error) {
      console.error("Failed to update sharing status:", error);
      message.error("Failed to update status");
    } finally {
      setIsLoadingArray((prev) =>
        prev.map((item, idx) => (idx === index ? false : item))
      );
    }
  };

  const getDesc = (item) => {
    let status = `Status: ${item.status}`;
    if (item.status === "Shared") {
      status += ` | Shared On: `;
    }

    return (
      <div>
        {status}
        {item.status === "Shared" && (
          <DateField value={item.created_at} type="secondary" />
        )}
      </div>
    );
  };

  return (
    <Modal
      title="Overview of Course Sharing with Partners"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width="40%"
    >
      <LoadingWrapper loading={isDataLoading}>
        {courseDetails ? (
          <List
            bordered
            dataSource={courseDetails.sharing_details}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <CustomAvatar
                      size="large"
                      shape="square"
                      src={item.partner_company_logo}
                      name={getNameInitials(item?.partner_company_name || "")}
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 48,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                      }}
                    />
                  }
                  title={item.partner_company_name}
                  description={getDesc(item)}
                />
                <Button
                  type={item.status === "Shared" ? "primary" : "default"}
                  onClick={() =>
                    updateSharingStatus(
                      item,
                      item.status !== "Shared",
                      index
                    )
                  }
                  loading={isLoadingArray[index]}
                  disabled={
                    isLoadingArray[index] || item.status === "Missing Contacts"
                  }
                >
                  {item.status === "Shared" ? "Unshare" : "Share"}
                </Button>
              </List.Item>
            )}
          />
        ) : (
          <Typography.Text>Course details not available.</Typography.Text>
        )}
      </LoadingWrapper>
    </Modal>
  );
};

export default CourseSharingDetailsModal;