import { CustomFieldsTypeCreatePage } from "@/pages/custom_fields/types/create";
import "@/pages/partners/create-higlight-input.css";

type Props = {
  isOverModal?: boolean;
};

export const PartnerTypeCreatePage = ({ isOverModal }: Props) => {
  return (
    <CustomFieldsTypeCreatePage
      isOverModal={isOverModal}
      parent_resources={"partner-types"}
      reference_table={"Company"}
    />
  );
};
