import { CustomFieldsTypeShowPage } from "@/pages/custom_fields/types/show";
import "@/pages/partners/create-higlight-input.css";

type Props = {
  isOverModal?: boolean;
};

export const OpportunityTypeShowPage = ({ isOverModal }: Props) => {
  return (
    <CustomFieldsTypeShowPage
      isOverModal={isOverModal}
      parent_resources={"opportunity-types"}
      reference_table={"Tag"}
      underlying_resource={"Opportunities"}
    />
  );
};
