export type ClientRecordStatus =
  | "CUSTOMER"
  | "LOST"
  | "OPPORTUNITY"
  | "PROSPECT";

export const ClientRecordStatusOptions: {
  label: string;
  value: ClientRecordStatus;
}[] = [
  { label: "Customer", value: "CUSTOMER" },
  { label: "Lost", value: "LOST" },
  { label: "Opportunity", value: "OPPORTUNITY" },
  { label: "Prospect", value: "PROSPECT" },
];
