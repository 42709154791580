import "@/pages/partners/create-higlight-input.css";
import { useOpportunityStatuses } from "@/providers/options";
import { CommissionStructure } from "@/types";
import {
  DollarOutlined,
  LeftOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useInvalidate, useNavigation, useParsed } from "@refinedev/core";
import { Form, Input, InputNumber, Modal, Select } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const EditCommissionsPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id: commission_id } = useParsed();

  const { formProps, modalProps, close } = useModalForm<CommissionStructure>({
    action: "edit",
    defaultVisible: true,
    resource: "commission_structures",
    id: commission_id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "structure" });
      list("structure", "replace");
    },
    redirect: false,
    syncWithLocation: { key: "structure" },
    successNotification: () => {
      return {
        key: "structure",
        type: "success",
        message: "Successfully updated status",
        description: "Successful",
      };
    },
  });
  const options: {
    label: string;
    value: string;
  }[] = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "One-Time", value: "ONE_TIME" },
  ];
  const statusOptions = useOpportunityStatuses();

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("structure", "replace");
      }}
      title="Edit Commission Structure"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Commission Type"
          name="commission_type"
          rules={[{ required: true }]}
        >
          <Select options={options} />
        </Form.Item>
        <Form.Item label="Commission Amount" name={["actual_value", "Float64"]}>
          <InputNumber
            min={0}
            addonBefore={<DollarOutlined />}
            placeholder="0,00"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>
        <Form.Item
          label="Commission Percentage"
          name={["percent_value", "Float64"]}
        >
          <InputNumber
            min={0}
            addonAfter={<PercentageOutlined />}
            placeholder="0.00"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>
        <Form.Item label="Stage" name="status" rules={[{ required: true }]}>
          <Select options={statusOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
