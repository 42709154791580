import {
  CustomAvatar,
  DatePickerWrapper,
  SingleElementForm,
  Text,
} from "@/components";
import {
  Company,
  PartnerRecord,
  Person,
  Status,
  Tag,
  TagInfo,
  transformPersons,
  transformStatus,
  transformTags,
} from "@/types";
import { FilterOption } from "@/utilities/select";
import { CaretDownOutlined, ShopOutlined } from "@ant-design/icons";
import { DateField } from "@refinedev/antd";
import {
  GetOneResponse,
  useGetLocale,
  useInvalidate,
  useList,
  useParsed,
} from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Card, FormProps, Select, Space } from "antd";
import { FC, useState } from "react";

type Props = {
  formProps: FormProps;
  queryResult: QueryObserverResult<GetOneResponse<PartnerRecord>> | undefined;
};

export const PartnerInfoForm: FC<Props> = ({ formProps, queryResult }) => {
  const { id: partnerId } = useParsed();
  const invalidate = useInvalidate();
  const [activeForm, setActiveForm] = useState<
    | "last_touch"
    | "next_meeting"
    | "status_id"
    | "owner_id"
    | "Domain"
    | "Description"
    | "tag"
  >();

  const { data: statuses } = useList<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });

  const { data: owners } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "partners/owners",
  });

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Company",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "Partner",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: partnerId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const statusOptions = transformStatus(statuses?.data);
  const ownerOptions = transformPersons(owners?.data);
  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);
  const locale = useGetLocale();
  const data = queryResult?.data?.data;

  const getActiveForm = (args: {
    formName: keyof PartnerRecord | keyof Company | "tag";
  }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }

    if (formName in ({} as PartnerRecord)) {
      if (!data?.[formName as keyof PartnerRecord]) {
        return "empty";
      }
    }
    if (formName in ({} as Company)) {
      if (!data?.partner_company[formName as keyof Company]) {
        return "empty";
      }
    }
    return "view";
  };

  const loading = queryResult?.isLoading;

  return (
    <Card
      title={
        <Space>
          <ShopOutlined className="sm" />
          <Text>Partner info</Text>
        </Space>
      }
      styles={{
        header: {
          padding: "1rem",
        },
        body: {
          padding: "0",
        },
      }}
    >
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "owner_id" })}
        itemProps={{
          name: "owner_id",
          label: "Owner",
        }}
        view={
          <Space>
            <CustomAvatar name={data?.owner.name} />
            <Text
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {data?.owner.name}
            </Text>
          </Space>
        }
        onClick={() => setActiveForm("owner_id")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          autoFocus
          allowClear
          defaultValue={data?.owner_id || [""]}
          options={ownerOptions}
          showSearch={true}
          filterOption={FilterOption}
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "status_id" })}
        itemProps={{
          name: "status_id",
          label: "Status",
        }}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={data?.status_id}
            value={data?.status_id}
            options={statusOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        onClick={() => setActiveForm("status_id")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          autoFocus
          allowClear
          defaultValue={data?.status_id}
          options={statusOptions}
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "next_meeting" })}
        itemProps={{
          name: ["next_meeting", "Time"],
          label: "Next Meeting",
        }}
        view={
          data?.next_meeting.Valid ? (
            <DateField format="YYYY-MM-DD" value={data?.next_meeting.Time} />
          ) : (
            <Text>-</Text>
          )
        }
        onClick={() => setActiveForm("next_meeting")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <DatePickerWrapper locale={locale} />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "last_touch" })}
        itemProps={{
          name: ["last_touch", "Time"],
          label: "Last Meeting",
        }}
        view={
          data?.last_touch.Valid ? (
            <DateField format="YYYY-MM-DD" value={data?.last_touch.Time} />
          ) : (
            <Text>-</Text>
          )
        }
        editable={false}
        onClick={() => setActiveForm(undefined)}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      ></SingleElementForm>

      {tagOptions.length > 0 && (
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          state={getActiveForm({ formName: "tag" })}
          itemProps={{
            name: "tags",
            label: "Partner Type",
          }}
          view={
            <Select
              autoFocus={false}
              showSearch={false}
              open={false}
              mode="multiple"
              suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
              removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
              defaultValue={appliedTagValues}
              value={appliedTagValues}
              options={tagOptions}
              style={{
                width: "100%",
                pointerEvents: "none",
                boxShadow: "none",
                borderColor: "initial",
              }}
            />
          }
          onClick={() => setActiveForm("tag")}
          onUpdate={() => {
            invalidate({ invalidates: ["list"], resource: "generic_tags" });
            invalidate({ invalidates: ["list"], resource: "contracts" });
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
        >
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            defaultValue={appliedTagValues}
            options={tagOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              width: "100%",
            }}
          />
        </SingleElementForm>
      )}

      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "Domain" })}
        itemProps={{
          name: "Domain",
          label: "Domain",
        }}
        view={<Text>{data?.partner_company?.Domain}</Text>}
        //onClick={() => setActiveForm("Domain")}
        editable={false}
        onClick={() => setActiveForm(undefined)}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      ></SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        itemProps={{
          name: "has_users",
          label: "Has Partner Account",
        }}
        view={<Text>{data?.has_users ? "Yes" : "No"}</Text>}
        //onClick={() => setActiveForm("Domain")}
        editable={false}
        onClick={() => setActiveForm(undefined)}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      ></SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "Description" })}
        itemProps={{
          name: "Description",
          label: "Description",
        }}
        view={<Text>{data?.partner_company?.Description}</Text>}
        //onClick={() => setActiveForm("Description")}
        editable={false}
        onClick={() => setActiveForm(undefined)}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      ></SingleElementForm>
    </Card>
  );
};
