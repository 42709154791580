import { Workflow } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useInvalidate, useNavigation, useParsed } from "@refinedev/core";
import { Form, Input, Modal } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const AutomationEditPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const apiResource = "workflows";
  const resource = "automations";

  const { formProps, modalProps, close } = useModalForm<Workflow>({
    action: "edit",
    defaultVisible: true,
    resource: apiResource,
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
    redirect: false,
    syncWithLocation: { key: resource },
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Edit Workflow"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
