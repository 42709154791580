import { Modal, Select, message } from "antd";
import { useUpdate, useInvalidate } from "@refinedev/core";
import { useState } from "react";


interface DuplicateLead {
  id: string;
  url: string;
  name: string;
}

export const useCreateLead = () => {
  const invalidate = useInvalidate();
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);

  const { mutateAsync: createLead } = useUpdate({
    resource: "referral_pages/create_lead",
    successNotification: false,
  });

  const handleCreateLead = async (opportunityId: number) => {
    try {
      const response = await createLead({
        id: opportunityId,
        values: {},
      });

      if (response.data.status === "DUPLICATE_LEADS") {
        showDuplicateLeadModal(opportunityId, response.data.duplicateLeads);
      } else {
        message.success("Lead created successfully!");
        invalidate({ resource: "public_opportunities", invalidates: ["list"] });
      }
    } catch (error) {
      message.error("Error creating lead: " + error.message);
    }
  };

  const handleModalOk = async (opportunityId: number, leadIdToUse: string) => {
    try {
      await createLead({
        id: opportunityId,
        values: { selected_lead_external_link: leadIdToUse },
      });
      message.success("Lead updated successfully!");
      invalidate({ resource: "public_opportunities", invalidates: ["list"] });
    } catch (error) {
      message.error("Error updating lead: " + error.message);
    }
  };

  const showDuplicateLeadModal = (recordId: number, duplicateLeads: DuplicateLead[]) => {
    let tempSelectedLeadId: string | null = null;

    Modal.confirm({
      title: "Duplicate Leads Found",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Select a lead"
          onChange={(value) => {
            tempSelectedLeadId = value as string;
            setSelectedLeadId(value as string);
          }}
          optionLabelProp="label"
        >
          {duplicateLeads.map((lead) => (
            <Select.Option key={lead.id} value={lead.url} label={lead.name}>
              <a href={lead.url} target="_blank" rel="noopener noreferrer">
                {lead.name}
              </a>
            </Select.Option>
          ))}
        </Select>
      ),
      okText: "Use Selected Lead",
      cancelText: "Cancel",
      onOk: async () => {
        if (!tempSelectedLeadId && !selectedLeadId) {
          message.error("Please select a lead before proceeding.");
          return Promise.reject();
        }
        const leadIdToUse = tempSelectedLeadId || selectedLeadId;
        await handleModalOk(recordId, leadIdToUse);
      },
    });
  };

  return {
    handleCreateLead,
  };
};