import { Text } from "@/components";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { CustomFieldResponse } from "@/types";
import { NativeObjectType } from "@/types/native_object_type";
import { RenderCustomFieldsViewCell } from "@/utilities/custom-fields-render";
import {
  EditOutlined,
  InboxOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { getValueFromEvent, ShowButton, useTable } from "@refinedev/antd";
import { file2Base64, useInvalidate } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Space,
  Table,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { FC, useState } from "react";

type CustomFieldsTableProps = {
  title: string;
  style?: React.CSSProperties;
  object_reference_table: NativeObjectType;
  object_reference_id: number;
  field_reference_table: NativeObjectType;
  field_reference_id: number;
  show_resource: string;
  view_only?: boolean;
  info_only?: boolean;
};

export const CustomFieldsEditableTable: FC<CustomFieldsTableProps> = ({
  title,
  style,
  object_reference_table,
  object_reference_id,
  field_reference_table,
  field_reference_id,
  show_resource,
  view_only = false,
  info_only = false,
}) => {
  const invalidate = useInvalidate();
  const { tableProps } = useTable<CustomFieldResponse[]>({
    resource: "custom_fields_instance",
    hasPagination: false,
    filters: {
      permanent: [
        {
          field: "object_reference_table",
          operator: "eq",
          value: object_reference_table,
        },
        {
          field: "object_reference_id",
          operator: "eq",
          value: object_reference_id,
        },
        {
          field: "field_reference_table",
          operator: "eq",
          value: field_reference_table,
        },
        {
          field: "field_reference_id",
          operator: "eq",
          value: field_reference_id,
        },
      ],
    },
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const [editing, setEditing] = useState<boolean>(false);
  const [editData, setEditData] = useState<Map<number, CustomFieldResponse>>(
    new Map()
  );

  const startEdit = () => {
    setEditing(true);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  const saveEdit = async () => {
    for (const value of editData.values()) {
      const payload = {
        reference_table: field_reference_table,
        reference_id: field_reference_id,
        id: value.id,
        string_value: value.string_value,
        decimal_value: value.decimal_value,
        bool_value: value.bool_value,
        datetime_value: value.datetime_value,
        email_value: value.email_value,
        select_value: value.select_value,
        multi_select_value: value.multi_select_value,
        file: value.file,
      };

      if (value.file) {
        const base64String = await file2Base64(value.file);
        const updatedValue = {
          base64: base64String,
          fileName: value.file.name,
          contentType: value.file.type || "application/octet-stream", // Fallback to a generic MIME type
        };
        payload.file = updatedValue;
      }

      await globalAxiosInstance.patch(
        `${API_BASE_URL}/custom_fields/${value.id}`,
        payload
      );
    }
    setEditData(new Map());
    invalidate({ invalidates: ["list"], resource: "custom_fields_instance" });
    setEditing(false);
  };

  const handleInputChange = async (
    value: any,
    id: number,
    fieldName: keyof CustomFieldResponse
  ) => {
    setEditData((prev) => {
      const newEditData = new Map(prev);
      const item = newEditData.get(id);
      if (item) {
        newEditData.set(id, { ...item, [fieldName]: value });
      } else {
        const baseStruct: CustomFieldResponse = { id: id };
        newEditData.set(id, { ...baseStruct, [fieldName]: value });
      }
      return newEditData;
    });
  };

  const renderEditCell = (record: CustomFieldResponse) => {
    switch (record.field_type) {
      case "BOOL":
        return (
          <Checkbox
            defaultChecked={record.bool_value}
            onChange={(value) =>
              handleInputChange(value.target.checked, record.id, "bool_value")
            }
          />
        );
      case "DECIMAL":
        return (
          <InputNumber
            defaultValue={record.decimal_value}
            onChange={(value) =>
              handleInputChange(value, record.id, "decimal_value")
            }
            style={{ width: "100%" }}
          />
        );
      case "STRING":
        return (
          <Input
            defaultValue={record.string_value}
            onChange={(value) =>
              handleInputChange(value.target.value, record.id, "string_value")
            }
          />
        );
      case "DATETIME":
        return (
          <DatePicker
            defaultValue={dayjs(record.datetime_value)}
            showTime={true}
            onChange={(value) =>
              handleInputChange(value, record.id, "datetime_value")
            }
            style={{ width: "100%" }}
          />
        );
      case "EMAIL":
        return (
          <Input
            defaultValue={record.email_value}
            type="email"
            onChange={(value) =>
              handleInputChange(value.target.value, record.id, "email_value")
            }
          />
        );
      case "SELECT":
        return (
          <Radio.Group
            style={{ display: "block" }}
            defaultValue={record.select_value}
            onChange={(e) =>
              handleInputChange(e.target.value, record.id, "select_value")
            }
          >
            <Space size="middle">
              {record.metadata?.options.map((option, index) => (
                <Radio key={index} value={option}>
                  {option}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        );
      case "MULTI_SELECT":
        return (
          <Checkbox.Group
            style={{
              display: "block",
            }}
            defaultValue={record.multi_select_value || []}
            onChange={(value) =>
              handleInputChange(value, record.id, "multi_select_value")
            }
          >
            <Space size="large">
              {record.metadata?.options.map((option, index) => (
                <Checkbox key={index} value={option}>
                  {option}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        );
      case "FILE":
        return (
          <Upload.Dragger
            name="file"
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
            onChange={(value) => {
              const file = getValueFromEvent(value);
              handleInputChange(file[0], record.id, "file");
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
          </Upload.Dragger>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      styles={{
        header: {
          borderBottom: "1px solid #D9D9D9",
          marginBottom: "1px",
        },
        body: { padding: 0 },
      }}
      title={
        title != null && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Space size="middle">
              {!view_only && (
                <ShowButton
                  icon={<SettingOutlined />}
                  hideText
                  size="small"
                  resource={show_resource}
                  recordItemId={"0"}
                />
              )}
              <Text>{title}</Text>
            </Space>
            {hasData && !view_only && !info_only && (
              <Space size="middle">
                {!editing && (
                  <Button onClick={startEdit} icon={<EditOutlined />} />
                )}
              </Space>
            )}
          </div>
        )
      }
      style={style}
    >
      {!hasData && (
        <div
          style={{
            padding: 16,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text>There are no fields defined</Text>
        </div>
      )}
      {hasData && (
        <Table {...tableProps} rowKey="ID" pagination={false}>
          <Table.Column<CustomFieldResponse>
            title="Field"
            render={(_, record) => {
              return <Space>{record.name}</Space>;
            }}
            width={150}
          />

          {!info_only && (
            <Table.Column<CustomFieldResponse>
              title="Value"
              render={(_, record) => {
                return (
                  <>
                    {editing
                      ? renderEditCell(record)
                      : RenderCustomFieldsViewCell(record)}
                  </>
                );
              }}
            />
          )}
          {info_only && (
            <Table.Column<CustomFieldResponse>
              title="Type"
              render={(_, record) => {
                return <Space>{record.field_type.toLowerCase()}</Space>;
              }}
            />
          )}
        </Table>
      )}
      {editing && (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            padding: "16px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          <Button onClick={saveEdit}>Save</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </Space>
      )}
    </Card>
  );
};
