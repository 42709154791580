import ReactQuillEditor from "@/components/quill-editor/editor";
import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import {
  PartnerPageInfo,
  Person,
  Tag,
  TagInfo,
  transformPersons,
  transformTags,
} from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  useInvalidate,
  useList,
  useNavigation,
  useParsed,
  useUpdate,
} from "@refinedev/core";
import { Form, Input, Modal, Select } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

type Props = {
  isOverModal?: boolean;
};

export const PartnerInboundEditPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const resource = "partner-page";

  const { formProps, modalProps, close } = useModalForm<PartnerPageInfo>({
    action: "edit",
    defaultVisible: true,
    resource: "partners/pages",
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
    redirect: false,
    syncWithLocation: { key: resource },
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  const originalKey = formProps?.initialValues?.key;

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });
  const peopleOptions = transformPersons(people?.data);

  const [key, setKey] = useState();
  const [found, setFound] = useState(false);
  useEffect(() => {
    if (key !== undefined) {
      if (key !== "") {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<PartnerPageInfo>(
              `${API_BASE_URL}/partners/pages/search`,
              {
                params: { key: key },
              }
            );

            if (response.data && response.data.ID !== 0) {
              setFound(true);
            } else {
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [key]);
  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setKey(changedValues.key);
    }
  }, 500);

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Company",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "PartnerPageInfos",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: id,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);

  const { mutate } = useUpdate();
  const handleChange = (newValues) => {
    mutate(
      {
        resource: "partners/pages",
        values: {
          tags: newValues,
        },
        id: id,
        successNotification: false,
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ["list"],
            resource: "generic_tags",
          });
        },
      }
    );
  };

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Edit Partner Page"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Key"
          name={"key"}
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (found && originalKey !== value) {
                  return Promise.reject(
                    new Error("This Key is already in use.")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^[a-zA-Z0-9_-]+$/,
              message:
                "Key can only contain letters, numbers, hyphen, or underscore",
            },
            {
              max: 255,
              message: "Key must be less than 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="subscribed_people_ids"
          label="People"
        >
          <Select
            mode="multiple"
            autoFocus
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Subscribed People"
          />
        </Form.Item>

        <Form.Item label="Partner Type">
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            defaultValue={appliedTagValues}
            value={appliedTagValues}
            options={tagOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleChange}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item label="Details" name={"details"}>
          <ReactQuillEditor style={{ maxWidth: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
