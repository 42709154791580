import { CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { CustomField, PublicPartnerOpportunity } from "@/types";
import FormItem, { processCustomFieldsForm } from "@/utilities/public-form";
import { SaveOutlined } from "@ant-design/icons";
import { ErrorComponent, useForm } from "@refinedev/antd";
import { HttpError, useOne, useParsed } from "@refinedev/core";
import { RefineErrorPageProps } from "@refinedev/ui-types";
import { Button, Col, Form, Row, Space } from "antd";
import React from "react";

type PartnerPagePublicGetResponse = {
  id: number;
  company_name: string;
  company_logo: string;
  details: string;
  custom_fields: CustomField[];
};

export const PartnerInboundPage: React.FC<RefineErrorPageProps> = () => {
  const { form, formProps } = useForm<
    PublicPartnerOpportunity,
    HttpError,
    PublicPartnerOpportunity
  >({
    action: "create",
    resource: "public/partners_page",
    redirect: false,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      form.resetFields();
    },
    successNotification: () => ({
      key: `interest`,
      message: "Successfully submitted interest",
      description: "Successful",
      type: "success",
    }),
    errorNotification: () => ({
      key: `interest`,
      message:
        "Error submitting interest, please reach out to tony@propelgtm.com to fix the issue",
      description: "Error",
      type: "error",
    }),
  });

  const { id: key } = useParsed();

  const queryResult = useOne<PartnerPagePublicGetResponse>({
    resource: "public/partners_page",
    id: key,
    queryOptions: {
      retry: 1,
    },
    errorNotification: false,
  });

  if (queryResult.isError) {
    return <ErrorComponent />;
  }

  const data: PartnerPagePublicGetResponse = queryResult.data?.data;
  const handleSubmit = async (values) => {
    const updatedValues = await processCustomFieldsForm(values);
    form.setFieldsValue(updatedValues);
    return formProps.onFinish?.(updatedValues as PublicPartnerOpportunity);
  };

  return (
    <div className="page-container">
      <LoadingWrapper loading={queryResult.isLoading}>
        <Row
          gutter={[32, 32]}
          style={{
            marginTop: 32,
          }}
        >
          <Col
            xs={32}
            sm={24}
            xl={16}
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Space
              size={16}
              style={{
                width: "100%",
                paddingBottom: "24px",
              }}
            >
              <CustomAvatar
                size="large"
                shape="square"
                src={data?.company_logo}
                name={data?.company_name}
                style={{
                  width: 60,
                  height: 60,
                  fontSize: 48,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
              />
              <Text style={{ fontSize: 50, fontWeight: 700 }}>
                {data?.company_name}
              </Text>
            </Space>

            {data?.details && (
              <Space
                size={16}
                style={{
                  width: "100%",
                  paddingBottom: "24px",
                  borderBottom: "2px solid #D9D9D9",
                  marginBottom: "8px",
                  fontSize: 14,
                }}
              >
                <div
                  className="styled-content"
                  dangerouslySetInnerHTML={{
                    __html: data?.details,
                  }}
                />
                <style>
                  {`
                    .styled-content p {
                      margin: 0;
                      padding: 0;
                      font-size: 16px;
                      line-height: 1.6;
                      margin-bottom: 0;
                    }
                    `}
                </style>
              </Space>
            )}
            <Form
              {...formProps}
              layout="vertical"
              style={{
                marginTop: 8,
              }}
              onFinish={handleSubmit}
            >
              {data?.custom_fields.map((x) => (
                <FormItem
                  key={x.ID}
                  field={x.ID}
                  name={x.name}
                  type={x.type}
                  metadata={x.metadata}
                  applyCustomStyle
                />
              ))}
              <Form.Item
                hidden={true}
                name={["partner_page_info_id"]}
                initialValue={data?.id}
              ></Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ height: 50, fontSize: 16 }}
                  icon={<SaveOutlined />}
                >
                  Submit Partnership Interest
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} sm={0} xl={4}></Col>
        </Row>
      </LoadingWrapper>
    </div>
  );
};
