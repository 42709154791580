
export type ActionType = 
	| "EMAIL"
	
export const ActionTypeOptions: {
	label: string;
	value: ActionType;
}[] = [
		{ label: "Email", value: "EMAIL" },
	];
