import { ClerkProvider } from "@clerk/clerk-react";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { init, replayIntegration } from "@sentry/react";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

const initializeSentry = () => {
  init({
    dsn: "https://f33fcc3554db47257e37662a8254040e@o4507946609147904.ingest.de.sentry.io/4507946714333264",
    integrations: [
      replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

const initializeLogRocket = () => {
  LogRocket.init("ozcoex/propel-prod");
  setupLogRocketReact(LogRocket);
};

if (import.meta.env.MODE === "production") {
  initializeSentry();
  initializeLogRocket();
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const CLERK_PUBLISHABLE_KEY =
  import.meta.env.MODE === "production"
    ? "pk_live_Y2xlcmsucHJvcGVsZ3RtLmNvbSQ"
    : "pk_test_c3VwZXItZHVja2xpbmctMTYuY2xlcmsuYWNjb3VudHMuZGV2JA";

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY} afterSignOutUrl="/">
      <App />
    </ClerkProvider>
  </React.StrictMode>
);
