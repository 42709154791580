import { CustomFieldsTypeCreatePage } from "@/pages/custom_fields/types/create";
import "@/pages/partners/create-higlight-input.css";

type Props = {
  isOverModal?: boolean;
};

export const ContactTypeCreatePage = ({ isOverModal }: Props) => {
  return (
    <CustomFieldsTypeCreatePage
      isOverModal={isOverModal}
      parent_resources={"contact-types"}
      reference_table={"Contact"}
    />
  );
};
