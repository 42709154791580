import { API_BASE_URL } from "@/providers/data";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { useIdentity } from "@/providers/identity";
import {
  Company,
  PartnerRecord,
  Person,
  Status,
  Tag,
  transformPersons,
  transformStatus,
  transformTags,
} from "@/types";
import {
  CheckCircleOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
} from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import isFQDN from "validator/lib/isFQDN";
import "./create-higlight-input.css";
import { FilterOption } from "@/utilities/select";

type Props = {
  isOverModal?: boolean;
};

export const PartnerCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const [searchParams] = useSearchParams();
  const me = useIdentity();

  const { form, formProps, modalProps, close } = useModalForm<
    PartnerRecord,
    HttpError,
    PartnerRecord
  >({
    action: "create",
    defaultVisible: true,
    resource: "partners",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partners" });
      list("partners", "replace");
    },
  });

  const { data: statuses } = useList<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });
  const statusOptions = transformStatus(statuses?.data);

  const { data: owners } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "partners/owners",
  });
  const ownerOptions = transformPersons(owners?.data);

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Company",
      },
    ],
    pagination: {
      mode: "off",
    },
  });
  const tagOptions = transformTags(tags?.data);

  const [domain, setDomain] = useState();
  const [found, setFound] = useState(false);
  const statusIdParam = searchParams.get("statusId");
  const initialStatusValue =
    statusIdParam !== null ? parseFloat(statusIdParam) : undefined;

  const defaultOwner = ownerOptions?.find(
    (item) => item.value === me.person_id
  );

  useEffect(() => {
    if (domain !== undefined) {
      if (domain === "") {
        form?.setFieldsValue({
          partner_company_id: 0,
        });
        setFound(false);
      } else if (isFQDN(domain)) {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<Company>(
              `${API_BASE_URL}/companies/search`,
              {
                params: { domain: domain },
              }
            );

            if (response.data && response.data.ID !== 0) {
              form?.setFieldsValue({
                partner_company: response.data,
                partner_company_id: response.data.ID,
              });
              setFound(true);
            } else {
              form?.setFieldsValue({
                partner_company: {},
                partner_company_id: 0,
              });
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [domain]);

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setDomain(changedValues.domain);
    }
  }, 500);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("partners", "replace");
      }}
      title="Add new partner"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Please enter a domain"
          name="domain"
          rules={[{ required: true }]}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              placeholder="Please enter a domain"
              style={{ marginRight: 8 }}
            />
            {found ? (
              <Tooltip title="Domain found in our database">
                <CheckCircleOutlined style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Domain not found in our database">
                <QuestionCircleOutlined style={{ color: "gray" }} />
              </Tooltip>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label="Partner name"
          name={["partner_company", "CompanyName"]}
          rules={[{ required: true }]}
        >
          <Input
            className="readonlyable-input"
            readOnly={found}
            placeholder="Please enter company name"
          />
        </Form.Item>

        <Form.Item hidden={true} name={["partner_company_id"]}>
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status_id"
          rules={[{ required: true }]}
          initialValue={initialStatusValue}
        >
          <Select options={statusOptions} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Owner"
          name="owner_id"
          rules={[{ required: true }]}
          initialValue={defaultOwner?.value}
        >
          <Select
            options={ownerOptions}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={FilterOption}
          />
        </Form.Item>

        <Form.Item
          label="Partner Type"
          name="tags"
          hidden={tagOptions?.length === 0}
        >
          <Select
            options={tagOptions}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={FilterOption}
            mode="multiple"
          />
        </Form.Item>

        <Form.Item label="Next Meeting" name="next_meeting">
          <DatePicker
            style={{
              width: "100%",
            }}
            format={"YYYY/MM/DD"}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
