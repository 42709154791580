import { CustomAvatar, ListTitleButton, Text } from "@/components";
import { Contract } from "@/types";
import { DownloadOutlined } from "@ant-design/icons";
import { DeleteButton, List, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Button, Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";

export const ContractsListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<Contract, HttpError>({
    resource: "contracts",
    pagination: {
      mode: "off",
    },
  });

  return (
    <div className="page-container">
      <ContractsLib />

      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={
          <ListTitleButton toPath="contracts" buttonText="Add new contract" />
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Contract>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <DeleteButton
                  hideText
                  size="small"
                  resource="contracts"
                  recordItemId={record.ID}
                />
                <Button
                  size="small"
                  href={record.Url}
                  download={record.Name}
                  icon={<DownloadOutlined />}
                />
              </Space>
            )}
          />
          <Table.Column<Contract>
            dataIndex="name"
            title="Partner"
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    shape="square"
                    name={record.client_company.CompanyName}
                    src={record.client_company.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.client_company.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Contract>
            dataIndex="key"
            title="File Name"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.Name}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};

const ContractsLib: FC = () => {
  const data = [
    {
      key: "1",
      name: "mNDA",
      links: [
        {
          label: "Common Paper",
          url: "https://commonpaper.com/standards/mutual-nda/",
        },
        {
          label: "Bonterms",
          url: "https://bonterms.com/wp-content/uploads/2022/10/Bonterms-Mutual-NDA-Version-1.pdf",
        },
      ],
    },
    {
      key: "2",
      name: "Partnership Agreements",
      links: [
        {
          label: "Common Paper",
          url: "https://commonpaper.com/standards/partnership-agreement/",
        },
      ],
    },
  ];

  const columns = [
    {
      title: "Contract Type",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Resources",
      dataIndex: "links",
      key: "links",
      render: (links: { label: string; url: string }[]) => (
        <div>
          {links.map((link, index) => (
            <div key={index}>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.label}
              </a>
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="key"
      pagination={false}
      style={{
        marginBottom: "50px",
      }}
    />
  );
};
