import { useModalForm } from "@refinedev/antd";

export const createPerson = async (values, submitFunction) => {
  const transformedValues = {
    name: values.name,
    email: { String: values.email, Valid: true },
    company_id: values.company_id,
  };

  await submitFunction(transformedValues);
};

export const useCreatePersonModalForm = (afterSuccess) => {
  const { formProps, modalProps, show, close, submit } = useModalForm({
    action: "create",
    resource: "persons",
    defaultVisible: false,
    warnWhenUnsavedChanges: false,
    successNotification: false,
    onMutationSuccess: async (response) => {
      const personData = response?.data;
      await afterSuccess(personData);
      formProps.form.resetFields();
      close();
    },
  });

  const showWithInitialValues = (initialValues) => {
    formProps.form.setFieldsValue({ name: initialValues });
    show();
  };

  return {
    formProps,
    modalProps,
    show: showWithInitialValues,
    close,
    submit,
  };
};
