export function getMapFromObjectArray<T, K extends keyof T>(
  array: T[] = [],
  key: K
): Map<T[K], T> {
  return array.reduce((map, item) => {
    const mapKey = item[key];
    map.set(mapKey, item);
    return map;
  }, new Map<T[K], T>());
}
