import { AvatarGroup, CustomAvatar, Text } from "@/components";
import { useIdentity } from "@/providers/identity";
import { Meeting, Person, transformPersons, User } from "@/types";
import { ScheduleOutlined } from "@ant-design/icons";
import { DeleteButton, SaveButton, useForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useList, useParsed } from "@refinedev/core";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { disabledMinutes } from "@/utilities";

type Props = {
  style?: React.CSSProperties;
  reference_table: string;
  people?: Person[];
  me?: User;
  showCreation?: boolean;
};

export const ObjectMeetings: FC<Props> = ({
  style,
  reference_table,
  people = [],
  showCreation = true,
}) => {
  const me = useIdentity();

  return (
    <Card
      styles={{
        body: {
          padding: "0",
        },
        header: {
          borderBottom: "1px solid #D9D9D9",
        },
      }}
      title={
        <Space size={16}>
          <ScheduleOutlined />
          <Text>Meetings</Text>
        </Space>
      }
      style={style}
    >
      {showCreation && (
        <ObjectMeetingForm
          reference_table={reference_table}
          me={me}
          people={people}
        />
      )}
      <ObjectMeetingList
        reference_table={reference_table}
        me={me}
        people={people}
      />
    </Card>
  );
};

type ObjectMeetingFormProps = {
  style?: React.CSSProperties;
  reference_table: string;
  people: Person[];
  me?: User;
};

const ObjectMeetingForm: FC<ObjectMeetingFormProps> = ({
  reference_table,
  me,
  people,
}) => {
  const { id: entityId } = useParsed();
  const peopleOptions = transformPersons(people);

  const { form, formProps, saveButtonProps } = useForm<
    Meeting,
    HttpError,
    Meeting
  >({
    action: "create",
    resource: "meetings",
    meta: {
      params: {
        reference_id: entityId,
        reference_table: reference_table,
      },
    },
    redirect: false,
    mutationMode: "optimistic",
    successNotification: () => ({
      key: `${reference_table}-meeting`,
      message: "Successfully added Meeting",
      description: "Successful",
      type: "success",
    }),
    onMutationSuccess: () => {
      form?.resetFields();
    },
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "1rem",
        borderBottom: "1px solid #F0F0F0",
      }}
    >
      <CustomAvatar
        style={{ flexShrink: 0 }}
        name={me?.person.name}
        src={me?.person.avatar_url}
      />

      <Form
        {...formProps}
        style={{ width: "100%" }}
        labelAlign="left"
        labelCol={{ span: 2 }}
      >
        <Form.Item
          name="date"
          label="Date"
          required
          initialValue={dayjs().minute(0).second(0)}
        >
          <DatePicker
            showTime={{
              use12Hours: true,
              format: "hh:mm A",
              disabledMinutes: disabledMinutes,
              hideDisabledOptions: true,
            }}
            format="YYYY-MM-DD hh:mm A"
          />
        </Form.Item>

        <Form.Item required name="attendee_ids" label="Attendees">
          <Select
            mode="multiple"
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Attendees"
          />
        </Form.Item>

        <Form.Item
          name="content"
          label="Notes"
          rules={[
            {
              required: true,
              transform(value) {
                return value?.trim();
              },
              message: "Please Add Meeting Notes",
            },
          ]}
        >
          <Input.TextArea required minLength={1} />
        </Form.Item>
        {/* HACK - added hidden form fields to make these params passed through */}
        <Form.Item
          name="reference_id"
          initialValue={parseInt(entityId?.toString() || "0", 10)}
          hidden={true}
        ></Form.Item>
        <Form.Item
          name="reference_table"
          initialValue={reference_table}
          hidden={true}
        ></Form.Item>
        {/* END HACK */}
        <Form.Item wrapperCol={{ offset: 2 }}>
          <SaveButton {...saveButtonProps} />
        </Form.Item>
      </Form>
    </div>
  );
};

type ObjectMeetingListProps = {
  style?: React.CSSProperties;
  reference_table: string;
  people: Person[];
  me?: User;
};

const ObjectMeetingList: FC<ObjectMeetingListProps> = ({
  reference_table,
  me,
  people,
}) => {
  const params = useParams();
  const invalidate = useInvalidate();
  const peopleOptions = transformPersons(people);

  const { data: notes } = useList<Meeting>({
    resource: "meetings",
    filters: [
      {
        field: "reference_id",
        operator: "eq",
        value: params.id,
      },
      {
        field: "reference_table",
        operator: "eq",
        value: reference_table,
      },
    ],
    hasPagination: false,
  });

  const { formProps, setId, id, saveButtonProps } = useForm<
    Meeting,
    HttpError,
    Meeting
  >({
    resource: "meetings",
    action: "edit",
    queryOptions: {
      enabled: false,
    },
    mutationMode: "optimistic",
    onMutationSuccess: () => {
      setId(undefined);
      invalidate({
        invalidates: ["list"],
        resource: "meetings",
      });
    },
    successNotification: () => ({
      key: `${reference_table}-update-meeting`,
      message: "Successfully updated meeting",
      description: "Successful",
      type: "success",
    }),
  });

  return (
    <Space
      size={16}
      direction="vertical"
      style={{
        borderRadius: "8px",
        padding: "1rem",
        width: "100%",
      }}
    >
      {notes?.data?.map((item) => {
        const isMe = me?.person_id === item.created_by_id;

        return (
          <div key={item.ID} style={{ display: "flex", gap: "12px" }}>
            <CustomAvatar
              style={{ flexShrink: 0 }}
              name={item.created_by?.person.name}
              src={item.created_by?.person.avatar_url}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: 500 }}>
                  {item.created_by?.person?.name}
                </Text>
                {isMe && id === item.ID ? (
                  <Text size="xs"></Text>
                ) : (
                  <Text size="xs">
                    {dayjs(item.date).format("MMMM D, YYYY - h:mma")}
                  </Text>
                )}
              </div>

              {isMe && id === item.ID ? (
                <Form {...formProps}>
                  <Form.Item
                    style={{
                      paddingBottom: 0,
                      marginBottom: 10,
                      width: "50%",
                    }}
                    name="date"
                    label="Date"
                    required
                    initialValue={dayjs(item.date)}
                  >
                    <DatePicker
                      showTime={{
                        use12Hours: true,
                        format: "hh:mm A",
                        disabledMinutes: disabledMinutes,
                        hideDisabledOptions: true,
                      }}
                      format="YYYY-MM-DD hh:mm A"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      paddingBottom: 0,
                      marginBottom: 10,
                      width: "50%",
                    }}
                    required
                    name="attendee_ids"
                    label="Attendees"
                    initialValue={item.attendee_ids}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch={true}
                      options={peopleOptions}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="content"
                    initialValue={item.content}
                    rules={[
                      {
                        required: true,
                        transform(value) {
                          return value?.trim();
                        },
                        message: "Please enter a note",
                      },
                    ]}
                  >
                    <Input.TextArea
                      required
                      minLength={1}
                      style={{
                        width: "100%",
                        minHeight: "100px",
                      }}
                    />
                  </Form.Item>
                </Form>
              ) : (
                <div>
                  {item.attendees?.length > 0 && (
                    <Space style={{ marginBottom: 10 }}>
                      <Text>Attendees: </Text>
                      <AvatarFromPeople people={item.attendees} />
                    </Space>
                  )}
                  <br />
                  <Text style={{ whiteSpace: "pre-wrap" }}>{item.content}</Text>
                </div>
              )}

              {isMe && !id && (
                <Space size={16}>
                  <Typography.Link
                    type="secondary"
                    style={{
                      fontSize: "12px",
                    }}
                    onClick={() => setId(item.ID)}
                  >
                    Edit
                  </Typography.Link>
                  <DeleteButton
                    resource="meetings"
                    recordItemId={item.ID}
                    size="small"
                    type="link"
                    successNotification={() => ({
                      key: `${reference_table}-delete-meeting`,
                      message: "Successfully deleted meeting",
                      description: "Successful",
                      type: "success",
                    })}
                    icon={null}
                    className="ant-typography secondary"
                    style={{
                      fontSize: "12px",
                    }}
                  />
                </Space>
              )}

              {id === item.ID && (
                <Space>
                  <Button size="small" onClick={() => setId(undefined)}>
                    Cancel
                  </Button>
                  <Button size="small" type="primary" {...saveButtonProps}>
                    Save
                  </Button>
                </Space>
              )}
            </div>
          </div>
        );
      })}
    </Space>
  );
};

type AvatarProps = {
  people: Person[];
};

export const AvatarFromPeople: FC<AvatarProps> = ({ people }) => {
  const avatars =
    people?.map((person) => {
      return {
        name: person?.name as string | undefined,
        src: person?.avatar_url as string | undefined,
      };
    }) || [];

  return (
    <AvatarGroup
      avatars={avatars}
      size={"small"}
      maxCount={10}
      showName={false}
    />
  );
};
