import { SortableTable, Text } from "@/components";
import { Status } from "@/types";
import { useModalForm, useTable } from "@refinedev/antd";
import { useInvalidate, useNavigation, useParsed } from "@refinedev/core";
import { Form, Input, InputNumber, Modal, Space, Table } from "antd";

export const KanbanEditStage = () => {
  const invalidate = useInvalidate();
  const { list } = useNavigation();
  const { id: status_id } = useParsed();
  const { formProps, modalProps, close } = useModalForm<Status>({
    action: "edit",
    defaultVisible: true,
    resource: "partners/status",
    id: status_id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partners" });
      list("partners", "replace");
    },
    redirect: false,
    syncWithLocation: { key: "partners" },
    successNotification: () => {
      return {
        key: "partners",
        type: "success",
        message: "Successfully updated stage",
        description: "Successful",
      };
    },
  });

  const { tableProps } = useTable<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: Status) => (
        <Space>
          <Text style={{ whiteSpace: "nowrap" }}>{record?.name}</Text>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("partners", "replace");
      }}
      title="Edit Stage"
      width={"50%"}
    >
      <div>
        <Form {...formProps} layout="vertical">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>

        <SortableTable<Status>
          tableProps={tableProps}
          columns={columns}
          resource="partners/status"
        />
      </div>
    </Modal>
  );
};
