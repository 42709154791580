import { CopyableText, CustomAvatar, Text } from "@/components";
import {
  ClientRecord,
  Person,
  TeamAssignment,
  transformClientRecords,
  transformPersons,
} from "@/types";
import { TeamAssignmentTypeOptions } from "@/types/team_assignment_type";
import { FilterOption } from "@/utilities/select";
import { getTablePropsWithPagination } from "@/utilities/tables";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { SaveButton, useTable } from "@refinedev/antd";
import { HttpError, useCreateMany, useList } from "@refinedev/core";
import { Button, Card, Col, Form, Row, Select, Space, Table } from "antd";
import { useMemo } from "react";

export const ClientMappingsPage: React.FC = () => {
  const { tableProps, filters, setFilters } = useTable<TeamAssignment[]>({
    resource: "teams",
    hasPagination: false,
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const { data: teamData } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "partners/owners", // Reusing the same API for finding owners - we can migrate later to separate one or a generic one.
  });

  const { data: clientRecords } = useList<ClientRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners/client_records",
  });

  const teamOptions = transformPersons(teamData?.data);
  const companyOptions = transformClientRecords(clientRecords?.data);

  const { mutateAsync } = useCreateMany<
    TeamAssignment,
    HttpError,
    TeamAssignment
  >();

  const [form] = Form.useForm<TeamAssignment>();
  const teamAssignments = Form.useWatch("team_assignments", form);
  const { hasTeamAssignments } = useMemo(() => {
    const hasTeamAssignments = teamAssignments?.length > 0;

    return {
      hasTeamAssignments,
    };
  }, [teamAssignments]);

  const handleOnFinish = async (args) => {
    form.validateFields();
    const assignments: TeamAssignment[] = args.team_assignments.map(
      (assignment) => ({
        assignee_id: assignment.assignee_id,
        client_company_id: assignment.client_company_id,
        type: assignment.type,
      })
    );

    await mutateAsync({
      resource: "teams",
      values: assignments,
      successNotification: false,
    });

    form.resetFields();
  };
  return (
    <div className="page-container">
      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col span={24}>
          <Card
            title={
              <Space size="middle">
                <TeamOutlined />
                <Text>Team Assignment</Text>
              </Space>
            }
            styles={{
              header: {
                borderBottom: "1px solid #D9D9D9",
                marginBottom: "1px",
              },
              body: { padding: 0 },
            }}
          >
            {!hasData && (
              <div
                style={{
                  padding: 16,
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <Text>No Assignments Yet</Text>
              </div>
            )}
            {hasData && (
              <Table
                {...getTablePropsWithPagination(tableProps, "")}
                rowKey="ID"
              >
                <Table.Column<TeamAssignment>
                  title="Company"
                  render={(_, record) => {
                    return (
                      <Space>
                        <CustomAvatar
                          name={record?.client_company?.CompanyName}
                          src={record?.client_company?.PhotoUrl}
                        />
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {record?.client_company?.CompanyName}
                        </Text>
                      </Space>
                    );
                  }}
                />
                <Table.Column<TeamAssignment>
                  title="Name"
                  render={(_, record) => {
                    return (
                      <Space>
                        <CustomAvatar
                          name={record?.assignee?.name}
                          src={record?.assignee?.avatar_url}
                        />
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {record?.assignee?.name}
                        </Text>
                      </Space>
                    );
                  }}
                />
                <Table.Column<TeamAssignment>
                  title="E-mail"
                  render={(_, record) => {
                    return (
                      <CopyableText content={record?.assignee?.email.String} />
                    );
                  }}
                />
                <Table.Column<TeamAssignment>
                  title="Assignment Type"
                  render={(_, record) => {
                    return (
                      <Space>
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {TeamAssignmentTypeOptions.find(
                            (item) => item.value === record?.type
                          )?.label || ""}
                        </Text>
                      </Space>
                    );
                  }}
                />
              </Table>
            )}

            <Form form={form} onFinish={handleOnFinish}>
              <Form.List name="team_assignments">
                {(fields, { add, remove }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start ",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "4px 0px 16px",
                      }}
                    >
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Row
                            key={key}
                            gutter={12}
                            align="top"
                            style={{
                              paddingLeft: "16px",
                              width: "100%",
                            }}
                          >
                            <Col span={9}>
                              <Form.Item
                                required
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Company",
                                  },
                                ]}
                                name={[name, "client_company_id"]}
                              >
                                <Select
                                  placeholder={"Company"}
                                  allowClear
                                  options={companyOptions}
                                  showSearch={true}
                                  filterOption={FilterOption}
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Assignee",
                                  },
                                ]}
                                name={[name, "assignee_id"]}
                              >
                                <Select
                                  placeholder={"Assignee"}
                                  allowClear
                                  options={teamOptions}
                                  showSearch={true}
                                  filterOption={FilterOption}
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                required
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter a Type",
                                  },
                                ]}
                                name={[name, "type"]}
                              >
                                <Select
                                  placeholder={"Type"}
                                  allowClear
                                  options={TeamAssignmentTypeOptions}
                                  showSearch={true}
                                  filterOption={FilterOption}
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={() => add()}
                      >
                        Add New Team Assignment
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
              {hasTeamAssignments && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "8px",
                    padding: "16px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <Button
                    size="large"
                    type="default"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    size="large"
                    icon={undefined}
                    onClick={() => form.submit()}
                  />
                </div>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
