import { SingleElementForm, SortableTable, Text } from "@/components";
import "@/pages/partners/create-higlight-input.css";
import { CustomField } from "@/types";
import { CustomFieldTypeOptions } from "@/types/custom_field_type";
import { NativeObjectType } from "@/types/native_object_type";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  DeleteButton,
  EditButton,
  SaveButton,
  useTable,
} from "@refinedev/antd";
import { HttpError, useCreateMany } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";

type Props = {
  reference_table: NativeObjectType;
  reference_id: number;
};

export const CustomFieldsCreateTable: FC<Props> = ({
  reference_table,
  reference_id,
}) => {
  const { tableProps } = useTable<CustomField>({
    resource: "custom_fields",
    filters: {
      permanent: [
        {
          field: "reference_id",
          operator: "eq",
          value: reference_id,
        },
        {
          field: "reference_table",
          operator: "eq",
          value: reference_table,
        },
      ],
    },
    hasPagination: false,
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const [form] = Form.useForm<{ fields: CustomField[] }>();
  const customFields = Form.useWatch("fields", form);
  const { mutateAsync } = useCreateMany<CustomField, HttpError, CustomField>();
  // const [fields, setFields] = useState([]);
  const [fieldTypes] = useState<Record<number, string>>({});

  // Open modal only for "checkbox" or "multi-select"
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState<number | null>(null);

  const openOptionsModal = (fieldIndex: number) => {
    if (
      fieldTypes[fieldIndex] === "MULTI_SELECT" ||
      fieldTypes[fieldIndex] === "SELECT"
    ) {
      setActiveFieldIndex(fieldIndex);
      setIsModalVisible(true);
    }
  };

  // Close the modal
  const closeModal = () => {
    setIsModalVisible(false);
    setActiveFieldIndex(null);
  };

  const { hasFields } = useMemo(() => {
    const hasFields = customFields?.length > 0;

    return {
      hasFields,
    };
  }, [customFields]);

  const [fieldOptions, setFieldOptions] = useState<Record<number, string[]>>(
    {}
  ); // Manages options for each field
  const [otherFieldIdx, setOtherFieldIdx] = useState<Record<number, number>>(
    {}
  ); // Manages options for each field

  const handleAddOption = (fieldIndex: number, other = false) => {
    console.log(`Add Field options are ${JSON.stringify(fieldOptions)}`);
    setFieldOptions((prev) => ({
      ...prev,
      [fieldIndex]: [...(prev[fieldIndex] || []), ""], // Add an empty option
    }));

    if (other) {
      const currentFieldIdx = fieldOptions[fieldIndex].length - 1;
      console.log(
        `Setting otherFieldIdx to ${fieldIndex}, which is ${currentFieldIdx}`
      );
      otherFieldIdx[fieldIndex] = currentFieldIdx;
    }
  };

  const handleRemoveOption = (fieldIndex: number) => {
    const currentIdx = fieldOptions[fieldIndex].length - 1;
    setFieldOptions((prev) => {
      const updatedOptions = [...(prev[fieldIndex] || [])];
      updatedOptions.splice(fieldIndex, 1);
      return { ...prev, [fieldIndex]: updatedOptions };
    });

    if (otherFieldIdx[fieldIndex] === currentIdx) {
      otherFieldIdx[fieldIndex] = null;
    }
  };

  const handleFieldChange = (value, index, field) => {
    fieldTypes[index] = value;
    openOptionsModal(index);
    // setFields(updatedFields);
  };

  const handleOptionChange = (
    value: string,
    fieldIndex: number,
    optionIndex: number
  ) => {
    setFieldOptions((prev) => {
      const updatedOptions = [...(prev[fieldIndex] || [])];
      updatedOptions[optionIndex] = value;
      return { ...prev, [fieldIndex]: updatedOptions };
    });
  };

  const handleOnFinish = async (args) => {
    form.validateFields();
    const contacts: CustomField[] = args.fields.map((field, index) => ({
      name: field.name,
      type: field.type,
      metadata: field.metadata,
      reference_id: reference_id,
      reference_table: reference_table,
      order: index + 1 + tableProps?.dataSource?.length,
    }));
    await mutateAsync({
      resource: "custom_fields",
      values: contacts,
      successNotification: false,
    });
    form.resetFields();
  };

  const handleOptionsSave = () => {
    console.log(`Save Field options are ${JSON.stringify(fieldOptions)}`);
    if (activeFieldIndex !== null) {
      // Get the current field values from the form
      const currentFields = form.getFieldValue("fields") || [];

      // Update the specific field's options
      const updatedFields = [...currentFields];
      if (updatedFields[activeFieldIndex]) {
        updatedFields[activeFieldIndex] = {
          ...updatedFields[activeFieldIndex], // Retain other field properties (name, type, etc.)
          metadata: { options: fieldOptions[activeFieldIndex] },
        };
      }
      console.log(`Updated fields are ${JSON.stringify(updatedFields)}`);

      // Set the updated fields back to the form
      form.setFieldsValue({
        fields: updatedFields, // Update the entire fields array with the modified field
      });
      console.log(
        `Current fields are ${JSON.stringify(form.getFieldValue("fields"))}`
      );
    }

    closeModal(); // Close the modal after saving
  };

  const [activeForm, setActiveForm] = useState<number>();

  const getActiveForm = (args: { formId: number }) => {
    const { formId } = args;

    if (activeForm === formId) {
      return "form";
    }
    return "view";
  };

  const columns = [
    {
      title: "",
      key: "action",
      width: 40,
      render: (_, record: CustomField) => (
        <Space>
          <DeleteButton
            hideText
            size="small"
            resource="custom_fields"
            recordItemId={record.ID}
          />

          <Button
            onClick={() => setActiveForm(record.ID)}
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: CustomField) => (
        <SingleElementForm
          resource={"custom_fields"}
          id={record.ID}
          style={{
            marginBottom: "0",
            border: "none",
            minHeight: "0px",
            padding: "0px 0px 0px 0px",
            alignItems: "center",
          }}
          state={getActiveForm({ formId: record.ID })}
          itemProps={{
            name: ["name"],
          }}
          editable={false} // this is just to not show the edit button
          view={
            <Space>
              <Text>{record?.name}</Text>
            </Space>
          }
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(0)}
        >
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            defaultValue={record?.name}
          />
        </SingleElementForm>
      ),
    },
    {
      title: "Field Type",
      dataIndex: "type",
      key: "type",
      render: (_, record: CustomField) => (
        <Space>
          <Text style={{ whiteSpace: "nowrap" }}>
            {
              CustomFieldTypeOptions.find((item) => item.value === record.type)
                ?.label
            }
          </Text>
        </Space>
      ),
    },
  ];

  return (
    <Card
      style={{ overflow: "auto" }}
      styles={{
        header: {
          borderBottom: "1px solid #D9D9D9",
          marginBottom: "1px",
        },
        body: { padding: 0 },
      }}
    >
      {!hasData && (
        <div
          style={{
            padding: 16,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text>No Fields Yet</Text>
        </div>
      )}
      {hasData && (
        <SortableTable<CustomField>
          tableProps={tableProps}
          columns={columns}
          resource="custom_fields"
        />
      )}

      <Form form={form} onFinish={handleOnFinish}>
        <Form.List name="fields">
          {(fields, { add, remove }) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start ",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "4px 0px 16px",
                }}
              >
                {fields.map(({ key, name, ...restField }, fieldIndex) => {
                  return (
                    <Row
                      key={key}
                      gutter={12}
                      align="top"
                      style={{
                        paddingLeft: "16px",
                        width: "100%",
                      }}
                    >
                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          style={{
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter field name",
                            },
                          ]}
                          name={[name, "name"]}
                        >
                          <Input placeholder="Field name" />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          required
                          style={{
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a field type",
                            },
                          ]}
                          name={[name, "type"]}
                        >
                          <Select
                            options={CustomFieldTypeOptions}
                            onSelect={(e) =>
                              handleFieldChange(e, fieldIndex, "type")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  onClick={() => add()}
                >
                  Add New Field
                </Button>
              </div>
            );
          }}
        </Form.List>
        {hasFields && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              padding: "16px",
              borderTop: "1px solid #D9D9D9",
            }}
          >
            <Button
              size="large"
              type="default"
              onClick={() => {
                setFieldOptions({});
                setOtherFieldIdx({});
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <SaveButton
              size="large"
              icon={undefined}
              onClick={() => {
                setFieldOptions({});
                setOtherFieldIdx({});
                console.log(
                  `form fields ${JSON.stringify(form.getFieldValue("fields"))}`
                );
                form.submit();
              }}
            />
          </div>
        )}
      </Form>
      <Modal
        title="Manage Options"
        open={isModalVisible}
        onCancel={closeModal}
        onOk={handleOptionsSave} // Save and close modal
      >
        {activeFieldIndex !== null && (
          <>
            {/* Render options for the active field */}
            {(fieldOptions[activeFieldIndex] || []).map(
              (option, optionIndex) => (
                <Row
                  key={optionIndex}
                  gutter={12}
                  align="middle"
                  style={{ marginBottom: "8px" }}
                >
                  <Col span={20}>
                    <Input
                      placeholder={
                        otherFieldIdx[activeFieldIndex] === optionIndex
                          ? "Other ..."
                          : `Enter option ${optionIndex + 1}`
                      }
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(
                          e.target.value,
                          activeFieldIndex,
                          optionIndex
                        )
                      }
                    />
                  </Col>
                  <Col span={2}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveOption(activeFieldIndex)}
                    />
                  </Col>
                </Row>
              )
            )}

            {/* Add new option button */}
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => handleAddOption(activeFieldIndex)}
            >
              Add Option
            </Button>
            {/* <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => handleAddOption(activeFieldIndex, true)}
              disabled={otherFieldIdx[activeFieldIndex] !== null}
            >
              Add "Other"
            </Button> */}

            {/* {
              hasFields && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1px",
                    padding: "5px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <SaveButton
                    size="large"
                    icon={undefined}
                    onClick={handleOptionsSave} // Save when "Save" button is clicked
                  />
                </div>
              )
            } */}
          </>
        )}
      </Modal>
    </Card>
  );
};
