import { FC } from "react";
import { Select, Spin } from "antd";
import { useList } from "@refinedev/core";

const { Option } = Select;

interface TagsData {
    Partner: string[];
    Contact: string[];
}

interface TagSelectionProps {
    onAddTag: (tagName: string) => void;
    onRemoveTag: (tagName: string) => void;
    selectedTags?: { [key: string]: string[] };
    onTagsLoaded?: (tags: TagsData) => void;
}

export const TagSelectionComponent: FC<TagSelectionProps> = ({
    onAddTag,
    onRemoveTag,
    selectedTags = {},
    onTagsLoaded,
}) => {
    const { data: tagsData, isLoading } = useList({
        resource: "generic_tags/options",
    });

    if (isLoading) return <Spin />;

    const isTagsDataObject = (data: any): data is TagsData => {
        if (!data || typeof data !== "object") {
            return false;
        }

        const hasPartnerTags = "Partner" in data && Array.isArray(data.Partner);
        const hasContactTags = "Contact" in data && Array.isArray(data.Contact);

        return hasPartnerTags && hasContactTags;
    };

    const tags = isTagsDataObject(tagsData?.data)
        ? tagsData.data
        : { Partner: [], Contact: [] };

    if (onTagsLoaded && isTagsDataObject(tagsData?.data)) {
        onTagsLoaded(tags);
    }

    const handleTagChange = (
        type: "Partner" | "Contact",
        selectedValues: string[]
    ) => {
        const currentSelectedTags = selectedTags[type] || [];
        const addedTags = selectedValues.filter(
            (tag) => !currentSelectedTags.includes(tag)
        );
        const removedTags = currentSelectedTags.filter(
            (tag) => !selectedValues.includes(tag)
        );

        addedTags.forEach(onAddTag);
        removedTags.forEach(onRemoveTag);
    };

    return (
        <div>
            <TagSelector
                title="Partner Tags"
                tags={tags.Partner}
                selectedValues={selectedTags.Partner || []}
                onChange={(values) => handleTagChange("Partner", values)}
            />
            <TagSelector
                title="Contact Tags"
                tags={tags.Contact}
                selectedValues={selectedTags.Contact || []}
                onChange={(values) => handleTagChange("Contact", values)}
            />
        </div>
    );
};

interface TagSelectorProps {
    title: string;
    tags: string[];
    selectedValues: string[];
    onChange: (values: string[]) => void;
}

const TagSelector: FC<TagSelectorProps> = ({ title, tags, selectedValues, onChange }) => (
    <div style={{ marginTop: "20px" }}>
        <h3>{title}</h3>
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={`Select ${title}`}
            value={selectedValues}
            onChange={onChange}
        >
            {tags.map((tagName) => (
                <Option key={tagName} value={tagName}>
                    {tagName}
                </Option>
            ))}
        </Select>
    </div>
);