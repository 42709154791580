import "@/pages/partners/create-higlight-input.css";
import { useIdentity } from "@/providers/identity";
import {
  PartnerRecord,
  Person,
  PublicPartnerOpportunity,
  Status,
  transformPersons,
  transformStatus,
} from "@/types";
import { FilterOption } from "@/utilities/select";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  useInvalidate,
  useList,
  useNavigation,
  useOne,
  useParsed,
} from "@refinedev/core";
import { Form, Input, Modal, Select } from "antd";
import { TagBasedCustomFieldsTable } from "../custom_fields";

type Props = {
  isOverModal?: boolean;
};

export const PartnerOpportunityShowPage = ({ isOverModal }: Props) => {
  const { goBack } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const me = useIdentity();

  const { formProps, modalProps, close } = useModalForm<PartnerRecord>({
    action: "create",
    defaultVisible: true,
    resource: "partners_convert_from_opportunity",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partners" });
      invalidate({ invalidates: ["list"], resource: "partner-page" });
      invalidate({
        invalidates: ["list"],
        resource: "partners/pages/list_inbound",
      });
      goBack();
    },
  });

  const { data: statuses } = useList<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });
  const statusOptions = transformStatus(statuses?.data);

  const { data: owners } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "partners/owners",
  });
  const ownerOptions = transformPersons(owners?.data);
  const defaultOwner = ownerOptions?.find(
    (item) => item.value === me.person_id
  );

  const queryResult = useOne<PublicPartnerOpportunity>({
    resource: "partners/opportunities",
    id: id,
  });
  const data = queryResult?.data?.data;

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        goBack();
      }}
      title="Convert Partner Opportunity"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <TagBasedCustomFieldsTable
        override_taggable_object={"Company"}
        override_taggable_object_id={
          queryResult?.data?.data?.partner_page_info_id
        }
        taggable_object={"PartnerPageInfos"}
        field_reference_table={"PartnerOpportunity"}
        override_object_id={+id}
        style={{
          marginTop: 8,
        }}
        show_resource="partner-types"
        collapsable
      />
      <Form {...formProps} layout="vertical">
        <Form.Item hidden initialValue={+id} name="opportunity_id"></Form.Item>

        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="status_id"
          label="Status"
          required
        >
          <Select
            autoFocus
            allowClear
            showSearch={true}
            options={statusOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Pick a status"
          />
        </Form.Item>

        <Form.Item
          label="Owner"
          name="owner_id"
          rules={[{ required: true }]}
          initialValue={defaultOwner?.value}
        >
          <Select
            options={ownerOptions}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={FilterOption}
          />
        </Form.Item>

        <Form.Item
          label="Partner name"
          name={"company_name"}
          initialValue={data?.company_name}
          rules={[{ required: true }]}
        >
          <Input placeholder="Please enter company name" />
        </Form.Item>

        <Form.Item
          label="Partner Domain"
          name={"domain"}
          initialValue={data?.domain}
          rules={[{ required: true }]}
        >
          <Input placeholder="Please enter company name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
