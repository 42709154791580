import { Text } from "@/components";
import { EmailRecord } from "@/types";
import { getTablePropsWithPagination } from "@/utilities/tables";
import { DateField, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Col, Collapse, Row, Space, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { FC, PropsWithChildren } from "react";

export const CompanyLogsPage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="page-container">
      <Tabs defaultActiveKey="1" type="line">
        <TabPane tab="Email Logs" key="1">
          <Row
            gutter={[32, 32]}
            style={{
              marginTop: 32,
            }}
          >
            <Col span={24}>
              <EmailListPage />
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      {children}
    </div>
  );
};

const EmailListPage: FC = () => {
  const { tableProps } = useTable<EmailRecord, HttpError>({
    resource: "email_records",
    syncWithLocation: false,
    pagination: {
      mode: "server",
    },
  });

  return (
    <Table {...getTablePropsWithPagination(tableProps, "emails")} rowKey="ID">
      <Table.Column<EmailRecord>
        dataIndex="CreatedAt"
        title="Sent"
        render={(_, record) => {
          return (
            <Space>
              <DateField format="LLL" value={record.CreatedAt} />
            </Space>
          );
        }}
      />
      <Table.Column<EmailRecord>
        dataIndex="to"
        title="To"
        render={(_, record) => {
          return (
            <Space>
              <Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {record.to}
              </Text>
            </Space>
          );
        }}
      />
      <Table.Column<EmailRecord>
        dataIndex="subject"
        title="Subject"
        render={(_, record) => {
          return (
            <Space>
              <Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {record.subject}
              </Text>
            </Space>
          );
        }}
      />
      <Table.Column<EmailRecord>
        dataIndex="body"
        title="Body"
        render={(_, record) => {
          return (
            <Collapse accordion={true}>
              <Collapse.Panel header="View Email" key="1">
                <div
                  className="html-viewer"
                  style={{
                    border: "1px solid #ccc",
                    maxWidth: "100%",
                    marginLeft: "auto", // Adjust margin to align it to the right
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${record.body}`,
                    }}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          );
        }}
      />
    </Table>
  );
};
