import { Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { API_BASE_URL } from "@/providers";
import { DataConnection, ObjectSyncStatus } from "@/types";
import { FilterOption } from "@/utilities/select";
import { SyncOutlined } from "@ant-design/icons";
import type { Identity as User } from "@/types";
import { DateField } from "@refinedev/antd";
import {
  BaseKey,
  useCustom,
  useInvalidate,
  useList,
  useUpdate,
} from "@refinedev/core";
import { Card, Select, Space, Tooltip } from "antd";
import { FC } from "react";
import { FaHubspot, FaSalesforce } from "react-icons/fa";

type Props = {
  style?: React.CSSProperties;
  reference_table: string;
  reference_id: BaseKey;
  me?: User;
};

export const ObjectSyncStatusComponent: FC<Props> = ({
  style,
  reference_table,
  reference_id,
}) => {
  const { mutate } = useUpdate();
  const invalidate = useInvalidate();
  const handleFieldChange = (recordId: number) => {
    mutate(
      {
        resource: "object_sync_status_instance",
        values: {
          reference_id: reference_id,
        },
        id: recordId,
        successNotification: false,
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ["all"],
            resource: "object_sync_status_instance",
          });
        },
      }
    );
  };

  const queryResult = useCustom<ObjectSyncStatus>({
    url: `${API_BASE_URL}/object_sync_status_instance`,
    method: "get",
    config: {
      filters: [
        {
          field: "reference_table",
          operator: "eq",
          value: reference_table,
        },
        {
          field: "reference_id",
          operator: "eq",
          value: reference_id,
        },
      ],
    },
  });

  const { data: statusOptions, isLoading: statusOptionsLoading } =
    useList<ObjectSyncStatus>({
      pagination: {
        mode: "off",
      },
      filters: [
        {
          field: "reference_table",
          operator: "eq",
          value: "Company",
        },
        {
          field: "type",
          operator: "eq",
          value: "salesforce",
        },
      ],
      resource: "object_sync_status",
    });

  const { data: dataConnections, isLoading: dataConnectionsLoading } =
    useList<DataConnection>({
      pagination: {
        mode: "off",
      },
      filters: [
        {
          field: "provider_config_key",
          operator: "eq",
          value: "salesforce",
        },
      ],
      resource: "data_connections",
    });
  if (!dataConnections?.data?.length) {
    return <div />;
  }

  const objectSyncOptions = transformOptions(statusOptions?.data);

  return (
    <LoadingWrapper
      loading={
        queryResult?.isLoading || statusOptionsLoading || dataConnectionsLoading
      }
    >
      <Card // where do I see it?
        styles={{
          body: {
            padding: "0",
          },
          header: {
            borderBottom: "1px solid #D9D9D9",
          },
        }}
        title={
          queryResult?.data?.data?.external_id ? (
            <Tooltip
              title={`ID: ${queryResult?.data?.data?.external_id} - Name: ${queryResult?.data?.data?.external_name}`}
            >
              <Space>
                <a
                  href={queryResult?.data?.data?.external_link}
                  target="_blank"
                >
                  <ObjectSyncIcon name={""} />
                </a>
                <SyncOutlined />
                <Text>Last Updated: </Text>
                <DateField
                  format="YYYY-MM-DD"
                  value={queryResult?.data?.data?.last_updated}
                />
              </Space>
            </Tooltip>
          ) : (
            <Space>
              <ObjectSyncIcon name={""} />
              <Select
                options={objectSyncOptions}
                placeholder="Select External Record"
                showSearch={true}
                filterOption={FilterOption}
                onChange={handleFieldChange}
              />
            </Space>
          )
        }
        style={style}
        size={"small"}
      ></Card>
    </LoadingWrapper>
  );
};

export const ObjectSyncIcon: FC<{ name: string }> = ({ name }) => {
  return (
    iconMapping[name] || (
      <FaSalesforce
        size={24}
        style={{
          color: "#00A1E0",
          verticalAlign: "middle",
        }}
      />
    )
  );
};

const iconMapping: { [key: string]: JSX.Element } = {
  hubspot: (
    <FaHubspot
      size={24}
      style={{
        color: "#FF7A59",
        verticalAlign: "middle",
      }}
    />
  ),
  salesforce: (
    <FaSalesforce
      size={24}
      style={{
        color: "#00A1E0",
        verticalAlign: "middle",
      }}
    />
  ),
};

function transformOptions(
  objects: ObjectSyncStatus[] | undefined
): { label: string; value: number }[] {
  if (!objects) {
    return [];
  }
  return objects.map((node) => ({
    label: node.external_name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
