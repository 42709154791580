import { ListTitleButton } from "@/components";
import {
  PartnerRecord,
  PublicPartnerOpportunity,
  Status,
  transformStatus,
} from "@/types";
import {
  AppstoreOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { List, useTable } from "@refinedev/antd";
import { HttpError, useList, useUpdate } from "@refinedev/core";
import { Form, Grid, Input, Radio, Space, Spin } from "antd";
import debounce from "lodash/debounce";
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { PartnersTableView } from "./components";
import { KanbanPage } from "./kanban";
import { useUserPreference } from "@/providers/identity";

type View = "card" | "table";

export const PartnerListPage: FC<PropsWithChildren> = ({ children }) => {
  const [view, setView] = useState<View>("card");
  const screens = Grid.useBreakpoint();
  const { mutate } = useUpdate();

  const { data: statuses } = useList<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });
  const statusOptions = transformStatus(statuses?.data);

  const preference = useUserPreference();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      if (preference?.default_partner_view) {
        setView(preference.default_partner_view as View);
        initialized.current = true;
      }
    }
  }, [preference, view]);

  const handleSelectTypeChange = (newValue: string) => {
    mutate({
      resource: "user/preference",
      values: {
        default_partner_view: newValue,
      },
      id: "0",
      successNotification: false,
    });
  };

  const {
    tableProps,
    tableQuery,
    searchFormProps,
    filters,
    sorters,
    setCurrent,
    setPageSize,
    setFilters,
  } = useTable<PartnerRecord, HttpError, { name: string }>({
    resource: "partners",
    syncWithLocation: false,
    hasPagination: false,
    onSearch: (values) => {
      return [
        {
          field: "company_name",
          operator: "contains",
          value: values.name,
        },
      ];
    },
  });

  const { tableQuery: publicPartnerOpportunityQuery } = useTable<
    PublicPartnerOpportunity,
    HttpError
  >({
    resource: `partners/pages/list_inbound/0`,
    pagination: {
      mode: "off",
    },
  });

  const onViewChange = (value: View) => {
    setView(value);
    handleSelectTypeChange(value);
    setFilters([], "replace");
    // TODO: useForm should handle this automatically. remove this when its fixed from antd useForm.
    searchFormProps.form?.resetFields();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFormProps?.onFinish?.({
      name: e.target.value ?? "",
    });
  };
  const debouncedOnChange = debounce(onSearch, 500);

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return (
            <Space
              style={{
                marginTop: screens.xs ? "1.6rem" : undefined,
              }}
            >
              <Form {...searchFormProps} layout="inline">
                <Form.Item name="name" noStyle>
                  <Input
                    prefix={<SearchOutlined className="anticon tertiary" />}
                    suffix={
                      <Spin size="small" spinning={tableQuery?.isFetching} />
                    }
                    placeholder="Search by name"
                    onChange={debouncedOnChange}
                  />
                </Form.Item>
              </Form>
              {!screens.xs ? (
                <Radio.Group
                  value={view}
                  onChange={(e) => onViewChange(e.target.value)}
                >
                  <Radio.Button value="table">
                    <UnorderedListOutlined />
                  </Radio.Button>
                  <Radio.Button value="card">
                    <AppstoreOutlined />
                  </Radio.Button>
                </Radio.Group>
              ) : null}
            </Space>
          );
        }}
        title={
          <div>
            <ListTitleButton toPath="partners" buttonText="Add new partner" />
          </div>
        }
      >
        {view === "table" ? (
          <PartnersTableView
            tableProps={tableProps}
            filters={filters}
            sorters={sorters}
            statusOptions={statusOptions}
          />
        ) : (
          <KanbanPage
            tableProps={tableProps}
            filters={filters}
            sorters={sorters}
            statuses={statuses?.data}
            publicPartnerOpportunityQuery={publicPartnerOpportunityQuery}
          />
        )}
      </List>
      {children}
    </div>
  );
};
