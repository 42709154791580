import { ConfigProvider, theme } from "antd";
import { PropsWithChildren, createContext, useEffect, useState } from "react";

type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  const { darkAlgorithm, defaultAlgorithm, compactAlgorithm } = theme;

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#383D3E",
            fontFamily: "Inter",
            boxShadow: "none",
            boxShadowSecondary: "none",
            wireframe: true,
          },
          components: {
            Menu: {
              itemSelectedBg: "HSL(190, 10%, 70%)",
              itemSelectedColor: mode === "light" ? "#000000" : "#FFFFFF",
              itemHoverBg: "HSL(190, 10%, 65%)",
              itemActiveBg: "HSL(190, 10%, 50%)",
            },
            Tabs: {
              itemColor:
                mode === "light" ? "HSL(0, 0%, 50%)" : "HSL(0, 0%, 70%)",
              itemActiveColor:
                mode === "light" ? "HSL(0, 0%, 20%)" : "HSL(0, 0%, 90%)",
              itemHoverColor:
                mode === "light" ? "HSL(0, 0%, 40%)" : "HSL(0, 0%, 80%)",
              itemSelectedColor: mode === "light" ? "#000000" : "#FFFFFF",
              inkBarColor: mode === "light" ? "#000000" : "#FFFFFF",

              cardBg: mode === "light" ? "#F5F5F5" : "#1F1F1F",
              // titleFontSize: 14,
              // titleFontSizeLG: 16,
              // titleFontSizeSM: 12,

              // cardPadding: "12px 16px",
              // cardPaddingSM: "8px 12px",
              // cardPaddingLG: "16px 24px",
              // cardHeight: "40px",

              horizontalItemPadding: "8px 16px",
              // horizontalItemPaddingLG: "12px 20px",
              // horizontalItemPaddingSM: "6px 12px",
              // verticalItemPadding: "8px 12px",
              // horizontalItemMargin: "0 8px",
              // horizontalItemMarginRTL: "0 8px",
              // verticalItemMargin: "8px 0",
            },
            Button: {
              defaultShadow: "none",
              primaryShadow: "none",
            },
          },
          algorithm: [
            mode === "light" ? defaultAlgorithm : darkAlgorithm,
            compactAlgorithm,
          ],
        }}
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
