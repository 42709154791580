import { DashboardTotalCountCard, Text } from "@/components";
import { API_BASE_URL } from "@/providers/data";
import { DashboardResponse, GaugeChartConfiguration } from "@/types";
import { useCustom } from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { Funnel, FunnelChart, Legend, Tooltip } from "recharts";

export const DashboardPage: React.FC = () => {
  const { data, isLoading } = useCustom<DashboardResponse>({
    url: API_BASE_URL + "/dashboard",
    method: "get",
    //dataProviderName: "propelApi"
  });

  const opportunitiesGauge: GaugeChartConfiguration = {
    title: "Opportunities Created",
    percentage: 53 / 80,
    type: "number",
    total: 80,
    realized: 53,
  };

  const closedWonGauge: GaugeChartConfiguration = {
    title: "Closed Won",
    percentage: 123271 / 200000,
    type: "money",
    total: 200000,
    realized: 123271,
  };

  return (
    <div className="page-container">
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={24} xl={8}>
          <DashboardTotalCountCard
            resource="companies"
            isLoading={isLoading}
            totalCount={data?.data.active_partners}
            title="Number of Partners"
          />
        </Col>
        <Col xs={24} sm={24} xl={8}>
          <DashboardTotalCountCard
            resource="contacts"
            isLoading={isLoading}
            totalCount={data?.data.active_clients}
            title="Number of Partner Clients"
          />
        </Col>
        <Col xs={24} sm={24} xl={8}>
          <DashboardTotalCountCard
            resource="emails"
            isLoading={isLoading}
            totalCount={data?.data.pending_actions}
            title="Number of Actions Scheduled"
          />
        </Col>
      </Row>
      {/*
            <Row
                gutter={[32, 32]}
                style={{
                    marginTop: "32px",
                }}
            >
                <Col
                    xs={24}
                    sm={24}
                    xl={12}
                >
                    <Card style={{ padding: 0 }}>
                        <DashboardGaugeChart  {...opportunitiesGauge} />
                        <TextDetailsCard title="Accounts Touched" value="521" />
                        <TextDetailsCard title="Opportunity Creation Rate" value="10.17%" />
                        <TextDetailsCard title="Opportunity Lost" value="24" />
                    </Card>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    xl={12}
                >
                    <Card style={{ padding: 0 }}>
                        <DashboardGaugeChart  {...closedWonGauge} />
                        <TextDetailsCard title="Win Rate" value="47.23%" />
                        <TextDetailsCard title="Average Sale Cycle" value="37 Days" />
                        <TextDetailsCard title="Average Contract Value" value="$23,232" />
                    </Card>
                </Col>
            </Row>
            <Row
                gutter={[32, 32]}
                style={{
                    marginTop: "32px",
                }}
            >
                <Col
                    xs={24}
                    sm={24}
                    xl={12}
                >
                    <Card title={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <DollarOutlined />
                            <Text size="sm">Partner Pipeline</Text>
                        </div>
                    }>
                        <DashboardFunnelChart />
                    </Card>
                </Col>
            </Row>
             */}
    </div>
  );
};

const data = [
  { name: "S1 - Demo Scheduled", value: 60, fill: "#004080" },
  { name: "S2 - Demo Taken", value: 52, fill: "#0066cc" },
  { name: "S3 - Decision Maker", value: 45, fill: "#0077b6" },
  { name: "S4 - Negotiation / Review", value: 40, fill: "#009688" },
  { name: "S5 - Closed Won", value: 15, fill: "#66c2a5" },
];

const DashboardFunnelChart = () => {
  return (
    <FunnelChart
      width={800}
      height={300}
      style={{
        width: "100%",
      }}
    >
      <Tooltip />
      <Funnel
        dataKey="value"
        nameKey="name"
        data={data}
        isAnimationActive //legendType="rect"
        style={{
          alignItems: "left",
          width: "100%",
        }}
      ></Funnel>
      <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        iconType="circle"
        content={<CustomLegend data={data} />}
      />
    </FunnelChart>
  );
};

interface CustomLegendProps {
  data: Array<{
    name: string;
    value: number;
    fill: string;
  }>;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ data }) => {
  return (
    <div>
      {data.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{ display: "flex", alignItems: "center", margin: "0px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: entry.fill,
              marginRight: "5px",
            }}
          />
          <Text size="xs" strong>
            {" "}
            {entry.name}
          </Text>
        </div>
      ))}
    </div>
  );
};
