import { CustomFieldsTypeEditPage } from "@/pages/custom_fields/types";
import "@/pages/partners/create-higlight-input.css";

type Props = {
  isOverModal?: boolean;
};

export const PartnerTypeEditPage = ({ isOverModal }: Props) => {
  return (
    <CustomFieldsTypeEditPage
      isOverModal={isOverModal}
      parent_resources={"partner-types"}
      reference_table={"Tag"}
      default_object_table="Company"
    />
  );
};
