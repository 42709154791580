import { Text } from "@/components";
import ReactQuillEditor from "@/components/quill-editor/editor";
import {
  ObjectDetail,
  ObjectField,
  Operation,
  transformCustomFieldChoice,
  Workflow,
} from "@/types";
import { ActionTypeOptions } from "@/types/action_types";
import { FilterOption } from "@/utilities/select";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Card, Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import { useState } from "react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

type Props = {
  isOverModal?: boolean;
};

export const AutomationCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const apiResource = "workflows";
  const resource = "automations";

  const { formProps, modalProps, close } = useModalForm<
    Workflow,
    HttpError,
    Workflow
  >({
    action: "create",
    defaultVisible: true,
    resource: apiResource,
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
  });

  const { data: object_configs } = useList<ObjectDetail>({
    pagination: {
      mode: "off",
    },
    resource: "object_configs",
  });

  const objectDetailOptions = transformObjectDetails(object_configs?.data);

  const [fieldsOptions, setFieldOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const [choiceOptions, setChoiceOptions] = useState([]);

  const handleObjectChange = (selectedObjectKey) => {
    const selectedObject = objectDetailOptions.find(
      (obj) => obj.value === selectedObjectKey
    );
    if (selectedObject) {
      setFieldOptions(transformObjectField(selectedObject.fields));
    } else {
      setFieldOptions([]); // Clear fields if no object is selected
    }
  };

  const handleFieldChange = (selectedFieldKey) => {
    const selectedField = fieldsOptions.find(
      (obj) => obj.value === selectedFieldKey
    );
    if (selectedField) {
      setOperationOptions(transformOperation(selectedField.operations));
      if (selectedField?.choice_values?.length > 0) {
        setChoiceOptions(
          transformCustomFieldChoice(selectedField.choice_values)
        );
      } else {
        setChoiceOptions([]);
      }
    } else {
      setOperationOptions([]); // Clear fields if no object is selected
    }
  };

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Add new agent"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Card style={{ marginBottom: "20px", borderWidth: "3px" }}>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Active" name="active" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Card>

        <Row gutter={16} align="middle">
          <Col span={11}>
            <Card style={{ borderWidth: "3px" }}>
              <Text size="lg">Condition</Text>
              <Form.Item
                label="Record"
                name="object_key"
                rules={[{ required: true }]}
                style={{ marginTop: "20px" }}
              >
                <Select
                  options={objectDetailOptions}
                  onChange={handleObjectChange}
                />
              </Form.Item>
              <Form.Item
                label="Field"
                name="field_key"
                rules={[{ required: true }]}
              >
                <Select options={fieldsOptions} onChange={handleFieldChange} />
              </Form.Item>
              <Form.Item
                label="Operation"
                name="operation"
                rules={[{ required: true }]}
              >
                <Select options={operationOptions} />
              </Form.Item>

              {choiceOptions.length > 0 ? (
                <Form.Item
                  label="Value"
                  name="id_value"
                  rules={[{ required: true }]}
                >
                  <Select options={choiceOptions} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Value"
                  name="string_value"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
            </Card>
          </Col>
          <Col span={2}>
            <FaRegArrowAltCircleRight
              style={{ display: "block", margin: "4px auto", fontSize: "24" }}
            />
          </Col>
          <Col span={11}>
            <Card style={{ borderWidth: "3px" }}>
              <Text size="lg">Action</Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Form.Item
                  label="Type"
                  name="action_type"
                  rules={[{ required: true }]}
                  style={{ width: "45%" }}
                  initialValue={ActionTypeOptions[0].value}
                >
                  <Select
                    options={ActionTypeOptions}
                    style={{}}
                    showSearch={true}
                    filterOption={FilterOption}
                  />
                </Form.Item>

                <Form.Item
                  label="Enable AI Personalization"
                  name="personalize"
                  valuePropName="checked"
                  style={{ width: "45%" }}
                >
                  <Checkbox />
                </Form.Item>
              </div>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Body" name="body" rules={[{ required: true }]}>
                <ReactQuillEditor style={{ maxWidth: "100%" }} />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

function transformObjectDetails(
  records: ObjectDetail[] | undefined
): { label: string; value: string; fields: ObjectField[] }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.name,
    value: node.object_key,
    fields: node.fields,
  }));
}

function transformObjectField(
  records: ObjectField[] | undefined
): { label: string; value: string; operations: Operation[] }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.name,
    value: node.field_type,
    operations: node.operations,
    choice_values: node.choice_values,
  }));
}

function transformOperation(
  records: Operation[] | undefined
): { label: string; value: string }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.name,
    value: node.type,
  }));
}
