import { CustomAvatar, Text } from "@/components";
import { ClientRecord } from "@/types";
import { getTablePropsWithPagination } from "@/utilities/tables";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { DeleteButton, FilterDropdown, ShowButton } from "@refinedev/antd";
import {
  CrudFilters,
  CrudSorting,
  getDefaultFilter,
  useInvalidate,
} from "@refinedev/core";
import { Input, Space, Table, TableProps } from "antd";
import { FC } from "react";

type Props = {
  tableProps: TableProps<ClientRecord>;
  statusOptions: { label: string; value: number }[];
  parent_resources: string;
  filters: CrudFilters;
  sorters: CrudSorting;
};

export const ClientsTableView: FC<Props> = ({
  tableProps,
  filters,
  sorters,
  statusOptions,
  parent_resources,
}) => {
  const invalidate = useInvalidate();

  return (
    <Table
      {...getTablePropsWithPagination(tableProps, "companies")}
      rowKey="ID"
    >
      <Table.Column<ClientRecord>
        fixed="right"
        dataIndex="ID"
        title="Actions"
        width={80}
        render={(_, record) => (
          <Space>
            <ShowButton
              icon={<EyeOutlined />}
              hideText
              size="small"
              resource="companies"
              recordItemId={record.client_company_id}
            />
            <DeleteButton
              hideText
              size="small"
              resource="client_records"
              recordItemId={record.ID}
              onSuccess={() =>
                invalidate({
                  invalidates: ["list"],
                  resource: parent_resources,
                })
              }
            />
          </Space>
        )}
      />
      <Table.Column<ClientRecord>
        dataIndex="name"
        title="Company"
        defaultFilteredValue={getDefaultFilter("id", filters)}
        filterIcon={<SearchOutlined />}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder="Search Company" />
          </FilterDropdown>
        )}
        render={(_, record) => {
          return (
            <Space>
              <CustomAvatar
                shape="square"
                name={record.client_company.CompanyName}
                src={record.client_company.PhotoUrl}
              />
              <Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {record.client_company.CompanyName}
              </Text>
            </Space>
          );
        }}
      />
    </Table>
  );
};
