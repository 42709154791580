import { Text } from "@/components";
import { GaugeChartConfiguration } from "@/types";
import { currencyNumber } from "@/utilities";
import { DollarOutlined } from "@ant-design/icons";
import { GaugeConfig } from "@ant-design/plots";
import { Card, Space } from "antd";
import React, { Suspense } from "react";

const Gauge = React.lazy(() => import("@ant-design/plots/es/components/gauge"));

export const DashboardGaugeChart: React.FC<GaugeChartConfiguration> = (
  gaugeConfiguration
) => {
  const config: GaugeConfig = {
    animation: false,
    supportCSSTransform: true,
    // antd expects a percentage value between 0 and 1
    percent: gaugeConfiguration.percentage,
    range: {
      color: "l(0) 0:#D9F7BE 1:#52C41A",
    },
    axis: {
      tickLine: {
        style: {
          stroke: "#BFBFBF",
        },
      },
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    indicator: {
      pointer: {
        style: {
          fontSize: 4,
          stroke: "#BFBFBF",
          lineWidth: 2,
        },
      },
      pin: {
        style: {
          r: 8,
          lineWidth: 2,
          stroke: "#BFBFBF",
        },
      },
    },
    statistic: {
      content: {
        formatter: (datum) => {
          return (datum?.percent * 100).toFixed(2) + "%";
        },
        style: {
          color: "rgba(0,0,0,0.85)",
          fontWeight: 500,
          fontSize: "24px",
        },
      },
    },
  };

  return (
    <Card
      style={{ height: "100%" }}
      styles={{
        body: {
          padding: "0 32px 32px 32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
        header: { padding: "16px" },
      }}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <DollarOutlined />
          <Text size="sm">{gaugeConfiguration.title}</Text>
        </div>
      }
    >
      <Suspense>
        <Gauge {...config} padding={0} width={280} height={280} />
      </Suspense>

      <div
        style={{
          display: "flex",
          gap: "32px",
        }}
      >
        <Space direction="vertical" size={0}>
          <Text size="xs" className="secondary">
            Realized
          </Text>
          <Text
            size="md"
            className="primary"
            style={{
              minWidth: "100px",
            }}
          >
            {gaugeConfiguration.type == "money"
              ? currencyNumber(gaugeConfiguration.realized || 0)
              : gaugeConfiguration.realized || 0}
          </Text>
        </Space>
        <Space direction="vertical" size={0}>
          <Text size="xs" className="secondary">
            Expected
          </Text>
          <Text
            size="md"
            className="primary"
            style={{
              minWidth: "100px",
            }}
          >
            {gaugeConfiguration.type == "money"
              ? currencyNumber(gaugeConfiguration.total || 0)
              : gaugeConfiguration.total || 0}
          </Text>
        </Space>
      </div>
    </Card>
  );
};
