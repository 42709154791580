import { CopyableText, CustomAvatar, Text } from "@/components";
import { Contact, PartnerRecord } from "@/types";
import { isNullorEmpty } from "@/utilities";
import { getTablePropsWithPagination } from "@/utilities/tables";
import {
  ContactsOutlined,
  MailOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import type { Identity as User } from "@/types";

import {
  DeleteButton,
  SaveButton,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useCreateMany,
  useInvalidate,
  useNotification,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

type Props = {
  partner_record: PartnerRecord;
};

export const PartnersContactsTable: FC<Props> = ({ partner_record }) => {
  const params = useParams();
  const { mutateAsync: createUser } = useCreate<User>();
  const invalidate = useInvalidate();
  const { open } = useNotification();

  const { tableProps } = useTable<Contact[]>({
    resource: "contacts",
    filters: {
      permanent: [
        {
          field: "reference_id",
          operator: "eq",
          value: params.id,
        },
        {
          field: "reference_table",
          operator: "eq",
          value: "PartnerRecord",
        },
        {
          field: "include_users",
          operator: "eq",
          value: true,
        },
      ],
    },
    hasPagination: false,
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const [form] = Form.useForm<Contact>();
  const { mutateAsync } = useCreateMany<Contact, HttpError, Contact>();
  const handleOnFinish = async (args) => {
    form.validateFields();

    const contact: Contact = {
      reference_id: Number(params.id),
      reference_table: "PartnerRecord",
      person: {},
    };
    if (typeof args.name === "number") {
      contact.person = {
        ID: args.name,
        email: {
          String: args.email,
          Valid: true,
        },
      };
    } else {
      contact.person = {
        email: {
          String: args.email,
          Valid: true,
        },
        name: args.name,
      };
    }

    await mutateAsync({
      resource: "contacts",
      values: [contact],
      successNotification: false,
    });

    form.resetFields();
  };

  const [showForm, setShowForm] = useState(false);

  const handleAddUser = async (record: Contact) => {
    if (isNullorEmpty(record.person?.email?.String)) {
      open?.({
        type: "error",
        message: "Can't create account without an email",
      });

      return;
    }

    await createUser({
      resource: "users",
      values: {
        contact_id: record.ID,
        send_invite: true,
      },
      successNotification: () => {
        return {
          key: "users",
          type: "success",
          message: "Successfully Created User",
          description: "Successful",
        };
      },
    });

    invalidate({
      invalidates: ["all"],
      resource: "persons",
    });

    invalidate({
      invalidates: ["all"],
      resource: "contacts",
    });

    invalidate({
      invalidates: ["all"],
      resource: "partner",
    });
  };

  return (
    <Card
      headStyle={{
        borderBottom: "1px solid #D9D9D9",
        marginBottom: "1px",
      }}
      styles={{ body: { padding: 0 } }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Space size="middle">
            <TeamOutlined />
            <Text>Contacts</Text>
          </Space>
        </div>
      }
    >
      {!hasData && (
        <div
          style={{
            padding: 16,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text>No contacts yet</Text>
        </div>
      )}
      {hasData && (
        <Table {...getTablePropsWithPagination(tableProps, "")} rowKey="ID">
          <Table.Column<Contact>
            title="Name"
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    name={record?.person?.name}
                    src={record?.person?.avatar_url}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record?.person?.name}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Contact>
            title="E-mail"
            render={(_, record) => {
              return <CopyableText content={record?.person?.email.String} />;
            }}
          />

          <Table.Column<Contact>
            dataIndex="id"
            width={160}
            align="right"
            render={(value, record) => {
              return (
                <Space>
                  {!partner_record?.has_users &&
                    record?.person?.email.String && (
                      <Tooltip title={"Send Invite"}>
                        <Button
                          size="small"
                          onClick={() => handleAddUser(record)}
                          icon={<UserAddOutlined />}
                        />
                      </Tooltip>
                    )}
                  <Tooltip title={"Email"}>
                    <Button
                      size="small"
                      href={`mailto:${record?.person?.email.String}`}
                      icon={<MailOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title={"View"}>
                    <ShowButton
                      hideText
                      size="small"
                      resource="contacts"
                      recordItemId={record.ID}
                    />
                  </Tooltip>
                  <Tooltip title={"Delete"}>
                    <DeleteButton
                      hideText
                      size="small"
                      resource="contacts"
                      recordItemId={record.ID}
                    />
                  </Tooltip>
                </Space>
              );
            }}
          />
        </Table>
      )}

      {showForm && (
        <Form form={form} onFinish={handleOnFinish}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start ",
              flexDirection: "column",
              gap: "16px",
              padding: "4px 0px 16px",
            }}
          >
            <Row
              gutter={12}
              align="top"
              style={{
                paddingLeft: "16px",
                width: "100%",
              }}
            >
              <Col span={11}>
                <Form.Item
                  required
                  style={{
                    marginBottom: 0,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter contact name",
                    },
                  ]}
                  name={["name"]}
                >
                  <Input
                    addonBefore={<ContactsOutlined />}
                    placeholder="Contact name"
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  required
                  style={{
                    marginBottom: 0,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter contact e-mail",
                    },
                    {
                      type: "email",
                      message: "The input is not valid e-mail",
                    },
                  ]}
                  name={["email"]}
                >
                  <Input
                    addonBefore={<MailOutlined />}
                    placeholder="Contact email"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      )}

      {!showForm && (
        <Button
          type="link"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setShowForm(true);
          }}
        >
          Add new contact
        </Button>
      )}

      {showForm && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            padding: "16px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          <Button
            size="large"
            type="default"
            onClick={() => {
              form.resetFields();
              setShowForm(false);
            }}
          >
            Cancel
          </Button>
          <SaveButton
            size="large"
            icon={undefined}
            onClick={() => {
              form.submit();
              setShowForm(false);
            }}
          />
        </div>
      )}
    </Card>
  );
};
