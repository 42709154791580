import { CustomAvatar, Text } from "@/components";
import { PartnerRecord } from "@/types";
import {
  ClockCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useDelete, useNavigation } from "@refinedev/core";
import type { MenuProps } from "antd";
import {
  Button,
  Card,
  ConfigProvider,
  Dropdown,
  Skeleton,
  Space,
  Tag,
  theme,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";

type ProjectCardProps = {
  partner: PartnerRecord;
};

export const ProjectCard = ({ partner }: ProjectCardProps) => {
  const { token } = theme.useToken();
  const { edit } = useNavigation();
  const { mutate: deleteRecord } = useDelete();

  const dropdownItems = useMemo(() => {
    const dropdownItems: MenuProps["items"] = [
      {
        label: "View Partner",
        key: "1",
        icon: <EyeOutlined />,
        onClick: () => {
          edit("partners", partner.ID);
        },
      },
      {
        danger: true,
        label: "Delete Partner",
        key: "2",
        icon: <DeleteOutlined />,
        onClick: () => {
          deleteRecord({
            resource: "partners",
            id: partner.ID,
          });
        },
      },
    ];

    return dropdownItems;
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Tag: {
            colorText: token.colorTextSecondary,
          },
          Card: {
            headerBg: "transparent",
          },
        },
      }}
    >
      <Card
        size="small"
        title={
          <Space
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <CustomAvatar
              shape="square"
              name={partner?.partner_company?.CompanyName}
              src={partner?.partner_company?.PhotoUrl}
            />
            <Text ellipsis={{ tooltip: partner?.partner_company?.CompanyName }}>
              {partner?.partner_company?.CompanyName}
            </Text>
          </Space>
        }
        onClick={() => {
          edit("partners", partner.ID);
        }}
        extra={
          <Dropdown
            trigger={["click"]}
            menu={{
              items: dropdownItems,
              onPointerDown: (e) => {
                e.stopPropagation();
              },
              onClick: (e) => {
                e.domEvent.stopPropagation();
              },
            }}
            placement="bottom"
            arrow={{ pointAtCenter: true }}
          >
            <Button
              type="text"
              shape="circle"
              icon={
                <MoreOutlined
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              }
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        }
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Tooltip key={partner?.owner.ID} title={partner?.owner.name}>
            <CustomAvatar
              name={partner?.owner.name}
              src={partner?.owner.avatar_url}
            />
          </Tooltip>
          <Tag
            icon={
              <ClockCircleOutlined
                style={{
                  fontSize: "12px",
                }}
              />
            }
            style={{
              padding: "0 4px",
              marginInlineEnd: "0",
              backgroundColor: "transparent",
            }}
          >
            Last:{" "}
            {partner?.last_touch.Valid
              ? dayjs(partner?.last_touch.Time).format("MMM D")
              : "-"}
          </Tag>
          <Tag
            icon={
              <ClockCircleOutlined
                style={{
                  fontSize: "12px",
                }}
              />
            }
            style={{
              padding: "0 4px",
              marginInlineEnd: "0",
              backgroundColor: "transparent",
            }}
          >
            Next:{" "}
            {partner?.next_meeting.Valid
              ? dayjs(partner?.next_meeting.Time).format("MMM D")
              : "-"}
          </Tag>
        </div>
      </Card>
    </ConfigProvider>
  );
};

export const ProjectCardSkeleton = () => {
  return (
    <Card
      size="small"
      styles={{
        body: {
          display: "flex",
          justifyContent: "center",
          gap: "8px",
        },
      }}
      title={
        <Skeleton.Button
          active
          size="small"
          style={{
            width: "200px",
            height: "22px",
          }}
        />
      }
    >
      <Skeleton.Button
        active
        size="small"
        style={{
          width: "200px",
        }}
      />
      <Skeleton.Avatar active size="small" />
    </Card>
  );
};
