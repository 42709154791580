import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import {
  PartnerRecord,
  Person,
  ReferralPageInfo,
  Tag,
  transformPersons,
  transformTags,
} from "@/types";
import { FilterOption } from "@/utilities/select";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Checkbox, Form, Input, Modal, Select, InputNumber } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import ReactQuillEditor from "@/components/quill-editor/editor";
import FormLayoutInput from "@/components/form-layout-input";

type Props = {
  isOverModal?: boolean;
};

export const ReferralPageCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { formProps, modalProps, close } = useModalForm<
    ReferralPageInfo,
    HttpError,
    ReferralPageInfo
  >({
    action: "create",
    defaultVisible: true,
    resource: "referral_pages",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "referrals" });
      list("referrals", "replace");
    },
  });

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners",
  });
  const partnerOptions = transformPartnerRecords(partners?.data);

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = transformPersons(people?.data);

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Opportunity",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);

  const [key, setKey] = useState();
  const [found, setFound] = useState(false);
  useEffect(() => {
    if (key !== undefined) {
      if (key !== "") {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<ReferralPageInfo>(
              `${API_BASE_URL}/referral_pages/search`,
              {
                params: { key: key },
              }
            );

            if (response.data && response.data.ID !== 0) {
              setFound(true);
            } else {
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [key]);
  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setKey(changedValues.key);
    }
  }, 500);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("referrals", "replace");
      }}
      title="Add new page"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item label="Partner" name="partner_record_id">
          <Select
            options={partnerOptions}
            showSearch={true}
            filterOption={FilterOption}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Key"
          name="key"
          rules={[
            { required: true },
            {
              validator: () => {
                if (found) {
                  return Promise.reject(
                    new Error("This Key is already in use.")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^[a-zA-Z0-9_-]+$/,
              message:
                "Key can only contain letters, numbers, hyphen, or underscore",
            },
            {
              max: 255,
              message: "Key must be less than 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="subscribed_people_ids"
          label="People"
        >
          <Select
            mode="multiple"
            autoFocus
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Subscribed People"
          />
        </Form.Item>
        <Form.Item name="tags" label="Opportunity Type">
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            options={tagOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // onChange={handleChange}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item name="private" valuePropName="checked">
          <Checkbox>Only allow logged in user</Checkbox>
        </Form.Item>
        <Form.Item name="should_create_lead_in_crm" valuePropName="checked">
          <Checkbox>Should Create Lead in CRM</Checkbox>
        </Form.Item>
        <Form.Item name="should_notify_before_expiry" valuePropName="checked">
          <Checkbox>Should Notify On Expiry</Checkbox>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.should_notify_before_expiry !==
            currentValues.should_notify_before_expiry
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue("should_notify_before_expiry") ? (
              <Form.Item
                label="Default Expiration Date After Creation"
                name="pre_expiry_notification_days"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of days",
                  },
                  {
                    type: "number",
                    min: 0,
                    message: "Must be at least 0 days",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <FormLayoutInput />
        {/* @TODO: remove it after migration */}
        <Form.Item label="Details" name={"details"} hidden initialValue="" />
        <Form.Item label="Details" name={"html_details"}>
          <ReactQuillEditor style={{ maxWidth: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function transformPartnerRecords(
  records: PartnerRecord[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.partner_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
