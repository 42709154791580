import { CustomFieldsEditableTable } from "@/pages/custom_fields";
import { TagInfo } from "@/types";
import { NativeObjectType } from "@/types/native_object_type";
import { useList, useParsed } from "@refinedev/core";
import { Collapse } from "antd";
import { FC } from "react";

type Props = {
  style?: React.CSSProperties;
  taggable_object: NativeObjectType;
  field_reference_table: NativeObjectType;
  override_taggable_object?: NativeObjectType;
  override_taggable_object_id?: number;
  override_object_id?: number;
  show_resource: string;
  view_only?: boolean;
  info_only?: boolean;
  collapsable?: boolean;
};

export const TagBasedCustomFieldsTable: FC<Props> = ({
  style,
  taggable_object,
  field_reference_table,
  override_taggable_object,
  override_taggable_object_id,
  override_object_id,
  show_resource,
  view_only = false,
  info_only = false,
  collapsable = false,
}) => {
  const { id: objectId } = useParsed();
  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: taggable_object,
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: override_taggable_object_id ?? +objectId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const content = (
    <>
      <CustomFieldsEditableTable
        title={"Default Fields"}
        object_reference_table={override_taggable_object ?? taggable_object}
        object_reference_id={0}
        field_reference_id={override_object_id ?? +objectId}
        field_reference_table={field_reference_table}
        show_resource={show_resource}
        view_only={view_only}
        info_only={info_only}
      />

      {appliedTags?.data?.map((tag) => (
        <CustomFieldsEditableTable
          key={tag.tag_id}
          title={tag.name}
          style={style}
          object_reference_table={"Tag"}
          object_reference_id={tag.tag_id}
          field_reference_id={+objectId}
          field_reference_table={field_reference_table}
          show_resource={show_resource}
          view_only={view_only}
          info_only={info_only}
        />
      ))}
    </>
  );

  return (
    <div>
      {collapsable ? (
        <Collapse accordion={true} defaultActiveKey={"1"}>
          <Collapse.Panel header="View Fields" key="1">
            {content}
          </Collapse.Panel>
        </Collapse>
      ) : (
        content
      )}
    </div>
  );
};
