import { CustomFieldsTypeListPage } from "@/pages/custom_fields/types";
import { FC, PropsWithChildren } from "react";

export const OpportunityTypesListPage: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <CustomFieldsTypeListPage
      children={children}
      reference_table={"Opportunity"}
      create_resources={"opportunity-types"}
    />
  );
};
