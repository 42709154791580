import { CustomFieldsTypeListPage } from "@/pages/custom_fields/types";
import { FC, PropsWithChildren } from "react";

export const ContactTypesListPage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <CustomFieldsTypeListPage
      children={children}
      reference_table={"Contact"}
      create_resources={"contact-types"}
    />
  );
};
