import { Text } from "@/components";
import { API_BASE_URL } from "@/providers";
import { HttpError, useCustom } from "@refinedev/core";
import { FC, PropsWithChildren } from "react";

export const DebugPage: FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading, error } = useCustom<{ data: string }, HttpError>({
    url: `${API_BASE_URL}/commit`,
    method: "get",
  });

  return (
    <div className="page-container">
      {/* Debug Commit Information */}
      <div
        style={{ marginTop: "20px", padding: "10px", border: "1px solid #ddd" }}
      >
        <h2>Last 5 Commits</h2>
        <Text
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {data?.data?.data}
        </Text>
      </div>
      {children}
    </div>
  );
};
