import { EmailCampaignPayload, PartnerRecord } from "@/types";
import { FilterOption } from "@/utilities/select";
import { SendOutlined } from "@ant-design/icons";
import { Create, SaveButton, useForm } from "@refinedev/antd";
import { HttpError, useList, useOne } from "@refinedev/core";

import { App, Col, Form, Input, Row, Select, Typography } from "antd";
import debounce from "lodash/debounce";

import { useCallback, useEffect, useMemo, useState } from "react";

import ReactQuillEditor from "@/components/quill-editor/editor";
import { HTMLPreview } from "@/components";
import { getMapFromObjectArray } from "@/utilities";
import { useSelectAllOption } from "@/providers";

const Text = Typography.Text;
function transformPartnerRecords(
  records: PartnerRecord[] = []
): { label: string; value: number }[] {
  return records.map((node) => ({
    label: node.partner_company.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

const wrapContentWithHTMLTemplate = (
  subject: string,
  content: string,
  template = ""
) => {
  return template
    .replace("{{ .Subject }}", subject)
    .replace("{{ .Content }}", content);
};

const INITIAL_SUBJECT = "Broadcast from Propel";
const INITIAL_CONTENT =
  "Dear Partners, <br /> Here is the campaign content <br />Regards,<br />Propel";

export const EmailCampagin: React.FC = () => {
  const { modal } = App.useApp();

  const { formProps, query, saveButtonProps, form, onFinish } = useForm<
    EmailCampaignPayload,
    HttpError
  >({
    redirect: false,
    resource: "email_campaign",
    dataProviderName: "propelApi",
    action: "create",
    onMutationSuccess: () => {
      form.resetFields();
      setEmailSubject(INITIAL_SUBJECT);
      setEmailContent(INITIAL_CONTENT);
    },

    successNotification() {
      return {
        message: `Email braodcasted successfully`,
        type: "success",
      };
    },
  });

  const emailTemplateBase = useOne<
    {
      template: string;
    },
    HttpError
  >({
    resource: "email_templates_base",
    id: "0",
  });

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners",
  });

  const [getValueFromEvent, optionsWithAllOption] = useSelectAllOption(
    transformPartnerRecords(partners?.data)
  );

  const partnersMap = useMemo(
    () => getMapFromObjectArray(partners?.data, "ID"),
    [partners?.data]
  );

  const [emailSubject, setEmailSubject] = useState<string>(
    query?.data?.data?.subject || INITIAL_SUBJECT
  );
  const [emailContent, setEmailContent] = useState<string>(
    query?.data?.data?.content || INITIAL_CONTENT
  );

  useEffect(() => {
    if (query?.data?.data) {
      setEmailSubject(query.data.data.subject || "");
      setEmailContent(query.data.data.content || "");
    }
  }, [query?.data]);

  const srcDoc = useMemo(() => {
    return wrapContentWithHTMLTemplate(
      emailSubject,
      emailContent,
      emailTemplateBase?.data?.data?.template
    );
  }, [emailSubject, emailContent, emailTemplateBase?.data?.data?.template]);

  const onValuesChange = useMemo(
    () =>
      debounce((changedValues) => {
        if (changedValues.subject !== undefined) {
          setEmailSubject(changedValues.subject);
        }
        if (changedValues.content !== undefined) {
          setEmailContent(changedValues.content);
        }
      }, 500),
    []
  );

  const onFormFinish = useCallback(
    (values) => {
      const partnerIDs = values.partner_record_ids ?? [];
      const contactsData = partnerIDs.map((id) => {
        const partner = partnersMap.get(id);
        return {
          partnerId: id,
          contacts: partner?.contacts ?? [],
          name: partner?.partner_company?.CompanyName ?? `Partner ${id}`, // Retrieve name via partner_company.CompanyName
        };
      });

      const partnersWithContacts = contactsData.filter(
        ({ contacts }) => contacts.length > 0
      );
      const partnersWithoutContacts = contactsData.filter(
        ({ contacts }) => contacts.length === 0
      );

      const contactDetails = contactsData.map(({ name, contacts }) => (
        <div key={name} style={{ marginBottom: "8px" }}>
          <Text strong>{name}:</Text>{" "}
          {contacts.length > 0 ? (
            contacts.map((contact, index) => (
              <Text key={index} type="secondary">
                {contact.person.name} &lt;{contact.person.email.String}&gt;
                {index < contacts.length - 1 ? ", " : ""}
              </Text>
            ))
          ) : (
            <Text type="warning">[no email]</Text>
          )}
        </div>
      ));

      let content = null;

      if (partnersWithContacts.length === 0) {
        content = (
          <div>
            <Text>
              There are no contacts associated with any of the partners you have
              selected. Please add contacts to your partners first.
            </Text>
          </div>
        );
      } else {
        content = (
          <div>
            <Text>
              Are you sure you want to broadcast this email to the following
              partners?
            </Text>
            <div style={{ marginTop: "12px" }}>{contactDetails}</div>
            <br />
            {partnersWithoutContacts.length > 0 && (
              <Text type="warning">
                Note: <Text strong>{partnersWithoutContacts.length}</Text>{" "}
                {partnersWithoutContacts.length === 1
                  ? "partner has"
                  : "partners have"}{" "}
                no contacts associated.
              </Text>
            )}
          </div>
        );
      }

      const confirmData = modal.confirm({
        title: "Confirm Broadcast",
        content,
        onOk:
          partnersWithContacts.length > 0
            ? () => {
                onFinish(values);
              }
            : undefined,
        onCancel: () => {
          confirmData?.destroy();
        },
      });
    },
    [modal, onFinish, partnersMap]
  );

  return (
    <Create
      saveButtonProps={saveButtonProps}
      footerButtons={({ saveButtonProps }) => (
        <>
          <SaveButton {...saveButtonProps} icon={<SendOutlined />}>
            Broadcast
          </SaveButton>
        </>
      )}
      goBack={null}
    >
      <Row gutter={[32, 32]}>
        <Col span={8}>
          <Form
            {...formProps}
            layout="vertical"
            onValuesChange={onValuesChange}
            onFinish={onFormFinish}
          >
            <Form.Item
              label="Partners"
              name="partner_record_ids"
              rules={[{ required: true }]}
              getValueFromEvent={getValueFromEvent}
            >
              <Select
                options={optionsWithAllOption}
                style={{ width: "100%" }}
                showSearch={true}
                filterOption={FilterOption}
                placeholder="Select partners"
                mode="multiple"
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="Email Subject"
              name="subject"
              rules={[{ required: true }]}
              initialValue={INITIAL_SUBJECT}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email Content"
              name="content"
              rules={[{ required: true }]}
              initialValue={INITIAL_CONTENT}
            >
              <ReactQuillEditor style={{ maxWidth: "100%" }} />
            </Form.Item>
          </Form>
        </Col>

        <Col span={16}>
          <HTMLPreview srcDoc={srcDoc} />
        </Col>
      </Row>
    </Create>
  );
};
export default EmailCampagin;
