import { ListTitleButton } from "@/components";
import { ClientRecord, Status, transformStatus } from "@/types";
import { List, useTable } from "@refinedev/antd";
import { HttpError, useList } from "@refinedev/core";
import { Space } from "antd";
import { FC, PropsWithChildren } from "react";
import { ClientsTableView } from "./components";

export const ClientsListPage: FC<PropsWithChildren> = ({ children }) => {
  const { data: statuses } = useList<Status>({
    pagination: {
      mode: "off",
    },
    resource: "partners/status",
  });
  const statusOptions = transformStatus(statuses?.data);

  const { tableProps, filters, sorters } = useTable<
    ClientRecord,
    HttpError,
    { name: string }
  >({
    resource: "partners/client_records",
    pagination: {
      pageSize: 50,
    },
  });

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <Space></Space>;
        }}
        title={<ListTitleButton toPath="clients" buttonText="Add new client" />}
      >
        <ClientsTableView
          tableProps={tableProps}
          filters={filters}
          parent_resources={"partners/client_records"}
          sorters={sorters}
          statusOptions={statusOptions}
        />
      </List>
      {children}
    </div>
  );
};
