import { DashboardPage, PartnerDashboardPage, ResourcesPage } from "@/pages";
import {
  common_resources,
  company_resources,
  partner_resources,
  super_admin_resources,
} from "@/resources";
import type { Identity } from "@/types";
import { useAuth, useClerk, useUser } from "@clerk/clerk-react";
import {
  ErrorComponent,
  ThemedLayoutV2,
  ThemedSiderV2,
  useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { Authenticated, AuthProvider, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { App as AntdApp } from "antd";
import LogRocket from "logrocket";
import { useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { FullScreenLoading } from "./components";
import { CatalogPage } from "./components/connections";
import { Header } from "./components/header";
import { AppTitle } from "./components/layout/title";
import { AppModeContext, AppModeContextProvider } from "./contexts/app-context";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { CompanyLogsPage } from "./pages/admin/logs";
import { CompanySettingsPage } from "./pages/admin/settings";
import {
  AutomationCreatePage,
  AutomationEditPage,
  AutomationListPage,
} from "./pages/automations";
import {
  CommissionStructurePage,
  CreateOpportunitiesPage,
  EditCommissionsPage,
  OpportunitiesListPage,
} from "./pages/billing";
import { EditOpportunitiesPage } from "./pages/billing/opportunities_edit";
import { OpportunitiesPageView } from "./pages/billing/opportunities_view";
import { ClientRecordCreatePage } from "./pages/client_records/create";
import { ClientMappingsPage } from "./pages/clients/client_mapping";
import { ClientsListPage } from "./pages/clients/list";
import { CompanyShowPage } from "./pages/companies";
import { ContactShowPage } from "./pages/contacts";
import {
  ContactTypeCreatePage,
  ContactTypeEditPage,
  ContactTypeShowPage,
  ContactTypesListPage,
} from "./pages/contacts/types";
import { ContractsCreatePage, ContractsListPage } from "./pages/contracts";
import { CustomFieldsExternalList } from "./pages/custom_fields_external";
import { DealDeskCreatePage, DealDeskListPage } from "./pages/deal_desk";
import { OpportunitiesPagePartnerView } from "./pages/deal_desk/view";
import { Login } from "./pages/login";
import { MeetingListPage } from "./pages/meetings";
import {
  OpportunityTypeCreatePage,
  OpportunityTypeEditPage,
  OpportunityTypeShowPage,
  OpportunityTypesListPage,
} from "./pages/opportunity/types";
import {
  PartnerInboundCreatePage,
  PartnerInboundEditPage,
  PartnerInboundListPage,
  PartnerOpportunityShowPage,
} from "./pages/partner_inbound";
import { PartnerInboundPage } from "./pages/partner_inbound/page";
import { PartnerResourcesPage } from "./pages/partner_resources";
import {
  PartnerCreatePage,
  PartnerEditPage,
  PartnerListPage,
} from "./pages/partners";
import { KanbanCreateStage, KanbanEditStage } from "./pages/partners/kanban";
import {
  PartnerTypeCreatePage,
  PartnerTypeEditPage,
  PartnerTypeShowPage,
} from "./pages/partners/types";
import { PartnerTypesListPage } from "./pages/partners/types/list";
import { PlaceHolderPage } from "./pages/placeholder";
import {
  ReferralPageCreatePage,
  ReferralPageListPage,
  ReferralsShowPage,
} from "./pages/referral-pages";
import { ReferralPageEditPage } from "./pages/referral-pages/edit";
import { ReferralPage } from "./pages/referrals/create";
import { ReportsEditPage, ReportsListPage } from "./pages/reports";
import { ReportsCreatePage } from "./pages/reports/create";
import {
  FeatureFlagsCreatePage,
  FeatureFlagsEditPage,
  FeatureFlagsListPage,
} from "./pages/super_admin/feature_flag";
import {
  PromptsCreatePage,
  PromptsEditPage,
  PromptsListPage,
} from "./pages/super_admin/prompt";
import { TaskCreatePage, TaskEditPage, TaskListPage } from "./pages/tasks";
import { EditPersonPage } from "./pages/team/edit";
import { TeamListPage } from "./pages/team/list";
import {
  TrainingCreatePage,
  TrainingEditPage,
  TrainingListPage,
} from "./pages/training";
import { PartnerTrainingListPage } from "./pages/training/partner_list";
import { API_BASE_URL } from "./providers";
import { customTitleHandler } from "./providers/customHeader";
import globalAxiosInstance from "./providers/globalAxiosProvider";
import EmailCampaign from "@/pages/emailCampaign";
import { CustomThemedSiderV2 } from "./components/custom-sider";
import { DebugPage } from "./pages/super_admin/debug";

function App() {
  const { loaded } = useClerk(); // Clerk uses isLoaded instead of isLoading

  return loaded ? (
    <BrowserRouter>
      <AppModeContextProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <RefineWrapper />
          </AntdApp>
        </ColorModeContextProvider>
      </AppModeContextProvider>
    </BrowserRouter>
  ) : (
    <FullScreenLoading />
  );
}

const RefineWrapper: React.FC = () => {
  const { getToken } = useAuth(); // Clerk's token retrieval method
  const { signOut } = useClerk(); // Using `useClerk` to log out
  const { user, isSignedIn } = useUser(); // Using `useClerk` to log out
  const { mode } = useContext(AppModeContext);
  globalAxiosInstance.interceptors.request.use(async (config) => {
    const token = await getToken();
    if (config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });

  const authProvider: AuthProvider = {
    login: async () => {
      const token = await getToken();
      if (!token) {
        return {
          success: false,
        };
      }
      return {
        success: true,
        redirectTo: "/",
      };
    },

    logout: async () => {
      await signOut();
      return {
        success: true,
        redirectTo: "/",
      };
    },

    onError: async (error) => {
      console.error(error);
      if (error && error.response && error.response.status === 403) {
        await signOut();
        return {
          success: true,
          redirectTo: "/",
        };
      }

      return { error };
    },

    check: async () => {
      try {
        const token = await getToken();
        if (!token) {
          return { authenticated: false };
        }

        // Ping the API for verification
        await globalAxiosInstance.post(`${API_BASE_URL}/login`);

        // Clerk's `useUser` gives access to user details
        const email = user?.primaryEmailAddress?.emailAddress;

        // LogRocket initialization for certain users
        if (
          !email?.endsWith("@propelgtm.com") &&
          email !== "tonydong91@gmail.com" &&
          email !== "tony3dong@gmail.com"
        ) {
          if (import.meta.env.MODE === "production") {
            LogRocket.identify(email, {
              name: user?.fullName,
              email,
            });
          }
        }

        return {
          redirectTo: "/",
          authenticated: true,
        };
      } catch (error: any) {
        await signOut(); // Log out in case of an error
        return {
          authenticated: false,
          error: new Error(error),
          redirectTo: "/login",
          logout: true,
        };
      }
    },

    getPermissions: async () => null,

    getIdentity: async (): Promise<Identity> => {
      if (isSignedIn && user) {
        return user;
      }
      return null;
    },
  };

  let resources = mode === "Company" ? company_resources : partner_resources;
  resources = resources.concat(common_resources);

  const isSuperAdmin =
    user?.primaryEmailAddress?.emailAddress?.endsWith("@propelgtm.com");
  if (isSuperAdmin) {
    resources = resources.concat(super_admin_resources);
  }

  return (
    <DevtoolsProvider>
      <Refine
        dataProvider={{
          default: dataProvider(
            "https://api.propelgtm.com",
            globalAxiosInstance
          ),
          propelApi: dataProvider(
            "https://api.propelgtm.com",
            globalAxiosInstance
          ),
        }}
        notificationProvider={useNotificationProvider}
        routerProvider={routerBindings}
        authProvider={authProvider}
        resources={resources}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
        }}
      >
        <Routes>
          {/** Optionally Authed Pages */}

          <Route
            element={
              <ThemedLayoutV2
                Title={AppTitle}
                Header={() => <div />}
                Sider={() => <div />}
              >
                <Outlet />
              </ThemedLayoutV2>
            }
          >
            <Route
              path="/referrals/:id"
              element={<ReferralPage authed={false} />}
            />
            <Route
              path="/partner-signup/:id"
              element={<PartnerInboundPage />}
            />
          </Route>

          {/** Authed Pages */}
          <Route
            element={
              <Authenticated
                key="authenticated-inner"
                fallback={<CatchAllNavigate to="/login" />}
              >
                <ThemedLayoutV2
                  Title={AppTitle}
                  Header={() => <Header sticky />}
                  Sider={(props) => <CustomThemedSiderV2 {...props} fixed />}
                >
                  <Outlet />
                </ThemedLayoutV2>
              </Authenticated>
            }
          >
            {mode === "Company" ? (
              <Route index element={<DashboardPage />} />
            ) : (
              <Route index element={<PartnerDashboardPage />} />
            )}
            {/********************** Company View ********************/}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route
              path="/partners"
              element={
                <PartnerListPage>
                  <Outlet />
                </PartnerListPage>
              }
            >
              <Route path="create" element={<PartnerCreatePage />} />
              <Route path="statuses/create" element={<KanbanCreateStage />} />
              <Route path="statuses/edit/:id" element={<KanbanEditStage />} />
              <Route path="statuses/manage" element={<KanbanCreateStage />} />
            </Route>
            <Route path="/partners/edit/:id" element={<PartnerEditPage />} />
            {/*<Route path="/partners/prospecting" element={<ProspectingPage />} />*/}
            <Route path="/partners/prospecting" element={<PlaceHolderPage />} />
            <Route
              path="/partners/types"
              element={
                <PartnerTypesListPage>
                  <Outlet />
                </PartnerTypesListPage>
              }
            >
              <Route path="create" element={<PartnerTypeCreatePage />} />
              <Route path="edit/:id" element={<PartnerTypeEditPage />} />
              <Route path="show/:id" element={<PartnerTypeShowPage />} />
            </Route>
            <Route
              path="/contacts/types"
              element={
                <ContactTypesListPage>
                  <Outlet />
                </ContactTypesListPage>
              }
            >
              <Route path="create" element={<ContactTypeCreatePage />} />
              <Route path="edit/:id" element={<ContactTypeEditPage />} />
              <Route path="show/:id" element={<ContactTypeShowPage />} />
            </Route>
            <Route
              path="/partners/fields/external"
              element={
                <CustomFieldsExternalList>
                  <Outlet />
                </CustomFieldsExternalList>
              }
            ></Route>
            <Route
              path="/partners/pages"
              element={
                <PartnerInboundListPage>
                  <Outlet />
                </PartnerInboundListPage>
              }
            >
              <Route path="create" element={<PartnerInboundCreatePage />} />
              <Route path="edit/:id" element={<PartnerInboundEditPage />} />
              {/*
              <Route
                path="opportunities/show/:id"
                element={<PartnerOpportunityShowPage />}
              />
              */}
            </Route>
            <Route
              path="/partners/pages/opportunities/show/:id"
              element={<PartnerOpportunityShowPage />}
            />
            {!isSuperAdmin && (
              <Route path="/automations" element={<PlaceHolderPage />} />
            )}
            {isSuperAdmin && (
              <Route
                path="/automations"
                element={
                  <AutomationListPage>
                    <Outlet />
                  </AutomationListPage>
                }
              >
                <Route path="create" element={<AutomationCreatePage />} />
                <Route path="edit/:id" element={<AutomationEditPage />} />
              </Route>
            )}
            {!isSuperAdmin && (
              <Route path="/tasks" element={<PlaceHolderPage />} />
            )}
            {isSuperAdmin && (
              <Route
                path="/tasks"
                element={
                  <TaskListPage>
                    <Outlet />
                  </TaskListPage>
                }
              >
                <Route path="create" element={<TaskCreatePage />} />
                <Route path="edit/:id" element={<TaskEditPage />} />
              </Route>
            )}
            <Route
              path="/partners/contracts"
              element={
                <ContractsListPage>
                  <Outlet />
                </ContractsListPage>
              }
            >
              <Route path="create" element={<ContractsCreatePage />} />
            </Route>
            <Route
              path="/partners/meetings"
              element={
                <MeetingListPage>
                  <Outlet />
                </MeetingListPage>
              }
            ></Route>
            <Route path="/commissions" element={<Outlet />}>
              <Route
                path="opportunities"
                element={
                  <OpportunitiesListPage>
                    <Outlet />
                  </OpportunitiesListPage>
                }
              >
                <Route path="create" element={<CreateOpportunitiesPage />} />
                <Route
                  path="edit/:id"
                  element={<EditOpportunitiesPage enableStatus={true} />}
                />
                <Route
                  path="convert/:id"
                  element={<ReferralsShowPage close_route="opportunities" />}
                />
              </Route>
              <Route
                path="opportunities/types"
                element={
                  <OpportunityTypesListPage>
                    <Outlet />
                  </OpportunityTypesListPage>
                }
              >
                <Route path="create" element={<OpportunityTypeCreatePage />} />
                <Route path="edit/:id" element={<OpportunityTypeEditPage />} />
                <Route path="show/:id" element={<OpportunityTypeShowPage />} />
              </Route>
              <Route
                path="structure"
                element={
                  <CommissionStructurePage>
                    <Outlet />
                  </CommissionStructurePage>
                }
              >
                <Route path="edit/:id" element={<EditCommissionsPage />} />
              </Route>

              <Route path="analytics" element={<PlaceHolderPage />} />
              {/*<Route path="payments" element={<PlaceHolderPage />} />*/}
              <Route path="rewards" element={<PlaceHolderPage />} />
            </Route>
            <Route
              path="/commissions/opportunities/show/:id"
              element={<OpportunitiesPageView enableStatus={true} />}
            />
            <Route
              path="/referral-pages"
              element={
                <ReferralPageListPage>
                  <Outlet />
                </ReferralPageListPage>
              }
            >
              <Route path="create" element={<ReferralPageCreatePage />} />
              <Route path="edit/:id" element={<ReferralPageEditPage />} />
              <Route
                path="opportunities/show/:id"
                element={<ReferralsShowPage />}
              />
            </Route>
            <Route
              path="/referrals_authed/:id"
              element={<ReferralPage authed={true} />}
            />
            <Route path="/revenue/import" element={<PlaceHolderPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/co-marketing" element={<PlaceHolderPage />} />
            {isSuperAdmin ? (
              <Route path="/email_campaign" element={<EmailCampaign />} />
            ) : (
              <Route path="/email_campaign" element={<PlaceHolderPage />} />
            )}
            <Route
              path="/training"
              element={
                <TrainingListPage>
                  <Outlet />
                </TrainingListPage>
              }
            >
              <Route path="create" element={<TrainingCreatePage />} />
              <Route path="edit/:id" element={<TrainingEditPage />} />
            </Route>
            )
            <Route path="/feedback" element={<PlaceHolderPage />} />
            <Route path="/connections" element={<Outlet />}>
              <Route path="catalog" element={<CatalogPage />} />
            </Route>
            <Route
              path="/reports"
              element={
                <ReportsListPage>
                  <Outlet />
                </ReportsListPage>
              }
            >
              <Route path="create" element={<ReportsCreatePage />} />
              <Route path="edit/:id" element={<ReportsEditPage />} />
            </Route>
            {/********************** Partner View ********************/}
            <Route
              path="/partner-dashboard"
              element={<PartnerDashboardPage />}
            />
            <Route
              path="/clients"
              element={
                <ClientsListPage>
                  <Outlet />
                </ClientsListPage>
              }
            >
              <Route path="create" element={<ClientRecordCreatePage />} />
            </Route>
            <Route path="/clients/edit/:id" element={<PartnerEditPage />} />
            <Route path="/clients/mapping" element={<ClientMappingsPage />} />
            <Route
              path="/team"
              element={
                <TeamListPage>
                  <Outlet />
                </TeamListPage>
              }
            >
              <Route
                path="edit/:id"
                element={<EditPersonPage return_resource="team" />}
              />
            </Route>
            <Route
              path="partner-resources"
              element={<PartnerResourcesPage />}
            />
            <Route
              path="/partner-training"
              element={
                <PartnerTrainingListPage>
                  <Outlet />
                </PartnerTrainingListPage>
              }
            ></Route>
            <Route path="/revenue" element={<Outlet />}>
              <Route
                path="deal-desk"
                element={
                  <DealDeskListPage>
                    <Outlet />{" "}
                  </DealDeskListPage>
                }
              >
                <Route path="create" element={<DealDeskCreatePage />} />
              </Route>
              {/*<Route path="payments" element={<PlaceHolderPage />} /> */}
            </Route>
            <Route
              path="/revenue/deal-desk/show/:id"
              element={
                <OpportunitiesPagePartnerView
                  enableStatus={false}
                  resource="deal-desk"
                />
              }
            />
            <Route path="/connections-partners" element={<Outlet />}>
              <Route path="catalog" element={<CatalogPage />} />
            </Route>
            {/********************** All View ********************/}
            <Route path="/administration" element={<Outlet />}>
              <Route
                path="settings"
                element={
                  <CompanySettingsPage>
                    <Outlet />
                  </CompanySettingsPage>
                }
              >
                <Route
                  path="team/edit/:id"
                  element={<EditPersonPage return_resource="settings" />}
                />
              </Route>
              <Route path="logs" element={<CompanyLogsPage />} />
            </Route>
            <Route path="/companies/show/:id" element={<CompanyShowPage />} />
            <Route path="/contacts/show/:id" element={<ContactShowPage />} />
            <Route path="/contacts/edit/:id" element={<ContactShowPage />} />
            {/********************** Super Admin View ********************/}
            {isSuperAdmin && (
              <Route>
                <Route
                  path="/prompts"
                  element={
                    <PromptsListPage>
                      <Outlet />
                    </PromptsListPage>
                  }
                >
                  <Route path="create" element={<PromptsCreatePage />} />
                  <Route path="edit/:id" element={<PromptsEditPage />} />
                </Route>
                <Route
                  path="/feature_flags"
                  element={
                    <FeatureFlagsListPage>
                      <Outlet />
                    </FeatureFlagsListPage>
                  }
                >
                  <Route path="create" element={<FeatureFlagsCreatePage />} />
                  <Route path="edit/:id" element={<FeatureFlagsEditPage />} />
                </Route>
                <Route path="debug" element={<DebugPage />}></Route>
              </Route>
            )}
            <Route path="*" element={<ErrorComponent />} />
          </Route>

          {/** Login Pages */}
          <Route
            element={
              <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                <NavigateToResource
                  resource={
                    mode === "Company" ? "dashboard" : "partner-dashboard"
                  }
                />
              </Authenticated>
            }
          >
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
        <UnsavedChangesNotifier />
        <DocumentTitleHandler handler={customTitleHandler} />
      </Refine>
      <DevtoolsPanel />
    </DevtoolsProvider>
  );
};

export default App;
