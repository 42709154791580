import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import {
  Person,
  ReferralPageInfo,
  Tag,
  TagInfo,
  transformPersons,
  transformTags,
} from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  useInvalidate,
  useList,
  useNavigation,
  useParsed,
  useUpdate,
} from "@refinedev/core";
import MDEditor from "@uiw/react-md-editor";
import { Form, Input, Modal, Select, InputNumber, Checkbox } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import ReactQuillEditor from "@/components/quill-editor/editor";
import FormLayoutInput from "@/components/form-layout-input";

type Props = {
  isOverModal?: boolean;
};

export const ReferralPageEditPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const resource = "referrals";

  const { formProps, modalProps, close } = useModalForm<ReferralPageInfo>({
    action: "edit",
    defaultVisible: true,
    resource: "referral_pages",
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
    redirect: false,
    syncWithLocation: { key: resource },
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  const originalKey = formProps?.initialValues?.key;
  const haveMarkdownDetails = !!formProps?.initialValues?.details;

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = transformPersons(people?.data);

  const [key, setKey] = useState();
  const [found, setFound] = useState(false);
  useEffect(() => {
    if (key !== undefined) {
      if (key !== "") {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<ReferralPageInfo>(
              `${API_BASE_URL}/referral_pages/search`,
              {
                params: { key: key },
              }
            );

            if (response.data && response.data.ID !== 0) {
              setFound(true);
            } else {
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [key]);
  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setKey(changedValues.key);
    }
  }, 500);

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Opportunity",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "ReferralPageInfos",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: id,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);

  const { mutate } = useUpdate();
  const handleChange = (newValues) => {
    mutate(
      {
        resource: "referral_pages",
        values: {
          tags: newValues,
        },
        id: id,
        successNotification: false,
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ["list"],
            resource: "generic_tags",
          });
        },
      }
    );
  };

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Edit Opportunity Page"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Key"
          name={"key"}
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (found && originalKey !== value) {
                  return Promise.reject(
                    new Error("This Key is already in use.")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^[a-zA-Z0-9_-]+$/,
              message:
                "Key can only contain letters, numbers, hyphen, or underscore",
            },
            {
              max: 255,
              message: "Key must be less than 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="subscribed_people_ids" label="People">
          <Select
            mode="multiple"
            autoFocus
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Subscribed People"
          />
        </Form.Item>
        <Form.Item label="Opportunity Type">
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            defaultValue={appliedTagValues}
            value={appliedTagValues}
            options={tagOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleChange}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item name="private" valuePropName="checked">
          <Checkbox>Only allow logged in user</Checkbox>
        </Form.Item>
        <Form.Item name="should_create_lead_in_crm" valuePropName="checked">
          <Checkbox>Should Create Lead in CRM</Checkbox>
        </Form.Item>

        <Form.Item name="should_notify_before_expiry" valuePropName="checked">
          <Checkbox>Should Enable Expiry</Checkbox>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.should_notify_before_expiry !==
            currentValues.should_notify_before_expiry
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue("should_notify_before_expiry") ? (
              <Form.Item
                label="Default Expiration Date After Creation"
                name="pre_expiry_notification_days"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of days",
                  },
                  {
                    type: "number",
                    min: 0,
                    message: "Must be at least 0 days",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <div
          style={{
            marginTop: 10,
            marginBottom: 16,
          }}
        >
          <TagBasedCustomFieldsTable
            taggable_object={"ReferralPageInfos"}
            field_reference_table={"Opportunity"}
            override_taggable_object={"Opportunity"}
            override_object_id={0}
            style={{
              marginTop: 8,
            }}
            show_resource="opportunity-types"
            info_only={true}
            collapsable={true}
          />
        </div>
        <FormLayoutInput />
        <Form.Item
          label="Details"
          name={"html_details"}
          hidden={haveMarkdownDetails}
        >
          <ReactQuillEditor style={{ maxWidth: "100%" }} />
        </Form.Item>

        {/* @TODO: remove it after migration */}
        <Form.Item
          label="Details"
          name={"details"}
          hidden={!haveMarkdownDetails}
        >
          <MDEditor height={350} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
