import React from "react";
import { Form, Select, Space, Card } from "antd";
import type { FormItemProps } from "antd";

const FormLayoutInput: React.FC<FormItemProps> = (props) => (
  <Form.Item {...props}>
    <Card title="Form customization">
      {/* Layout Selector */}
      <Space
        size="large"
        style={{ width: "100%" }}
        styles={{ item: { width: "100%" } }}
      >
        <Form.Item
          name={["form_styles", "layout"]}
          label="Form layout"
          initialValue="vertical"
        >
          <Select
            options={[
              { label: "Horizontal", value: "horizontal" },
              { label: "Vertical", value: "vertical" },
            ]}
            placeholder="Select Layout"
          />
        </Form.Item>

        {/* Variant Selector */}
        <Form.Item
          name={["form_styles", "variant"]}
          label="Variant"
          initialValue="outlined"
        >
          <Select
            options={[
              { label: "Borderless", value: "borderless" },
              { label: "Outlined", value: "outlined" },
              { label: "Filled", value: "filled" },
            ]}
            placeholder="Select Variant"
          />
        </Form.Item>

        {/* Size Selector */}
        <Form.Item
          name={["form_styles", "size"]}
          label="Size"
          initialValue="large"
        >
          <Select
            options={[
              { label: "Small", value: "small" },
              { label: "Middle", value: "middle" },
              { label: "Large", value: "large" },
            ]}
            placeholder="Select Size"
          />
        </Form.Item>

        {/* Size Selector */}
        <Form.Item
          name={["form_styles", "align"]}
          label="Alignment"
          initialValue="center"
        >
          <Select
            options={[
              { label: "Left", value: "start" },
              { label: "Center", value: "center" },
              { label: "Right", value: "end" },
            ]}
            placeholder="Select alignment"
          />
        </Form.Item>
      </Space>
    </Card>
  </Form.Item>
);

export default FormLayoutInput;
