import { CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { Contact } from "@/types";
import { getNameInitials } from "@/utilities";
import { GetOneResponse } from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Form, Space } from "antd";
import { FC } from "react";
import { ObjectSyncStatusComponent } from "../sync";

type Props = {
  queryResult: QueryObserverResult<GetOneResponse<Contact>> | undefined;
};

export const ContactTitleForm: FC<Props> = ({ queryResult }) => {
  const contact = queryResult?.data?.data;

  return (
    <LoadingWrapper loading={queryResult?.isLoading}>
      <Space
        direction="vertical"
        style={{ width: "100%", position: "relative" }}
      >
        <Form>
          <Space
            size={16}
            style={{
              width: "100%",
              paddingBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <CustomAvatar
              size="large"
              shape="square"
              src={contact?.person?.avatar_url}
              name={getNameInitials(contact?.person?.name || "")}
              style={{
                width: 30,
                height: 30,
                fontSize: 48,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
              }}
            />
            <Text style={{ fontSize: "32px", fontWeight: 700 }}>
              {contact?.person?.name}
            </Text>
          </Space>
        </Form>
        <ObjectSyncStatusComponent
          style={{ position: "absolute", right: 0, top: 5 }}
          reference_table="Contact"
          reference_id={contact.ID}
        />
      </Space>
    </LoadingWrapper>
  );
};
