import { Modal, Form, Input, Select } from "antd";
import { FilterOption } from "@/utilities/select";

const CreatePersonModal = ({
  title,
  formProps,
  modalProps,
  onFinish,
  companyOptions,
  closeModal,
}) => (
  <Modal
    {...modalProps}
    title={title}
    onCancel={() => {
      formProps.form.resetFields();
      closeModal();
    }}
  >
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please enter a name" }]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please enter an email" },
          { type: "email", message: "Please enter a valid email" },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item label="Company" name="company_id" rules={[{ required: true }]}>
        <Select
          allowClear
          showSearch={true}
          options={companyOptions}
          filterOption={FilterOption}
          placeholder="Company"
        />
      </Form.Item>
    </Form>
  </Modal>
);

export default CreatePersonModal;
