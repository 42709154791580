import ReactQuillEditor from "@/components/quill-editor/editor";
import "@/pages/partners/create-higlight-input.css";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { PartnerPageInfo, Person, transformPersons } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Form, Input, Modal, Select } from "antd";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

type Props = {
  isOverModal?: boolean;
};

export const PartnerInboundCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { formProps, modalProps, close } = useModalForm<
    PartnerPageInfo,
    HttpError,
    PartnerPageInfo
  >({
    action: "create",
    defaultVisible: true,
    resource: "partners/pages",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "partner-page" });
      list("partner-page", "replace");
    },
  });

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = transformPersons(people?.data);

  const [key, setKey] = useState();
  const [found, setFound] = useState(false);
  useEffect(() => {
    if (key !== undefined) {
      if (key !== "") {
        const fetchData = async () => {
          try {
            const response = await globalAxiosInstance.get<PartnerPageInfo>(
              `${API_BASE_URL}/partners/pages/search`,
              {
                params: { key: key },
              }
            );

            if (response.data && response.data.ID !== 0) {
              setFound(true);
            } else {
              setFound(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [key]);
  const handleValuesChange = debounce((changedValues) => {
    if (changedValues) {
      setKey(changedValues.key);
    }
  }, 500);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("partner-page", "replace");
      }}
      title="Add new page"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Key"
          name="key"
          rules={[
            { required: true },
            {
              validator: () => {
                if (found) {
                  return Promise.reject(
                    new Error("This Key is already in use.")
                  );
                }
                return Promise.resolve();
              },
            },
            {
              pattern: /^[a-zA-Z0-9_-]+$/,
              message:
                "Key can only contain letters, numbers, hyphen, or underscore",
            },
            {
              max: 255,
              message: "Key must be less than 255 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="subscribed_people_ids"
          label="People"
        >
          <Select
            mode="multiple"
            autoFocus
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Subscribed People"
          />
        </Form.Item>
        <Form.Item label="Details" name={"details"}>
          <ReactQuillEditor style={{ maxWidth: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
