import { useClerk } from "@clerk/clerk-react";
import { useLink } from "@refinedev/core";
import { Button, Layout, Space, Typography } from "antd";

export const Login: React.FC = () => {
  const { redirectToSignIn } = useClerk();
  const Link = useLink();

  return (
    <Layout
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space direction="vertical" align="center">
        <Link
          to="/"
          style={{
            display: "inline-block",
            textDecoration: "none",
          }}
        >
          <Space
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "22px",
              marginBottom: "36px",
            }}
          >
            <Typography.Title
              style={{
                fontSize: "inherit",
                marginBottom: 0,
                fontWeight: 700,
              }}
            >
              Propel GTM
            </Typography.Title>
          </Space>
        </Link>

        <Button
          style={{ width: "240px", marginBottom: "32px" }}
          type="primary"
          size="middle"
          onClick={() => redirectToSignIn()}
        >
          Sign in
        </Button>
      </Space>
    </Layout>
  );
};
