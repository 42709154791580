import type { FormProps, UploadFile } from "antd";
import { CustomFieldType } from "./custom_field_type";

export interface DashboardResponse {
  active_partners: number;
  active_clients: number;
  pending_actions: number;
}

export interface PartnerDashboardResponse {
  active_partners: number;
  active_clients: number;
  pending_actions: number;
}

export type PartnerRecord = {
  ID: number;
  company_id: number;
  company: Company;
  partner_company_id: number;
  partner_company: Company;
  owner_id: number;
  owner: Person;
  status_id: number;
  status: Status;
  last_touch: NullableDate;
  next_meeting: NullableDate;
  contacts: Contact[];
  tags: Tag[];
  has_users?: boolean;
};

export type Status = {
  ID: number;
  name: string;
  order: number;
};

export type CompanyResponse = {
  nodes: Array<
    Pick<Company, "CompanyName" | "PhotoUrl" | "Domain" | "CompanySize">
  >;
};

export type CompanyDetailsResponse = {
  company: Company;
  connections: ExternalCompanyConnection;
  events: CompanyEvent[];
};

export interface Company {
  ID: number;
  CompanyName: string;
  PhotoUrl: string;
  Domain: string;
  CompanySize: string;
  Description: string;
  Industries: string[];
  Owner: Person;
}

export interface CompanyEvent {
  Action: string;
  Type: string;
}

export interface ExternalCompanyConnection {
  ExternalId: string;
  Type: string;
  connection_company_id: number;
}

export type CampaignResponse = {
  nodes: Array<
    Pick<
      Campaign,
      | "ID"
      | "Name"
      | "CampaignDescription"
      | "Active"
      | "CampaignIncentive"
      | "CampaignPrompts"
      | "CampaignSenderIDs"
      | "MaxPerCompany"
      | "CampaignBaseEmail"
      | "CompanyProfileIds"
      | "CompanyProfiles"
      | "PeopleProfileIds"
      | "PeopleProfiles"
      | "CampaignType"
    >
  >;
};
export interface Campaign {
  ID?: number;
  Name?: string;
  CampaignDescription?: string;
  Active?: boolean;
  CampaignIncentive?: string;
  CampaignPrompts?: string;
  CampaignSenderIDs?: number[];
  MaxPerCompany?: number;
  CampaignBaseEmail?: string;
  CompanyProfileIds?: number;
  CompanyProfiles?: CompanyProfile[];
  PeopleProfileIds?: number;
  PeopleProfiles?: PeopleProfile[];
  CampaignType?: string;
  EventPlatform?: string;
  LummaEvent?: LummaEvent;
}

export interface LummaEvent {
  cover_url: string;
  name: string;
  num_guests_approved: number;
  start_at: Date;
  end_at: Date;
}

export type Users = {
  nodes: Array<Pick<User, "ID" | "person_id" | "person">>;
};
export interface User {
  ID: number;
  person_id: number;
  person: Person;
  roles: PermissionsRole[];
}
export interface PermissionsRole {
  ID: number;
  name: string;
  type: string;
  policies: PermissionsPolicy[];
}

export interface PermissionsPolicy {
  ID: number;
  name: string;
  description: string;
  effect: string;
  action: string;
  resource: string;
}

export interface Preference {
  default_partner_filter: number;
  default_partner_type_filter: number;
  user_mode_state: string;
  default_partner_view: string;
}

export interface Person {
  ID?: number;
  email?: NullString;
  given_name?: string;
  family_name?: string;
  name?: string;
  company_id?: number;
  avatar_url?: string;
  company?: Company;
  current_jobs?: Job[];
  user?: User;
}

export interface NullString {
  String: string;
  Valid: boolean;
}

export type OutBoundResponse = {
  nodes: Array<
    Pick<OutBound, "ID" | "Content" | "ScheduledTime" | "SentTime" | "TypeId">
  >;
};
export interface OutBound {
  ID: number;
  Content: string;
  ScheduledTime: Date;
  SentTime: NullableDate;
  TypeId: number;
  Type: OutboundType;
  Company: Company;
  OutboundSequence: OutBoundSequence;
}

export interface OutBoundSequence {
  To: Person;
  From: Person;
}

export interface NullableDate {
  Time: Date;
  Valid: boolean;
}

export interface NullableFloat64 {
  Float64: number;
  Valid: boolean;
}

export interface OutboundType {
  ID: number;
  Name: string;
}

export interface Job {
  title: string;
  StartDate: NullableDate;
  JobFamily: string;
  JobLevel: string;
}

export interface GaugeChartConfiguration {
  title: string;
  percentage: number;
  type: string;
  total: number;
  realized: number;
}

export interface DataCatalogItem {
  ID?: number;
  name: string;
  icon_url: string;
  type: string;
  provider_config_key: string;
  connection_id: string;
  connection_state: string;
}

export interface DataSourceItem {
  ID?: number;
  name: string;
  icon_url: string;
  type: string;
  status: string;
}

export interface DataDestinationItem {
  ID?: number;
  name: string;
  icon_url: string;
  type: string;
  status: string;
}

export type PeopleProfileResponse = {
  nodes: Array<
    Pick<
      PeopleProfile,
      "Name" | "Description" | "TargetJobFamilies" | "TargetJobLevels"
    >
  >;
};
export interface PeopleProfile {
  ID?: number;
  Name?: string;
  Description?: string;
  TargetJobFamilies?: string[];
  TargetJobLevels?: string[];
}

export type CompanyProfileResponse = {
  nodes: Array<
    Pick<
      CompanyProfile,
      | "ID"
      | "Name"
      | "Description"
      | "TargetIndustries"
      | "TargetCompanySize"
      | "TargetCompanyTags"
    >
  >;
};

export interface CompanyProfile {
  ID?: number;
  Name?: string;
  Description?: string;
  TargetIndustries?: string[];
  TargetCompanySize?: string[];
  TargetCompanyTags?: string[];
}

export function transformCompanyProfiles(
  companies: CompanyProfile[] | undefined
): { label: string; value: number }[] {
  if (!companies) {
    return [];
  }
  return companies.map((node) => ({
    label: node.Name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

export function transformCompanies(
  companies: Company[] | undefined
): { label: string; value: number }[] {
  if (!companies) {
    return [];
  }
  return companies.map((node) => ({
    label: node.CompanyName ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

export function transformStatus(
  statuses: Status[] | undefined
): { label: string; value: number }[] {
  if (!statuses) {
    return [];
  }
  const sortedStatuses = statuses.sort((a, b) => {
    return (a.order ?? 0) - (b.order ?? 0);
  });

  return sortedStatuses.map((node) => ({
    label: String(node.order) + " - " + node.name,
    value: node.ID ?? 0,
  }));
}

export function transformPersons(
  persons: Person[] | undefined
): { label: string; value: number }[] {
  if (!persons) {
    return [];
  }
  return persons.map((node) => ({
    label: node.name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

export function transformClientRecords(
  records: ClientRecord[] | undefined
): { label: string; value: number }[] {
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.client_company.CompanyName ?? "ERROR",
    value: node.client_company_id ?? 0,
  }));
}

export function transformTags(
  tags: Tag[] | undefined
): { label: string; value: number }[] {
  if (!tags) {
    return [];
  }
  return tags.map((node) => ({
    label: node.Name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}

export function transformCustomFieldChoice(
  records: CustomFieldChoice[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.name,
    value: node.ID,
  }));
}

export interface Contact {
  ID?: number;
  company_id?: number;
  person_id?: number;
  person?: Person;
  reference_id?: number;
  reference_table?: string;
  user?: User;
}

export interface Note {
  ID?: number;
  CreatedAt: Date;
  company_id?: number;
  content?: string;
  created_by?: User;
  created_by_id?: number;
  reference_id?: number;
  reference_table?: string;
}

export interface Meeting {
  ID?: number;
  CreatedAt: Date;
  company_id?: number;
  reference_id?: number;
  reference_table?: string;
  content?: string;
  date?: Date;
  attendees?: Person[];
  attendee_ids: number[];
  created_by?: User;
  created_by_id?: number;
}

export interface ClientRecord {
  ID?: number;
  company: Company;
  company_id?: number;
  client_company_id: number;
  client_company: Company;
  status: string;
  team: TeamAssignment[];
  source_type: string;
  source_company_id: number;
  source_company: Company;
}

export interface PartnerClientRecord {
  ID?: number;
  company_id?: number;
  partner_company_id: number;
  partner_company: Company;
  client_company_id: number;
  client_company: Company;
  status: string;
}

export interface TeamAssignment {
  ID?: number;
  assignee_id?: number;
  assignee?: Person;
  client_company_id: number;
  client_company: Company;
  type: string;
}

export interface Contract {
  ID?: number;
  CreatedAt: Date;
  company_id?: number;
  Name: string;
  Url: string;
  ContentType: string;
  client_company_id: number;
  client_company: Company;
  files: UploadFile[];
}

export interface Opportunity {
  ID?: number;
  CreatedAt: Date;
  public_opportunity_id?: number;
  public_opportunity?: PublicOpportunity;
  name: string;
  company_id?: number;
  created_by_id: number;
  client_record_id: number;
  client_record: ClientRecord;
  partner_record_id: number;
  partner_record: PartnerRecord;
  contract_value: number;
  status: string;
  close_date?: Date;
  expiry_notificaton_date?: Date;
  commission_structure_id: number;
  commission_structure: CommissionStructure;
  commission_computed_value?: NullableFloat64;
  commission_override_value?: NullableFloat64;
  object_sync_status: ObjectSyncStatus;
  approved: boolean;
  rejection_reason: string;
  assignees?: Person[];
  assignees_ids?: number[];
  subscribed?: Person[];
  subscribed_ids?: number[];
}
export interface SelectOption {
  label: string;
  value: string;
}

export interface Tag {
  ID?: number;
  company_id?: number;
  Name: string;
}

export interface TagInfo {
  id?: number;
  name: string;
  taggable_type?: string;
  tag_id?: number;
}

export interface CustomField {
  ID?: number;
  company_id?: number;
  name?: string;
  type?: CustomFieldType;
  order?: number;
  metadata?: { [key: string]: string[] };
}

export interface CustomFieldChoice {
  ID?: number;
  name?: string;
}

export interface CommissionStructure {
  ID: number;
  name: string;
  commission_type: "ANNUAL" | "MONTHLY" | "QUARTERLY" | "ONE_TIME";
  actual_value?: NullableFloat64;
  percent_value?: NullableFloat64;
  status: "ACTIVE" | "INACTIVE";
  effective_from?: Date;
  effective_to?: NullableDate;
}

export interface CustomFieldResponse {
  id: number;
  name?: string;
  field_type?: CustomFieldType;
  string_value?: string;
  decimal_value?: number;
  bool_value?: boolean;
  datetime_value?: Date;
  email_value?: string;
  // TODO(jxu): consider other naming conventions
  select_value?: string;
  multi_select_value?: string[];
  file?: any;
  signed_file_value?: string;
  metadata?: { [key: string]: string[] };
}

export interface TagInfo {
  id?: number;
  name: string;
  taggable_type?: string;
}

export interface IDocument {
  ID: number;
  company_id: number;
  company: Company;
  CreatedAt: string;
  Name: string;
  Url: string;
  send_count: number;
  view_count: number;
  download_count: number;
  tag_infos?: Array<TagInfo>;
  ContentType: string;
}

export interface DocumentMetricResponse {
  share_metrics: DocumentMetricIntance[];
  view_metrics: DocumentMetricIntance[];
  download_metrics: DocumentMetricIntance[];
}

export interface DocumentMetricIntance {
  company_name: string;
  person_name: string;
  date: Date;
}

export interface FormStyles {
  layout: FormProps["layout"];
  variant: FormProps["variant"];
  size: FormProps["size"];
  align: "end" | "center" | "start";
}

export interface ReferralPageInfo {
  ID?: number;
  company_id: number;
  company: Company;
  key: string;
  partner_record_id: number;
  partner_record: PartnerRecord;
  details?: string;
  html_details?: string;
  subscribed_people_ids: number[];
  private: boolean;
  should_notify_before_expiry: boolean;
  should_create_lead_in_crm: boolean;
  pre_expiry_notification_days: number;
  form_styles?: FormStyles;
}

export type PublicOpportunity = {
  ID: number;
  CreatedAt: Date;
  first_name: string;
  last_name: string;
  job_title: string;
  email: string;
  company_name: string;
  partner_record_id: number;
  referral_page_id: number;
  referral_page: ReferralPageInfo;
  custom_fields: CustomFieldResponse[];
  expiration_date: Date;
  lead_external_link: string;
};

export type PublicPartnerOpportunity = {
  ID: number;
  first_name: string;
  last_name: string;
  job_title: string;
  email: string;
  company_name: string;
  company_id: number;
  domain: string;
  partner_page_info_id: number;
  referral_page_id: number;
  custom_fields: CustomFieldResponse[];
};

export interface PartnerPageInfo {
  ID?: number;
  company_id: number;
  company: Company;
  key: string;
  details: string;
  subscribed_people_ids: number[];
}

export interface ObjectSyncStatus {
  ID?: number;
  last_updated: Date;
  type: string;
  external_id: string;
  external_name: string;
  external_link: string;
}

export interface DataConnection {
  company_id: number;
  provider_config_key: string;
  connection_id: string;
}

export interface Task {
  ID?: number;
  title: string;
  description: string;
  completed_at: NullableDate;
  due_date: Date;
  assignee_ids: number[];
  subscribed_people_ids: number[];
  automated_task: boolean;
}

export interface Workflow {
  ID: number;
  name: string;
  description: string;
  active: boolean;
  created_by_id?: number;
  created_by?: User;
}

export interface WorkflowStep {
  ID: number;
  step_number: number;
  action_type: string;
  action_details: string;
}

export interface ObjectDetail {
  name: string;
  object_key: string;
  fields: ObjectField[];
}

export interface ObjectField {
  name: string;
  field_key: string;
  field_type: string;
  operations: Operation[];
  choice_values: CustomFieldChoice[];
}

export interface Operation {
  name: string;
  type: string;
}

export interface SalesforceCustomField {
  ID?: number;
  company_id?: number;
  reference_table: string;
  name: string;
  label: string;
  type: string;
  relationship_name: string;
  native_field: string;
  custom_field_id: number;
  custom_field: CustomField;
  sync_direction: string;
}

export interface SalesforceCustomObject {
  ID?: number;
  company_id?: number;
  reference_table: string;
  external_object: string;
  sync_direction: string;
  data_connection_id: number;
}

export interface DefaultField {
  ID?: number;
  company_id?: number;
  reference_table: string;
  name: string;
  value: string;
}

export type EmailTemplate = {
  ID: number;
  type: string;
  subject: string;
  content: string;
};

export type EmailCampaignPayload = {
  partner_record_ids: string[];
  subject: string;
  content: string;
};

export type EmailRecord = {
  ID: number;
  CreatedAt: Date;
  to: string;
  subject: string;
  body: string;
};
