import { CustomAvatar, Text } from "@/components";
import { useOpportunityStatuses } from "@/providers/options";
import { Opportunity } from "@/types";
import { currencyNumber } from "@/utilities";
import { DateField, List, ShowButton, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";
import { isDefaultDBDate } from "@/utilities";

export const DealDeskListPage: FC<PropsWithChildren> = ({ children }) => {
  const statusOptions = useOpportunityStatuses();

  const { tableProps } = useTable<Opportunity, HttpError, { name: string }>({
    resource: "opportunities",
    pagination: {
      mode: "off",
    },
    filters: {
      initial: [
        {
          field: "approved",
          operator: "eq",
          value: true,
        },
      ],
      mode: "server",
    },
    syncWithLocation: false,
  });

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title="Deal Desk"
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Opportunity>
            fixed="right"
            dataIndex="ID"
            title=""
            width={40}
            render={(_, record) => (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  resource="deal-desk"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />

          <Table.Column<Opportunity>
            dataIndex="name"
            title="Partner"
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    shape="square"
                    name={record.partner_record?.company?.CompanyName}
                    src={record.partner_record?.company?.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.partner_record?.company?.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Opportunity>
            dataIndex="name"
            title="Opportunity Name"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.name}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Opportunity>
            dataIndex="contract_value"
            title="Contract Value"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {currencyNumber(record.contract_value)}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Opportunity>
            dataIndex="close_date"
            title="Close Date"
            render={(_, record) => {
              return (
                <Space
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <DateField format="LL" value={record.close_date} />
                </Space>
              );
            }}
          />
          <Table.Column<Opportunity>
            dataIndex="expiry_notificaton_date"
            title="Expiry Date"
            render={(_, record) => {
              return (
                <Space
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {isDefaultDBDate(record.expiry_notificaton_date) ? (
                    <Text style={{ whiteSpace: "nowrap" }}>-</Text>
                  ) : (
                    <DateField
                      format="LL"
                      value={record.expiry_notificaton_date}
                    />
                  )}
                </Space>
              );
            }}
          />
          <Table.Column<Opportunity>
            dataIndex="status"
            title="Stage"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {statusOptions?.find((item) => item.value === record.status)
                      ?.label ?? record.status}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Opportunity>
            dataIndex="approved"
            title="Approved"
            filters={[
              {
                text: "Approved",
                value: "true",
              },
              {
                text: "Rejected",
                value: "false",
              },
              {
                text: "Show All",
                value: "",
              },
            ]}
            defaultFilteredValue={["true"]}
            filterMultiple={false}
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.approved
                      ? "Approved"
                      : "Rejected - " + record.rejection_reason}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
