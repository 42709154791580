import { useRef, useState } from "react";

export function HTMLPreview({
  srcDoc,
  returnFrame = false,
  autoAdjustHeight = false,
  minHeight = 400,
}: {
  srcDoc: string;
  returnFrame?: boolean;
  autoAdjustHeight?: boolean;
  minHeight?: number;
}) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState(minHeight);

  const handleLoad = () => {
    if (autoAdjustHeight && iframeRef.current) {
      const scrollHeightInFrame =
        iframeRef.current.contentWindow?.document.body.scrollHeight;
      const contentHeight = scrollHeightInFrame
        ? scrollHeightInFrame + 32
        : minHeight;
      setIframeHeight(contentHeight);
    }
  };

  const _frame = (
    <iframe
      ref={iframeRef}
      onLoad={handleLoad}
      style={{
        width: "100%",
        height: autoAdjustHeight ? `${iframeHeight}px` : "100%",
        minHeight: autoAdjustHeight ? iframeHeight : minHeight,
        border: "none",
        borderRadius: "5px",
      }}
      sandbox="allow-same-origin"
      srcDoc={srcDoc}
    />
  );

  return returnFrame ? (
    _frame
  ) : (
    <div
      className="html-viewer"
      style={{
        padding: "10px 8px 4px 8px",
        border: "1px solid #ccc",
        maxWidth: "100%",
        marginLeft: "auto",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h4>Email Preview</h4>
      {_frame}
    </div>
  );
}
