import { Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { TagSelectionComponent } from "@/pages/tags/select";
import {
  DocumentMetricIntance,
  DocumentMetricResponse,
  IDocument,
} from "@/types";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { DateField, DeleteButton, List, useTable } from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useDelete,
  useInvalidate,
  useOne,
} from "@refinedev/core";
import {
  Button,
  Drawer,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { FC, SetStateAction, useState } from "react";
import DocumentSharingDetailsModal from "./document_sharing";
import { UploadPage } from "./fileupload";

export const ResourcesPage = () => {
  return (
    <div className="page-container">
      <List title="My Resources" headerButtons={() => <div />}>
        <ResourcesComponent />
      </List>
    </div>
  );
};

interface TagInfo {
  id?: number;
  name: string;
  taggable_type?: string;
}

function handleAdd(buttonSet: React.Dispatch<SetStateAction<boolean>>) {
  console.log("Adding a doc");
  buttonSet(true);
}

interface DownloadDocumentButtonProps {
  record: IDocument;
  onDownloadComplete?: () => void;
}

export const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = ({
  record,
  onDownloadComplete,
}) => {
  const { data, isLoading, isError } = useOne<IDocument, HttpError>({
    resource: "document",
    id: record.ID,
  });

  const handleDownload = () => {
    const doc = data?.data;
    const url = doc?.Url;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (onDownloadComplete) {
      onDownloadComplete();
    }
  };

  return (
    <Button
      size="small"
      disabled={isLoading || isError}
      icon={<DownloadOutlined />}
      onClick={() => {
        handleDownload();
      }}
    ></Button>
  );
};

export const ResourcesComponent = () => {
  const refreshAndSetButtonPressedState = (state: boolean) => {
    invalidate({
      resource: "documents",
      invalidates: ["list"],
    });
    setButtonPressed(state);
  };

  const showModal = (docId: number) => {
    setModalDocId(docId);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const [buttonPressed, setButtonPressed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDocId, setModalDocId] = useState(0);
  const invalidate = useInvalidate();

  // For tagging purpose
  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<IDocument | null>(
    null
  );

  const [allAvailableTags, setAllAvailableTags] = useState<{
    Partner: string[];
    Contact: string[];
  }>({ Partner: [], Contact: [] });

  const handleTagsLoaded = (tags: { Partner: string[]; Contact: string[] }) => {
    setAllAvailableTags(tags);
  };

  const showTagModal = (document: IDocument) => {
    setCurrentDocument(document);
    setIsTagModalVisible(true);
  };
  const { mutateAsync: createTag } = useCreate();
  const handleAddTagFromModal = async (tagName: string) => {
    if (!currentDocument) {
      return;
    }
    try {
      await createTag({
        resource: "generic_tags",
        values: {
          name: tagName,
          taggable_id: currentDocument.ID,
          taggable_type: "documents",
        },
        successNotification: false,
      });

      message.success("Tag added successfully");
      refreshAndSetButtonPressedState(false); // Refresh your documents to show the new tag
      setCurrentDocument((prev) => ({
        ...prev,
        tag_infos: [...(prev?.tag_infos || []), { name: tagName }],
      }));
    } catch (error) {
      message.error("Failed to add tag");
    }
  };

  const { mutateAsync: deleteTagFromModal } = useDelete();

  const handleDeleteTagFromModal = async (tagName) => {
    if (!currentDocument) return;
    try {
      await deleteTagFromModal({
        resource: "generic_tags/clear",
        id: currentDocument.ID,
        values: {
          name: tagName,
          taggable_type: "documents",
        },
        successNotification: false,
      });
      message.success("Tag deleted successfully");
      refreshAndSetButtonPressedState(false);
      setCurrentDocument((prev) => ({
        ...prev,
        tag_infos: prev?.tag_infos?.filter((t) => t.name !== tagName),
      }));
    } catch (error) {
      message.error("Failed to remove tag");
    }
  };

  const { tableProps } = useTable<IDocument>({
    pagination: {
      mode: "off",
    },
    resource: "documents",
    syncWithLocation: false,
  });

  const { mutateAsync: deleteTag } = useDelete();

  const handleTagDelete = async (tagInfo: TagInfo) => {
    console.log(tagInfo);
    try {
      const response = await deleteTag({
        resource: "generic_tags",
        id: tagInfo.id,
        successNotification: false,
      });
      message.success("Tag deleted successfully");

      if (response.data) {
        console.log("Tag deleted successfully");
        refreshAndSetButtonPressedState(false);
      } else {
        // Handle error response
        console.error("Failed to delete tag");
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<IDocument>(null);
  const handleExpand = (record) => {
    setSelectedRecord(record);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedRecord(null);
  };

  return (
    <div>
      <Modal
        title={`Manage Tags for ${currentDocument?.Name}`}
        visible={isTagModalVisible}
        footer={null}
        onCancel={() => setIsTagModalVisible(false)}
      >
        {/* Render TagSelectionComponent inside Modal */}
        {currentDocument && (
          <TagSelectionComponent
            selectedTags={{
              Partner:
                currentDocument.tag_infos
                  ?.filter((t) => allAvailableTags.Partner.includes(t.name))
                  .map((t) => t.name) || [],
              Contact:
                currentDocument.tag_infos
                  ?.filter((t) => allAvailableTags.Contact.includes(t.name))
                  .map((t) => t.name) || [],
            }}
            onAddTag={(tagName) => handleAddTagFromModal(tagName)}
            onRemoveTag={(tagName) => handleDeleteTagFromModal(tagName)}
            onTagsLoaded={handleTagsLoaded}
          />
        )}
      </Modal>
      {!buttonPressed ? (
        <div>
          <Button
            onClick={() => handleAdd(setButtonPressed)}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            Add new document
          </Button>
          <Table {...tableProps} rowKey="ID">
            <Table.Column<IDocument>
              dataIndex="userCeritifcates"
              render={(_, record) => (
                <Space>
                  <Tooltip title="See details">
                    <Button
                      icon={<PlusCircleOutlined />}
                      size="small"
                      onClick={() => handleExpand(record)}
                    />
                  </Tooltip>
                </Space>
              )}
            />
            <Table.Column<IDocument> dataIndex="Name" title="File Name" />
            <Table.Column<IDocument>
              dataIndex="CreatedAt"
              title="Uploaded On"
              render={(value, record, index) => {
                return (
                  <Space
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <DateField format="LL" value={value} />
                  </Space>
                );
              }}
            />
            <Table.Column<IDocument>
              title="Tags"
              key="tags"
              render={(_, record) => {
                return (
                  <div>
                    {record.tag_infos &&
                      record.tag_infos.map((tag) => (
                        <Tag
                          color="blue"
                          key={tag.id}
                          closable
                          onClose={(e) => {
                            e.preventDefault();
                            handleTagDelete(tag);
                          }}
                          closeIcon={
                            <CloseOutlined
                              style={{ color: "red", cursor: "pointer" }}
                            />
                          }
                        >
                          {tag.name}
                        </Tag>
                      ))}
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => showTagModal(record)}
                    />
                  </div>
                );
              }}
            />
            <Table.Column<IDocument> dataIndex="send_count" title="Sent" />
            <Table.Column<IDocument> dataIndex="view_count" title="Views" />
            <Table.Column<IDocument>
              dataIndex="download_count"
              title="Downloads"
            />
            <Table.Column<IDocument>
              title="Actions"
              key="actions"
              render={(_, record) => {
                return (
                  <Space size="small">
                    <DownloadDocumentButton record={record} />
                    <Button
                      size="small"
                      icon={<ShareAltOutlined />}
                      onClick={() => showModal(record.ID)}
                    />
                    <DeleteButton
                      resource="document"
                      recordItemId={record.ID}
                      onSuccess={() => refreshAndSetButtonPressedState(false)}
                      icon={<DeleteOutlined />}
                      size="small"
                      hideText={true}
                    />
                  </Space>
                );
              }}
            />
          </Table>
          <Drawer
            title={`Data on ${selectedRecord?.Name}`}
            placement="right"
            width={800}
            onClose={closeDrawer}
            open={drawerVisible}
          >
            {selectedRecord && <MetricsTable record={selectedRecord} />}
          </Drawer>
          <DocumentSharingDetailsModal
            isVisible={isModalVisible}
            onClose={handleModalClose}
            documentId={modalDocId}
          />
        </div>
      ) : (
        <UploadPage callback={refreshAndSetButtonPressedState} />
      )}
    </div>
  );
};

type MetricsProps = {
  record: IDocument;
};

const MetricsTable: FC<MetricsProps> = ({ record }) => {
  const { data, isLoading } = useOne<DocumentMetricResponse>({
    resource: `document_metrics`,
    id: record.ID,
  });
  return (
    <LoadingWrapper loading={isLoading} size="small">
      {data?.data?.share_metrics.length +
        data?.data?.view_metrics.length +
        data?.data?.download_metrics.length ===
      0 ? (
        <Text>No Data Yet</Text>
      ) : (
        <Space direction="vertical">
          {GetMetricsView({
            title: "Shares",
            metrics: data?.data?.share_metrics,
          })}
          {GetMetricsView({
            title: "Views",
            metrics: data?.data?.view_metrics,
          })}
          {GetMetricsView({
            title: "Downloads",
            metrics: data?.data?.download_metrics,
          })}
        </Space>
      )}
    </LoadingWrapper>
  );
};

type Props = {
  title: string;
  metrics: DocumentMetricIntance[];
};

const GetMetricsView: FC<Props> = ({ title, metrics }) => {
  if (metrics === undefined || metrics.length == 0) {
    return <div />;
  }
  return (
    <Space align="center">
      <List
        title={
          <Text
            style={{
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {title}
          </Text>
        }
        breadcrumb={false}
      >
        <Table
          columns={[
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              render: (date) => new Date(date).toLocaleString(),
            },
            {
              title: "Company Name",
              dataIndex: "company_name",
              key: "company_name",
            },
            /*
            {
              title: "Person Name",
              dataIndex: "person_name",
              key: "person_name",
            },
            */
          ]}
          dataSource={metrics}
          pagination={false}
        />
      </List>
    </Space>
  );
};
