import LoadingWrapper from "@/components/loading";
import { Contact, PartnerRecord, Person } from "@/types";
import { useForm, useTable } from "@refinedev/antd";
import { HttpError, useList } from "@refinedev/core";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import { ObjectMeetings } from "../meetings";
import { ObjectNotes } from "../notes";
import {
  PartnerInfoForm,
  PartnersContactsTable,
  PartnerTitleForm,
} from "./components";
import { ContractsTable } from "./components/contracts-table";
import { PartnerCompaniesTable } from "./components/partner-companies-table";

export const PartnerEditPage = () => {
  const params = useParams();

  const { formProps, query, formLoading } = useForm<
    PartnerRecord,
    HttpError,
    PartnerRecord
  >({
    redirect: false,
    //resource: "partner",
    dataProviderName: "propelApi",
  });

  const { data: people, isLoading } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: query?.data?.data?.company_id,
      },
    ],
  });

  const { tableProps } = useTable<Contact>({
    resource: "contacts",
    filters: {
      permanent: [
        {
          field: "reference_id",
          operator: "eq",
          value: params.id,
        },
        {
          field: "reference_table",
          operator: "eq",
          value: "PartnerRecord",
        },
      ],
    },
    hasPagination: false,
  });

  const contactsPersons = tableProps?.dataSource?.map((c) => c.person);

  return (
    <LoadingWrapper
      loading={formLoading || isLoading || (tableProps.loading && true)}
    >
      <div className="page-container">
        <PartnerTitleForm formProps={formProps} queryResult={query} />
        <Row
          gutter={[32, 32]}
          style={{
            marginTop: 32,
          }}
        >
          <Col span={16}>
            <PartnersContactsTable partner_record={query?.data?.data} />
            {/*
            <PartnerClientCompaniesTable
              title={
                "Partner Clients of " +
                  queryResult?.data?.data.company.CompanyName || ""
              }
              company_id={queryResult?.data?.data.partner_company_id || 0}
              style={{
                marginTop: 32,
              }}
            />
            */}
            <PartnerCompaniesTable
              title={
                "Clients of " + query?.data?.data.partner_company.CompanyName ||
                ""
              }
              company_id={query?.data?.data.partner_company_id || 0}
              style={{
                marginTop: 32,
              }}
            />
            <ObjectMeetings
              reference_table="PartnerRecord"
              style={{
                marginTop: 32,
              }}
              people={people?.data.concat(contactsPersons)}
            />
            <ObjectNotes
              reference_table="PartnerRecord"
              style={{
                marginTop: 32,
              }}
            />
          </Col>
          <Col span={8}>
            <PartnerInfoForm formProps={formProps} queryResult={query} />
            <div
              style={{
                marginTop: 16,
              }}
            >
              <TagBasedCustomFieldsTable
                taggable_object={"Partner"}
                field_reference_table={"Partner"}
                override_taggable_object={"Company"}
                override_object_id={query?.data?.data.partner_company_id}
                style={{
                  marginTop: 16,
                }}
                show_resource="partner-types"
              />
            </div>
            {query?.isFetched && (
              <ContractsTable
                style={{
                  marginTop: 16,
                }}
                queryResult={query}
              />
            )}
          </Col>
        </Row>
      </div>
    </LoadingWrapper>
  );
};
