import { SingleElementForm, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { Company, Tag, TagInfo, transformTags } from "@/types";
import {
  CaretDownOutlined,
  FormOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  GetOneResponse,
  useInvalidate,
  useList,
  useParsed,
} from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Card, Select, Space } from "antd";
import { FC, useState } from "react";

type Props = {
  queryResult: QueryObserverResult<GetOneResponse<Company>> | undefined;
};

export const CompanyInfoForm: FC<Props> = ({ queryResult }) => {
  const [activeForm, setActiveForm] = useState<
    "Domain" | "Description" | "tags"
  >();
  const { id: companyId } = useParsed();
  const invalidate = useInvalidate();
  const data = queryResult?.data?.data;
  const getActiveForm = (args: { formName: keyof Company | "tags" }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }

    if (
      typeof data?.[formName] !== "boolean" &&
      !data?.[formName] &&
      formName !== "tags"
    ) {
      return "empty";
    }

    return "view";
  };

  const { data: tags, isLoading: tagsLoading } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Company",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags, isLoading: tagInfosLoading } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "Company",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: companyId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);

  const loading = queryResult?.isLoading || tagInfosLoading || tagsLoading;

  return (
    <LoadingWrapper loading={loading}>
      <Card
        title={
          <Space size={15}>
            <ShopOutlined className="sm" />
            <Text>Company info</Text>
          </Space>
        }
        styles={{
          header: {
            padding: "1rem",
          },
          body: {
            padding: "0",
          },
        }}
      >
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          icon={<FormOutlined className="tertiary" />}
          state={getActiveForm({ formName: "Domain" })}
          itemProps={{
            name: "Domain",
            label: "Domain",
          }}
          view={<Text>{data?.Domain}</Text>}
          editable={false}
          onClick={() => setActiveForm(undefined)}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
        >
          <Text>{data?.Domain}</Text>
        </SingleElementForm>
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          icon={<FormOutlined className="tertiary" />}
          state={getActiveForm({ formName: "Description" })}
          itemProps={{
            name: "Description",
            label: "Description",
          }}
          view={<Text>{data?.Description}</Text>}
          editable={false}
          onClick={() => setActiveForm(undefined)}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
        >
          <Text>{data?.Domain}</Text>
        </SingleElementForm>
        {tagOptions.length > 0 && (
          <SingleElementForm
            loading={loading}
            style={{
              padding: "0.5rem 1rem",
            }}
            state={getActiveForm({ formName: "tags" })}
            itemProps={{
              name: "tags",
              label: "Company Type",
            }}
            view={
              <Select
                autoFocus={false}
                showSearch={false}
                open={false}
                mode="multiple"
                suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
                removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
                defaultValue={appliedTagValues}
                value={appliedTagValues}
                options={tagOptions}
                style={{
                  width: "100%",
                  pointerEvents: "none",
                  boxShadow: "none",
                  borderColor: "initial",
                }}
              />
            }
            onClick={() => setActiveForm("tags")}
            onUpdate={() => {
              invalidate({ invalidates: ["list"], resource: "generic_tags" });
              invalidate({ invalidates: ["list"], resource: "contracts" });
              setActiveForm(undefined);
            }}
            onCancel={() => setActiveForm(undefined)}
          >
            <Select
              autoFocus
              allowClear
              showSearch={true}
              mode="multiple"
              defaultValue={appliedTagValues}
              options={tagOptions}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: "100%",
              }}
            />
          </SingleElementForm>
        )}
      </Card>
    </LoadingWrapper>
  );
};
