import "@/pages/partners/create-higlight-input.css";
import { useIdentity } from "@/providers/identity";
import { ChartEditor, QuillProvider } from "@quillsql/react";
import { useModal } from "@refinedev/antd";
import { useNavigation, useParsed } from "@refinedev/core";
import { Card, Modal } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const ReportsEditPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const me = useIdentity();
  const { id } = useParsed();

  const { modalProps, close } = useModal();
  modalProps.open = true;
  modalProps.visible = true;

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("reports", "replace");
      }}
      title="Edit Report"
      width={"80%"}
      footer={null}
      closeIcon={null}
    >
      <Card
        style={{
          width: "100%",
        }}
      >
        {me?.person?.company_id && (
          <QuillProvider
            organizationId={me?.person?.company_id?.toString()}
            publicKey="665610862cf7a3000be66453"
          >
            <ChartEditor
              isOpen={modalProps.open}
              setIsOpen={(isOpen: boolean) => {
                modalProps.open = isOpen;
                if (!isOpen) {
                  close();
                  list("reports", "replace");
                }
              }}
              reportId={id.toString()}
              hideDeleteButton={false}
              isAdmin={false}
              chartBuilderTitle="Edit Report"
              {...modalProps}
            />
          </QuillProvider>
        )}
      </Card>
    </Modal>
  );
};

/*

*/
