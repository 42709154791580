import { CustomAvatar } from "@/components";
import LoadingWrapper from "@/components/loading";
import { API_BASE_URL } from "@/providers/data";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { getNameInitials } from "@/utilities";
import { DateField } from "@refinedev/antd";
import { Button, List, message, Modal, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";

interface PartnerCompany {
  partner_company_id: number;
  partner_company_name: string;
  partner_company_logo: string;
  status: string;
  created_at: string | null;
  document_share_id: number | null;
  partner_record_id: number;
}

interface DocumentDetails {
  document_id: number;
  sharing_details: PartnerCompany[];
}

interface DocumentSharingDetailsModalProps {
  isVisible: boolean;
  onClose: () => void;
  documentId: number;
}

const DocumentSharingDetailsModal: React.FC<
  DocumentSharingDetailsModalProps
> = ({ isVisible, onClose, documentId }) => {
  const [documentDetails, setDocumentDetails] =
    useState<DocumentDetails | null>(null);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean[]>();

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      setIsDataLoading(true);
      try {
        // Adjust the URL to where your API endpoint is
        const response = await globalAxiosInstance.get(
          `${API_BASE_URL}/document/${documentId}/partner/sharing_details`
        );
        setDocumentDetails(response.data);
        if (response.data) {
          setIsLoading(
            new Array(response.data.sharing_details.length).fill(false)
          );
        }
      } catch (error) {
        console.error("Failed to fetch document details:", error);
        // Handle error, e.g., show notification
      } finally {
        setIsDataLoading(false);
      }
    };

    if (isVisible) {
      fetchDocumentDetails();
    }
  }, [isVisible, documentId]);

  const updateSharingStatus = async (
    docId: number,
    partnerRecord: PartnerCompany,
    newStatus: boolean,
    index: number
  ) => {
    setIsLoading((prev) =>
      prev.map((item, idx) => (idx === index ? true : item))
    ); // Set loading true for this index
    const status = newStatus ? "Shared" : "Not Shared";

    const payload = {
      company: null,
      partner: partnerRecord.partner_record_id,
      doc_id: docId,
    };

    try {
      let response;
      if (newStatus) {
        response = await globalAxiosInstance.post(
          `${API_BASE_URL}/document_share`,
          payload
        );
        partnerRecord.status = "Shared";
        partnerRecord.created_at = new Date().toISOString();
        partnerRecord.document_share_id = response.data.ID;
      } else {
        response = await globalAxiosInstance.delete(
          `${API_BASE_URL}/document_share/${partnerRecord.document_share_id}`
        );
        partnerRecord.status = "Not Shared";
      }
      // Handle response here, e.g., updating local state or showing a message
      message.success(`Status updated to ${status}`);
    } catch (error) {
      console.error("Failed to update sharing status:", error);
      message.error("Failed to update status");
    } finally {
      setIsLoading((prev) =>
        prev.map((item, idx) => (idx === index ? false : item))
      ); // Set loading false for this index
    }
  };

  const getDesc = (item) => {
    let status = `Status: ${item.status}`;
    if (item.status === "Shared") {
      status += ` | Shared On: `;
    }

    return (
      <div>
        {status}
        {item.status === "Shared" && (
          <DateField value={item.created_at} type="secondary" />
        )}
      </div>
    );
  };

  return (
    <Modal
      title="Overview of Document Sharing with Partners"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width="40%"
    >
      <LoadingWrapper loading={isDataLoading}>
        {documentDetails ? (
          <List
            bordered
            dataSource={documentDetails.sharing_details}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <CustomAvatar
                      size="large"
                      shape="square"
                      src={item.partner_company_logo}
                      name={getNameInitials(item?.partner_company_name || "")}
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 48,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                      }}
                    />
                  }
                  title={item.partner_company_name}
                  description={getDesc(item)}
                />
                <Button
                  type={item.status === "Shared" ? "primary" : "default"}
                  onClick={() =>
                    updateSharingStatus(
                      documentDetails.document_id,
                      item,
                      item.status !== "Shared",
                      index
                    )
                  }
                  loading={isLoading[index]}
                  disabled={
                    isLoading[index] || item.status === "Missing Contacts"
                  }
                >
                  {item.status === "Shared" ? "Unshare" : "Share"}
                </Button>
              </List.Item>
            )}
          />
        ) : (
          <Typography.Text>Document details not available.</Typography.Text>
        )}
      </LoadingWrapper>
    </Modal>
  );
};

export default DocumentSharingDetailsModal;
