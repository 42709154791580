import "@/pages/partners/create-higlight-input.css";
import { FeatureFlag } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useNavigation } from "@refinedev/core";
import { Checkbox, Form, Input, Modal } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const FeatureFlagsCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const resource = "feature_flags";
  const { formProps, modalProps, close } = useModalForm<
    FeatureFlag,
    HttpError,
    FeatureFlag
  >({
    action: "create",
    defaultVisible: true,
    resource: "feature_flags",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Add new Feature Flag"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Feature Name"
          name="feature_name"
          rules={[{ required: true }]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Enable Globally"
          name={"enabled_globally"}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
