import { Text } from "@/components";
import { CourseResponse } from "@/types";
import { CheckCircleOutlined, ReadOutlined } from "@ant-design/icons";
import { List, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Badge, Button, Space, Table } from "antd";
import { FC, PropsWithChildren, useState } from "react";

export const PartnerTrainingListPage: FC<PropsWithChildren> = ({
  children,
}) => {
  const { tableProps } = useTable<CourseResponse, HttpError>({
    resource: "lms/courses_partner",
    pagination: {
      mode: "off",
    },
  });

  // State to hold the selected link
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);

  return (
    <div className="page-container">
      {iframeSrc ? (
        // Render the iframe if a link is selected
        <div>
          <Button
            type="primary"
            onClick={() => setIframeSrc(null)}
            style={{ marginBottom: "16px" }}
          >
            Back to Course List
          </Button>
          <iframe
            src={iframeSrc}
            width="100%"
            height="1200px"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      ) : (
        // Render the list of courses if no link is selected
        <List title="Course List" headerButtons={() => <div />}>
          <Table {...tableProps} rowKey="ID">
            <Table.Column<CourseResponse>
              dataIndex="name"
              title="Course Name"
              render={(_, record) => {
                return (
                  <Space>
                    <Text>{record.name}</Text>
                  </Space>
                );
              }}
            />
            <Table.Column<CourseResponse>
              dataIndex="description"
              title="Description"
              render={(_, record) => {
                return (
                  <Space>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {record.description}
                    </Text>
                  </Space>
                );
              }}
            />
            <Table.Column<CourseResponse>
              render={(_, record) => {
                return (
                  <Space>
                    {/* Show the Completed Badge if status is COMPLETED */}
                    {record.status === "COMPLETED" ? (
                      <Space>
                        <CheckCircleOutlined style={{ color: "green" }} />
                        <Badge color="green" text="Completed" />
                      </Space>
                    ) : (
                      <Button
                        type="primary"
                        icon={<ReadOutlined />}
                        onClick={() => (window.location.href = record.link)}
                      >
                        Take Course
                      </Button>
                    )}
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      )}
      {children}
    </div>
  );
};
