import { CopyableText, CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { Company, EmailTemplate, PermissionsRole, Person, User } from "@/types";
import { isNullorEmpty } from "@/utilities/strings";
import { getTablePropsWithPagination } from "@/utilities/tables";
import {
  DeleteOutlined,
  MailOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  SearchOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  DeleteButton,
  EditButton,
  SaveButton,
  useForm,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useCreateMany,
  useInvalidate,
  useList,
  useNotification,
  useOne,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import debounce from "lodash/debounce";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { CompanyTitleForm } from "../../companies/title-form";
import ReactQuillEditor from "@/components/quill-editor/editor";
import { HTMLPreview } from "@/components";

export const CompanySettingsPage: FC<PropsWithChildren> = ({ children }) => {
  const queryResult = useOne<Company, HttpError>({
    resource: "companies",
    id: "0",
  });

  type Template = {
    name: string;
    key: string;
  };

  const { data: emailTemplates, isLoading: emailTemplateLoading } = useList<
    Template,
    HttpError
  >({
    resource: "email_templates",
    pagination: {
      mode: "off",
    },
  });

  return (
    <LoadingWrapper loading={queryResult?.isLoading || emailTemplateLoading}>
      <div className="page-container">
        <CompanyTitleForm
          queryResult={queryResult}
          showSync={false}
          editable={false}
        />
        <Tabs
          defaultActiveKey="1"
          type="line"
          style={{
            border: "none",
          }}
        >
          <TabPane tab="Team" key="1">
            <Row
              gutter={[32, 32]}
              style={{
                marginTop: 32,
              }}
            >
              <Col span={18}>
                <TeamListPage />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Email Templates" key="2">
            <Tabs
              type="line"
              tabPosition="left"
              style={{
                border: "none",
                boxShadow: "none",
              }}
            >
              {emailTemplates?.data?.map((template) => (
                <TabPane tab={template.name} key={template.key}>
                  <EmailPage id={template.key} />
                </TabPane>
              ))}
            </Tabs>
          </TabPane>
        </Tabs>

        {children}
      </div>
    </LoadingWrapper>
  );
};

interface EmailPageProps {
  id: string; // Make id a parameter
}

export const EmailPage: FC<EmailPageProps> = ({ id }) => {
  const { formProps, query } = useForm<EmailTemplate, HttpError, EmailTemplate>(
    {
      redirect: false,
      resource: "email_templates",
      dataProviderName: "propelApi",
      action: "edit",
      id: id,
    }
  );

  const emailTemplateBase = useOne<
    {
      template: string;
    },
    HttpError
  >({
    resource: "email_templates_base",
    id: "0",
  });

  // State to manage the HTML content and subject
  const [emailContent, setEmailContent] = useState<string>(
    query?.data?.data?.content || ""
  );
  const [emailSubject, setEmailSubject] = useState<string>(
    query?.data?.data?.subject || ""
  );

  useEffect(() => {
    if (query?.data?.data) {
      setEmailSubject(query.data.data.subject || "");
      setEmailContent(query.data.data.content || "");
    }
  }, [query?.data]);

  const wrapContentWithHTMLTemplate = (subject: string, content: string) => {
    return emailTemplateBase?.data?.data?.template
      .replace("{{ .Subject }}", subject)
      .replace("{{ .Content }}", content);
  };

  return (
    <div>
      <Row gutter={[32, 32]}>
        {/* Column for the Form */}
        <Col span={12}>
          <Form
            {...formProps}
            layout="vertical"
            onValuesChange={(changedValues) => {
              if (changedValues.subject !== undefined) {
                setEmailSubject(changedValues.subject);
              }
              if (changedValues.content !== undefined) {
                setEmailContent(changedValues.content);
              }
            }}
          >
            <Form.Item
              label="Email Subject"
              name="subject"
              rules={[{ required: true }]}
            >
              <Input
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Email Content"
              name="content"
              rules={[{ required: true }]}
            >
              <ReactQuillEditor
                style={{ maxWidth: "100%" }}
                value={emailContent}
                onChange={setEmailContent}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>

        {/* Column for the Preview */}
        <Col span={12}>
          <HTMLPreview
            srcDoc={wrapContentWithHTMLTemplate(emailSubject, emailContent)}
          />
        </Col>
      </Row>
    </div>
  );
};

export const TeamListPage: FC = () => {
  const { mutateAsync } = useCreateMany<Person, HttpError, Person>();
  const { mutateAsync: createUser } = useCreate<User>();
  const { mutateAsync: updateAsync } = useUpdate();
  const invalidate = useInvalidate();
  const { open } = useNotification();

  const { tableProps, tableQuery, searchFormProps } = useTable<
    Person,
    HttpError,
    { name: string }
  >({
    resource: "persons",
    hasPagination: false,
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "include_users",
          operator: "eq",
          value: true,
        },
      ],
    },
    onSearch: (values) => {
      return [
        {
          field: "name",
          operator: "contains",
          value: values.name,
        },
      ];
    },
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const [form] = Form.useForm<Person>();
  const people = Form.useWatch("people", form);
  const { hasNewPeople } = useMemo(() => {
    const hasNewPeople = people?.length > 0;

    return {
      hasNewPeople,
    };
  }, [people]);

  const handleOnFinish = async (args) => {
    form.validateFields();
    const people: Person[] = args.people.map((person) => ({
      email: {
        String: person.email,
        Valid: true,
      },
      name: person.name,
    }));

    await mutateAsync({
      resource: "persons",
      values: people,
      successNotification: false,
    });

    form.resetFields();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFormProps?.onFinish?.({
      name: e.target.value ?? "",
    });
  };
  const debouncedOnChange = debounce(onSearch, 500);

  const handleAddUser = async (record: Person) => {
    if (isNullorEmpty(record.email?.String)) {
      open?.({
        type: "error",
        message: "Can't create account without an email",
      });

      return;
    }

    await createUser({
      resource: "users",
      values: {
        person_id: record.ID,
      },
      successNotification: () => {
        return {
          key: "users",
          type: "success",
          message: "Successfully Created User",
          description: "Successful",
        };
      },
    });

    invalidate({
      invalidates: ["all"],
      resource: "persons",
    });
  };

  const { data: statuses } = useList<PermissionsRole>({
    pagination: {
      mode: "off",
    },
    resource: "permissions/role",
  });
  const roleOptions = transformRoles(statuses?.data);

  const handleStatusChange = (userId: number) => async (newValues: number) => {
    await updateAsync({
      resource: "users",
      values: {
        roles: [newValues],
      },
      id: userId,
    });

    invalidate({
      invalidates: ["all"],
      resource: "persons",
    });
  };

  return (
    <div className="page-container">
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Card
            styles={{
              header: {
                borderBottom: "1px solid #D9D9D9",
                marginBottom: "1px",
              },
              body: { padding: 0 },
            }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "2px", // adding 2 px padding is enogh to show the select's outline
                }}
              >
                <div>
                  <TeamOutlined />
                  <Text>Team Members</Text>
                </div>
                {hasData && (
                  <Form {...searchFormProps} layout="inline">
                    <Form.Item name="name" noStyle>
                      <Input
                        prefix={<SearchOutlined />}
                        suffix={
                          <Spin
                            size="small"
                            spinning={tableQuery?.isFetching}
                          />
                        }
                        placeholder="Search by name"
                        onChange={debouncedOnChange}
                      />
                    </Form.Item>
                  </Form>
                )}
              </div>
            }
          >
            {!hasData && (
              <div
                style={{
                  padding: 16,
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <Text>No one on the team yet</Text>
              </div>
            )}
            {hasData && (
              <Table
                {...getTablePropsWithPagination(tableProps, "teammates")}
                rowKey="ID"
                style={{
                  width: "100%",
                }}
              >
                <Table.Column<Person>
                  title="Name"
                  render={(_, record) => {
                    return (
                      <Space>
                        <CustomAvatar
                          name={record?.name}
                          src={record?.avatar_url}
                        />
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {record?.name}
                        </Text>
                      </Space>
                    );
                  }}
                />
                <Table.Column<Person>
                  title="E-mail"
                  render={(_, record) => {
                    return <CopyableText content={record?.email.String} />;
                  }}
                />

                <Table.Column<Person>
                  dataIndex="ID"
                  width={40 * 4}
                  render={(_, record) => {
                    return (
                      <Space>
                        <Tooltip title="Send Email">
                          <Button
                            size="small"
                            href={`mailto:${record?.email.String}`}
                            icon={<MailOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Edit User">
                          <EditButton
                            hideText
                            size="small"
                            resource="settings_team"
                            recordItemId={record.ID}
                          />
                        </Tooltip>
                        {!record.user && (
                          <Tooltip title="Delete User">
                            <DeleteButton
                              hideText
                              size="small"
                              resource="persons"
                              recordItemId={record.ID}
                            />
                          </Tooltip>
                        )}

                        {!record.user && (
                          <Tooltip
                            title={"Provision User Account".concat(
                              isNullorEmpty(record.email?.String)
                                ? " - An Email is Required to Create the User"
                                : ""
                            )}
                          >
                            <Button
                              size="small"
                              onClick={() => handleAddUser(record)}
                              icon={<UserAddOutlined />}
                            />
                          </Tooltip>
                        )}
                      </Space>
                    );
                  }}
                />
                <Table.Column<Person>
                  dataIndex="roles"
                  title="Roles"
                  width={500}
                  render={(_, record) => {
                    return (
                      record.user && (
                        <Select
                          options={roleOptions}
                          value={record.user?.roles?.[0]?.ID ?? null}
                          style={{ width: "100%" }}
                          onChange={handleStatusChange(record.user.ID)}
                        />
                      )
                    );
                  }}
                />
              </Table>
            )}

            <Form form={form} onFinish={handleOnFinish}>
              <Form.List name="people">
                {(fields, { add, remove }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start ",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "4px 0px 16px",
                      }}
                    >
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Row
                            key={key}
                            gutter={12}
                            align="top"
                            style={{
                              paddingLeft: "16px",
                              width: "100%",
                            }}
                          >
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter contact name",
                                  },
                                ]}
                                name={[name, "name"]}
                              >
                                <Input
                                  addonBefore={<UserOutlined />}
                                  placeholder="Contact name"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                required
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter an e-mail",
                                  },
                                  {
                                    type: "email",
                                    message: "The input is not valid e-mail",
                                  },
                                ]}
                                name={[name, "email"]}
                              >
                                <Input
                                  addonBefore={<MailOutlined />}
                                  placeholder="Contact email"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={() => add()}
                      >
                        Add new team member
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
              {hasNewPeople && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "8px",
                    padding: "16px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <Button
                    size="large"
                    type="default"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    size="large"
                    icon={undefined}
                    onClick={() => form.submit()}
                  />
                </div>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

function transformRoles(
  roles: PermissionsRole[] | undefined
): { label: string; value: number }[] {
  if (!roles) {
    return [];
  }
  return roles.map((node) => ({
    label: node.name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
