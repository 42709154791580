export type ClientRecordSourceType = "COMPANY" | "PARTNER" | "PUBLIC";

export const ClientRecordSourceTypeOptions: {
  label: string;
  value: ClientRecordSourceType;
}[] = [
  { label: "Company Reported", value: "COMPANY" },
  { label: "Partner Upload", value: "PARTNER" },
  { label: "Public Data", value: "PUBLIC" },
];
