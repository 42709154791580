import { DatePickerWrapper, SingleElementForm, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import "@/pages/partners/create-higlight-input.css";
import { useIdentity } from "@/providers/identity";
import { useOpportunityStatuses } from "@/providers/options";
import { usePeople } from "@/providers/people";
import { Opportunity, transformPersons } from "@/types";
import {
  CaretDownOutlined,
  DollarOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { DateField } from "@refinedev/antd";
import { BaseKey, HttpError, useOne, useParsed } from "@refinedev/core";
import { Card, Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { FC, useState } from "react";
import { ObjectNotes } from "../notes";
import { ObjectSyncStatusComponent } from "../sync";
import { isValidDate } from "@/utilities";
import { TagBasedCustomFieldsTable } from "../custom_fields";

type Props = {
  isOverModal?: boolean;
  enableStatus?: boolean;
  resource?: string;
};

export const OpportunitiesPagePartnerView = ({
  enableStatus,
  resource = "opportunities",
}: Props) => {
  const { id } = useParsed();
  const { id: opportunityId } = useParsed();
  const queryResult = useOne<Opportunity, HttpError>({
    resource: "opportunities",
    id: opportunityId,
  });

  return (
    <LoadingWrapper loading={queryResult.isLoading}>
      <div className="page-container">
        <Title name={queryResult.data?.data?.name} id={id} />
      </div>

      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col span={16}>
          <OpportunityInfoForm opportunity={queryResult?.data?.data} />
          <ObjectNotes
            reference_table="Opportunity"
            style={{
              marginTop: 32,
            }}
          />
        </Col>
        <Col span={8}>
          <TagBasedCustomFieldsTable
            taggable_object={"Opportunity"}
            field_reference_table={"Opportunity"}
            override_object_id={+id}
            style={{
              marginTop: 32,
            }}
            show_resource="opportunity-types"
            view_only={true}
          />
        </Col>
      </Row>
    </LoadingWrapper>
  );
};

type TitleProps = {
  name: string;
  id: BaseKey;
};
const Title: FC<TitleProps> = ({ name, id }) => {
  return (
    <Space direction="vertical" style={{ width: "100%", position: "relative" }}>
      <Form>
        <Space
          size={16}
          style={{
            width: "100%",
            paddingBottom: "24px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text style={{ fontSize: "32px", fontWeight: 700 }}>{name}</Text>
        </Space>
      </Form>

      <ObjectSyncStatusComponent
        style={{ position: "absolute", right: 0, top: 5 }}
        reference_table="Opportunity"
        reference_id={id}
      />
    </Space>
  );
};

type InfoFormProps = {
  opportunity: Opportunity;
};

export const OpportunityInfoForm: FC<InfoFormProps> = ({ opportunity }) => {
  const me = useIdentity();
  const statusOptions = useOpportunityStatuses();
  const [activeForm, setActiveForm] = useState<
    | "name"
    | "contract_value"
    | "close_date"
    | "expiry_notificaton_date"
    | "status"
    | "subscribed_ids"
    | "assignees_ids"
    | "rejection_reason"
  >();

  const getActiveForm = (args: {
    formName: keyof Opportunity | "subscribed_ids" | "assignees_ids";
  }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }

    return "view";
  };

  const { people, loading: peopleLoading } = usePeople(
    opportunity?.partner_record_id
  );
  const loading = peopleLoading;

  const peopleOptions = transformPersons(loading ? [] : people);
  return (
    <Card
      title={
        <Space>
          <ShopOutlined className="sm" />
          <Text>Opportunity Info</Text>
        </Space>
      }
      styles={{
        header: {
          padding: "1rem",
        },
        body: {
          padding: "0",
        },
      }}
    >
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "name" })}
        itemProps={{
          name: "name",
          label: "Name",
        }}
        view={<Text>{opportunity?.name}</Text>}
        editable={false}
        onClick={() => setActiveForm("name")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Input
          autoFocus
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "contract_value" })}
        itemProps={{
          name: "contract_value",
          label: "Contract Value",
        }}
        view={
          <Text>
            $
            {`${opportunity?.contract_value}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}
          </Text>
        }
        editable={false}
        onClick={() => setActiveForm("contract_value")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <InputNumber
          min={0}
          addonBefore={<DollarOutlined />}
          placeholder="0,00"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "close_date" })}
        itemProps={{
          name: "close_date",
          label: "Close Date",
        }}
        view={<DateField format="YYYY-MM-DD" value={opportunity?.close_date} />}
        editable={false}
        onClick={() => setActiveForm("close_date")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <DatePickerWrapper />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "expiry_notificaton_date" })}
        itemProps={{
          name: "expiry_notificaton_date",
          label: "Expiry Date",
        }}
        view={
          isValidDate(opportunity?.expiry_notificaton_date) ? (
            <DateField
              format="YYYY-MM-DD"
              value={opportunity?.expiry_notificaton_date}
            />
          ) : (
            <Text>-</Text>
          )
        }
        editable={false}
        onClick={() => setActiveForm("expiry_notificaton_date")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <DatePickerWrapper />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "status" })}
        itemProps={{
          name: "status",
          label: "Stage",
        }}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={opportunity?.status}
            value={opportunity?.status}
            options={statusOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={false}
        onClick={() => setActiveForm("status")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          autoFocus
          allowClear
          defaultValue={opportunity?.status}
          options={statusOptions}
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "subscribed_ids" })}
        itemProps={{
          name: "subscribed_ids",
          label: "Subscribed",
        }}
        resource="opportunities"
        id={opportunity?.ID}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            mode="multiple"
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={opportunity?.subscribed_ids}
            value={opportunity?.subscribed_ids}
            options={peopleOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={true}
        onClick={() => setActiveForm("subscribed_ids")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          mode="multiple"
          autoFocus
          allowClear
          defaultValue={opportunity?.subscribed_ids}
          options={peopleOptions}
          style={{
            width: "100%",
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "assignees_ids" })}
        itemProps={{
          name: "assignees_ids",
          label: "Assignees",
        }}
        resource="opportunities"
        id={opportunity?.ID}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            mode="multiple"
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={opportunity?.assignees_ids}
            value={opportunity?.assignees_ids}
            options={peopleOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={true}
        onClick={() => setActiveForm("assignees_ids")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          mode="multiple"
          autoFocus
          allowClear
          defaultValue={opportunity?.assignees_ids}
          options={peopleOptions}
          style={{
            width: "100%",
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </SingleElementForm>
      {opportunity?.approved === false && (
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          state={getActiveForm({ formName: "rejection_reason" })}
          itemProps={{
            name: "rejection_reason",
            label: "Rejection Reason",
          }}
          view={<Text>{opportunity?.rejection_reason}</Text>}
          editable={false}
          onClick={() => setActiveForm("rejection_reason")}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
        >
          <Input
            autoFocus
            style={{
              width: "100%",
            }}
          />
        </SingleElementForm>
      )}
    </Card>
  );
};
