import { Text } from "@/components";
import { Course } from "@/types";
import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useDelete,
  useInvalidate,
} from "@refinedev/core";
import {
  Button,
  Drawer,
  Space,
  Table,
  Tag,
  Modal,
  Tooltip,
  message,
} from "antd";
import { TableProps } from "antd/lib";
import { FC, PropsWithChildren, useState } from "react";
import {
  ShareAltOutlined,
  CloseOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CourseSharingDetailsModal from "./course_sharing";
import { TagSelectionComponent } from "../tags/select";
import { UserCertification } from "@/types";

export const TrainingListPage: FC<PropsWithChildren> = ({ children }) => {
  const invalidate = useInvalidate();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Course>(null);

  const handleExpand = (record) => {
    setSelectedRecord(record);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedRecord(null);
  };

  const [allAvailableTags, setAllAvailableTags] = useState<{
    Partner: string[];
    Contact: string[];
  }>({ Partner: [], Contact: [] });

  // Callback function when TagSelectionComponent loads all tags
  const handleTagsLoaded = (tags: { Partner: string[]; Contact: string[] }) => {
    setAllAvailableTags(tags); // Store all available tag options in state
  };

  // Fetching the course list using useTable hook
  const { tableProps } = useTable<Course, HttpError>({
    resource: "lms/courses",
    pagination: {
      mode: "off",
    },
  });

  // State for managing modals
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalCourseId, setModalCourseId] = useState<number | null>(null);

  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<Course | null>(null);

  // Show course sharing modal
  const showModal = (courseId: number) => {
    setModalCourseId(courseId);
    setIsModalVisible(true);
  };

  // Close course sharing modal
  const handleModalClose = () => {
    setIsModalVisible(false);
    setModalCourseId(null);
  };

  // Show tag modal
  const showTagModal = (course: Course) => {
    setCurrentCourse(course);
    setIsTagModalVisible(true); // Open modal with TagSelectionComponent
  };

  // Handle adding/removing tags using TagSelectionComponent callbacks
  const { mutateAsync: createTag } = useCreate();

  const handleAddTagFromModal = async (tagName: string) => {
    if (!currentCourse) return;
    try {
      await createTag({
        resource: "generic_tags",
        values: {
          name: tagName,
          taggable_id: currentCourse.ID,
          taggable_type: "courses",
        },
        successNotification: false,
      });
      message.success("Tag added successfully");
      invalidate({ resource: "lms/courses", invalidates: ["list"] });
      setCurrentCourse((prev) => ({
        ...prev,
        tag_infos: [...(prev?.tag_infos || []), { name: tagName }],
      }));
    } catch (error) {
      message.error("Failed to add tag");
    }
  };

  const { mutateAsync: deleteTagFromModal } = useDelete();

  const handleDeleteTagFromModal = async (tagName) => {
    if (!currentCourse) return;
    try {
      await deleteTagFromModal({
        resource: "generic_tags/clear",
        id: currentCourse.ID,
        values: {
          name: tagName,
          taggable_type: "courses",
        },
        successNotification: false,
      });
      message.success("Tag deleted successfully");
      invalidate({ resource: "lms/courses", invalidates: ["list"] });
      setCurrentCourse((prev) => ({
        ...prev,
        tag_infos: prev?.tag_infos?.filter((t) => t.name !== tagName),
      }));
    } catch (error) {
      message.error("Failed to remove tag");
    }
  };

  const { mutateAsync: deleteTag } = useDelete();

  const handleTagDelete = async (tagInfo) => {
    try {
      await deleteTag({
        resource: "generic_tags",
        id: tagInfo.id,
        successNotification: false,
      });
      message.success("Tag deleted successfully");
      invalidate({ resource: "lms/courses", invalidates: ["list"] });
    } catch (error) {
      message.error("Failed to delete tag");
    }
  };

  return (
    <div className="page-container">
      <List title="Course List" headerButtons={() => <div />}>
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Course>
            dataIndex="userCeritifcates"
            render={(_, record) => (
              <Space>
                <Tooltip title="See learners">
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={() => handleExpand(record)}
                  />
                </Tooltip>
              </Space>
            )}
          />
          {/* Course Name */}
          <Table.Column<Course>
            dataIndex="name"
            title="Name"
            render={(_, record) => (
              <Space>
                <Text>{record.name}</Text>
              </Space>
            )}
          />
          {/* Description */}
          <Table.Column<Course>
            dataIndex="description"
            title="Description"
            render={(_, record) => (
              <Space>
                <Text style={{ whiteSpace: "nowrap" }}>
                  {record.description}
                </Text>
              </Space>
            )}
          />

          {/* Uploaded On */}
          <Table.Column<Course>
            dataIndex="CreatedAt"
            title="Uploaded On"
            render={(value) => (
              <Space
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <DateField format="LL" value={value} />
              </Space>
            )}
          />

          {/* Tags */}
          <Table.Column<Course>
            title="Tags"
            key="tags"
            render={(_, record) => (
              <div>
                {record.tag_infos &&
                  record.tag_infos.map((tag) => (
                    <Tag
                      color="blue"
                      key={tag.id}
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        handleTagDelete(tag);
                      }}
                      closeIcon={
                        <CloseOutlined
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      }
                    >
                      {tag.name}
                    </Tag>
                  ))}
                <Button
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={() => showTagModal(record)}
                />
              </div>
            )}
          />
          <Table.Column<Course> dataIndex="send_count" title="Sent" />

          {/* Actions */}
          <Table.Column<Course>
            title="Actions"
            key="actions"
            render={(_, record) => (
              <Space size="small">
                <EditButton
                  hideText
                  size="small"
                  resource="training"
                  recordItemId={record.ID}
                />

                <Button
                  size="small"
                  icon={<ShareAltOutlined />}
                  onClick={() => showModal(record.ID)}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="lms/courses"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
        </Table>

        {/* Drawer for showing additional information */}
        <Drawer
          title={`User Certification Details for ${selectedRecord?.name}`}
          placement="right"
          width={800}
          onClose={closeDrawer}
          open={drawerVisible}
        >
          {selectedRecord && (
            <UserCertificationTableWrapper recordId={selectedRecord.ID} />
          )}
        </Drawer>

        {/* Course Sharing Details Modal */}
        {modalCourseId && (
          <CourseSharingDetailsModal
            isVisible={isModalVisible}
            onClose={handleModalClose}
            courseId={modalCourseId}
          />
        )}
      </List>

      {/* Add/Remove Tags Modal with TagSelectionComponent */}
      <Modal
        title={`Manage Tags for ${currentCourse?.name}`}
        visible={isTagModalVisible}
        onCancel={() => setIsTagModalVisible(false)}
        footer={null}
      >
        {/* Render TagSelectionComponent inside Modal */}
        {currentCourse && (
          <TagSelectionComponent
            selectedTags={{
              Partner:
                currentCourse.tag_infos
                  ?.filter((t) => allAvailableTags.Partner.includes(t.name))
                  .map((t) => t.name) || [],
              Contact:
                currentCourse.tag_infos
                  ?.filter((t) => allAvailableTags.Contact.includes(t.name))
                  .map((t) => t.name) || [],
            }}
            onAddTag={(tagName) => handleAddTagFromModal(tagName)}
            onRemoveTag={(tagName) => handleDeleteTagFromModal(tagName)}
            onTagsLoaded={handleTagsLoaded}
          />
        )}
      </Modal>

      {children}
    </div>
  );
};

type UserCertificationTableWrapperProps = {
  recordId: number;
};

const UserCertificationTableWrapper: FC<UserCertificationTableWrapperProps> = ({
  recordId,
}) => {
  const { tableProps } = useTable<UserCertification, HttpError>({
    resource: `lms/user_certification/course/${recordId}`,
    pagination: {
      mode: "off",
    },
  });
  return <UserCertificationTable tableProps={tableProps} />;
};

const getCompletionStatusText = (status) => {
  switch (status) {
    case "UNKNOWN":
      return "Registered";
    case "INCOMPLETE":
      return "In Progress";
    case "COMPLETED":
      return "Completed";
    default:
      return "";
  }
};

type UserCertificationTableProps = {
  tableProps: TableProps<UserCertification>;
};

export const UserCertificationTable: FC<UserCertificationTableProps> = ({
  tableProps,
}) => {
  if (!tableProps.loading && tableProps?.dataSource?.length == 0) {
    return <Text>No Learners Yet</Text>;
  }
  return (
    <Table
      {...tableProps}
      showHeader={tableProps?.dataSource?.length > 0}
      rowKey="ID"
    >
      <Table.Column<UserCertification>
        dataIndex="CreatedAt"
        title="Registered On"
        render={(value) => (
          <Space>
            <DateField
              style={{ whiteSpace: "nowrap" }}
              format="LL"
              value={value}
            />
          </Space>
        )}
      />
      <Table.Column<UserCertification>
        dataIndex="user.person.name"
        title="Name"
        render={(_, record) => (
          <Space>
            <Text style={{ whiteSpace: "nowrap" }}>
              {record.user.person.name}
            </Text>
          </Space>
        )}
      />
      <Table.Column<UserCertification>
        dataIndex="registration_completion"
        title="Completion Status"
        render={(value) => (
          <Space>
            <Text style={{ whiteSpace: "nowrap" }}>
              {getCompletionStatusText(value)}
            </Text>
          </Space>
        )}
      />
      <Table.Column<UserCertification>
        dataIndex="UpdatedAt"
        title="Completed On"
        render={(_, record) => (
          <Space>
            <DateField
              style={{ whiteSpace: "nowrap" }}
              format="LL"
              value={
                record.registration_completion === "COMPLETED"
                  ? record.UpdatedAt
                  : ""
              }
            />
          </Space>
        )}
      />
    </Table>
  );
};
