import { AvatarGroup, CustomAvatar, Text } from "@/components";
import { ClientRecord } from "@/types";
import { ClientRecordSourceTypeOptions } from "@/types/client_record_source_type";
import { DownloadOutlined, EyeOutlined, HomeOutlined } from "@ant-design/icons";
import { ImportButton, ShowButton, useImport, useTable } from "@refinedev/antd";
import { Button, Card, Select, Space, Table } from "antd";
import { FC, useState } from "react";

type Props = {
  style?: React.CSSProperties;
  title: string;
  company_id: number;
};

export const PartnerCompaniesTable: FC<Props> = ({
  style,
  title,
  company_id,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleSelectChange = (newValue: string) => {
    setSelectedValue(newValue);
  };

  const importProps = useImport<ClientRecord>({
    resource: "client_records/import",
    mapData: (record) => {
      return record;
    },
  });

  const { tableProps } = useTable<ClientRecord[]>({
    resource: "partners/client_records",
    filters: {
      permanent: [
        {
          field: "company_id",
          operator: "eq",
          value: company_id,
        },
        {
          field: "source_type",
          operator: "eq",
          value: selectedValue,
        },
      ],
    },
    syncWithLocation: false,
    pagination: {
      pageSize: 20,
    },
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  importProps.buttonProps.disabled = true;

  return (
    <Card
      style={style}
      styles={{
        header: {
          borderBottom: "1px solid #D9D9D9",
          marginBottom: "1px",
        },
        body: { padding: 0 },
      }}
      title={
        <Space size="middle">
          <HomeOutlined />
          <Text>{title}</Text>
        </Space>
      }
      extra={
        <Space size="middle">
          <Select
            options={[
              { label: "All", value: "" },
              ...ClientRecordSourceTypeOptions,
            ]}
            defaultValue=""
            value={selectedValue}
            style={{ width: "150px" }}
            onChange={handleSelectChange}
          />

          <ImportButton {...importProps} />
          <DownloadCSVButton
            keys={["Company Domain", "Company Name", "Team"]}
          />
        </Space>
      }
    >
      {!hasData && (
        <div
          style={{
            padding: 16,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text>
            This partner has no clients in our database. Reach out to
            tony@propelgtm.com to help your partner get onboarded.
          </Text>
        </div>
      )}
      {hasData && (
        <Table {...tableProps} rowKey="ID">
          <Table.Column<ClientRecord>
            fixed="right"
            title=""
            width={40}
            render={(_, record) => (
              <Space>
                <ShowButton
                  icon={<EyeOutlined />}
                  hideText
                  size="small"
                  resource={"companies"}
                  recordItemId={record.client_company_id}
                />
              </Space>
            )}
          />
          <Table.Column<ClientRecord>
            title="Name"
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    name={record?.client_company?.CompanyName}
                    src={record?.client_company?.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record?.client_company.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<ClientRecord>
            title="Source"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {ClientRecordSourceTypeOptions.find(
                      (item) => item.value === record?.source_type
                    )?.label || ""}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<ClientRecord>
            title="Contacts"
            render={(_, record) => {
              const avatars =
                record.team?.map((contact) => {
                  return {
                    name: contact.assignee?.name as string | undefined,
                    src: contact.assignee?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />
          <Table.Column<ClientRecord>
            title="Description"
            render={(_, record) => {
              return (
                <Space
                  style={{
                    maxWidth: 500,
                    overflow: "hidden",
                  }}
                >
                  <Text>{record?.client_company?.Description}</Text>
                </Space>
              );
            }}
          />
        </Table>
      )}
    </Card>
  );
};

type DownloadCSVProps = {
  keys: string[];
};

const DownloadCSVButton: FC<DownloadCSVProps> = ({ keys }) => {
  const downloadCSV = () => {
    const header = keys.join(",") + "\n";
    const csvContent = header;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "example.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={downloadCSV}>
      Download Example CSV
    </Button>
  );
};
