import LoadingWrapper from "@/components/loading";
import { PartnerRecord, PublicPartnerOpportunity, Status } from "@/types";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { QueryObserverResult } from "@tanstack/react-query";
import {
  CrudFilters,
  CrudSorting,
  GetListResponse,
  HttpError,
  useDelete,
  useNavigation,
  useUpdate,
} from "@refinedev/core";
import { MenuProps } from "antd";
import { TableProps } from "antd/lib/table";
import { FC, ReactNode, useMemo } from "react";
import {
  KanbanAddCardButton,
  KanbanAddStageButton,
  KanbanBoard,
  KanbanColumn,
  KanbanItem,
  ProjectCard,
  ProjectCardSkeleton,
} from "./components";
import { PartnerInboundCard } from "./components/partner-inbound-card";

type TaskStageColumn = {
  tasks: PartnerRecord[];
  status: Status;
};

type Props = {
  tableProps: TableProps<PartnerRecord>;
  statuses?: Status[];
  filters: CrudFilters;
  sorters: CrudSorting;
  children?: ReactNode;
  publicPartnerOpportunityQuery?: QueryObserverResult<
    GetListResponse<PublicPartnerOpportunity>,
    HttpError
  >;
};

export const KanbanPage: FC<Props> = ({
  tableProps,
  statuses,
  children,
  publicPartnerOpportunityQuery,
}) => {
  const { create, edit, replace } = useNavigation();

  const partners = tableProps.dataSource;

  const isLoadingTasks = tableProps.loading;
  const taskStages = useMemo(() => {
    if (!partners || !statuses)
      return {
        unassignedStage: [],
        stages: [],
      };

    const unassignedStage = partners?.filter(
      (partner) => (partner.status_id ?? 0) == 0
    );

    // prepare unassigned stage
    const grouped = statuses.map((stage) => ({
      ...stage,
      tasks: partners.filter(
        (partner) => (partner.status_id ?? 0) === (stage.ID ?? -1)
      ),
      status: stage,
    }));

    return {
      unassignedStage,
      stages: grouped,
    };
  }, [partners, statuses]);

  const { mutate: deleteStage } = useDelete();
  const { mutate: updateTask } = useUpdate();

  const handleOnDragEnd = (event: DragEndEvent) => {
    let stageId = event.over?.id as undefined | string | null;
    const partnerRecordId = event.active.id as string;
    const taskStageId = event.active.data.current?.stageId;

    if (taskStageId === stageId) {
      return;
    }

    if (stageId === "unassigned") {
      stageId = null;
    }

    updateTask({
      resource: "partners",
      values: {
        status_id: stageId,
      },
      id: partnerRecordId,
    });
  };

  const handleAddStage = () => {
    create("statuses", "replace");
  };

  const handleEditStage = (args: { status: Status }) => {
    edit("statuses", args.status.ID);
  };

  const handleDeleteStage = (args: { status: Status }) => {
    deleteStage({
      resource: "partners/status",
      id: args.status.ID,
      successNotification: () => ({
        key: "delete-stage",
        type: "success",
        message: "Successfully deleted stage",
        description: "Successful",
      }),
    });
  };

  const handleAddCard = (args: { statusId?: number }) => {
    const path =
      (args.statusId ?? 0) === 0
        ? "create"
        : `create?statusId=${args.statusId}`;

    replace(path);
  };

  const getContextMenuItems = (column: TaskStageColumn) => {
    const hasItems = column.tasks.length > 0;

    const items: MenuProps["items"] = [
      {
        label: "Edit Stage",
        key: "1",
        icon: <EditOutlined />,
        onClick: () => handleEditStage({ status: column.status }),
      },
      {
        danger: true,
        label: "Delete Stage",
        key: "2",
        icon: <DeleteOutlined />,
        disabled: hasItems,
        onClick: () => handleDeleteStage({ status: column.status }),
      },
    ];

    return items;
  };

  const isLoading = isLoadingTasks;

  const data = publicPartnerOpportunityQuery.data?.data;

  return (
    <LoadingWrapper loading={isLoading ? true : false}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {data?.length > 0 && (
          <KanbanColumn
            title={"Pending"}
            id={0}
            count={data?.length}
            disableAdd
          >
            <KanbanItem id={0}>
              {data?.map((partner) => {
                return <PartnerInboundCard partner={partner} />;
              })}
            </KanbanItem>
          </KanbanColumn>
        )}
        <KanbanBoard onDragEnd={handleOnDragEnd}>
          {taskStages.stages?.map((column) => {
            const contextMenuItems = getContextMenuItems(column);

            return (
              <KanbanColumn
                key={column.ID}
                id={column.ID}
                title={column.name}
                count={column.tasks.length}
                contextMenuItems={contextMenuItems}
                onAddClick={() => handleAddCard({ statusId: column.ID })}
              >
                {isLoading && <ProjectCardSkeleton />}
                {!isLoading &&
                  column.tasks.map((task) => {
                    return (
                      <KanbanItem
                        key={task.ID}
                        id={task.ID}
                        data={{
                          ...task,
                          stageId: column.ID,
                        }}
                      >
                        <ProjectCard partner={task} />
                      </KanbanItem>
                    );
                  })}
                {!column.tasks.length && (
                  <KanbanAddCardButton
                    onClick={() => handleAddCard({ statusId: column.ID })}
                  />
                )}
              </KanbanColumn>
            );
          })}
          <KanbanAddStageButton onClick={handleAddStage} />
        </KanbanBoard>
      </div>
      {children}
    </LoadingWrapper>
  );
};
