import { ListTitleButton, Text } from "@/components";
import { CommissionStructure } from "@/types";
import { currencyNumber } from "@/utilities";
import { DollarOutlined, PercentageOutlined } from "@ant-design/icons";
import {
  DateField,
  EditButton,
  List,
  useModalForm,
  useTable,
} from "@refinedev/antd";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";

// Helper function to format dates
const formatDate = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const commissionStructureColumns = [
  {
    title: "",
    key: "actions",
    width: 40,
    render: (_, record) => (
      <Space>
        <EditButton
          hideText
          size="small"
          resource="structure"
          recordItemId={record.ID}
        />
      </Space>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => {
      return <Typography.Text>{text}</Typography.Text>;
    },
  },
  {
    title: "Type",
    dataIndex: "commission_type",
    render: (text) => {
      const color = text === "MONTHLY" ? "green" : "volcano";
      const tagText = text === "MONTHLY" ? "Recurring" : "One-Time";

      return (
        <Tag color={color} key={text}>
          {tagText}
        </Tag>
      );
    },
  },
  {
    title: "Commission Amount",
    // Merge ActualValue and PercentValue into one column
    render: (_, record: CommissionStructure) => {
      // Otherwise, format ActualValue as currency
      const formattedValue = record.actual_value.Valid
        ? currencyNumber(record.actual_value.Float64)
        : "-";
      return <Typography.Text>{formattedValue}</Typography.Text>;
    },
  },
  {
    title: "Commission Percentage",
    // Merge ActualValue and PercentValue into one column
    render: (_, record) => {
      const formattedValue = record.percent_value.Valid
        ? `${record.percent_value.Float64}%`
        : "-";

      return <Typography.Text>{formattedValue}</Typography.Text>;
    },
  },
  {
    title: "Status",
    key: "status",
    render: (_, record) => {
      let color = "default";
      switch (record.status) {
        case "ACTIVE":
          color = "green";
          break;
        // Add more cases as needed for different statuses
        // Example:
        case "INACTIVE":
          color = "volcano";
          break;
        // case "PENDING":
        //   color = "geekblue";
        //   break;
      }

      return <Tag color={color}>{record.status}</Tag>;
    },
  },
  {
    title: "Created At",
    render: (_, record) => formatDate(record.CreatedAt),
  },
  {
    title: "Effective From",
    render: (_, record) => (
      <DateField format="YYYY-MM-DD" value={record.effective_from} />
    ),
  },
  {
    title: "Effective To",
    render: (_, record) => {
      return !record.effective_to.Valid ? (
        <Text>-</Text>
      ) : (
        <DateField format="YYYY-MM-DD" value={record.effective_to.Time} />
      );
    },
  },
];

export const CommissionStructurePage: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
  } = useModalForm<CommissionStructure>({
    action: "create",
    resource: "commission_structures",
  });
  const { tableProps } = useTable<CommissionStructure>({
    resource: "commission_structures",
    pagination: { mode: "off" },
  });

  return (
    <div>
      <List
        title={
          <ListTitleButton
            toPath="commission_structures"
            buttonText="Add Commission Structure"
            onClick={() => {
              createModalShow();
            }}
          />
        }
        breadcrumb={false}
      >
        <Table
          {...tableProps}
          columns={commissionStructureColumns}
          rowKey="ID"
        />
      </List>
      <Modal {...createModalProps}>
        <Form {...createFormProps} layout="vertical">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Commission Type"
            name="commission_type"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="MONTHLY">Monthly</Select.Option>
              <Select.Option value="ONE_TIME">One-Time</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Commission Amount"
            name={["actual_value", "Float64"]}
          >
            <InputNumber
              min={0}
              addonBefore={<DollarOutlined />}
              placeholder="0,00"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
          <Form.Item
            label="Commission Percentage"
            name={["percent_value", "Float64"]}
          >
            <InputNumber
              min={0}
              addonAfter={<PercentageOutlined />}
              placeholder="0.00"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="ACTIVE">Active</Select.Option>
              <Select.Option value="INACTIVE">Inactive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Effective From" name="effective_from">
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>

      {children}
    </div>
  );
};
