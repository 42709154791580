import "@/pages/partners/create-higlight-input.css";
import { ReferralPageInfo } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useNavigation } from "@refinedev/core";
import { Button, Form, Input, Modal, Space, Steps } from "antd";
import { useState } from "react";

type Props = {
  isOverModal?: boolean;
};

export const TrainingCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const apiResource = "lms/courses";
  const resource = "training";

  const { form, formProps, modalProps, close, onFinish } = useModalForm<
    ReferralPageInfo,
    HttpError,
    ReferralPageInfo
  >({
    action: "create",
    defaultVisible: true,
    resource: apiResource,
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
  });

  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const [steps, setSteps] = useState([
    { title: "Step 1", content: <FormStep label="Step 1" name="step1" /> },
  ]);

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        // console.log("Validation Failed:", errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const addStep = () => {
    const newStepIndex = steps.length + 1;
    setSteps([
      ...steps,
      {
        title: `Step ${newStepIndex}`,
        content: (
          <FormStep
            label={`Step ${newStepIndex}`}
            name={`step${newStepIndex}`}
          />
        ),
      },
    ]);
  };

  const deleteStep = (index) => {
    if (steps.length > 1) {
      setSteps(steps.filter((_, i) => i !== index));
      if (current >= index) {
        setCurrent(current - 1);
      }
    }
  };

  const handleFinish = (values) => {
    onFinish({ ...formData, ...values });
  };

  modalProps.okButtonProps.disabled = current != steps.length - 1;

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("training", "replace");
      }}
      title="Add New Training Material"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Space direction="horizontal" align="start">
        <Steps current={current} direction="vertical">
          {steps.map((step, index) => (
            <Steps.Step key={index} title={step.title} />
          ))}
        </Steps>
        <Form
          {...formProps}
          layout="vertical"
          style={{ marginLeft: 10, marginTop: 5 }}
          onFinish={handleFinish}
        >
          {steps[current].content}
        </Form>
      </Space>

      <div style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Finish
          </Button>
        )}
      </div>
      <div style={{ marginTop: 24 }}>
        <Button type="dashed" onClick={addStep}>
          Add Step
        </Button>
        {steps.length > 1 && (
          <Button type="dashed" danger onClick={() => deleteStep(current)}>
            Delete Step
          </Button>
        )}
      </div>
    </Modal>
  );
};

const FormStep = ({ label, name }) => (
  <Form.Item
    label={label}
    name={name}
    rules={[
      {
        required: true,
      },
    ]}
  >
    <Input />
  </Form.Item>
);
