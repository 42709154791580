import { CustomFieldsTypeListPage } from "@/pages/custom_fields/types";
import { FC, PropsWithChildren } from "react";

export const PartnerTypesListPage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <CustomFieldsTypeListPage
      children={children}
      reference_table={"Company"}
      override_title={"Partner"}
      create_resources={"partner-types"}
    />
  );
};
