import { CustomFieldResponse } from "@/types";
import { DownloadOutlined } from "@ant-design/icons";
import { DateField, NumberField, TextField } from "@refinedev/antd";
import { Button } from "antd";

export const RenderCustomFieldsViewCell = (
  record: CustomFieldResponse
): React.ReactNode => {
  switch (record.field_type) {
    case "BOOL":
      return <TextField value={record.bool_value === true ? "Yes" : "No"} />;
    case "DECIMAL":
      return <NumberField value={record.decimal_value} />;
    case "STRING":
      return <TextField value={record.string_value} />;
    case "DATETIME":
      return new Date(record?.datetime_value).getTime() <= 0 ? (
        <TextField value={"-"} />
      ) : (
        <DateField value={record.datetime_value} />
      );
    case "EMAIL":
      return <TextField value={record.email_value} />;
    case "SELECT":
      return <TextField value={record.select_value} />;
    case "MULTI_SELECT":
      return <TextField value={record.multi_select_value?.join(", ")} />;
    case "FILE":
      return record?.signed_file_value ? (
        <Button
          key={record.signed_file_value}
          href={record.signed_file_value}
          target="_blank"
          rel="noopener noreferrer"
          icon={<DownloadOutlined />} // Ant Design icon for the button
        >
          Download File
        </Button>
      ) : (
        <TextField value={"-"} />
      );
    default:
      return null;
  }
};
