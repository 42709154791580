import "@/pages/partners/create-higlight-input.css";
import { useIdentity } from "@/providers/identity";
import { QuillProvider, ReportBuilder } from "@quillsql/react";
import { useModal } from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Card, Modal } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const ReportsCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const me = useIdentity();

  const { modalProps, close } = useModal();
  modalProps.open = true;
  modalProps.visible = true;

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("reports", "replace");
      }}
      title="Add New Report"
      width={"80%"}
      footer={null}
    >
      <Card
        style={{
          width: "100%",
        }}
      >
        {me?.person?.company_id && (
          <QuillProvider
            organizationId={me?.person?.company_id?.toString()}
            publicKey="665610862cf7a3000be66453"
          >
            <ReportBuilder isAIEnabled={false} isAdminEnabled={false} />
          </QuillProvider>
        )}
      </Card>
    </Modal>
  );
};
