import { AvatarGroup, ListTitleButton, Text } from "@/components";
import { Person, Task } from "@/types";
import {
  BooleanField,
  DateField,
  DeleteButton,
  EditButton,
  List,
  useTable,
} from "@refinedev/antd";
import { HttpError, useList } from "@refinedev/core";
import { Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";

export const TaskListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<Task, HttpError>({
    resource: "tasks",
    pagination: {
      mode: "off",
    },
  });

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = people?.data;

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={<ListTitleButton toPath="tasks" buttonText="Add new task" />}
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Task>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) =>
              !record.completed_at?.Valid && (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    resource="tasks"
                    recordItemId={record.ID}
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    resource="tasks"
                    recordItemId={record.ID}
                  />
                </Space>
              )
            }
          />
          <Table.Column<Task>
            dataIndex="title"
            title="Title"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.title}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Task>
            dataIndex="description"
            title="Description"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.description}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<Task>
            dataIndex="due_date"
            title="Due Date"
            render={(_, record) => {
              return <DateField format="YYYY-MM-DD" value={record.due_date} />;
            }}
          />

          <Table.Column<Task>
            dataIndex="subscribed_people_ids"
            title="Assignees"
            render={(_, record) => {
              const avatars =
                record?.assignee_ids?.map((person_id) => {
                  return {
                    name: peopleOptions?.find((item) => item.ID === person_id)
                      ?.name as string | undefined,
                    src: peopleOptions?.find((item) => item.ID === person_id)
                      ?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />

          <Table.Column<Task>
            dataIndex="subscribed_people_ids"
            title="Subscribed"
            render={(_, record) => {
              const avatars =
                record?.subscribed_people_ids?.map((person_id) => {
                  return {
                    name: peopleOptions?.find((item) => item.ID === person_id)
                      ?.name as string | undefined,
                    src: peopleOptions?.find((item) => item.ID === person_id)
                      ?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />
          <Table.Column<Task>
            dataIndex="automated_task"
            title="AI Automated"
            render={(_, record) => {
              return <BooleanField value={record.automated_task} />;
            }}
          />
          <Table.Column<Task>
            dataIndex="completed_at"
            title="Completed"
            render={(_, record) => {
              return <BooleanField value={record.completed_at.Valid} />;
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
