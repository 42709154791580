import "@/pages/partners/create-higlight-input.css";
import { Tag } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useNavigation } from "@refinedev/core";
import { Form, Input, Modal } from "antd";

type Props = {
  isOverModal?: boolean;
  parent_resources: string;
  reference_table: string;
  title?: string;
};

export const CustomFieldsTypeCreatePage = ({
  isOverModal,
  parent_resources,
  reference_table,
  title,
}: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const formattedTitle = title ?? `Add New ${reference_table} Type`;
  const { formProps, modalProps, close, onFinish } = useModalForm<
    Tag,
    HttpError,
    Tag
  >({
    action: "create",
    defaultVisible: true,
    resource: "tags",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: parent_resources });
      list(parent_resources, "replace");
    },
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(parent_resources, "replace");
      }}
      title={formattedTitle}
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="" name="Name">
          <Input />
        </Form.Item>

        <Form.Item
          label="reference_table"
          name="reference_table"
          hidden={true}
          initialValue={reference_table}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
