import { CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { ObjectSyncStatusComponent } from "@/pages/sync";
import { PartnerRecord } from "@/types";
import { getNameInitials } from "@/utilities";
import { GetOneResponse } from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Form, FormProps, Space } from "antd";
import { FC } from "react";

type Props = {
  formProps: FormProps;
  queryResult: QueryObserverResult<GetOneResponse<PartnerRecord>> | undefined;
};

export const PartnerTitleForm: FC<Props> = ({ formProps, queryResult }) => {
  const partner_record = queryResult?.data?.data;
  const loading = queryResult?.isLoading;

  return (
    <LoadingWrapper loading={loading}>
      <Space
        direction="vertical"
        style={{ width: "100%", position: "relative" }}
      >
        <Form {...formProps}>
          <Space
            size={16}
            style={{
              width: "100%",
              paddingBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <CustomAvatar
              size={"large"}
              shape="square"
              src={partner_record?.partner_company.PhotoUrl}
              name={getNameInitials(
                partner_record?.partner_company.CompanyName || ""
              )}
              style={{
                width: 40,
                height: 40,
                fontSize: 48,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
              }}
            />
            <Text style={{ fontSize: "32px", fontWeight: 700 }}>
              {partner_record?.partner_company.CompanyName}
            </Text>
          </Space>
        </Form>

        <ObjectSyncStatusComponent
          style={{ position: "absolute", right: 0, top: 5 }}
          reference_table="Company"
          reference_id={partner_record?.partner_company_id}
        />
      </Space>
    </LoadingWrapper>
  );
};
