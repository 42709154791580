import { Company } from "@/types";
import { HttpError, useOne, useParsed } from "@refinedev/core";
import { Col, Row } from "antd";
import { ObjectNotes } from "../notes";
import { CompanyInfoForm } from "./info-form";
import { CompanyTitleForm } from "./title-form";
import LoadingWrapper from "@/components/loading";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import { CompanyPartnersTable } from "./partners-table";

export const CompanyShowPage = () => {
  const { id: entityId } = useParsed();

  const queryResult = useOne<Company, HttpError>({
    resource: "companies",
    id: entityId,
  });

  return (
    <LoadingWrapper loading={queryResult.isLoading}>
      <div className="page-container">
        <CompanyTitleForm
          queryResult={queryResult}
          showSync={true}
          editable={false}
        />
        <Row
          gutter={[32, 32]}
          style={{
            marginTop: 32,
          }}
        >
          <Col span={16}>
            <CompanyPartnersTable />
            <ObjectNotes
              reference_table="Company"
              style={{
                marginTop: 32,
              }}
            />
          </Col>
          <Col span={8}>
            <CompanyInfoForm queryResult={queryResult} />
            <TagBasedCustomFieldsTable
              taggable_object={"Company"}
              field_reference_table={"Company"}
              style={{
                marginTop: 32,
              }}
              show_resource="partner-types"
            />
          </Col>
        </Row>
      </div>
    </LoadingWrapper>
  );
};
