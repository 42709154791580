import { CustomAvatar, Text } from "@/components";
import { API_BASE_URL } from "@/providers/data";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { useOpportunityStatuses } from "@/providers/options";
import { CommissionStructure, Opportunity } from "@/types";
import { currencyNumber } from "@/utilities";
import { getTablePropsWithPagination } from "@/utilities/tables";
import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useSelect,
  useTable,
} from "@refinedev/antd";
import { HttpError, useInvalidate } from "@refinedev/core";
import {
  Button,
  Card,
  message,
  Space,
  Table,
  Tooltip,
  InputNumber,
  Flex,
} from "antd";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { ObjectSyncIcon } from "../sync";
import { PublicOpportunitiesTable } from "./public_opportunities_table";
import { useSalesforceConversion } from "@/utilities/useSalesforceDeduplication";
import { isDefaultDBDate } from "@/utilities";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TextWithInfo } from "@/components/text-with-info";

export const OpportunitiesListPage: FC<PropsWithChildren> = ({ children }) => {
  const invalidate = useInvalidate();
  const statusOptions = useOpportunityStatuses();

  const handleChangeCommissionStructure = async (
    opportunityId: number,
    commissionStructureId: number
  ) => {
    // if (!newCommissionStructure) {
    //   message.error("Please enter a valid commission structure");
    //   return;
    // }

    try {
      await globalAxiosInstance.patch(
        `${API_BASE_URL}/opportunities/${opportunityId}`,
        {
          commission_structure_id: commissionStructureId,
        }
      );

      message.success("Commission Structure updated successfully");
      invalidate({
        resource: "opportunities_view_as_company",
        invalidates: ["list"],
      });
    } catch (error) {
      console.error(`Error while updating commission structure: ${error}`);
      message.error("Error while updating commission structure");
    }
  };

  const { selectProps } = useSelect<CommissionStructure>({
    resource: "commission_structures",
    optionLabel: (item: CommissionStructure) => item.name,
    optionValue: (item: CommissionStructure) => `${item.ID}`,
  });

  const { tableProps } = useTable<Opportunity, HttpError, { name: string }>({
    resource: "opportunities_view_as_company",
    pagination: {
      mode: "server",
    },
    filters: {
      initial: [
        {
          field: "approved",
          operator: "eq",
          value: true,
        },
      ],
      mode: "server",
    },
    syncWithLocation: false,
  });

  const [editingCSForOpportunityMap, setEditingCSForOpportunityMap] = useState(
    {}
  );
  const opportunities = tableProps.dataSource;
  // Use useEffect to update the state when opportunities changes
  useEffect(() => {
    if (!opportunities) {
      return;
    }
    // Create a new map from opportunities
    const newMap = opportunities.reduce((acc, opportunity, index) => {
      // Assuming opportunity has a unique identifier, e.g., opportunity.id
      // If not, you can use index or another unique property
      acc[opportunity.ID] = false;
      return acc;
    }, {});

    // Update the state with the new map
    setEditingCSForOpportunityMap(newMap);
  }, [opportunities]); // Dependency array includes opportunities

  // const [editingCSForOpportunityMap, setEditingCSForOpportunityMap] = useState({
  //   0: false, // Example ID with its boolean value
  //   1: false, // Another example
  // });
  const toggleCSEditForOpportunity = (id) => {
    setEditingCSForOpportunityMap((editingCSForOpportunityMap) => ({
      ...editingCSForOpportunityMap,
      [id]: !editingCSForOpportunityMap[id],
    }));
  };

  const [loadingMap, setLoadingMap] = useState<{ [key: number]: boolean }>({});
  const { handleCreateRecord } = useSalesforceConversion({
    setLoadingMap,
    mode: "opportunities",
    onSuccess: () => {
      invalidate({
        invalidates: ["all"],
        resource: "opportunities",
      });
      invalidate({
        invalidates: ["all"],
        resource: "opportunities_view_as_company",
      });
    },
  });

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
      >
        <Card
          title="Pending"
          bordered={false}
          style={{ width: "100%", marginBottom: "20px" }}
        >
          <PublicOpportunitiesTable />
        </Card>

        <Card title="In Process" bordered={false} style={{ width: "100%" }}>
          <Table {...getTablePropsWithPagination(tableProps, "")} rowKey="ID">
            <Table.Column<Opportunity>
              fixed="right"
              dataIndex="ID"
              title=""
              width={80}
              render={(_, record) => (
                <Space>
                  <Tooltip title="View">
                    <ShowButton
                      hideText
                      size="small"
                      resource="opportunities"
                      recordItemId={record.ID}
                    />
                  </Tooltip>
                  <Tooltip title="Edit">
                    <EditButton
                      hideText
                      size="small"
                      resource="opportunities"
                      recordItemId={record.ID}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteButton
                      hideText
                      size="small"
                      resource="opportunities"
                      recordItemId={record.ID}
                      onSuccess={() => {
                        invalidate({
                          invalidates: ["all"],
                          resource: "opportunities",
                        });
                        invalidate({
                          invalidates: ["all"],
                          resource: "opportunities_view_as_company",
                        });
                      }}
                    />
                  </Tooltip>
                </Space>
              )}
            />

            <Table.Column<Opportunity>
              dataIndex="approved"
              title="CRM Record"
              filters={[
                {
                  text: "Approved",
                  value: "true",
                },
                {
                  text: "Rejected",
                  value: "false",
                },
                {
                  text: "Show All",
                  value: "",
                },
              ]}
              defaultFilteredValue={["true"]}
              filterMultiple={false}
              render={(_, record) => {
                if (!record?.approved) {
                  return (
                    <Space>
                      <Text>Rejected - {record.rejection_reason}</Text>
                    </Space>
                  );
                }
                const isLoading = loadingMap[record.ID] || false;
                return (
                  <Space>
                    {record.object_sync_status?.external_id ? (
                      <Button
                        size="small"
                        icon={<ObjectSyncIcon name={""} />}
                        onClick={() => {
                          window.open(
                            record.object_sync_status?.external_link,
                            "_blank"
                          );
                        }}
                      >
                        View Record
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        loading={isLoading} // Use loading state
                        onClick={() => handleCreateRecord(record.ID)} // Call the handler
                      >
                        {isLoading ? "Creating..." : "Create Record"}
                      </Button>
                    )}
                  </Space>
                );
              }}
            />

            <Table.Column<Opportunity>
              dataIndex="name"
              title="Partner"
              render={(_, record) => {
                return (
                  <Space>
                    <CustomAvatar
                      shape="square"
                      name={record.partner_record?.partner_company?.CompanyName}
                      src={record.partner_record?.partner_company?.PhotoUrl}
                    />
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {record.partner_record?.partner_company?.CompanyName}
                    </Text>
                  </Space>
                );
              }}
            />
            <Table.Column<Opportunity>
              dataIndex="name"
              title="Opportunity Name"
              sorter
              render={(_, record) => {
                return (
                  <Space>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {record.name}
                    </Text>
                  </Space>
                );
              }}
            />

            <Table.Column<Opportunity>
              dataIndex="contract_value"
              title="Contract Value"
              sorter
              render={(_, record) => {
                return (
                  <Space>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {currencyNumber(record.contract_value)}
                    </Text>
                  </Space>
                );
              }}
            />

            <Table.Column<Opportunity>
              dataIndex="close_date"
              title="Close Date"
              sorter
              render={(_, record) => {
                return (
                  <Space
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <DateField format="LL" value={record.close_date} />
                  </Space>
                );
              }}
            />

            <Table.Column<Opportunity>
              dataIndex="status"
              title="Stage"
              sorter
              render={(_, record) => {
                return (
                  <Space>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {statusOptions?.find(
                        (item) => item.value === record.status
                      )?.label ?? record.status}
                    </Text>
                  </Space>
                );
              }}
            />

            <Table.Column<Opportunity>
              dataIndex="expiry_notificaton_date"
              title="Expiry Date"
              sorter
              render={(_, record) => {
                return (
                  <Space
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {isDefaultDBDate(record.expiry_notificaton_date) ? (
                      <Text style={{ whiteSpace: "nowrap" }}>-</Text>
                    ) : (
                      <DateField
                        format="LL"
                        value={record.expiry_notificaton_date}
                      />
                    )}
                  </Space>
                );
              }}
              filterDropdown={({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <div style={{ padding: 8 }}>
                    <InputNumber
                      placeholder="Days until expiry"
                      defaultValue={selectedKeys[0] as number}
                      onChange={(e) => setSelectedKeys([e])}
                      onPressEnter={() => confirm()}
                      style={{
                        marginBottom: 8,
                        display: "block",
                        width: 130,
                      }}
                      controls={false}
                    />
                    <Flex gap={8} justify="end">
                      <Button
                        onClick={() => {
                          clearFilters();
                          confirm();
                        }}
                        size="small"
                        style={{ width: 60 }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          confirm();
                        }}
                        size="small"
                        style={{ width: 60 }}
                      >
                        Filter
                      </Button>
                    </Flex>
                  </div>
                );
              }}
            />
            <Table.Column<Opportunity>
              dataIndex="registered_at"
              title={
                <TextWithInfo
                  title="Deal Registration Date"
                  infoDetails="The date the deal was registered"
                />
              }
              render={(_, record) => {
                return (
                  <Space
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {record.public_opportunity ? (
                      <DateField
                        format="LL"
                        value={record.public_opportunity.CreatedAt}
                      />
                    ) : (
                      <Text>-</Text>
                    )}
                  </Space>
                );
              }}
            />
            <Table.Column<Opportunity>
              dataIndex="created_at"
              title={
                <TextWithInfo
                  title="Deal Processed Date"
                  infoDetails="The date the deal was first approved or rejected."
                />
              }
              sorter
              render={(_, record) => {
                return (
                  <Space
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <DateField format="LL" value={record.CreatedAt} />
                  </Space>
                );
              }}
            />
            {/* 
          <Table.Column<Opportunity>
            dataIndex="status"
            title="Commission Structure"
            render={(_, record) => {
              if (
                !record.commission_structure ||
                editingCSForOpportunityMap[record.ID]
              ) {
                return (
                  <Select
                    onChange={
                      (value) => {
                        handleChangeCommissionStructure(record.ID, value);
                        toggleCSEditForOpportunity(record.ID);
                        //setEditingCommissionStructure(false);
                      }
                      // console.log("Selecting a commission Structure")
                      // console.log(value)
                    }
                    defaultValue={
                      record.commission_structure
                        ? record.commission_structure.ID
                        : null
                    }
                    placeholder="Select a commission structure"
                    style={{ width: 300 }}
                    {...selectProps}
                  />
                );
              }
              return (
                <div>
                  <Space>
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      {record.commission_structure
                        ? `${record.commission_structure.name}`
                        : ""}
                    </Text>
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => {
                        console.log("Changing commission structure");
                        toggleCSEditForOpportunity(record.ID);
                      }}
                    />
                  </Space>
                </div>
              );
            }}
          />
          
          <Table.Column<Opportunity>
            title="Computed Commission Amount"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.commission_computed_value.Valid
                      ? currencyNumber(record.commission_computed_value.Float64)
                      : "_"}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Opportunity>
            title="Override Commission Amount"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.commission_override_value.Valid
                      ? currencyNumber(record.commission_override_value.Float64)
                      : "_"}
                  </Text>
                </Space>
              );
            }}
          />
        */}
          </Table>
        </Card>
      </List>
      {children}
    </div>
  );
};
