import { Text } from "@/components";
import { CustomFieldResponse } from "@/types";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { file2Base64 } from "@refinedev/core";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Upload,
} from "antd";
import type { Rule } from "antd/es/form";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";

/**
 * A reusable form component that renders different form fields based on the type.
 *
 * @param {string} name - The label name for the form field.
 * @param {string} field - The field key or identifier.
 * @param {string} type - The field type (e.g., "STRING", "DECIMAL", "BOOL", "DATETIME", "SELECT", "MULTI_SELECT").
 * @param {object} metadata - Additional metadata (e.g., options for SELECT or MULTI_SELECT).
 * @param {boolean} required - Boolean to identify if an form field is required.
 * @returns {JSX.Element} The appropriate form item component.
 */

const baseStyle = {
  paddingBottom: "16px",
  marginBottom: "16px",
  borderBottom: "2px solid #D9D9D9",
};

const labelStyle = { fontSize: 18, marginRight: 8 };

const inputStyle = {
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
};

const inputStyleAlt = { height: 50, width: 500, fontSize: 18 };

const FormItem = ({
  name,
  field,
  type = "STRING",
  metadata = null,
  required = true,
  applyCustomStyle = false,
}) => {
  const [otherValue, setOtherValue] = useState("");

  const onOtherInputChange = (e) => {
    setOtherValue(e.target.value);
  };

  if (type === "DECIMAL") {
    return (
      <Form.Item
        name={field}
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
        style={applyCustomStyle ? { ...baseStyle } : undefined}
        rules={[{ required, message: `Please select a ${name}` }]}
      >
        <InputNumber
          style={applyCustomStyle ? inputStyleAlt : { width: "100%" }}
          placeholder={name}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          controls={false}
        />
      </Form.Item>
    );
  }

  if (type === "DATETIME") {
    return (
      <Form.Item
        name={field}
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
        style={applyCustomStyle ? { ...baseStyle } : undefined}
        rules={[{ required, message: `Please select a ${name}` }]}
      >
        <DatePicker
          placeholder={"Pick a date"}
          showTime={false}
          format="YYYY-MM-DD"
          style={applyCustomStyle ? inputStyleAlt : { width: "100%" }}
        />
      </Form.Item>
    );
  }

  if (type === "BOOL") {
    return (
      <Form.Item
        name={field}
        valuePropName="checked"
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
        style={applyCustomStyle ? { ...baseStyle } : undefined}
        rules={[{ required, message: `Please select a ${name}` }]}
      >
        <Checkbox />
      </Form.Item>
    );
  }

  if (type === "SELECT") {
    return (
      <Form.Item
        name={field}
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
        rules={[{ required, message: `Please select a ${name}` }]}
        style={applyCustomStyle ? { ...baseStyle } : undefined}
      >
        <Radio.Group
          style={applyCustomStyle ? { display: "block" } : undefined}
        >
          {metadata?.options.map((option, index) => (
            <Radio
              key={index}
              value={option}
              style={applyCustomStyle ? inputStyle : undefined}
            >
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }

  if (type === "MULTI_SELECT") {
    return (
      <Form.Item
        name={field}
        initialValue={[]}
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
        rules={[{ required, message: `Please select a ${name}` }]}
        onReset={() => setOtherValue("")}
        style={applyCustomStyle ? { ...baseStyle } : undefined}
      >
        <Checkbox.Group
          style={
            applyCustomStyle
              ? {
                  display: "block",
                }
              : undefined
          }
        >
          {metadata?.options.map((option, index) => (
            <Checkbox
              key={index}
              value={option}
              style={
                applyCustomStyle
                  ? inputStyle
                  : { display: "flex", alignItems: "center" }
              }
            >
              {option}
            </Checkbox>
          ))}
          <Checkbox
            key={metadata?.options.length}
            value={otherValue}
            checked={otherValue.length > 0}
            style={
              applyCustomStyle
                ? inputStyle
                : { display: "flex", alignItems: "center" }
            }
          >
            <Input
              placeholder={"Other"}
              value={otherValue}
              onChange={onOtherInputChange}
              size="small"
              variant="outlined"
            />
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
    );
  }

  if (type === "FILE") {
    return (
      <Form.Item
        name={field}
        rules={[{ required, message: `Please select a ${name}` }]}
        label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
      >
        <Upload.Dragger
          name="file"
          listType="picture"
          maxCount={1}
          beforeUpload={() => false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
        </Upload.Dragger>
      </Form.Item>
    );
  }

  const rules: Rule[] = [
    {
      required,
      message: `Please Enter ${name}`,
    },
  ];

  if (type === "EMAIL") {
    rules.push({
      type: "email",
      message: "The input is not valid e-mail",
    });
  }

  return (
    <Form.Item
      name={field}
      rules={rules}
      label={applyCustomStyle ? <span style={labelStyle}>{name}</span> : name}
      style={applyCustomStyle ? { ...baseStyle } : undefined}
    >
      <Input
        placeholder={name}
        style={applyCustomStyle ? inputStyleAlt : { width: "100%" }}
      />
    </Form.Item>
  );
};

export const processCustomFieldsForm = async (values: Record<string, any>) => {
  const updatedValues = { ...values };

  for (const [key, value] of Object.entries(values)) {
    if (value && value.fileList) {
      const base64Files = await Promise.all(
        value.fileList.map(async (file) => {
          const base64String = await file2Base64(file);

          return {
            base64: base64String,
            fileName: file.name,
            contentType: file.type || "application/octet-stream", // Fallback to a generic MIME type
          };
        })
      );

      // Assume it's a single file for now
      updatedValues[key] = base64Files[0];
    }
  }

  return updatedValues;
};

export const generateColumns = <
  T extends { custom_fields: CustomFieldResponse[] }
>(
  data: readonly T[]
): ColumnsType<T> => {
  if (!data || data.length === 0) {
    return [];
  }

  const titleStyle: React.CSSProperties = {
    lineHeight: "1.2em",
    maxHeight: "3.6em",
    overflow: "auto",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    overflowY: "hidden",
    whiteSpace: "normal",
    display: "block",
    textWrap: "wrap",
    minWidth: 200,
  };

  const customFields = data[0]?.custom_fields || [];

  return customFields.map((field, index) => {
    if (field.field_type === "MULTI_SELECT") {
      return {
        title: <div style={{ ...titleStyle }}>{field.name}</div>,
        dataIndex: [
          "custom_fields",
          index,
          `${field.field_type.toLowerCase()}_value`,
        ],
        key: field.id,
        render: (values: string[]) => (values ? values.join(", ") : ""),
      };
    }

    if (field.field_type === "FILE") {
      return {
        title: <div style={{ ...titleStyle }}>{field.name}</div>,
        dataIndex: ["custom_fields", index, `signed_file_value`],
        key: field.id,
        // TODO(jxu): change over to display name
        render: (record) =>
          record ? (
            <Button
              key={record}
              href={record}
              target="_blank"
              rel="noopener noreferrer"
              icon={<DownloadOutlined />} // Ant Design icon for the button
            >
              Download File
            </Button>
          ) : (
            <Text>-</Text>
          ),
      };
    }

    const truncateText = (text: string, maxLength = 120) =>
      text.length > maxLength ? `${text.slice(0, maxLength)} ...` : text;

    return {
      title: <div style={{ ...titleStyle }}>{field.name}</div>,
      dataIndex: [
        "custom_fields",
        index,
        `${field.field_type.toLowerCase()}_value`,
      ],
      key: field.id,
      render: (value: string) => (value ? truncateText(value) : ""),
    };
  });
};

export default FormItem;
