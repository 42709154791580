import { Create, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Upload } from "antd";
import { file2Base64 } from "@refinedev/core";
import type { HttpError } from "@refinedev/core";
import type { UploadFile } from "antd";
interface IDocUpload {
  files: UploadFile[];
}

type Props = {
  callback: (boolean) => void;
};

export const UploadPage: React.FC<Props> = (props) => {
  const { form, formProps, saveButtonProps } = useForm<
    undefined,
    HttpError,
    IDocUpload
  >({
    resource: "document",
    redirect: "list",
    onMutationSuccess: () => props.callback(false),
  });
  return (
    <Create
      title="Add a document"
      saveButtonProps={saveButtonProps}
      headerProps={{
        onBack: () => props.callback(false),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values) => {
          const base64Files = [];
          const { files } = values;

          for (const file of files) {
            if (file.originFileObj) {
              const base64String = await file2Base64(file);

              base64Files.push({
                ...file,
                base64String,
              });
            } else {
              base64Files.push(file);
            }
          }

          return formProps.onFinish?.({
            ...base64Files[0],
          });
        }}
      >
        <Form.Item label="File">
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
