import { Text } from "@/components";
import { Contract, PartnerRecord } from "@/types";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { DateField, DeleteButton, useTable } from "@refinedev/antd";
import { GetOneResponse, useCreate, useInvalidate } from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Button, Card, Space, Table } from "antd";
import { FC, useRef } from "react";

type Props = {
  queryResult: QueryObserverResult<GetOneResponse<PartnerRecord>> | undefined;
  style?: React.CSSProperties;
};

export const ContractsTable: FC<Props> = ({ queryResult, style }) => {
  const invalidate = useInvalidate();
  const fileInputRef = useRef(null);
  const { mutate: create } = useCreate();

  const handleButtonClick = () => {
    // Trigger the file input click event
    if (fileInputRef !== null && fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  // Method to handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    // Promise to handle the file reading completion
    const fileReadPromise = new Promise((resolve, reject) => {
      reader.onload = () => {
        // The file's text will be printed here
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
    // Read the file
    reader.readAsDataURL(file);
    const base64String = await fileReadPromise;

    create({
      resource: "contracts",
      values: {
        name: file.name,
        base64_string: base64String,
        file_type: file.type,
        client_company_id: queryResult?.data?.data.partner_company_id,
      },
    });
    invalidate({
      resource: "partners",
      invalidates: ["detail"],
      id: queryResult?.data?.data.ID,
    });
    invalidate({
      invalidates: ["all"],
      resource: "contracts_for_company",
    });
  };

  const { tableProps } = useTable<Contract[]>({
    resource: "contracts_for_company",
    filters: {
      permanent: [
        {
          field: "company_id",
          operator: "eq",
          value: queryResult?.data?.data?.partner_company_id,
        },
      ],
    },
    hasPagination: false,
  });

  const hasData =
    tableProps.loading || queryResult?.isLoading
      ? true
      : (tableProps?.dataSource?.length || 0) > 0;

  return (
    <Card
      styles={{
        header: {
          borderBottom: "1px solid #D9D9D9",
          marginBottom: "1px",
        },
        body: {
          padding: 0,
        },
      }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Space size="middle">
            <FileTextOutlined />
            <Text>Contracts</Text>
          </Space>
          <Space size="middle">
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Button onClick={handleButtonClick} icon={<FileAddOutlined />} />
          </Space>
        </div>
      }
      style={style}
    >
      {!hasData && (
        <div
          style={{
            padding: 16,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text>No contracts found</Text>
        </div>
      )}
      {hasData && (
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Contract>
            title="Name"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record?.Name}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<Contract>
            title="Date"
            render={(_, record) => {
              return (
                <Space
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <DateField format="LLL" value={record?.CreatedAt} />
                </Space>
              );
            }}
          />
          <Table.Column<Contract>
            dataIndex="id"
            width={112}
            render={(_, record) => {
              return (
                <Space>
                  <Button
                    size="small"
                    href={record.Url}
                    download={record.Name}
                    icon={<DownloadOutlined />}
                  />
                  <DeleteButton
                    resource="contracts"
                    recordItemId={record.ID}
                    icon={<DeleteOutlined />}
                    onSuccess={() => {
                      invalidate({
                        invalidates: ["all"],
                        resource: "contracts_for_company",
                      });
                    }}
                    size="small"
                    hideText={true}
                  />
                </Space>
              );
            }}
          />
        </Table>
      )}
    </Card>
  );
};
