import { Text } from "@/components";
import { AuditOutlined } from "@ant-design/icons";
import { AreaConfig } from "@ant-design/plots";
import { Card, Skeleton } from "antd";
import React, { FC, PropsWithChildren, Suspense } from "react";
import styles from "./index.module.css";

const Area = React.lazy(() => import("@ant-design/plots/es/components/area"));

type Type = "companies" | "contacts" | "emails";

export const DashboardTotalCountCard: React.FC<{
  resource: Type;
  isLoading: boolean;
  totalCount?: number;
  title: string;
}> = ({ resource, isLoading, totalCount, title }) => {
  const config: AreaConfig = {
    className: styles.area,
    appendPadding: [1, 0, 0, 0],
    padding: 0,
    syncViewPadding: true,
    data: [],
    autoFit: true,
    tooltip: false,
    animation: false,
    xField: "index",
    yField: "value",
    xAxis: false,
    yAxis: {
      tickCount: 12,
      label: {
        style: {
          fill: "transparent",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "transparent",
          },
        },
      },
    },
    smooth: true,
    areaStyle: () => {
      return {
        fill: `l(270) 0:#fff 0.2:#FFD8BF 1:#FA541C`,
      };
    },
    line: {
      color: "#FA541C",
    },
  };

  return (
    <Card
      style={{ height: "96px", padding: 0 }}
      styles={{
        body: {
          padding: "8px 8px 8px 12px",
        },
      }}
      size="small"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <IconWrapper color="#FFF2E8">
          <AuditOutlined
            className="md"
            style={{
              color: "#FA541C",
            }}
          />
        </IconWrapper>
        <Text size="md" className="secondary" style={{ marginLeft: "8px" }}>
          {title}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text
          size="xxxl"
          strong
          style={{
            textAlign: "start",
            marginLeft: "48px",
            fontVariantNumeric: "tabular-nums",
          }}
        >
          {isLoading ? (
            <Skeleton.Button
              style={{
                marginTop: "8px",
                width: "74px",
              }}
            />
          ) : (
            totalCount
          )}
        </Text>
        <Suspense>
          <Area {...config} />
        </Suspense>
      </div>
    </Card>
  );
};

const IconWrapper: FC<PropsWithChildren<{ color: string }>> = ({
  color,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  );
};
