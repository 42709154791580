import { FeatureFlag } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useInvalidate, useNavigation, useParsed } from "@refinedev/core";
import { Checkbox, Form, Input, Modal, Select } from "antd";

type Props = {
  isOverModal?: boolean;
};

export const FeatureFlagsEditPage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const resource = "feature_flags";

  const { formProps, query, modalProps, close } = useModalForm<FeatureFlag>({
    action: "edit",
    defaultVisible: true,
    resource: "feature_flags",
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
    redirect: false,
    syncWithLocation: { key: resource },
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Edit Feature Flag"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        {/* Feature Name Input */}
        <Form.Item
          label="Feature Name"
          name="feature_name"
          rules={[{ required: true, message: "Feature name is required" }]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>

        {/* Enable Globally Checkbox */}
        <Form.Item
          label="Enable Globally"
          name="enabled_globally"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        {/* Users IDs Input using Select with tags mode */}
        <Form.Item
          label="User IDs"
          name="users_ids"
          rules={[{ required: false, message: "Please provide user IDs" }]}
        >
          <Select
            mode="tags" // Enables the user to add multiple values
            placeholder="Enter or select user IDs"
            defaultValue={query.data?.data?.users?.map((c) => c.ID.toString())}
            style={{ width: "100%" }}
          >
            {/* Users can manually type IDs, or if you have predefined IDs you can include them as <Option> */}
          </Select>
        </Form.Item>

        {/* Companies IDs Input using Select with tags mode */}
        <Form.Item
          label="Company IDs"
          name="companies_ids"
          rules={[{ required: false, message: "Please provide company IDs" }]}
        >
          <Select
            mode="tags" // Enables the user to add multiple values
            placeholder="Enter or select company IDs"
            defaultValue={query.data?.data?.companies?.map((c) =>
              c.ID.toString()
            )}
            style={{ width: "100%" }}
          >
            {/* Companies can manually type IDs, or if you have predefined IDs you can include them as <Option> */}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
