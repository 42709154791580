import { AvatarGroup, CustomAvatar, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import { ClientRecord } from "@/types";
import { EyeOutlined } from "@ant-design/icons";
import { EditButton, useTable } from "@refinedev/antd";
import { useParsed } from "@refinedev/core";
import { Card, Space, Table } from "antd";
import { FC } from "react";

export const CompanyPartnersTable: FC = () => {
  const { id: company_id } = useParsed();

  const { tableProps, tableQuery } = useTable<ClientRecord[]>({
    resource: `partners/companies/${company_id}`,
    pagination: { mode: "off" },
  });

  return (
    <LoadingWrapper loading={tableQuery.isLoading}>
      <Card
        styles={{
          header: {
            borderBottom: "1px solid #D9D9D9",
            marginBottom: "1px",
          },
          body: { padding: 0 },
        }}
        title={
          <Space size="middle">
            <Text>Partners</Text>
          </Space>
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<ClientRecord>
            fixed="right"
            title=""
            width={40}
            render={(_, record) => (
              <Space>
                <EditButton
                  icon={<EyeOutlined />}
                  hideText
                  size="small"
                  resource={"partners"}
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
          <Table.Column<ClientRecord>
            title="Name"
            render={(_, record) => {
              return (
                <Space>
                  <CustomAvatar
                    name={record?.company?.CompanyName}
                    src={record?.company?.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record?.company.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<ClientRecord>
            title="Contacts"
            render={(_, record) => {
              const avatars =
                record.team?.map((contact) => {
                  return {
                    name: contact.assignee?.name as string | undefined,
                    src: contact.assignee?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />
        </Table>
      </Card>
    </LoadingWrapper>
  );
};
