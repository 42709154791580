import "@/pages/partners/create-higlight-input.css";
import { LeftOutlined } from "@ant-design/icons";
import { getValueFromEvent, useModalForm } from "@refinedev/antd";
import {
  file2Base64,
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Form, Modal, Select, Upload } from "antd";
import { Contract, PartnerRecord } from "@/types";
import { FilterOption } from "@/utilities/select";

type Props = {
  isOverModal?: boolean;
};

export const ContractsCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const resource = "contracts";
  const { formProps, modalProps, close } = useModalForm<
    Contract,
    HttpError,
    Contract
  >({
    action: "create",
    defaultVisible: true,
    resource: "contracts",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
  });

  const { data: partners } = useList<PartnerRecord>({
    pagination: {
      mode: "off",
    },
    resource: "partners",
  });
  const partnerOptions = transformPartnerRecords(partners?.data);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(resource, "replace");
      }}
      title="Add new contract"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values) => {
          const base64Files = [];
          const { files = [] } = values;

          for (const file of files) {
            if (file.originFileObj) {
              const base64_string = await file2Base64(file);
              const file_type = file.type;
              base64Files.push({
                ...file,
                base64_string,
                file_type,
              });
            } else {
              base64Files.push(file);
            }
          }

          return formProps.onFinish?.({
            ...base64Files[0],
            ...values,
          });
        }}
      >
        <Form.Item
          label="Partner"
          name="client_company_id"
          rules={[{ required: true }]}
        >
          <Select
            options={partnerOptions}
            showSearch={true}
            filterOption={FilterOption}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label="File">
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[{ required: true }]}
          >
            <Upload.Dragger
              name="file"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function transformPartnerRecords(
  records: PartnerRecord[] | undefined
): { label: string; value: number }[] {
  // unique to this use case, that's why it's in this file
  if (!records) {
    return [];
  }
  return records.map((node) => ({
    label: node.partner_company.CompanyName ?? "ERROR",
    value: node.partner_company_id ?? 0,
  }));
}
