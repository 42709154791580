import {
  AvatarGroup,
  CopyableText,
  CustomAvatar,
  ListTitleButton,
  Text,
} from "@/components";
import { APP_BASE_URL } from "@/providers";
import { Person, PublicOpportunity, ReferralPageInfo } from "@/types";
import { isNullOrZero } from "@/utilities";
import { generateColumns } from "@/utilities/public-form";
import { SwapOutlined } from "@ant-design/icons";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  UrlField,
  useTable,
} from "@refinedev/antd";
import { HttpError, useInvalidate, useList } from "@refinedev/core";
import { Space, Table, Tooltip } from "antd";
import { FC, PropsWithChildren } from "react";

export const ReferralPageListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<ReferralPageInfo, HttpError>({
    resource: "referral_pages",
    pagination: {
      mode: "off",
    },
  });

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = people?.data;

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={<ListTitleButton toPath="referrals" buttonText="Add new page" />}
      >
        <Table
          {...tableProps}
          rowKey="ID"
          expandable={{
            expandedRowRender: (record) => {
              return <ReferralTable record={record} />;
            },
            rowExpandable: () => {
              return true;
            },
          }}
        >
          <Table.Column<ReferralPageInfo>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  resource="referrals"
                  recordItemId={record.ID}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="referral_pages"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
          <Table.Column<ReferralPageInfo>
            dataIndex="name"
            title="Partner"
            render={(_, record) => {
              const hasPartner = !isNullOrZero(record?.partner_record_id);

              if (!hasPartner) {
                return <Space></Space>;
              }

              return (
                <Space>
                  <CustomAvatar
                    shape="square"
                    name={record.partner_record?.partner_company?.CompanyName}
                    src={record.partner_record?.partner_company?.PhotoUrl}
                  />
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.partner_record?.partner_company?.CompanyName}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<ReferralPageInfo>
            dataIndex="key"
            title="Key"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.key}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<ReferralPageInfo>
            dataIndex="subscribed_people_ids"
            title="Subscribed"
            render={(_, record) => {
              const avatars =
                record?.subscribed_people_ids?.map((person_id) => {
                  return {
                    name: peopleOptions?.find((item) => item.ID === person_id)
                      ?.name as string | undefined,
                    src: peopleOptions?.find((item) => item.ID === person_id)
                      ?.avatar_url as string | undefined,
                  };
                }) || [];

              return <AvatarGroup avatars={avatars} size={"small"} />;
            }}
          />
          <Table.Column<ReferralPageInfo>
            dataIndex="key"
            title="URL"
            render={(_, record) => {
              "referrals_authed";
              const subUrl = record.private ? "referrals_authed" : "referrals";
              const url = `${APP_BASE_URL}/${subUrl}/${record.key}`;
              return (
                <CopyableText
                  content={url}
                  renderedContent={<UrlField value={url} target="_blank" />}
                />
              );
            }}
          />
          <Table.Column<ReferralPageInfo>
            dataIndex="private"
            title="Only allow logged in user"
            render={(_, record) => {
              return <Text>{record.private ? "Yes" : "No"}</Text>;
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};

type Props = {
  record: ReferralPageInfo;
};

const ReferralTable: FC<Props> = ({ record }) => {
  const { tableProps } = useTable<PublicOpportunity, HttpError>({
    resource: `opportunities/referrals/${record.ID}`,
    pagination: {
      mode: "off",
    },
  });

  const invalidate = useInvalidate();

  if (!tableProps.loading && tableProps?.dataSource?.length == 0) {
    return <Text>No Referrals Yet</Text>;
  }

  const customFieldsColumns = generateColumns(tableProps?.dataSource);
  return (
    <List breadcrumb={false} title={<div />} headerButtons={<div />}>
      <Table
        {...tableProps}
        showHeader={true}
        rowKey="ID"
        pagination={false}
        columns={[
          {
            title: "",
            key: "actions",
            width: 80,

            render: (_, record) => (
              <Space>
                <Tooltip title="Delete">
                  <DeleteButton
                    hideText
                    size="small"
                    resource="public/opportunities"
                    recordItemId={record.ID}
                    onSuccess={() => {
                      invalidate({
                        invalidates: ["all"],
                        resource: `opportunities/referrals/${record.ID}`,
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Convert to Opportunity">
                  <ShowButton
                    icon={<SwapOutlined />}
                    hideText
                    size="small"
                    resource="referrals"
                    recordItemId={record.ID}
                  />
                </Tooltip>
              </Space>
            ),
          },
          ...customFieldsColumns,
        ]}
      ></Table>
    </List>
  );
};
