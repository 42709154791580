import { Person, Task, transformPersons } from "@/types";
import { disabledMinutes } from "@/utilities";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  HttpError,
  useInvalidate,
  useList,
  useNavigation,
} from "@refinedev/core";
import { Checkbox, DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import dayjs from "dayjs";

type Props = {
  isOverModal?: boolean;
};

export const TaskCreatePage = ({ isOverModal }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();

  const { formProps, modalProps, close } = useModalForm<Task, HttpError, Task>({
    action: "create",
    defaultVisible: true,
    resource: "tasks",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "tasks" });
      list("tasks", "replace");
    },
  });

  const { data: people } = useList<Person>({
    pagination: {
      mode: "off",
    },
    resource: "persons",
  });

  const peopleOptions = transformPersons(people?.data);

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list("tasks", "replace");
      }}
      title="Add new task"
      width={"80%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
            width: "50%",
          }}
          name="due_date"
          label="Due Date"
          required
          initialValue={dayjs().minute(0).second(0)}
        >
          <DatePicker
            showTime={{
              use12Hours: true,
              format: "hh:mm A",
              disabledMinutes: disabledMinutes,
              hideDisabledOptions: true,
            }}
            format="YYYY-MM-DD hh:mm A"
          />
        </Form.Item>

        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="assignee_ids"
          label="Assignees"
        >
          <Select
            mode="multiple"
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Assignees"
          />
        </Form.Item>

        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="subscribed_people_ids"
          label="Subscribed"
        >
          <Select
            mode="multiple"
            allowClear
            showSearch={true}
            options={peopleOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Subscribed People"
          />
        </Form.Item>
        <Form.Item
          style={{
            paddingBottom: 0,
            marginBottom: 10,
          }}
          name="automated_task"
          valuePropName="checked"
          label="AI Automated"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
