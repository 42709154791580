
export type TeamAssignmentType = 
	| "LEAD"
	| "MEMBER"
	
export const TeamAssignmentTypeOptions: {
	label: string;
	value: TeamAssignmentType;
}[] = [
		{ label: "Lead", value: "LEAD" },
		{ label: "Member", value: "MEMBER" },
	];
