import { DatePickerWrapper, SingleElementForm, Text } from "@/components";
import LoadingWrapper from "@/components/loading";
import "@/pages/partners/create-higlight-input.css";
import { useIdentity } from "@/providers/identity";
import { useOpportunityStatuses } from "@/providers/options";
import { usePeople } from "@/providers/people";
import {
  Opportunity,
  Tag,
  TagInfo,
  transformPersons,
  transformTags,
} from "@/types";
import {
  CaretDownOutlined,
  DollarOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { DateField, useForm } from "@refinedev/antd";
import {
  BaseKey,
  HttpError,
  useInvalidate,
  useList,
  useOne,
  useParsed,
} from "@refinedev/core";
import { Card, Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { FC, useState } from "react";
import { TagBasedCustomFieldsTable } from "../custom_fields";
import { ObjectNotes } from "../notes";
import { ObjectSyncStatusComponent } from "../sync";
import { isValidDate } from "@/utilities";

type Props = {
  isOverModal?: boolean;
  enableStatus?: boolean;
  resource?: string;
};

export const OpportunitiesPageView = ({
  enableStatus,
  resource = "opportunities",
}: Props) => {
  const { id } = useParsed();
  const api_resource = "opportunities";

  const { query, formLoading } = useForm<Opportunity, HttpError, Opportunity>({
    action: "edit",
    redirect: false,
    resource: api_resource,
    dataProviderName: "propelApi",
  });

  return (
    <LoadingWrapper loading={formLoading}>
      <div className="page-container">
        <Title name={query?.data?.data?.name} id={id} />
      </div>

      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col span={16}>
          <OpportunityInfoForm />
          <ObjectNotes
            reference_table="Opportunity"
            style={{
              marginTop: 32,
            }}
          />
        </Col>
        <Col span={8}>
          {/*<PartnerInfoForm formProps={formProps} queryResult={query} />*/}
          <TagBasedCustomFieldsTable
            taggable_object={"Opportunity"}
            field_reference_table={"Opportunity"}
            override_object_id={+id}
            style={{
              marginTop: 32,
            }}
            show_resource="opportunity-types"
          />
        </Col>
      </Row>
    </LoadingWrapper>
  );
};

type TitleProps = {
  name: string;
  id: BaseKey;
};
const Title: FC<TitleProps> = ({ name, id }) => {
  return (
    <Space direction="vertical" style={{ width: "100%", position: "relative" }}>
      <Form>
        <Space
          size={16}
          style={{
            width: "100%",
            paddingBottom: "24px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Text style={{ fontSize: "32px", fontWeight: 700 }}>{name}</Text>
        </Space>
      </Form>

      <ObjectSyncStatusComponent
        style={{ position: "absolute", right: 0, top: 5 }}
        reference_table="Opportunity"
        reference_id={id}
      />
    </Space>
  );
};

type InfoFormProps = {
  placeholder?: string;
};

export const OpportunityInfoForm: FC<InfoFormProps> = ({
  placeholder = "",
}) => {
  const { id: opportunityId } = useParsed();
  const me = useIdentity();
  const invalidate = useInvalidate();
  const statusOptions = useOpportunityStatuses();
  const [activeForm, setActiveForm] = useState<
    | "name"
    | "contract_value"
    | "close_date"
    | "expiry_notificaton_date"
    | "status"
    | "subscribed_ids"
    | "assignees_ids"
    | "tag"
    | "rejection_reason"
    | "approved"
  >();

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: "Opportunity",
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: "Opportunity",
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: opportunityId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);
  const getActiveForm = (args: {
    formName: keyof Opportunity | "tag" | "subscribed_ids" | "assignees_ids";
  }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }

    return "view";
  };

  const queryResult = useOne<Opportunity, HttpError>({
    resource: "opportunities",
    id: opportunityId,
  });

  const data = queryResult?.data?.data;
  const { people, loading: peopleLoading } = usePeople(data?.partner_record_id);
  const loading = queryResult?.isLoading || peopleLoading;

  const peopleOptions = transformPersons(loading ? [] : people);

  return (
    <Card
      title={
        <Space>
          <ShopOutlined className="sm" />
          <Text>Opportunity Info</Text>
        </Space>
      }
      styles={{
        header: {
          padding: "1rem",
        },
        body: {
          padding: "0",
        },
      }}
    >
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "name" })}
        itemProps={{
          name: "name",
          label: "Opportunity Name",
        }}
        view={<Text>{queryResult?.data?.data?.name}</Text>}
        editable={true}
        onClick={() => setActiveForm("name")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Input
          autoFocus
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "contract_value" })}
        itemProps={{
          name: "contract_value",
          label: "Contract Value",
        }}
        view={
          <Text>
            ${`${data?.contract_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        }
        editable={true}
        onClick={() => setActiveForm("contract_value")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <InputNumber
          min={0}
          addonBefore={<DollarOutlined />}
          placeholder="0,00"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "close_date" })}
        itemProps={{
          name: "close_date",
          label: "Close Date",
        }}
        view={<DateField format="YYYY-MM-DD" value={data?.close_date} />}
        editable={true}
        onClick={() => setActiveForm("close_date")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <DatePickerWrapper />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "expiry_notificaton_date" })}
        itemProps={{
          name: "expiry_notificaton_date",
          label: "Expiry Date",
        }}
        view={
          isValidDate(data?.expiry_notificaton_date) ? (
            <DateField
              format="YYYY-MM-DD"
              value={data?.expiry_notificaton_date}
            />
          ) : (
            <Text>-</Text>
          )
        }
        editable={true}
        onClick={() => setActiveForm("expiry_notificaton_date")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <DatePickerWrapper />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "status" })}
        itemProps={{
          name: "status",
          label: "Stage",
        }}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={data?.status}
            value={data?.status}
            options={statusOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={true}
        onClick={() => setActiveForm("status")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          autoFocus
          allowClear
          defaultValue={data?.status}
          options={statusOptions}
          style={{
            width: "100%",
          }}
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "subscribed_ids" })}
        itemProps={{
          name: "subscribed_ids",
          label: "Subscribed",
        }}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            mode="multiple"
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={data?.subscribed_ids}
            value={data?.subscribed_ids}
            options={peopleOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={true}
        onClick={() => setActiveForm("subscribed_ids")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          mode="multiple"
          autoFocus
          allowClear
          defaultValue={data?.subscribed_ids}
          options={peopleOptions}
          style={{
            width: "100%",
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "assignees_ids" })}
        itemProps={{
          name: "assignees_ids",
          label: "Assignees",
        }}
        view={
          <Select
            autoFocus={false}
            showSearch={false}
            mode="multiple"
            open={false}
            suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
            removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
            defaultValue={data?.assignees_ids}
            value={data?.assignees_ids}
            options={peopleOptions}
            style={{
              width: "100%",
              pointerEvents: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
          />
        }
        editable={true}
        onClick={() => setActiveForm("assignees_ids")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          mode="multiple"
          autoFocus
          allowClear
          defaultValue={data?.assignees_ids}
          options={peopleOptions}
          style={{
            width: "100%",
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </SingleElementForm>
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "approved" })}
        itemProps={{
          name: "approved",
          label: "Status",
        }}
        view={
          <Text>
            {queryResult?.data?.data?.approved ? "Approved" : "Rejected"}
          </Text>
        }
        editable={true}
        onClick={() => setActiveForm("approved")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Select
          value={queryResult?.data?.data?.approved}
          style={{
            width: "100%",
          }}
        >
          <Select.Option value={true}>Approved</Select.Option>
          <Select.Option value={false}>Rejected</Select.Option>
        </Select>
      </SingleElementForm>
      {queryResult?.data?.data?.approved === false && (
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          state={getActiveForm({ formName: "rejection_reason" })}
          itemProps={{
            name: "rejection_reason",
            label: "Rejection Reason",
          }}
          view={<Text>{queryResult?.data?.data?.rejection_reason}</Text>}
          editable={true}
          onClick={() => setActiveForm("rejection_reason")}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
        >
          <Input
            autoFocus
            style={{
              width: "100%",
            }}
          />
        </SingleElementForm>
      )}
      {tagOptions.length > 0 && (
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          state={getActiveForm({ formName: "tag" })}
          itemProps={{
            name: "tags",
            label: "Opportunity Type",
          }}
          view={
            <Select
              autoFocus={false}
              showSearch={false}
              open={false}
              mode="multiple"
              suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
              removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
              defaultValue={appliedTagValues}
              value={appliedTagValues}
              options={tagOptions}
              style={{
                width: "100%",
                pointerEvents: "none",
                boxShadow: "none",
                borderColor: "initial",
              }}
            />
          }
          onClick={() => setActiveForm("tag")}
          onUpdate={() => {
            invalidate({ invalidates: ["list"], resource: "generic_tags" });
            invalidate({ invalidates: ["list"], resource: "contracts" });
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
        >
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            defaultValue={appliedTagValues}
            options={tagOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              width: "100%",
            }}
          />
        </SingleElementForm>
      )}
    </Card>
  );
};
