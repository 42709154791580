import { SingleElementForm, Text } from "@/components";
import { Contact, Person, Tag, TagInfo, transformTags } from "@/types";
import { FilterOption } from "@/utilities/select";
import { CaretDownOutlined, ShopOutlined } from "@ant-design/icons";
import {
  GetOneResponse,
  useInvalidate,
  useList,
  useParsed,
} from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/react-query";
import { Card, Select, Space } from "antd";
import { FC, useState } from "react";

type Props = {
  queryResult: QueryObserverResult<GetOneResponse<Contact>> | undefined;
};

export const ContactInfoForm: FC<Props> = ({ queryResult }) => {
  const { id: partnerId } = useParsed();
  const invalidate = useInvalidate();
  const reference_table = "Contact";
  const [activeForm, setActiveForm] = useState<"email" | "tag">();

  const { data: tags } = useList<Tag>({
    resource: "tags",
    filters: [
      {
        field: "reference_table",
        operator: "eq",
        value: reference_table,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const { data: appliedTags } = useList<TagInfo>({
    resource: "generic_tags",
    filters: [
      {
        field: "taggable_type",
        operator: "eq",
        value: reference_table,
      },
      {
        field: "taggable_id",
        operator: "eq",
        value: partnerId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const tagOptions = transformTags(tags?.data);
  const appliedTagValues = appliedTags?.data.map((node) => node.tag_id);
  const data = queryResult?.data?.data;

  const getActiveForm = (args: {
    formName: keyof Contact | keyof Person | "tag";
  }) => {
    const { formName } = args;

    if (activeForm === formName) {
      return "form";
    }

    if (formName in ({} as Contact)) {
      if (!data?.[formName as keyof Contact]) {
        return "empty";
      }
    }
    if (formName in ({} as Person)) {
      if (!data?.person[formName as keyof Person]) {
        return "empty";
      }
    }
    return "view";
  };

  const loading = queryResult?.isLoading;

  return (
    <Card
      title={
        <Space size={15}>
          <ShopOutlined className="sm" />
          <Text>Contact info</Text>
        </Space>
      }
      headStyle={{
        padding: "1rem",
      }}
      styles={{
        body: { padding: "0" },
      }}
    >
      <SingleElementForm
        loading={loading}
        style={{
          padding: "0.5rem 1rem",
        }}
        state={getActiveForm({ formName: "email" })}
        itemProps={{
          name: "email",
          label: "email",
        }}
        view={
          <Space>
            <Text
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {data?.person.email.String}
            </Text>
          </Space>
        }
        editable={false}
        onClick={() => setActiveForm(undefined)}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      ></SingleElementForm>

      {tagOptions.length > 0 && (
        <SingleElementForm
          loading={loading}
          style={{
            padding: "0.5rem 1rem",
          }}
          state={getActiveForm({ formName: "tag" })}
          itemProps={{
            name: "tags",
            label: "Contact Type",
          }}
          view={
            <Select
              autoFocus={false}
              showSearch={false}
              open={false}
              mode="multiple"
              suffixIcon={<CaretDownOutlined style={{ display: "none" }} />}
              removeIcon={<CaretDownOutlined style={{ display: "none" }} />}
              defaultValue={appliedTagValues}
              value={appliedTagValues}
              options={tagOptions}
              style={{
                width: "100%",
                pointerEvents: "none",
                boxShadow: "none",
                borderColor: "initial",
              }}
            />
          }
          onClick={() => setActiveForm("tag")}
          onUpdate={() => {
            invalidate({ invalidates: ["list"], resource: "generic_tags" });
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
        >
          <Select
            autoFocus
            allowClear
            showSearch={true}
            mode="multiple"
            defaultValue={appliedTagValues}
            options={tagOptions}
            optionFilterProp="children"
            filterOption={FilterOption}
            style={{
              width: "100%",
            }}
          />
        </SingleElementForm>
      )}
    </Card>
  );
};
