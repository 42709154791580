import { Text } from "@/components";
import "@/pages/partners/create-higlight-input.css";
import { TagInfo } from "@/types";
import { LeftOutlined } from "@ant-design/icons";
import { DeleteButton, EditButton, useTable } from "@refinedev/antd";
import { HttpError, useInvalidate, useNavigation } from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import { useParams } from "react-router-dom";

type Props = {
  isOverModal?: boolean;
  parent_resources: string;
  reference_table: string;
  title?: string;
  underlying_resource: string;
};

export const CustomFieldsTypeShowPage = ({
  isOverModal,
  parent_resources,
  reference_table,
  title,
  underlying_resource,
}: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const params = useParams();

  const { tableProps } = useTable<TagInfo, HttpError>({
    resource: `tag_objects/${params.id}`,
    pagination: { mode: "off" },
  });

  const formattedTitle = title; //?? `Define Fields For ${data?.Name}`;
  return (
    <Modal
      mask={isOverModal}
      onCancel={() => {
        close();
        list(parent_resources, "replace");
      }}
      title={formattedTitle}
      width={"50%"}
      closeIcon={<LeftOutlined />}
      cancelText={""}
      footer={<div />}
      visible={true}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column<TagInfo>
          fixed="right"
          dataIndex="id"
          title=""
          width={40}
          render={(_, record) => (
            <Space>
              <DeleteButton
                hideText
                size="small"
                resource="generic_tags"
                recordItemId={record.id}
                onSuccess={() => {
                  invalidate({
                    invalidates: ["list"],
                    resource: `tag_objects/${params.id}`,
                  });
                }}
              />
              <EditButton
                hideText
                size="small"
                resource={underlying_resource}
                recordItemId={record.tag_id}
              />
            </Space>
          )}
        />
        <Table.Column<TagInfo>
          fixed="right"
          dataIndex="name"
          title="Name"
          render={(_, record) => (
            <Space>
              <Text
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {record.name}
              </Text>
            </Space>
          )}
        />
      </Table>
    </Modal>
  );
};
