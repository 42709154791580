import { ListTitleButton, Text } from "@/components";
import { FeatureFlag } from "@/types";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";

export const FeatureFlagsListPage: FC<PropsWithChildren> = ({ children }) => {
  const { tableProps } = useTable<FeatureFlag, HttpError>({
    resource: "feature_flags",
    pagination: {
      mode: "off",
    },
  });

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return <div />;
        }}
        title={
          <ListTitleButton
            toPath="feature_flags"
            buttonText="Add new Feature Flag"
          />
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<FeatureFlag>
            fixed="right"
            dataIndex="ID"
            title=""
            width={80}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  resource="feature_flags"
                  recordItemId={record.ID}
                />
                <DeleteButton
                  hideText
                  size="small"
                  resource="feature_flags"
                  recordItemId={record.ID}
                />
              </Space>
            )}
          />
          <Table.Column<FeatureFlag>
            dataIndex="feature_name"
            title="Feature Name"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.feature_name}
                  </Text>
                </Space>
              );
            }}
          />

          <Table.Column<FeatureFlag>
            dataIndex="enabled_globally"
            title="Enabled Globally"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.enabled_globally ? "True" : "False"}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<FeatureFlag>
            dataIndex="companies"
            title="Companies"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {record.companies
                      .map(
                        (company) =>
                          company.ID.toString() + ": " + company.CompanyName
                      )
                      .join("\n")}
                  </Text>
                </Space>
              );
            }}
          />
          <Table.Column<FeatureFlag>
            dataIndex="users"
            title="Users"
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {record.users
                      .map(
                        (user) => user.ID.toString() + ": " + user.person.name
                      )
                      .join("\n")}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
