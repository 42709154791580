import { CustomAvatar, Text } from "@/components";
import { useIdentity } from "@/providers/identity";
import { Note, User } from "@/types";
import { NativeObjectType } from "@/types/native_object_type";
import { LoadingOutlined, MessageOutlined } from "@ant-design/icons";
import { DeleteButton, useForm } from "@refinedev/antd";
import { HttpError, useInvalidate, useList, useParsed } from "@refinedev/core";
import { Button, Card, Form, Input, Space, Typography } from "antd";
import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";

type Props = {
  style?: React.CSSProperties;
  reference_table: NativeObjectType;
  me?: User;
};

export const ObjectNotes: FC<Props> = ({ style, reference_table }) => {
  const me = useIdentity();

  return (
    <Card
      styles={{
        body: {
          padding: "0",
        },
        header: {
          borderBottom: "1px solid #D9D9D9",
        },
      }}
      title={
        <Space size={16}>
          <MessageOutlined />
          <Text>Notes</Text>
        </Space>
      }
      style={style}
    >
      <ObjectNoteForm reference_table={reference_table} me={me} />
      <ObjectNoteList reference_table={reference_table} me={me} />
    </Card>
  );
};

const ObjectNoteForm: FC<Props> = ({ reference_table, me }) => {
  const { id: entityId } = useParsed();
  const { form, formProps, formLoading } = useForm<Note, HttpError, Note>({
    action: "create",
    resource: "notes",
    meta: {
      params: {
        reference_id: entityId,
        reference_table: reference_table,
      },
    },
    redirect: false,
    mutationMode: "optimistic",
    successNotification: () => ({
      key: `${reference_table}-note`,
      message: "Successfully added note",
      description: "Successful",
      type: "success",
    }),
    onMutationSuccess: () => {
      form?.resetFields();
    },
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "1rem",
        borderBottom: "1px solid #F0F0F0",
      }}
    >
      <CustomAvatar
        style={{ flexShrink: 0 }}
        name={me?.person.name}
        src={me?.person.avatar_url}
      />
      <Form {...formProps} style={{ width: "100%" }}>
        <Form.Item
          name="content"
          noStyle
          rules={[
            {
              required: true,
              transform(value) {
                return value?.trim();
              },
              message: "Please enter a note",
            },
          ]}
        >
          <Input
            placeholder="Add your note"
            addonAfter={formLoading && <LoadingOutlined />}
          />
        </Form.Item>

        {/* HACK - added hidden form fields to make these params passed through */}
        <Form.Item
          name="reference_id"
          initialValue={parseInt(entityId?.toString() || "0", 10)}
          hidden={true}
        ></Form.Item>
        <Form.Item
          name="reference_table"
          initialValue={reference_table}
          hidden={true}
        ></Form.Item>
        {/* END HACK */}
      </Form>
    </div>
  );
};

const ObjectNoteList: FC<Props> = ({ reference_table, me }) => {
  const params = useParams();
  const invalidate = useInvalidate();

  const { data: notes } = useList<Note>({
    resource: "notes",
    filters: [
      {
        field: "reference_id",
        operator: "eq",
        value: params.id,
      },
      {
        field: "reference_table",
        operator: "eq",
        value: reference_table,
      },
    ],
    hasPagination: false,
  });

  const { formProps, setId, id, saveButtonProps } = useForm<
    Note,
    HttpError,
    Note
  >({
    resource: "notes",
    action: "edit",
    queryOptions: {
      enabled: false,
    },
    mutationMode: "optimistic",
    onMutationSuccess: () => {
      setId(undefined);
      invalidate({
        invalidates: ["list"],
        resource: "notes",
      });
    },
    successNotification: () => ({
      key: `${reference_table}-update-note`,
      message: "Successfully updated note",
      description: "Successful",
      type: "success",
    }),
  });

  return (
    <Space
      size={16}
      direction="vertical"
      style={{
        borderRadius: "8px",
        padding: "1rem",
        width: "100%",
      }}
    >
      {notes?.data?.map((item) => {
        const isMe = me?.person_id === item.created_by_id;

        return (
          <div key={item.ID} style={{ display: "flex", gap: "12px" }}>
            <CustomAvatar
              style={{ flexShrink: 0 }}
              name={item.created_by?.person.name}
              src={item.created_by?.person.avatar_url}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontWeight: 500 }}>
                  {item.created_by?.person?.name}
                </Text>
                <Text size="xs">
                  {dayjs(item.CreatedAt).format("MMMM D, YYYY - h:mma")}
                </Text>
              </div>

              {isMe && id === item.ID ? (
                <Form {...formProps}>
                  <Form.Item
                    name="content"
                    initialValue={item.content}
                    rules={[
                      {
                        required: true,
                        transform(value) {
                          return value?.trim();
                        },
                        message: "Please enter a note",
                      },
                    ]}
                  >
                    <Input.TextArea
                      autoFocus
                      required
                      minLength={1}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Form>
              ) : (
                <Typography.Paragraph
                  style={{
                    borderRadius: "6px",
                    padding: "8px",
                    marginBottom: 0,
                  }}
                  ellipsis={{ rows: 3, expandable: true }}
                >
                  {item.content}
                </Typography.Paragraph>
              )}

              {isMe && !id && (
                <Space size={16}>
                  <Typography.Link
                    type="secondary"
                    style={{
                      fontSize: "12px",
                    }}
                    onClick={() => setId(item.ID)}
                  >
                    Edit
                  </Typography.Link>
                  <DeleteButton
                    resource="notes"
                    recordItemId={item.ID}
                    size="small"
                    type="link"
                    successNotification={() => ({
                      key: `${reference_table}-delete-note`,
                      message: "Successfully deleted note",
                      description: "Successful",
                      type: "success",
                    })}
                    icon={null}
                    className="ant-typography secondary"
                    style={{
                      fontSize: "12px",
                    }}
                  />
                </Space>
              )}

              {id === item.ID && (
                <Space>
                  <Button size="small" onClick={() => setId(undefined)}>
                    Cancel
                  </Button>
                  <Button size="small" type="primary" {...saveButtonProps}>
                    Save
                  </Button>
                </Space>
              )}
            </div>
          </div>
        );
      })}
    </Space>
  );
};
