import { ListTitleButton, Text } from "@/components";
import { Tag } from "@/types";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Grid, Space, Table } from "antd";
import { FC, PropsWithChildren } from "react";

type Props = {
  override_title?: string;
  reference_table: string;
  create_resources: string;
};

export const CustomFieldsTypeListPage: FC<PropsWithChildren & Props> = ({
  children,
  override_title,
  reference_table,
  create_resources,
}) => {
  const screens = Grid.useBreakpoint();

  const { tableProps } = useTable<Tag, HttpError, { name: string }>({
    resource: "tags",
    permanentFilter: [
      {
        field: "reference_table",
        operator: "eq",
        value: reference_table,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  if (!tableProps.loading) {
    const customItem: Tag = {
      Name: "Default Fields",
    };
    tableProps.dataSource = [customItem, ...tableProps.dataSource];
  }

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={() => {
          return (
            <Space
              style={{
                marginTop: screens.xs ? "1.6rem" : undefined,
              }}
            ></Space>
          );
        }}
        title={
          <ListTitleButton
            toPath={create_resources}
            buttonText={`Add New ${override_title ?? reference_table} Type`}
          />
        }
      >
        <Table {...tableProps} rowKey="ID">
          <Table.Column<Tag>
            fixed="right"
            dataIndex="id"
            title=""
            width={120}
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.ID ?? "0"}
                />
                {record.ID && (
                  <ShowButton
                    hideText
                    size="small"
                    recordItemId={record.ID ?? "0"}
                  />
                )}
                {record.ID && (
                  <DeleteButton
                    hideText
                    size="small"
                    resource="tags"
                    recordItemId={record.ID}
                    errorNotification={() => ({
                      key: `${reference_table}-delete-tag`,
                      message:
                        "We could not delete the type, there are records linked to it.",
                      type: "error",
                    })}
                  />
                )}
              </Space>
            )}
          />

          <Table.Column<Tag>
            dataIndex="Name"
            title={`${override_title ?? reference_table} Type`}
            render={(_, record) => {
              return (
                <Space>
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {record.Name}
                  </Text>
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {children}
    </div>
  );
};
