import { PlusCircleOutlined } from "@ant-design/icons";
import { useGo, useNavigation } from "@refinedev/core";
import { Button, Grid } from "antd";
import { FC, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";

interface ListTitleButtonProps {
  toPath: string;
  buttonText: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const ListTitleButton: FC<ListTitleButtonProps> = ({
  buttonText,
  toPath,
  onClick,
}) => {
  const go = useGo();
  const { pathname } = useLocation();
  const { createUrl } = useNavigation();
  const screens = Grid.useBreakpoint();
  const fn = onClick
    ? onClick
    : () => {
        return go({
          to: `${createUrl(toPath)}`,
          query: {
            to: pathname,
          },
          options: {
            keepQuery: true,
          },
          type: "push",
        });
      };
  return (
    <Button type="primary" icon={<PlusCircleOutlined />} onClick={fn}>
      {!screens.xs ? buttonText : null}
    </Button>
  );
};
