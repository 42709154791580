import { CopyableText, CustomAvatar, Text } from "@/components";
import { PermissionsRole, Person } from "@/types";
import { isNullorEmpty } from "@/utilities/strings";
import { getTablePropsWithPagination } from "@/utilities/tables";
import {
  DeleteOutlined,
  MailOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { Identity as User } from "@/types";
import {
  DeleteButton,
  EditButton,
  SaveButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useCreateMany,
  useInvalidate,
  useList,
  useNotification,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import debounce from "lodash/debounce";
import { FC, PropsWithChildren, useMemo } from "react";

export const TeamListPage: FC<PropsWithChildren> = ({ children }) => {
  const { mutateAsync } = useCreateMany<Person, HttpError, Person>();
  const { mutateAsync: createUser } = useCreate<User>();
  const { mutateAsync: updateAsync } = useUpdate();
  const invalidate = useInvalidate();
  const { open } = useNotification();

  const { tableProps, tableQuery, searchFormProps } = useTable<
    Person,
    HttpError,
    { name: string }
  >({
    resource: "persons",
    hasPagination: false,
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "include_users",
          operator: "eq",
          value: true,
        },
      ],
    },
    onSearch: (values) => {
      return [
        {
          field: "name",
          operator: "contains",
          value: values.name,
        },
      ];
    },
  });

  const hasData = tableProps.loading
    ? true
    : (tableProps?.dataSource?.length || 0) > 0;

  const [form] = Form.useForm<Person>();
  const people = Form.useWatch("people", form);
  const { hasNewPeople } = useMemo(() => {
    const hasNewPeople = people?.length > 0;

    return {
      hasNewPeople,
    };
  }, [people]);

  const handleOnFinish = async (args) => {
    form.validateFields();
    const people: Person[] = args.people.map((person) => ({
      email: {
        String: person.email,
        Valid: true,
      },
      name: person.name,
    }));

    await mutateAsync({
      resource: "persons",
      values: people,
      successNotification: false,
    });

    form.resetFields();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFormProps?.onFinish?.({
      name: e.target.value ?? "",
    });
  };
  const debouncedOnChange = debounce(onSearch, 500);

  const handleAddUser = async (record: Person) => {
    if (isNullorEmpty(record.email?.String)) {
      open?.({
        type: "error",
        message: "Can't create account without an email",
      });

      return;
    }

    await createUser({
      resource: "users",
      values: {
        person_id: record.ID,
      },
      successNotification: () => {
        return {
          key: "users",
          type: "success",
          message: "Successfully Created User",
          description: "Successful",
        };
      },
    });

    invalidate({
      invalidates: ["all"],
      resource: "persons",
    });
  };

  const { data: statuses } = useList<PermissionsRole>({
    pagination: {
      mode: "off",
    },
    resource: "permissions/role",
  });
  const roleOptions = transformRoles(statuses?.data);

  const handleStatusChange = (userId: number) => async (newValues: number) => {
    await updateAsync({
      resource: "users",
      values: {
        roles: [newValues],
      },
      id: userId,
    });

    invalidate({
      invalidates: ["all"],
      resource: "persons",
    });
  };

  return (
    <div className="page-container">
      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col span={24}>
          <Card
            styles={{
              header: {
                borderBottom: "1px solid #D9D9D9",
                marginBottom: "1px",
              },
              body: { padding: 0 },
            }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "2px",
                }}
              >
                <div>
                  <TeamOutlined />
                  <Text>Team Members</Text>
                </div>
                {hasData && (
                  <Form {...searchFormProps} layout="inline">
                    <Form.Item name="name" noStyle>
                      <Input
                        prefix={<SearchOutlined />}
                        suffix={
                          <Spin size="small" spinning={tableQuery.isFetching} />
                        }
                        placeholder="Search by name"
                        onChange={debouncedOnChange}
                      />
                    </Form.Item>
                  </Form>
                )}
              </div>
            }
          >
            {!hasData && (
              <div
                style={{
                  padding: 16,
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <Text>No one on the team yet</Text>
              </div>
            )}
            {hasData && (
              <Table
                {...getTablePropsWithPagination(tableProps, "")}
                rowKey="ID"
              >
                <Table.Column<Person>
                  title="Name"
                  render={(_, record) => {
                    return (
                      <Space>
                        <CustomAvatar
                          name={record?.name}
                          src={record?.avatar_url}
                        />
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {record?.name}
                        </Text>
                      </Space>
                    );
                  }}
                />
                <Table.Column<Person>
                  title="E-mail"
                  render={(_, record) => {
                    return <CopyableText content={record?.email.String} />;
                  }}
                />

                <Table.Column<Person>
                  dataIndex="ID"
                  width={40 * 4}
                  render={(_, record) => {
                    return (
                      <Space>
                        <Tooltip title="Send Email">
                          <Button
                            size="small"
                            href={`mailto:${record?.email.String}`}
                            icon={<MailOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Edit User">
                          <EditButton
                            hideText
                            size="small"
                            recordItemId={record.ID}
                          />
                        </Tooltip>
                        {!record.user && (
                          <Tooltip title="Delete User">
                            <DeleteButton
                              hideText
                              size="small"
                              resource="persons"
                              recordItemId={record.ID}
                            />
                          </Tooltip>
                        )}

                        {!record.user && (
                          <Tooltip
                            title={"Provision User Account".concat(
                              isNullorEmpty(record.email?.String)
                                ? " - An Email is Required to Create the User"
                                : ""
                            )}
                          >
                            <Button
                              size="small"
                              onClick={() => handleAddUser(record)}
                              icon={<UserAddOutlined />}
                            />
                          </Tooltip>
                        )}
                      </Space>
                    );
                  }}
                />
                <Table.Column<Person>
                  dataIndex="roles"
                  title="Roles"
                  width={300}
                  render={(_, record) => {
                    return (
                      record.user && (
                        <Select
                          options={roleOptions}
                          value={record.user?.roles?.[0]?.ID ?? null}
                          style={{ width: "100%" }}
                          onChange={handleStatusChange(record.user.ID)}
                        />
                      )
                    );
                  }}
                />
              </Table>
            )}

            <Form form={form} onFinish={handleOnFinish}>
              <Form.List name="people">
                {(fields, { add, remove }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start ",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "4px 0px 16px",
                      }}
                    >
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <Row
                            key={key}
                            gutter={12}
                            align="top"
                            style={{
                              paddingLeft: "16px",
                              width: "100%",
                            }}
                          >
                            <Col span={11}>
                              <Form.Item
                                {...restField}
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter contact name",
                                  },
                                ]}
                                name={[name, "name"]}
                              >
                                <Input
                                  addonBefore={<UserOutlined />}
                                  placeholder="Contact name"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                required
                                style={{
                                  marginBottom: 0,
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter an e-mail",
                                  },
                                  {
                                    type: "email",
                                    message: "The input is not valid e-mail",
                                  },
                                ]}
                                name={[name, "email"]}
                              >
                                <Input
                                  addonBefore={<MailOutlined />}
                                  placeholder="Contact email"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={() => add()}
                      >
                        Add new team member
                      </Button>
                    </div>
                  );
                }}
              </Form.List>
              {hasNewPeople && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "8px",
                    padding: "16px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <Button
                    size="large"
                    type="default"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    size="large"
                    icon={undefined}
                    onClick={() => form.submit()}
                  />
                </div>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
      {children}
    </div>
  );
};

function transformRoles(
  roles: PermissionsRole[] | undefined
): { label: string; value: number }[] {
  if (!roles) {
    return [];
  }
  return roles.map((node) => ({
    label: node.name ?? "ERROR",
    value: node.ID ?? 0,
  }));
}
