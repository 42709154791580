import React, { useCallback, useMemo } from "react";
import { UrlField } from "@refinedev/antd";

interface Option {
  label: string | React.ReactNode;
  value: string | number;
}

const SELECT_ALL_OPTION: Option = {
  label: <UrlField value="Select All" />,
  value: "_SELECT_ALL_OPTION",
};

export function useSelectAllOption(
  options: Option[]
): [(value: Option["value"][]) => void, Option[]] {
  const optionsWithAllOption = useMemo<Option[]>(
    () => [SELECT_ALL_OPTION, ...options],
    [options]
  );
  /** pass this to Form.Item's getValueFromEvent prop */
  const getValueFromEvent = useCallback(
    (value: Option["value"][]) => {
      const selectAllSelected = value.includes("_SELECT_ALL_OPTION");
      return selectAllSelected ? options.map((opt) => opt.value) : value;
    },
    [options]
  );

  return [getValueFromEvent, optionsWithAllOption];
}
