import "@/pages/partners/create-higlight-input.css";
import { LeftOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useInvalidate, useNavigation, useParsed } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { Person } from "@/types";

type Props = {
  isOverModal?: boolean;
  return_resource: string;
};

export const EditPersonPage = ({ isOverModal, return_resource }: Props) => {
  const { list } = useNavigation();
  const invalidate = useInvalidate();
  const { id } = useParsed();
  const resource = "team";
  const { formProps, modalProps, close } = useModalForm<Person>({
    action: "edit",
    defaultVisible: true,
    resource: "persons",
    id: id,
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: resource });
      list(resource, "replace");
    },
    redirect: false,
    syncWithLocation: { key: resource },
    successNotification: () => {
      return {
        key: resource,
        type: "success",
        message: "Successfully updated",
        description: "Successful",
      };
    },
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        list(return_resource, "replace");
      }}
      title="Edit Team Member"
      width={"50%"}
      closeIcon={<LeftOutlined />}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={["email", "String"]}
          rules={[
            {
              required: true,
              message: "Please Enter an e-mail",
            },
            {
              type: "email",
              message: "The input is not valid e-mail",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
