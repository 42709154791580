import { Text } from "@/components";
import { PublicOpportunity } from "@/types";
import { generateColumns } from "@/utilities/public-form";
import { SwapOutlined, CloudSyncOutlined, EyeTwoTone } from "@ant-design/icons";
import { DateField, DeleteButton, useTable } from "@refinedev/antd";
import { HttpError, useGo, useInvalidate } from "@refinedev/core";
import { Button, Empty, Space, Table, Tooltip } from "antd";
import { FC } from "react";
import { useCreateLead } from "@/pages/billing/useCreateLead";

export const PublicOpportunitiesTable: FC = () => {
  const invalidate = useInvalidate();
  const go = useGo();

  const { tableProps } = useTable<
    PublicOpportunity,
    HttpError,
    { name: string }
  >({
    resource: "public_opportunities",
    pagination: {
      mode: "server",
    },
    filters: {
      mode: "server",
    },
    syncWithLocation: false,
  });
  const customFieldsColumns = generateColumns(tableProps?.dataSource);

  const { handleCreateLead } = useCreateLead();
  return (
    <Table
      {...tableProps}
      showHeader={tableProps?.dataSource?.length > 0}
      rowKey="ID"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description="No pending opportunities found"
          />
        ),
      }}
      pagination={false}
      columns={[
        {
          title: "",
          key: "actions",
          width: 80,

          render: (_, record) => (
            <Space>
              <Tooltip title="Delete">
                <DeleteButton
                  hideText
                  size="small"
                  resource="public/opportunities"
                  recordItemId={record.ID}
                  onSuccess={() => {
                    invalidate({
                      invalidates: ["all"],
                      resource: `opportunities/referrals/${record.ID}`,
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title="Convert to Opportunity">
                <Button
                  icon={<SwapOutlined />}
                  size="small"
                  onClick={() => {
                    go({
                      to: `/commissions/opportunities/convert/${record.ID}`,
                      type: "push",
                    });
                  }}
                />
              </Tooltip>
              {!record.lead_external_link && (
                <Tooltip title="Create Lead in Salesforce">
                  <Button
                    icon={<CloudSyncOutlined />}
                    size="small"
                    onClick={() => handleCreateLead(record.ID)}
                  />
                </Tooltip>
              )}
              {record.lead_external_link && (
                <Tooltip title="View Lead in Salesforce">
                  <Button
                    icon={<EyeTwoTone />}
                    size="small"
                    onClick={() => {
                      window.open(
                        record.lead_external_link,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          ),
        },
        {
          title: "Referral Page",
          key: "referral_page",
          width: 80,

          render: (_, record) => (
            <Text style={{ whiteSpace: "nowrap" }}>
              {record.referral_page.key}
            </Text>
          ),
        },
        {
          dataIndex: "CreatedAt",
          title: "Submit Date",
          width: 300,
          render: (_, record) => (
            <Space
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <DateField format="LLL" value={record.CreatedAt} />
            </Space>
          ),
        },

        ...customFieldsColumns,
      ]}
    ></Table>
  );
};
