import { Modal, Select, message } from "antd";
import { useUpdate, useInvalidate, useCreate, useOne } from "@refinedev/core";
import { useState } from "react";

interface AccountResponse {
  hasAccount: boolean;
  accountId: string;
  accountURL: string;
  contactURL: string;
}

interface UseSalesforceConversionProps {
  setLoadingMap?: (
    fn: (prev: Record<number, boolean>) => Record<number, boolean>
  ) => void;
  onSuccess?: () => void;
  mode: "opportunities" | "referrals";
}

export const useSalesforceConversion = ({
  setLoadingMap,
  onSuccess,
  mode,
}: UseSalesforceConversionProps) => {
  const invalidate = useInvalidate();
  const { mutateAsync: convertOpportunity } = useUpdate({
    resource: `opportunities_convert_external`,
    successNotification: false,
  });
  const { mutateAsync: convertReferral } = useCreate({
    resource: `referral_pages/convert_from_opportunity`,
    successNotification: false,
  });

  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  );
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );

  const { refetch: fetchContactDetails } = useOne<AccountResponse>({
    resource: "opportunities/contact",
    id: selectedContactId || "",
    queryOptions: {
      enabled: false,
    },
  });

  const handleCreateRecord = async (
    opportunityId: number,
    additionalData?: any
  ) => {
    if (setLoadingMap) {
      setLoadingMap((prev) => ({ ...prev, [opportunityId]: true }));
    }

    try {
      let response;
      if (mode === "opportunities") {
        response = await convertOpportunity({ id: opportunityId, values: {} });
      } else {
        response = await convertReferral({
          values: {
            opportunity_id: opportunityId,
            ...additionalData,
          },
        });
      }
      const data = response.data;

      if (
        data.status === "DUPLICATE_CONTACTS" ||
        data.status === "DUPLICATE_ACCOUNTS"
      ) {
        if (mode === "referrals") {
          opportunityId = data.dealId;
        }
        if (data.status === "DUPLICATE_CONTACTS") {
          showDuplicateContactModal(data, opportunityId);
        } else if (data.status === "DUPLICATE_ACCOUNTS") {
          const contactId = data.contactId;
          showDuplicateAccountModal(data, contactId, opportunityId);
        }
      } else {
        if (onSuccess) {
          onSuccess();
        } else {
          invalidateQueries();
        }
      }
    } catch (error) {
      console.error(`Error while creating record: ${error}`);
      Modal.error({
        title: "Error",
        content: "Error while creating record",
      });
    } finally {
      if (setLoadingMap) {
        setLoadingMap((prev) => ({ ...prev, [opportunityId]: false }));
      }
    }
  };

  const showDuplicateContactModal = (
    duplicateData: any,
    opportunityId: number
  ) => {
    let tempSelectedContactId: string | null = null;
    const modal = Modal.confirm({
      title: "Duplicate Contact Found",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Select a contact"
          onChange={(value) => {
            tempSelectedContactId = value as string;
            setSelectedContactId(value as string);
          }}
          optionLabelProp="label"
        >
          {duplicateData.duplicateContacts.map(
            (contact: { id: string; url: string; name: string }) => (
              <Select.Option
                key={contact.id}
                value={contact.id}
                label={contact.name}
              >
                <a href={contact.url} target="_blank" rel="noopener noreferrer">
                  {contact.name}
                </a>
              </Select.Option>
            )
          )}
        </Select>
      ),
      okText: "Use Selected Contact",
      cancelText: "Cancel",
      onOk: async () => {
        if (!tempSelectedContactId && !selectedContactId) {
          message.error("Please select a contact before proceeding.");
          return Promise.reject();
        }

        const contactIdToUse = tempSelectedContactId || selectedContactId;
        modal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });

        try {
          await handleDuplicateContactSelection(
            contactIdToUse!,
            opportunityId!
          );
        } catch (error) {
          modal.update({
            okButtonProps: { loading: false },
            cancelButtonProps: { disabled: false },
          });
          return Promise.reject(error);
        }
      },
    });
  };

  const showDuplicateAccountModal = (
    duplicateData: any,
    contactId: string,
    opportunityId: number
  ) => {
    let tempSelectedAccountId: string | null = null;
    const modal = Modal.confirm({
      title: "Duplicate Account Found",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Select an account"
          onChange={(value) => {
            tempSelectedAccountId = value as string;
            setSelectedAccountId(value as string);
          }}
          optionLabelProp="label"
        >
          {duplicateData.duplicateAccounts.map(
            (account: { id: string; url: string; name: string }) => (
              <Select.Option
                key={account.id}
                value={account.id}
                label={account.name}
              >
                <a href={account.url} target="_blank" rel="noopener noreferrer">
                  {account.name}
                </a>
              </Select.Option>
            )
          )}
        </Select>
      ),
      okText: "Use Selected Account",
      cancelText: "Create New Account",
      onOk: async () => {
        if (!selectedAccountId && !tempSelectedAccountId) {
          message.error("Please select an account before proceeding.");
          return Promise.reject();
        }

        const accountIdToUse = tempSelectedAccountId || selectedAccountId;
        try {
          await handleDuplicateAccountSelection(
            accountIdToUse!,
            contactId!,
            opportunityId!
          );
        } catch (error) {
          return Promise.reject(error);
        }
      },
      onCancel: async () => {
        try {
          await completeConversion(opportunityId, contactId, undefined, true);
        } catch (error) {
          return Promise.reject(error);
        }
      },
    });
  };

  const handleDuplicateAccountSelection = async (
    accountId: string,
    contactId: string,
    opportunityId: number
  ) => {
    try {
      await completeConversion(opportunityId, contactId, accountId);
    } catch (error) {
      console.error(`Error while checking linked account: ${error}`);
      message.error("Error while processing account selection");
      throw error;
    }
  };

  const handleDuplicateContactSelection = async (
    contactId: string,
    opportunityId: number
  ) => {
    try {
      const accountResponse = await fetchContactDetails();
      const accountData = accountResponse.data?.data;

      if (accountData?.hasAccount) {
        showAccountDecisionModal(
          contactId,
          accountData.accountId,
          accountData.accountURL,
          accountData.contactURL,
          opportunityId
        );
      } else {
        await completeConversion(opportunityId, contactId);
      }
    } catch (error) {
      console.error(`Error while checking linked account: ${error}`);
      message.error("Error while processing contact selection");
      throw error;
    }
  };

  const showAccountDecisionModal = (
    contactId: string,
    accountId: string,
    accountURL: string,
    contactURL: string,
    opportunityId: number
  ) => {
    Modal.confirm({
      title: "Existing Account Found",
      content: (
        <div>
          <p>This contact is linked to an existing account.</p>
          <p>
            <a href={accountURL} target="_blank" rel="noopener noreferrer">
              View Account in Salesforce
            </a>
          </p>
          <p>
            <a href={contactURL} target="_blank" rel="noopener noreferrer">
              View Contact in Salesforce
            </a>
          </p>
          <p>Do you want to use this account or create a new one?</p>
        </div>
      ),
      okText: "Use Existing Account",
      cancelText: "Create New Account",
      onOk: () => completeConversion(opportunityId, contactId, accountId),
      onCancel: () => completeConversion(opportunityId, contactId),
    });
  };

  const completeConversion = async (
    opportunityId: number,
    contactId: string,
    accountId?: string,
    shouldCreateNewAccount?: boolean
  ) => {
    try {
      const response = await convertOpportunity({
        id: opportunityId,
        values: {
          selectedContact: contactId,
          accountId: accountId || "",
          shouldCreateNewAccount: shouldCreateNewAccount || false,
        },
      });
      const data = response.data;
      if (data.status === "DUPLICATE_ACCOUNTS") {
        const contactId = data.contactId;
        showDuplicateAccountModal(data, contactId, opportunityId);
      } else {
        Modal.success({
          title: "Success",
          content: "Record created successfully",
          onOk() {
            if (onSuccess) onSuccess();
          },
        });
      }
    } catch (error) {
      console.error(`Error while completing conversion: ${error}`);
      Modal.error({
        title: "Error",
        content: "Error while creating record",
      });
    }
  };

  const invalidateQueries = () => {
    invalidate({
      invalidates: ["all"],
      resource: "opportunities",
    });
    invalidate({
      invalidates: ["all"],
      resource: "opportunities_view_as_company",
    });
  };

  return {
    handleCreateRecord,
    showDuplicateContactModal,
    handleDuplicateContactSelection,
    showAccountDecisionModal,
    completeConversion,
    invalidateQueries,
  };
};
