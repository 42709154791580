import { Text } from "@/components";
import { API_BASE_URL } from "@/providers";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import { DataCatalogItem } from "@/types/responses";
import { CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Nango from "@nangohq/frontend";
import { List as AntdList, useTable } from "@refinedev/antd";
import { HttpError, useInvalidate } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Image,
  List,
  Menu,
  MenuProps,
  Row,
  TableProps,
  theme,
} from "antd";
import { FC, useMemo, useState } from "react";
const { useToken } = theme;

const nango = new Nango({ publicKey: "2d944b00-f98b-4c3c-b6fb-4b61c454e09c" });

export const CatalogPage = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>("all");

  const { tableProps, setCurrent, setPageSize, setFilters } = useTable<
    DataCatalogItem,
    HttpError
  >({
    resource: "connections/catalog",
    dataProviderName: "propelApi",
    hasPagination: false,
    syncWithLocation: false,
  });

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const handleMenuClick = (e: { key: string }) => {
    const newSelectedMenu = e.key;
    setSelectedMenu(newSelectedMenu);

    // Update the filters when a menu item is selected
    if (newSelectedMenu === "All") {
      setFilters([{ field: "type", operator: "eq", value: "" }]);
    } else {
      setFilters([
        {
          field: "type",
          operator: "eq",
          value: newSelectedMenu,
        },
      ]);
    }
  };

  const items: MenuItem[] = [
    getItem("All", "All"),
    getItem("CRM", "CRM"),
    getItem("Support", "Support"),
    getItem("Productivity", "Productivity"),
    getItem("Sales Intelligence", "Sales Intelligence"),
  ];

  return (
    <div className="page-container">
      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col
          xs={{ span: 4 }}
          md={{ span: 4 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
        >
          <Text
            strong
            size="xl"
            style={{
              marginTop: "12px",
            }}
          >
            Catalog
          </Text>
          <Menu
            mode="inline"
            style={{
              marginTop: "36px",
            }}
            items={items}
            onClick={handleMenuClick}
            selectedKeys={[selectedMenu]}
          />
        </Col>

        <Col
          xs={{ span: 20 }}
          md={{ span: 20 }}
          lg={{ span: 20 }}
          xl={{ span: 20 }}
        >
          <AntdList
            breadcrumb={false}
            contentProps={{
              style: {
                marginTop: "28px",
              },
            }}
            title={""}
          >
            <DataCatalogCardView
              tableProps={tableProps}
              setPageSize={setPageSize}
              setCurrent={setCurrent}
            />
          </AntdList>
        </Col>
      </Row>
    </div>
  );
};

type Props = {
  tableProps: TableProps<DataCatalogItem>;
  setCurrent: (current: number) => void;
  setPageSize: (pageSize: number) => void;
};

export const DataCatalogCardView: FC<Props> = ({
  tableProps: { dataSource, loading },
}) => {
  const data = useMemo(() => {
    return [...(dataSource || [])];
  }, [dataSource]);

  return (
    <div>
      <List
        grid={{
          gutter: 32,
          column: 4,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 4,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <DataCatalogCard catalogItem={item} />
          </List.Item>
        )}
      ></List>
    </div>
  );
};

type DataCatalogCardProps = {
  catalogItem: DataCatalogItem | null;
};

export const DataCatalogCard: FC<DataCatalogCardProps> = ({ catalogItem }) => {
  const invalidate = useInvalidate();
  const { token } = useToken();

  if (!catalogItem) return <div />;

  return (
    <Card
      size="small"
      actions={[
        <div>
          {catalogItem.connection_state === "CONNECTED" ? (
            <Button
              style={{
                backgroundColor: token.colorSuccess,
                color: token.colorText,
                borderColor: token.colorSuccess,
              }}
              disabled={true}
            >
              <CheckCircleOutlined style={{ marginRight: 8 }} />
              Connected
            </Button>
          ) : (
            <Button
              disabled={catalogItem.connection_state === "DISABLED"}
              onClick={() => {
                nango
                  .auth(
                    catalogItem.provider_config_key,
                    catalogItem.connection_id
                  )
                  .then(
                    (result: {
                      providerConfigKey: string;
                      connectionId: string;
                    }) => {
                      const payload = {
                        provider_config_key: result.providerConfigKey,
                        connection_id: result.connectionId,
                      };
                      globalAxiosInstance
                        .post(`${API_BASE_URL}/data_connections`, payload)
                        .then((response) => {
                          console.log(response);
                          invalidate({
                            invalidates: ["all"],
                            resource: "connections/catalog",
                          });
                          invalidate({
                            invalidates: ["all"],
                            resource: "catalog",
                          });
                        });
                    }
                  )
                  .catch((err: { message: string; type: string }) => {
                    // TODO handle error
                  });
              }}
            >
              <PlusCircleOutlined />
              Connect
            </Button>
          )}
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Image
          style={{
            width: "128px",
            height: "64px",
            objectFit: "contain",
          }}
          src={catalogItem.icon_url}
          preview={false}
        />
        <Text
          strong
          size="md"
          ellipsis={{ tooltip: catalogItem.name }}
          style={{
            marginTop: "12px",
          }}
        >
          {catalogItem.name}
        </Text>
        <Text
          size="sm"
          style={{
            marginTop: "12px",
          }}
        >
          {catalogItem.type}
        </Text>
      </div>
    </Card>
  );
};
