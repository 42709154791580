import { DatePicker } from "antd";
import dayjs from "dayjs";

export const DatePickerWrapper = ({ format = "YYYY-MM-DD", ...props }) => {
  let convertedValue = props.value
    ? dayjs(props.value, format)
    : dayjs().hour(0).minute(0).second(0);

  if (convertedValue.year() === 1901) {
    convertedValue = dayjs().hour(0).minute(0).second(0);
  }
  props.value = convertedValue;

  return (
    <DatePicker defaultValue={convertedValue} showTime={false} {...props} />
  );
};
